import { Box, styled } from '@mui/material';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';
import { Breadcrumb, SimpleCard } from 'app/components';
import ControlledExpansionPanels from './ControlledAccordion';
import CustomizedExpansionPanels from './CustomizedExpansionPanel';
import DetailedExpansionPanel from './DetailedExpansionPanel';

import SimpleExpansionPanel from './SimpleExpansionPanel';

const Container = styled('div')(({ theme }) => ({
  // margin: '30px',
  // [theme.breakpoints.down('sm')]: { margin: '16px' },
  // '& .breadcrumb': {
  //   marginBottom: '30px',
  //   [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  // }
}));

const AppExpansionPanel = () => {
  return (
    <Grid item md="12">
      <DetailedExpansionPanel />
    </Grid>
  );
};

export default AppExpansionPanel;
