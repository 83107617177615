import React, { Component } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Autocomplete,
  Chip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Postie from 'app/helpers/postie';
import AppExpansionPanel from '../expansion-panel/AppExpansionPanel';
import config from '../../constants/index.jsx';
import displayer from 'app/helpers/displayer';
import Auth from 'app/helpers/authorization';
import { json } from 'react-router-dom/dist';
import LeadView from './components/LeadView';
import staticData from 'app/helpers/staticdata';

class PresalesLeads extends Component {
  constructor(props) {
    super(props);
    // Set default start date to January 1, 2023
    const StartDate = '2023-01-01';

    // Get the current date in the format YYYY-MM-DD
    const currentDate = new Date().toISOString().split('T')[0];
    this.state = {
      data: '',
      isAddLeadDialogOpen: false,
      clientData: {
        name: '',
        phone: '',
        email: '',
        address: ''
      },
      validationErrors: {},
      filters: {
        start_date: StartDate,
        end_date: currentDate,
        active: 1,
        page: 1
      }
    };
  }

  handleFilterChange = (field, e) => {
    this.setState({ filters: { ...this.state.filters, [field]: e.target.value } }, function () {
      //console.log('filters', this.state.filters);
      this.loadData();
    });
  };
  onTagsChange = (newvalue) => {
    let e = { target: { name: 'team', value: newvalue } };
    this.handleFilterChange('team', e);
  };

  addNewLead = () => {
    this.setState({ isAddLeadDialogOpen: true, validationErrors: {} });
  };

  closeAddLeadDialog = () => {
    this.setState({ isAddLeadDialogOpen: false });
  };

  handleClientDetails = (e) => {
    const { name, value } = e.target;
    this.setState(
      (prevState) => ({
        clientData: {
          ...prevState.clientData,
          [name]: value
        }
      }),
      () => {
        //console.log('Updated clientData:', this.state.clientData); // Log the updated clientData
      }
    );
  };

  validateForm = () => {
    const { name, phone, email, address } = this.state.clientData;
    const errors = {};

    if (!name) {
      errors.name = 'Name is required';
    }

    if (!phone) {
      errors.phone = 'Phone is required';
    }

    if (!email) {
      errors.email = 'Email is required';
    }

    if (!address) {
      errors.address = 'Address is required';
    }

    return errors;
  };

  submitClientDetails = async () => {
    const validationErrors = this.validateForm();
    if (Object.keys(validationErrors).length === 0) {
      const authToken = localStorage.getItem(config.Token_name);
      const clientData = this.state.clientData;
      const postData = {
        authToken,
        address: clientData.address,
        email: clientData.email,
        fullname: clientData.name,
        phone: clientData.phone
      };
      // console.log('update product', postData, this.state);
      let serviceUrl = config.BASE_API_URL + 'clients';
      let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
      //console.log('reply', reply);
      this.closeAddLeadDialog();
      const Url = '/add/newlead/' + reply.data.id;
      //console.log('Url', Url);
      Postie.navigateLink(Url);
    } else {
      // Display validation errors
      this.setState({ validationErrors });
    }
  };

  checkClientExist = async () => {
    const authToken = localStorage.getItem(config.Token_name);
    const clientData = this.state.clientData;
    const postData = {
      authToken,

      phone: clientData.phone
    };

    let checkUrl = config.BASE_API_URL + 'client/exists';
    let checkReply = await Postie.sendAsynchrnousCall(postData, checkUrl);

    if (checkReply.status === 'error') {
      const Url = '/add/newlead/' + checkReply.data.id;
      Postie.navigateLink(Url);
      //console.log('User already exists. Navigating to:', Url);
    }
  };

  componentDidMount = () => {
    this.loadData();
    this.getTeamDetails();
  };

  loadData = async () => {
    let presalesStage = staticData.getStages()['PRESALES'];
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url = config.BASE_API_URL + 'project/show/presales';
      let postData = { ...this.state.filters, authToken: localStorage.getItem(config.Token_name) };
      let jsonData = await Postie.sendAsynchrnousCall(postData, url);
      //console.log('sata', jsonData);
      if (jsonData.status === 'success') {
        this.setState(
          {
            ...this.state,
            data: jsonData.data.records,
            displayNote: jsonData.data.displayNote,
            totalRecords: jsonData.data.totalRecords,
            totalPages: jsonData.data.totalPages,
            stages: presalesStage,
            dataLoaded: true // Set dataLoaded to true when data is loaded
          },
          function () {
            console.log('presales leads Data', this.state);
          }
        );
      }
    }
  };

  getTeamDetails = async () => {
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url = config.BASE_API_URL + 'team/list/' + Auth.urlTokenizer();
      let jsonData = await Postie.fetchUrl(url);
      //console.log('sata', jsonData);
      if (jsonData) {
        this.setState(
          {
            ...this.state,
            team: jsonData,
            dataLoaded: true // Set dataLoaded to true when data is loaded
          },
          function () {
            //console.log('TeamData', this.state.team);
          }
        );
      }
    }
  };

  render() {
    const {
      data,
      isAddLeadDialogOpen,
      clientData,
      validationErrors,
      startDate,
      endDate,
      status,
      stages,
      team
    } = this.state;
    console.log('state data', data);

    return (
      <Grid
        container
        style={{ display: 'flex', alignItems: 'center', padding: '0px', marginLeft: '10px' }}
      >
        <h1>Presales Projects</h1>
        <Button
          variant="contained"
          color="primary"
          onClick={this.addNewLead}
          style={{ marginLeft: '500px' }}
        >
          <AddIcon />
          Add New Lead
        </Button>

        <Dialog open={isAddLeadDialogOpen} onClose={this.closeAddLeadDialog}>
          <DialogTitle>Add New Lead</DialogTitle>
          <DialogContent>
            <TextField
              label="Name"
              name="name"
              value={clientData.name}
              onChange={this.handleClientDetails}
              fullWidth
              required // Mark the field as required
              style={{ marginBottom: '16px' }} // Add spacing
              error={Boolean(validationErrors.name)}
              helperText={validationErrors.name}
            />
            <TextField
              label="Phone"
              name="phone"
              value={clientData.phone}
              onBlur={this.checkClientExist}
              onChange={this.handleClientDetails}
              fullWidth
              required // Mark the field as required
              style={{ marginBottom: '16px' }} // Add spacing
              error={Boolean(validationErrors.phone)}
              helperText={validationErrors.phone}
            />
            <TextField
              label="Email"
              name="email"
              value={clientData.email}
              onChange={this.handleClientDetails}
              fullWidth
              required // Mark the field as required
              style={{ marginBottom: '16px' }} // Add spacing
              error={Boolean(validationErrors.email)}
              helperText={validationErrors.email}
            />
            <TextField
              label="Address"
              name="address"
              value={clientData.address}
              onChange={this.handleClientDetails}
              fullWidth
              required // Mark the field as required
              style={{ marginBottom: '16px' }} // Add spacing
              error={Boolean(validationErrors.address)}
              helperText={validationErrors.address}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeAddLeadDialog} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.submitClientDetails} color="primary">
              Add Client
            </Button>
          </DialogActions>
        </Dialog>

        <div style={{ marginBottom: '16px', width: '100%' }}>
          <TextField
            label="Start Date"
            defaultValue={this.state.filters.start_date}
            type="date"
            onChange={(e) => this.handleFilterChange('start_date', e)}
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            style={{ marginLeft: '16px' }}
            label="End Date"
            type="date"
            defaultValue={this.state.filters.end_date}
            onChange={(e) => this.handleFilterChange('end_date', e)}
            InputLabelProps={{
              shrink: true
            }}
          />
          <FormControl style={{ marginLeft: '16px', width: '100px' }}>
            <InputLabel>Status</InputLabel>
            <Select
              defaultValue={this.state.filters.active}
              onChange={(e) => this.handleFilterChange('active', e)}
            >
              <MenuItem value={0}> Inactive</MenuItem>
              <MenuItem value={1}> Active</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{ marginBottom: '16px', width: '100%' }}>
          <Autocomplete
            style={{ marginLeft: '16px', width: '200px' }}
            multiple
            freeSolo
            id={'tags'}
            options={team}
            getOptionLabel={(option) => option.member_name}
            defaultValue={this.state.tags}
            onChange={(event, newValue) => {
              this.onTagsChange(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label={'Filter By Team'}
                placeholder={'Choose Team Member'}
                margin="normal"
                fullWidth
              />
            )}
          />
          <TextField
            label="Client Name"
            name="Client Name"
            defaultValue={this.state.filters?.client}
            style={{ marginLeft: '16px' }}
            onChange={(e) => this.handleFilterChange('client', e)}
          />
          <TextField
            label="Client Address"
            name="Client Address"
            defaultValue={this.state.filters?.location}
            style={{ marginLeft: '16px' }}
            onChange={(e) => this.handleFilterChange('location', e)}
          />
          <FormControl style={{ marginLeft: '16px', width: '200px' }}>
            <InputLabel>Stage</InputLabel>
            <Select
              defaultValue={this.state.filters?.stage}
              onChange={(e) => this.handleFilterChange('stage', e)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {stages &&
                Object.keys(stages).map((key) => <MenuItem value={stages[key]}>{key}</MenuItem>)}
            </Select>
          </FormControl>
        </div>

        {data.projects &&
          data.projects.map((record) => (
            <LeadView
              key={record}
              data={record}
              view={true}
              teams={data.team}
              callback={() => this.loadData()}
            />
          ))}
      </Grid>
    );
  }
}

export default PresalesLeads;
