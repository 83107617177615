import React, { Component } from 'react';
import {
  Grid,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl
} from '@mui/material';
import FileView from 'app/views/bids/components/fileView';
import displayer from 'app/helpers/displayer';
import UploadFile from 'app/views/files/uploadFile';

class Procurment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openOrderNow: false,
      openMark: false
    };
  }
  openOrderNow = () => {
    this.setState({
      openOrderNow: true
    });
  };
  closeOrderNow = () => {
    this.setState({
      openOrderNow: false
    });
  };

  openMark = () => {
    this.setState({
      openMark: true
    });
  };

  closeMark = () => {
    this.setState({
      openMark: false
    });
  };

  render() {
    //const { selectedMainTab, selectedSecondaryTab, heading, selectedProcurmentTab } = this.state;
    return (
      <Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ padding: '8px' }}>Material</TableCell>
              <TableCell>Procure Before</TableCell>
              <TableCell>PO</TableCell>
              <TableCell>ETD</TableCell>
              <TableCell>Delivered</TableCell>
              <TableCell>received On</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell style={{ padding: '8px' }}>
                <span style={{ fontWeight: 'bold' }}>Century HDHMR 18mm</span>
              </TableCell>
              <TableCell>
                <div>
                  <span style={{ fontWeight: 'bold' }}>24 Dec 2023</span>
                </div>

                <div>
                  <span style={{ fontWeight: 'bold' }}>Delivered to Manufecturer</span>
                </div>
                <div>
                  <Button variant="contained" color="primary" onClick={() => this.openOrderNow()}>
                    Order Now
                  </Button>
                </div>
              </TableCell>
              <TableCell>
                <FileView key={`nobaseImage`} id={''} url={displayer.absolutingImageURl('')} />
              </TableCell>
              <TableCell>
                <span style={{ fontWeight: 'bold' }}>24 Dec 2023</span>
              </TableCell>
              <TableCell>
                <span style={{ fontWeight: 'bold' }}>24 Dec 2023</span>
              </TableCell>
              <TableCell>
                <div>
                  <span style={{ fontWeight: 'bold' }}>24 Dec 2023</span>
                </div>
                <div>
                  <Button variant="contained" color="primary" onClick={() => this.openMark()}>
                    Mark
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <Dialog open={this.state.openOrderNow} onClose={this.closeOrderNow} fullWidth>
          <DialogTitle>Order hinges for Kitchen!!</DialogTitle>
          <DialogContent>
            <Grid container md="12">
              <Grid item md="6">
                <h4>Procure Before:</h4>
              </Grid>
              <Grid item md="6">
                <TextField
                  key={'followup-date'}
                  type="date"
                  //onChange={(e) => this.handleValueChange(e)}
                  placeholder={`choose date `}
                  name={'date'}
                  fullWidth
                ></TextField>
              </Grid>
            </Grid>
            <Grid container md="12">
              <Grid item md="6">
                <h4>Deliver To:</h4>
              </Grid>
              <Grid item md="6">
                <FormControl variant="outlined" style={{ width: '30%' }}>
                  <Select
                    //onChange={(e) => this.handleAreaChange(e.target.value)}
                    defaultValue="Choose"
                    label="Choose Place"
                    name="Choose Place"
                  >
                    <MenuItem value="">Choose</MenuItem>

                    <MenuItem value={''}>Manufacture</MenuItem>
                    <MenuItem value={''}>In House</MenuItem>
                    <MenuItem value={''}>Site</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={this.closeOrderNow}>
              Close
            </Button>
            <Button variant="contained" color="primary" style={{ marginTop: '2px' }}>
              Procure
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.openMark} onClose={this.closeMark} fullWidth>
          <DialogTitle>Received Century HDHMR 18 mm</DialogTitle>
          <DialogContent>
            <Grid container md="12">
              <Grid item md="6">
                <h4>Qty to Receive:</h4>
              </Grid>
              <Grid item md="6">
                <TextField
                  key={'received-qty'}
                  type="number"
                  //onChange={(e) => this.handleValueChange(e)}
                  placeholder={`write numbers `}
                  name={'write number'}
                  fullWidth
                ></TextField>
              </Grid>
            </Grid>
            <Grid container md="12">
              <Grid item md="6">
                <h4>Received Qty:</h4>
              </Grid>
              <Grid item md="6">
                <TextField
                  key={'received-qty'}
                  type="number"
                  //onChange={(e) => this.handleValueChange(e)}
                  placeholder={`write numbers `}
                  name={'write number'}
                  fullWidth
                ></TextField>
              </Grid>
            </Grid>
            <Grid container md="12">
              <Grid item md="6">
                <h4>Upload Picture:</h4>
              </Grid>
              <Grid item md="6">
                <UploadFile
                  filename="Procure file"
                  displayTosection={false}
                  areaName=""
                  type="procure_file"
                  projectId={''}
                  areaId={''}
                  //callback={(File) => props.handleFile(File, props.data?.area_id)}
                  showAlert={false}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={this.closeMark}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" style={{ marginTop: '2px' }}>
              Received
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default Procurment;
