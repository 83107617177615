import React, { Component } from 'react';
import { Grid, Button, TableCell, TableRow, TableBody, Table, Chip } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import Create from './create';
import Postie from 'app/helpers/postie';
import config from '../../constants/index.jsx';
import swal from 'sweetalert';
import FileView from '../bids/components/fileView';
import displayer from 'app/helpers/displayer';
import staticData from 'app/helpers/staticdata';
import ConnectModule from './connectModule';
import AddIcon from '@mui/icons-material/Add';
import LoadDropDown from './components/LoadDropdown';
import UploadFile from '../files/uploadFile';
import NexusPagination from './components/nexuspagination';
import LoadCheckBox from './components/LoadCheckBox';
import LoadTextField from './components/LoadTextField';
import LoadButton from './components/LoadButton';
import LoadRadioGroup from './components/LoadRadioGroup';
import LoadCheckList from './components/LoadCheckList';
import SingleTonTags from './components/SingleTonTags';
import FilterData from './components/FilterData';
import Tags from './components/Tags';
import ConnectionDataTable from './components/ConnectionDataTable';
import $ from 'jquery';
class Rud extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Fields: this.props.fields,
      Data: this.props.data,
      apiCalls: this.props.apiCalls,
      updatedstate: false,
      connection: {
        makeConnection: false,
        data: [],
        editedData: []
      },
      filterType: {}
    };
  }

  componentDidMount() {}

  handleFunction = (event, type, identifier) => {
    console.log('handleFunction called with type:', type);
    if (type.length > 0) {
      event.target['identifier'] = identifier;
      switch (type) {
        case 'create':
          this.createFunctionality(this.state.apiCalls['create'], event);
          break;
        case 'update':
          console.log(this.state.apiCalls['update']);
          this.updateFunctionality(this.state.apiCalls['update'], event);
          break;
        case 'delete':
          this.deleteFunctionality(this.state.apiCalls['update'], identifier);
          break;
        case 'connect':
          this.connectFunctionality(this.state.apiCalls['connect'], identifier);
          break;
        default:
          console.log('default Block Called');
          Postie.navigateLink(this.state.apiCalls[type]);
          break;
      }
    }
  };

  updateFunctionality = async (apiCall, event) => {
    console.log('event', event);
    if (apiCall.type === 'post') {
      if (event.target.value !== '' && event.target.value !== '\n') {
        let postData = this.generatePostData(apiCall, event);
        console.log('postData', postData);
        let serviceUrl = config.BASE_API_URL + apiCall.url;
        let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
        if (reply.status === 'success') {
          this.props.callback();
        } else {
          Postie.showAlert(reply);
        }
      }
    } else {
    }
  };
  createFunctionality = async (data) => {
    console.log('createFunctionality', data);
    let postData = data;
    postData['authToken'] = localStorage.getItem(config.Token_name);
    let serviceUrl = config.BASE_API_URL + this.state.apiCalls['create'].url;
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      if (this.state.apiCalls['create'].postSubmit) {
        this.postCreate(postData);
      }
      this.setState({ ...this.state, Data: reply.data.data, updatedstate: true });
    } else {
      Postie.showAlert(reply);
    }
  };
  postCreate = async (postData) => {
    let serviceUrl = config.BASE_API_URL + this.state.apiCalls['create'].postSubmit;
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
    } else {
      Postie.showAlert(reply);
    }
  };
  deleteFunctionality = (apiCall, identifier) => {
    swal({
      title: 'Are you sure?',
      text: 'You will not be able to recover data once deleted!',
      icon: 'warning',
      buttons: ['No, cancel it!', 'Yes, I am sure!'],
      dangerMode: true
    }).then((isConfirm) => {
      if (isConfirm) {
        let data = { target: { name: 'status', value: '0', identifier: identifier } };
        this.updateFunctionality(apiCall, data);
      }
    });
  };
  generatePostData = (apiCall, event) => {
    console.log('generatePostData', apiCall, event);
    let keyData = Object.keys(apiCall?.mandatory);
    let postData = {};
    for (var i = 0; i < keyData.length; i++) {
      if (apiCall.mandatory[keyData[i]] === 'token') {
        postData[[keyData[i]]] = localStorage.getItem(config.Token_name);
      } else {
        postData[[keyData[i]]] = event.target[apiCall.mandatory[keyData[i]]];
      }
    }
    return postData;
  };

  handleInputChange = (e) => {
    let selected = [];
    $('[name="' + e.target.name + '"]:checked').each((elem, input) => {
      selected.push(input.value);
    });
    let value = selected.join('|');
    //console.log('value', value);
    if (value === '') {
      value = 'None';
    }
    //console.log(e.target.name, selected.join('|'));
    let sarr = e.target.name.split('-');
    let elem = { target: { name: sarr[0], value: value } };
    this.handleFunction(elem, 'update', parseInt(sarr[1].substring(0, sarr[1].length - 2)));
  };

  filterdata = (event) => {
    let filterType = this.state.filterType;
    let { name, value } = event.target;
    if (isNaN(value) && value.toLowerCase() === 'none') {
      delete filterType[name];
    } else {
      filterType[name] = value;
    }
    this.setState({ ...this.state, filterType: filterType }, function () {
      this.props.filterData(event);
    });
  };

  render() {
    let { Fields, data } = this.props;
    if (this.state.updatedstate) {
      data = this.state.Data;
    }
    const parentKeys = Object.keys(this.props.defaultValue);
    //console.log('Fields', this.props,parentKeys);
    return (
      <Grid container>
        <Grid
          item
          md={12}
          style={{ display: 'flex', alignItems: 'center', padding: '30px', marginLeft: '20px' }}
        >
          <Grid item md={6}>
            <h1>{this.props.heading.replace(/_/g, ' ')}</h1>
          </Grid>
          <Grid item md={6}>
            {/*console.log('addDifferent', this.props)*/}
            {this.props.addDifferent === true ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => Postie.navigateLink(this.state.apiCalls['create'].url)}
              >
                <AddIcon />
                Add Product
              </Button>
            ) : this.state?.apiCalls['create'] ? (
              <Create
                heading={this.props.heading}
                fields={Fields}
                apiCall={this.state?.apiCalls['create']}
                handleFile={(file, type) => this.props.handleFile(file, type)}
                add={(data) => this.createFunctionality(data)}
                defaultValue={this.props.defaultValue}
                filters={this.state.filterType}
              />
            ) : null}
          </Grid>
        </Grid>

        <Grid item md={12} style={{ padding: '30px' }}>
          {/* <Table>
            <TableHead>
              <TableRow>
                {Fields?.map(
                  (record) =>
                    !parentKeys.includes(record.dbname) && (
                      <TableCell
                        key={`filter-${record.dbname ? record.dbname : record.ftype[0].dbname}`}
                      >
                        {['Button', 'file', 'link'].includes(record.ftype[0].type) ||
                        record.disabled ? (
                          <p>{record.label}</p>
                        ) : record.ftype[0].type === 'dropdown' ||
                          record.ftype[0].type === 'tags' ||
                          record.ftype[0].type === 'checkBox' ? (
                          <LoadDropDown
                            record={record}
                            defaultValue={
                              this.props.filterValues[
                                record.dbname ? record.dbname : record.ftype[0].dbname
                              ] || ''
                            }
                            callback={(event) => this.props.filterData(event)}
                            ftype={record.ftype[0]}
                          />
                        ) : (
                          <LoadDropDown
                            key={this.state.filterType}
                            record={record}
                            defaultValue={''}
                            callback={(event) => this.props.filterData(event)}
                            ftype={
                              this.state.filterType[record.dbname]
                                ? this.state.filterType[record.dbname]
                                : []
                            }
                          />
                        )}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <FilterData
              key={this.props.userFilters ? btoa(JSON.stringify(this.props.userFilters)) : data}
              fields={Fields}
              Heading={this.props.heading}
              filterKeys={parentKeys}
              filterType={this.state.filterType}
              apiCalls={this.state.apiCalls}
              userFilters={this.state.filterType}
              defaultValue={this.props.defaultValue}
              FilterData={(event) => this.filterdata(event)}
            />

            <TableBody>
              {data?.records &&
                data?.records?.map((item) => (
                  <TableRow key={`${this.props.heading}-${item.id}`}>
                    {Fields?.map(
                      (record) =>
                        !parentKeys.includes(record.dbname) && (
                          <TableCell
                            key={`data-${record.dbname ? record.dbname : record.ftype[0].dbname}`}
                          >
                            {record.ftype?.map((ftype, index) => (
                              <div key={`${item.id}${ftype.type}${index}`}>
                                {ftype.type === 'text' && <p>{item[record.dbname]}</p>}
                                {ftype.type === 'textBox' && (
                                  <LoadTextField
                                    record={record}
                                    name={record.dbname}
                                    defaultValue={item[record.dbname]}
                                    placeHolder={ftype.placeholder}
                                    callback={(e) => this.handleFunction(e, ftype.action, item.id)}
                                    disabled={ftype.disabled}
                                  />
                                )}
                                {ftype.type === 'dropdown' && (
                                  <LoadDropDown
                                    record={record}
                                    defaultValue={
                                      item[record.dbname ? record.dbname : ftype.dbname]
                                    }
                                    callback={(e) => this.handleFunction(e, ftype.action, item.id)}
                                    ftype={ftype}
                                    disabled={record.disabled}
                                  />
                                )}
                                {ftype.type === 'file' && item[record['dbname']] && (
                                  <FileView
                                    id={`file-${item.id}`}
                                    url={displayer.absolutingImageURl(item[record['dbname']])}
                                  />
                                )}
                                {ftype.type === 'file' && (
                                  <UploadFile
                                    style={{ float: 'right' }}
                                    Filename={record['dbname']}
                                    displayTosection={false}
                                    areaName={''}
                                    type={record['dbname']}
                                    projectId={0}
                                    areaId={0}
                                    callback={(File) =>
                                      this.handleFile(
                                        File,
                                        record['dbname'],
                                        this.state.apiCalls['update'],
                                        item.id
                                      )
                                    }
                                  />
                                )}
                                {ftype.type === 'list' && (
                                  <LoadRadioGroup
                                    callback={(e) => this.handleFunction(e, ftype.action, item.id)}
                                    ftype={ftype}
                                  />
                                )}

                                {ftype.type === 'checkBox' && (
                                  <LoadCheckBox
                                    record={record}
                                    ftype={ftype}
                                    callback={(e) => this.handleInputChange(e)}
                                    selectedValues={
                                      item[record.dbname] ? item[record.dbname].split('|') : []
                                    }
                                    itemid={item['id']}
                                  />
                                )}
                                {ftype.type === 'textArea' && (
                                  <LoadTextField
                                    multiline={true}
                                    record={ftype}
                                    name={record.dbname ? record.dbname : ftype.dbname}
                                    defaultValue={
                                      item[record.dbname ? record.dbname : ftype.dbname]
                                    }
                                    placeHolder={ftype.placeholder}
                                    callback={(e) => this.handleFunction(e, ftype.action, item.id)}
                                  />
                                )}
                                {ftype.type === 'link' && (
                                  <LoadButton
                                    ftype={ftype}
                                    callback={(e) => Postie.navigateLink(ftype.url + item.id)}
                                  />
                                )}
                                {ftype.type === 'number' && (
                                  <LoadTextField
                                    type="number"
                                    record={record}
                                    name={record.dbname ? record.dbname : ftype.dbname}
                                    placeHolder={ftype.placeholder}
                                    defaultValue={
                                      item[record.dbname ? record.dbname : ftype.dbname]
                                    }
                                    callback={(e) => this.handleFunction(e, ftype.action, item.id)}
                                  />
                                )}
                                {ftype.type === 'checkList' && (
                                  <LoadCheckList
                                    record={record}
                                    ftype={ftype}
                                    optionChoosen={item[ftype.optionId]}
                                    callback={(e) => this.handleInputChange(e)}
                                    disabled={ftype.disabled}
                                    selectedValues={
                                      item[record.dbname] ? item[record.dbname].split('|') : []
                                    }
                                    itemid={item['id']}
                                  />
                                )}
                                {ftype.type === 'tags' && (
                                  <Tags
                                    style={{ width: 150 }}
                                    options={ftype.options}
                                    label={record.label}
                                    placeholder={record.label}
                                    baseModel={record.dbname}
                                    defaultValue={
                                      item[record.dbname] ? JSON.parse(item[record.dbname]) : []
                                    }
                                    handleChange={(data) => {
                                      let elem = {
                                        target: { name: record.dbname, value: data }
                                      };
                                      this.handleFunction(elem, ftype.action, item.id);
                                    }}
                                  />
                                )}

                                {ftype.type === 'autocomplete' && (
                                  <SingleTonTags
                                    options={this.state.filterType[record.dbname]?.options}
                                    label={record.label}
                                    placeholder={record.label}
                                    baseModel={record.dbname}
                                    defaultValue={item[record.dbname]}
                                    handleChange={(data) => {
                                      let elem = {
                                        target: { name: record.dbname, value: data }
                                      };
                                      this.handleFunction(elem, ftype.action, item.id);
                                    }}
                                  />
                                )}

                                {ftype.type === 'Button' && ftype.action !== 'connect' && (
                                  <LoadButton
                                    ftype={ftype}
                                    callback={(e) => this.handleFunction(e, ftype.action, item.id)}
                                  />
                                )}

                                {ftype.type === 'Button' && ftype.action === 'connect' && (
                                  <ConnectModule
                                    key={`connect-${item.id}`}
                                    baseModule={this.props.heading}
                                    ConnectModule={this.state.apiCalls['connect'].connection}
                                    baseModuleId={item.id}
                                    apiCall={this.state.apiCalls['connect']}
                                    callback={() => console.log('callback')}
                                  />
                                )}
                              </div>
                            ))}
                          </TableCell>
                        )
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table> */}

          <ConnectionDataTable
            key={data}
            data={data}
            fields={Fields}
            isConnect={false}
            isApiFilterable={true}
            showSuggesstion={false}
            apiCalls={this.state.apiCalls}
            callback={(e, action, id) => this.handleFunction(e, action, id)}
            heading={this.props.heading}
            filters={this.props.defaultValue}
            userFilters={this.state.filterType}
            filterData={(event) => this.filterdata(event)}
          />
          <NexusPagination
            key={data}
            displayNote={data.display_note}
            currentPage={data.currentPage}
            totalPages={data.totalpages}
            handleFunction={(desiredPage) => this.props.changePage(desiredPage, this.props.heading)}
          />
        </Grid>
      </Grid>
    );
  }
}

export default Rud;
