import React, { Component } from 'react';
import { Box, Tooltip } from '@mui/material';
import displayer from 'app/helpers/displayer';

class TeamInfo extends Component {
  render() {
    const { team, index } = this.props;
    //console.log('Project Colaborator', team);

    // Check if team is defined before accessing its properties
    if (!team) {
      return null; // Return null or another fallback if team is not defined
    }
    const abbreviatedName = displayer.abrivatedName(team?.detail?.member_name);

    // Check if the team profile picture is available
    const hasProfilePicture = team.profilePicture !== undefined;

    // Determine whether to show full name or abbreviated name based on index
    const showFullName = this.props.detail;

    // Define the tooltip content for the abbreviated name with full name in the tooltip
    const tooltipContent = `${team.role}: ${team?.detail?.member_name}`;

    return (
      <Box className="row" style={{ display: 'flex', flexDirection: 'row' }}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          {/* Conditional rendering based on whether a profile picture is available */}
          {hasProfilePicture ? (
            <Tooltip title={tooltipContent} arrow>
              <div
                style={{
                  borderRadius: '50%',
                  width: '50px',
                  height: '50px',
                  overflow: 'hidden',
                  marginRight: '10px'
                }}
              >
                <img
                  src={team.profilePicture}
                  alt="Profile"
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            </Tooltip>
          ) : (
            !this.props.dontdisplay && (
              <Tooltip title={tooltipContent} arrow>
                <div
                  style={{
                    borderRadius: '50%',
                    width: '30px',
                    height: '30px',
                    background: '#007bff',
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '15px',
                    marginRight: '10px'
                  }}
                >
                  {abbreviatedName}
                </div>
              </Tooltip>
            )
          )}
          {showFullName ? (
            <div>
              {' '}
              {team?.detail?.member_name} (
              {team.is_lead ? 'Account Manager' : team.is_admin ? 'Admin' : team.role})
            </div>
          ) : null}
        </Box>
      </Box>
    );
  }
}
export default TeamInfo;
