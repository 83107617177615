import React, { Component } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import { Box, Button, Chip, Divider } from '@mui/material';
import FileView from '../../bids/components/fileView.jsx';
import displayer from 'app/helpers/displayer';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import { styled } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

const AccordionRoot = styled(Box)(({ theme }) => ({
  width: '100%',
  '& .heading': {
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12)
    }
  },
  '& .secondaryHeading': {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12)
    }
  },
  '& .icon': {
    width: 20,
    height: 20,
    verticalAlign: 'bottom'
  },
  '& .details': { alignItems: 'center' },
  '& .column': { flexBasis: '33.33%' },
  '& .helper': {
    padding: theme.spacing(1, 2),
    borderLeft: `2px solid ${theme.palette.divider}`
  },
  '& .link': {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': { textDecoration: 'underline' }
  }
}));
class ModularListRow extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { data } = this.props;

    return (
      <AccordionRoot style={{ marginBottom: '5px', marginRight: '30px', marginTop: '10px' }}>
        <Accordion
          defaultExpanded={this.props.showDetail}
          style={{ marginBottom: '5px', marginRight: '22px' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={2}>
                <Box className="column">
                  <Typography className="heading">{data?.service?.type}</Typography>
                  <Typography className="heading">{data?.service?.sub_category}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Box className="column">
                  <Typography className="heading">
                    Location:{data?.service?.area?.project_display_name}
                  </Typography>
                  <Typography className="heading">
                    Elevation : {data?.service?.elevation}
                  </Typography>
                </Box>
              </Grid>
              {this.props.displayCosting && (
                <Grid item xs={12} sm={6} md={2}>
                  <Box className="column">
                    <Typography className="heading">Rate: &#8377; {data?.rate}</Typography>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={2}>
                <Box className="column">
                  <Typography className="heading">Qty: {data?.qty}</Typography>
                </Box>
              </Grid>
              {this.props.displayCosting && (
                <Grid item xs={12} sm={6} md={2}>
                  <Box className="column">
                    <Typography className="heading">Cost: {data?.cost}</Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <Divider />

          <Grid container spacing={2} style={{ margin: '5px' }}>
            <Grid item md={3}>
              <h3>Preview</h3>
              <FileView
                key={`nobaseImage`}
                id={data?.id}
                url={displayer.absolutingImageURl(data?.service?.internal_image)}
              />
            </Grid>
            <Grid item md={3}>
              <h3>Specification</h3>
              <p>
                {data?.service?.category}-{data?.service?.id}
              </p>
              <h5>{data?.service?.dimensions}</h5>
              <h5>Classification: {data?.service?.classification}</h5>
              <h5>Subcategory: {data?.service?.sub_category}</h5>
              <h5>EndPanel: {data?.service?.end_panel}</h5>
            </Grid>
            <Grid item md={3}>
              <h3>Internal Specifications</h3>
              <h5>Base Material: {data?.modular_setting?.base_material}</h5>
              <h5>Internal Finish:</h5>
              <Grid container spacing={2}>
                <Grid item md="6">
                  <FileView
                    key={`internalLaminateImage`}
                    id={data?.id}
                    url={displayer.absolutingImageURl(
                      JSON.parse(data?.global_setting?.internal_laminate)['base_image']
                    )}
                  />
                </Grid>
                <Grid item md="6">
                  {JSON.parse(data?.global_setting?.internal_laminate)['name']}
                </Grid>
              </Grid>
              <h5>Hardware: {data?.global_setting?.hardware}</h5>
            </Grid>
            <Grid item md={3}>
              <h3>Shutter Specification</h3>
              <h5>Shutter Material: {data?.modular_setting?.shutter_material}</h5>
              <h5>Shutter Finish :</h5>
              <Grid container spacing={2}>
                <Grid item md="6">
                  <FileView
                    key={`internalLaminateImage`}
                    id={data?.id}
                    url={displayer.absolutingImageURl(
                      JSON.parse(data?.modular_setting?.shutter_finish)['base_image']
                    )}
                  />
                </Grid>
                <Grid item md="6">
                  {JSON.parse(data?.modular_setting?.shutter_finish)['name']}
                </Grid>
              </Grid>
              <h5>Handles :</h5>
              <Grid container spacing={2}>
                <Grid item md="6">
                  <FileView
                    key={`internalLaminateImage`}
                    id={data?.id}
                    url={displayer.absolutingImageURl(
                      JSON.parse(data?.modular_setting?.handles)['base_image']
                    )}
                  />
                </Grid>
                <Grid item md="6">
                  {JSON.parse(data?.modular_setting?.handles)['name']}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Divider />

          {/*<AccordionActions>
            <Button size="small" variant="outlined">
              View
            </Button>

            <Button size="small" variant="outlined">
              Mark Inactive
            </Button>
                    </AccordionActions>*/}
        </Accordion>
      </AccordionRoot>
    );
  }
}

export default ModularListRow;
