import React, { Component } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
  TextField
} from '@mui/material';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import ReportIssue from './ReportIssue';
import Postie from 'app/helpers/postie';
import Auth from 'app/helpers/authorization';
import config from 'app/constants/index';

class ValidationPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ValidationPoint: '',
      isIssuePopupOpen: false,
      issueData: [],
      selectedAreadata: ''
    };
  }

  openIssuePopup = () => {
    this.setState({ isIssuePopupOpen: true });
  };

  closeIssuePopup = () => {
    this.setState({ isIssuePopupOpen: false });
    this.props.closePopUp();
  };

  sendIssueData = async () => {
    const { data } = this.props;

    if (data) {
      const selectedAreadata = data.map((record, index) => {
        return record;
      });

      this.setState(
        {
          ...this.state,
          selectedAreadata
        },
        async () => {
          await this.openIssuePopup();
        }
      );
    }
  };

  render() {
    const { data, areaId } = this.props;
    console.log('ValidationPopup data', data, areaId);

    return (
      <Grid>
        {data.length > 0 ? (
          <TableContainer component={Paper}>
            <Button
              variant="outlined"
              style={{ color: 'red', float: 'right' }}
              onClick={this.sendIssueData}
            >
              Report Issue
            </Button>
            <Grid container>
              <Grid item md={9} xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Checkpoint</TableCell>
                      <TableCell>Remarks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      data.map((record, index) => (
                        <TableRow key={record}>
                          <TableCell style={{ verticalAlign: 'top' }}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    key={index}
                                    color="primary"
                                    checked={record.approved}
                                    disabled={record.approved}
                                    name={`approved`}
                                    value={1}
                                    onChange={(event) =>
                                      this.props.handleUpdateValidation(record, event)
                                    }
                                  />
                                }
                                label={record.checkname}
                              />
                            </FormGroup>
                          </TableCell>
                          <TableCell style={{ verticalAlign: 'top' }}>
                            <FormGroup>
                              <TextField
                                key={record.id}
                                name={`remarks`}
                                disabled={record.approved}
                                defaultValue={record.approved ? '' : record.remarks}
                                onBlur={(event) => this.props.handleUpdateValidation(record, event)}
                              ></TextField>
                            </FormGroup>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item md={3} xs={12}>
                {/* <div>
                  <h4>Uploaded By Alisha on 15 September 2023</h4>
                </div>
                <div>
                  <h4>Approved By Sonal Rohtagi on 16 September 2023</h4>
                </div>
                <div>
                  <h4>
                    [Image 1] Render and Drawing mismatched-- Issue marked by Sonal Rohtagi on 16
                    September 2023
                  </h4>
                </div> */}
              </Grid>
            </Grid>
          </TableContainer>
        ) : (
          <div>
            <p style={{ color: 'red', margin: '10px' }}>
              No Checklist Exist for this File,So You can take actions as defined below:
            </p>
            <Button
              variant="outlined"
              style={{ color: 'red', margin: '10px' }}
              onClick={this.sendIssueData}
            >
              Report Issue
            </Button>
            <Button
              variant={'contained'}
              style={{ margin: '10px' }}
              onClick={() => this.props.handleUpdateStatus()}
            >
              Approve
            </Button>
          </div>
        )}

        <ReportIssue
          data={this.state.selectedAreadata}
          projectId={this.props.projectId}
          areaId={this.props.areaId}
          isIssuePopupOpen={this.state.isIssuePopupOpen}
          closeIssuePopup={this.closeIssuePopup}
          elevation={this.props.elevation}
          dwg_name={this.props.dwg_name}
          dwg_id={this.props.dwg_id}
          action_type={this.props.action_type}
        />
      </Grid>
    );
  }
}

export default ValidationPopup;
