import React, { Component } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  Button,
  Box,
  Divider,
  Tabs,
  Tab,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import config from '../../constants/index.jsx';
import Auth from 'app/helpers/authorization';
import Postie from 'app/helpers/postie';
import displayer from 'app/helpers/displayer';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.primary
}));

class QualifiedLeads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      dataLoaded: false,
      selectedTab: 0,
      selectedArea: '',
      buying_id: 0
    };
  }

  componentDidMount = () => {
    this.getQualifiedLeads();
  };

  getQualifiedLeads = async () => {
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url = config.BASE_API_URL + 'leads/qualified/' + Auth.urlTokenizer();
      let jsonData = await Postie.fetchUrl(url);
      //console.log('sata', jsonData);
      if (jsonData) {
        this.setState(
          {
            ...this.state,
            data: jsonData,
            dataLoaded: true // Set dataLoaded to true when data is loaded
          },
          function () {
            console.log('Qualified Leads state', this.state.data);
          }
        );
      }
    }
  };

  handleTabChange = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };

  handleAreaChange = (event) => {
    this.setState({ selectedArea: event.target.value }, function () {
      console.log('select Area', event.target.value);
    });
  };

  buylead = async (id, cost) => {
    this.setState({
      buying_id: id
    });
    let postData = {
      lead_id: id,
      cost: cost, // pass the cost to the server
      authToken: localStorage.getItem(config.Token_name)
    };
    var serviceUrl = config.BASE_API_URL + 'lead/buy';
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      Postie.navigateLink('/project/view/' + reply.data.id);
    }
    this.setState({
      buying_id: 0
    });
  };

  render() {
    const { data, selectedTab, dataLoaded, selectedArea } = this.state;
    console.log('Qualified Leads', data);

    if (!dataLoaded) {
      return (
        <Grid
          container
          md={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Center the loading message
            height: '100vh' // Center vertically
          }}
        >
          <h3>Loading data...</h3>
        </Grid>
      );
    }

    return (
      <Grid>
        <div>
          <h1 style={{ marginLeft: '20px' }}>Qualified Leads</h1>
        </div>
        {data &&
          data.map((record) => (
            <Grid style={{ marginBottom: '20px' }}>
              <div>
                <Item>
                  <Grid container md={12} style={{ marginLeft: '20px' }}>
                    <Grid item md={3}>
                      <h4>#{record.id}</h4>
                      <Box>Client Name:{record.client_name}</Box>
                      <h5>Client Description</h5>
                      <Box>Purpose:{record.project_description}</Box>
                      <Box>Address/Society name:{record.project_address}</Box>
                      <Box>Budget:{record.project_budget}</Box>
                      <Box>I Own a:Apartment</Box>
                      <Box>Rooms:{record.description}</Box>
                      <Box>Do You Have Possession ?:{record.posession}</Box>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container item spacing={2}>
                        <Grid item md={3} style={{ marginTop: '5px' }}>
                          <h5>Work Type:</h5>
                          <Box>{record.work_type}</Box>
                        </Grid>
                        <Grid item md={3}>
                          <h5>Possession:</h5>
                          <Box>{record.posession}</Box>
                        </Grid>
                        <Grid item md={3}>
                          <h5>Property Type:</h5>
                          <Box>{record.project_type}</Box>
                        </Grid>
                        <Grid item md={3}>
                          <h5>Build Type:</h5>
                          <Box>{record.project_build_type}</Box>
                        </Grid>
                      </Grid>
                      <Divider />

                      <Grid container item spacing={2} style={{ marginTop: '10px' }}>
                        <Grid item md={3}>
                          <h5>Area:</h5>
                          <Box>{record.area} sq/ft</Box>
                        </Grid>
                        <Grid item md={3}>
                          <h5>Room Type:</h5>
                          <Box>{record.description}</Box>
                        </Grid>
                        <Grid item md={3}>
                          <h5>Budget:</h5>
                          <Box>Rs.{record.project_budget}</Box>
                        </Grid>
                        <Grid item md={3}>
                          <h5>Work Start Date:</h5>
                          <Box>{record.work_start}</Box>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container item spacing={2} style={{ marginTop: '10px' }}>
                        <Grid item md={3}>
                          <h5>Location/Society:</h5>
                          <Box>{record.project_address}</Box>
                        </Grid>
                        <Grid item md={3}>
                          <h5>Floor Plan:</h5>
                          {record?.floor_plan === 1 ? (
                            <Box>Available</Box>
                          ) : (
                            <Box>Not Available</Box>
                          )}
                        </Grid>
                      </Grid>
                      <Divider />
                    </Grid>

                    <Grid item md={3}>
                      <h3>Varifier Comments</h3>
                      <Box>Purpose:{record.project_description}</Box>
                      <Box>Address/Society name:{record.project_address}</Box>
                      <Box>Budget:{record.project_budget}</Box>
                      <Box>I Own a:Apartment</Box>
                      <Box>Rooms:{record.description}</Box>
                      <Box>Do You Have Possession ?:{record.posession}</Box>
                      <h5>
                        Priority:
                        {record?.floor_plan === 1 ? (
                          <Button
                            variant="contained"
                            style={{ background: 'red', marginLeft: '5px' }}
                          >
                            Hot
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            style={{ background: 'yellow', marginLeft: '5px' }}
                          >
                            Cold
                          </Button>
                        )}
                      </h5>
                      <Grid>
                        <h3>Wallet Points :{record.pt_cost}</h3>
                        <Button
                          color="success"
                          variant="contained"
                          style={{ marginLeft: '40px' }}
                          onClick={() => this.buylead(record.id, true)}
                        >
                          Buy Lead
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Item>
              </div>
              <div>
                {record.areas.length > 0 ? (
                  <Grid style={{ marginLeft: '20px' }}>
                    <div>
                      <h4 style={{ marginLeft: '20px' }}>Work Scope</h4>
                    </div>
                    <Grid style={{ marginLeft: '20px' }}>
                      <div>
                        <FormControl variant="outlined" style={{ width: '120px' }}>
                          <InputLabel>Choose Area</InputLabel>
                          <Select
                            onChange={this.handleAreaChange}
                            value={selectedArea}
                            label="Choose Area"
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {record.areas.map((area, index) => (
                              <MenuItem
                                key={index}
                                value={`Floor ${area.floor} ${area.project_area_name}`}
                              >
                                {`Floor ${area.floor} ${area.project_area_name}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        {selectedTab === 0 && (
                          <div>
                            {selectedArea ? (
                              <Grid container md="12">
                                <Grid item md={4}>
                                  Fitouts
                                  {record.areas.map((fs) => (
                                    <Box key={fs.id}>
                                      {fs.fitout_scope &&
                                        fs.fitout_scope.split('|').map((service, index) => (
                                          <Box style={{ marginTop: '10px' }}>
                                            <Button key={index} variant="contained" color="primary">
                                              {service.trim()} {/* Trim to remove extra spaces */}
                                            </Button>
                                          </Box>
                                        ))}
                                    </Box>
                                  ))}
                                </Grid>
                                <Grid item md={4}>
                                  Modulars
                                  {record.areas.map((fs) => (
                                    <Box key={fs.id}>
                                      {fs.modular_scope &&
                                        fs.modular_scope.split('|').map((service, index) => (
                                          <Box style={{ marginTop: '10px' }}>
                                            <Button key={index} variant="contained" color="primary">
                                              {service.trim()} {/* Trim to remove extra spaces */}
                                            </Button>
                                          </Box>
                                        ))}
                                    </Box>
                                  ))}
                                </Grid>
                                <Grid item md={4}>
                                  Furniture
                                  {record.areas.map((fs) => (
                                    <Box key={fs.id}>
                                      {fs.furniture_scope &&
                                        fs.furniture_scope.split('|').map((service, index) => (
                                          <Box style={{ marginTop: '10px' }}>
                                            <Button key={index} variant="contained" color="primary">
                                              {service.trim()} {/* Trim to remove extra spaces */}
                                            </Button>
                                          </Box>
                                        ))}
                                    </Box>
                                  ))}
                                </Grid>
                              </Grid>
                            ) : null}
                            <Divider />
                          </div>
                        )}

                        {/* {selectedTab === 1 && (
                          //drop down for Room / Area
                          <Grid container item md={12} alignItems="center"></Grid>
                        )} */}
                      </div>
                    </Grid>
                  </Grid>
                ) : null}
              </div>
            </Grid>
          ))}
        <Divider />
      </Grid>
    );
  }
}

export default QualifiedLeads;
