import React, { Component } from 'react';
import { Button } from '@mui/material';

/* 
    How to render 
     <LoadButton
        ftype={ftype}
        callback={(e) => this.handleFunction(e, ftype.action, item.id)}
      />

    *** Defination of fields:
    
    callback: "Event to occur on click  of button"
    ftype:"Particular field ftype Format type object
    {
      optionType:"Defines what type of Values are to be Populated['object'/'array']",
      optionMap: this Field is mandatory only when optionType=>object 
      {
        label: "Name of the field whose value should be printed as menu label"
        value: "Name of the Field whose value should be printed as manu value"
      }
      color:"primary by default"
    }
    "
 */
class LoadButton extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <Button
        style={{ margin: '10px' }}
        variant="contained"
        color={this.props.ftype.color}
        onClick={(e) => this.props.callback(e)}
      >
        {this.props.ftype.placeholder}
      </Button>
    );
  }
}

export default LoadButton;
