import React from "react";
import config from '../constants/index.jsx';
import swal from "sweetalert";

const sendAsynchrnousCall=(postData,servicesUrl)=>{
    const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(postData)
        };
    return fetch(servicesUrl,requestOptions)
    .then(resp=>resp.json())
    .then(json=>{
    	return json;
    });
}
const openLink=(url)=>{
    var win = window.open(url, '_blank');
    if (win) {
        //Browser has allowed it to be opened
        win.focus();
    } else {
        //Browser has blocked it
        alert('Please allow popups for this website');
    }
}

const navigateLink=(url)=>{
    var win = window.open(url, '_self');
    if (win) {
        //Browser has allowed it to be opened
        win.focus();
    } else {
        //Browser has blocked it
        alert('Please allow popups for this website');
    }
}
const showAlert=(json)=>{
	swal({
         title: json.status==='success'?'Success !!':'Error !!',
         text: json.message,
         type: json.status,
         timer: 3000
         });
}

const fetchUrl=(url)=>{
    let retdata=[];
    return fetch(url)
        .then(res =>  res.json())
        .then(json => {                
            retdata=json.data;
            return retdata;
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
}

 const Postie={
    sendAsynchrnousCall,
 	openLink,
 	showAlert,
    navigateLink,
    fetchUrl,
    
 }

 export default Postie;