import React, { Component } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import FileView from 'app/views/bids/components/fileView';
import displayer from 'app/helpers/displayer';
import UploadFile from 'app/views/files/uploadFile';
import Auth from 'app/helpers/authorization';
import Postie from 'app/helpers/postie';
import config from '../../../constants/index.jsx';

class ChangeServiceImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      formdata: [],
      boqtype: {
        name: '',
        value: this.props.serviceData ? this.props.serviceData.workcategory?.boq_type : ''
      }
    };
  }

  handleFile = async (file) => {
    let piqqeToken = Auth.piqqeTokenizer();
    console.log('piqqeToken', piqqeToken);
    let postData = {
      token: piqqeToken,
      areaId: this.props.areaId,
      view: this.props.elevation
    };
    let url = config.BASE_API_URL + 'cms/project/addView';
    let json = await Postie.sendAsynchrnousCall(postData, url);
    console.log('json', json);
    if (json.status === 'success') {
      let postData1 = {
        token: piqqeToken,
        Image: file.data.url,
        view_id: json.data.id
      };
      let url1 = config.BASE_API_URL + 'cms/project/addRender';
      let json1 = await Postie.sendAsynchrnousCall(postData1, url1);
      if (json1.status === 'success') {
        this.props.handleSelectImage(file.data.url);
      }
    }
  };
  render() {
    const { imageData } = this.props;
    console.log('imageData', imageData);

    return (
      <>
        <Dialog
          open={this.props.setOpenPopup}
          onClose={this.props.closePopup}
          fullWidth
          maxWidth="md"
        >
          <Grid style={{ display: 'inline-flex' }}>
            <DialogTitle>Select Another Image</DialogTitle>
            {/* <Button
              variant="contained"
              color="warning"
              type="button"
              style={{ float: 'right', margin: '10px' }}
              onClick={() => this.props.handleOpenGallery()}
            >
              Select From Gallery
            </Button> */}
            <Grid style={{ margin: '15px' }}>
              <UploadFile
                filename="From Local"
                displayTosection={false}
                areaName=""
                type="project_files"
                projectId={this.props.projectId}
                areaId={this.props.areaId}
                callback={(File) => this.handleFile(File)}
                showAlert={false}
                fullWidth
              />
            </Grid>
            <Grid style={{ marginLeft: '370px', marginTop: '10px' }}>
              <Button onClick={this.props.closePopup} color="secondary" style={{ float: 'right' }}>
                Close
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <Grid container spacing={2} style={{ margin: '5px' }}>
              {imageData &&
                imageData?.map((image) => (
                  <Grid item md={4} sx={{ border: 1, padding: '5px' }}>
                    <FileView
                      key={image.id}
                      id={image.id}
                      url={displayer.absolutingImageURl(image?.image)}
                      style={{ height: '300px', width: '90%' }}
                    />
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        type="button"
                        color="warning"
                        variant="contained"
                        onClick={() => this.props.handleSelectImage(image.image)}
                      >
                        Select
                      </Button>
                    </div>
                  </Grid>
                ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.closePopup} color="secondary">
              Close
            </Button>
            {/* <Button onClick={this.submitService} color="primary">
            Submit
          </Button> */}
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default ChangeServiceImage;
