import React, { Component } from 'react';
import {
  Grid,
  Tabs,
  Tab,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  styled,
  Box,
  Button
} from '@mui/material';

import Postie from 'app/helpers/postie';
import displayer from 'app/helpers/displayer';
import Auth from 'app/helpers/authorization';
import config from 'app/constants/index';
import ScopeView from './components/ScopeView';
import ServiceView from './components/ServiceView';
import WorkscopeHeader from './components/WorkscopeHeader';
import ModularListRow from '../Invoice/components/ModularListRow';
import { green } from '@mui/material/colors';
import { id } from 'date-fns/locale';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const WorkscopeRoot = styled(FlexBox)(() => ({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center'
}));

const ContractorMenu = (props) => <></>;
class EditWorkscope extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: '',
      fields: [],
      data: null,
      dataLoaded: false,
      helpline_no: 1800000000,
      start_date: new Date(2023, 9, 10).toLocaleDateString(),
      end_date: new Date(2023, 9, 30).toLocaleDateString(),
      selectedTab: 'fitouts',
      fitoutSelectedarea: '',
      selectedWorkCategory: '', // Track selected Work Category
      selectedContractor: '', // Track selected Contractor
      elevationData: '',
      areaId: '',
      modularType: '',
      modularSelectedarea: ''
    };
  }

  componentDidMount = () => {
    const queryParams = displayer.getUrlParams('/bid/edit/workscope/');
    console.log(queryParams);
    this.loadData(queryParams[0]);
  };

  loadData = async (id) => {
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url = config.BASE_API_URL + 'project/workscope/' + id + '/' + Auth.urlTokenizer();
      let jsonData = await Postie.fetchUrl(url);
      //console.log('sata', jsonData);
      if (jsonData) {
        this.setState(
          {
            ...this.state,
            data: jsonData,
            dataLoaded: true // Set dataLoaded to true when data is loaded
          },
          function () {
            //console.log('Workscope Data', this.state);
          }
        );
      }
    }
  };
  getElevationDetails = async (area, type) => {
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url =
        config.BASE_API_URL + 'elevationsforArea/' + area + '/' + type + '/' + Auth.urlTokenizer();
      let jsonData = await Postie.fetchUrl(url);
      //console.log('sata', jsonData);
      if (jsonData) {
        this.setState(
          {
            ...this.state,
            elevationData: jsonData,
            dataLoaded: true // Set dataLoaded to true when data is loaded
          },
          function () {
            console.log('Elevation  Data ', this.state.elevationData);
          }
        );
      }
    }
  };

  handleTabChange = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };

  onAreaSelect = (event, tabkey) => {
    const { name, value } = event.target;
    this.setState({ ...this.state, [name]: value }, function () {
      console.log('area', name, value, this.state[name]);
    });
    if (tabkey === 'modulars') {
      this.getElevationDetails(
        this.state.data.workScopes[tabkey][this.state.modularType][value].id,
        this.state.modularType
      );
    } else {
      this.getElevationDetails(this.state.data.workScopes[tabkey][value].id, tabkey);
    }
  };
  modularTypeSelect = (type) => {
    this.setState({ ...this.state, modularType: type }, function () {
      //console.log('select Modular Type', this.state, this.state.modularType);
    });
  };
  onWorkCategorySelect = (selectedWorkCategory) => {
    this.setState({ selectedWorkCategory: selectedWorkCategory }, function () {
      console.log('selectedWorkCategory', selectedWorkCategory);
    });
  };

  onContractorSelect = (selectedContractor) => {
    this.setState({ selectedContractor: selectedContractor }, function () {
      console.log('selectedContractor', selectedContractor);
    });
  };

  downloadCutlist = async (modularType, id) => {
    let area = this.state.data.workScopes.modulars[modularType][id];
    console.log('Cutlist area', area);
    let estimateId = area.units && area.units.length > 0 ? area.units[0].estimate_id : '';
    console.log('estimateId', estimateId);
    let url = config.BASE_API_URL + 'modular/cuttinglist/' + modularType + '/' + estimateId;
    let jsonData = await Postie.fetchUrl(url);
    console.log('jsonData', jsonData);
    if (jsonData) {
      let fileUrl = jsonData.filepath;
      let absoluteUrl = config.FILES_API_URL + fileUrl;
      console.log('absoluteUrl', absoluteUrl);
      Postie.openLink(absoluteUrl);
    }
  };
  render() {
    const {
      data,
      dataLoaded,
      selectedTab,
      fitoutSelectedarea,
      selectedWorkCategory,
      selectedContractor,
      modularType,
      modularSelectedarea,
      elevationData
    } = this.state;
    //console.log('data', data);
    console.log('seleced area', fitoutSelectedarea, data?.workScopes?.modulars);

    const fitOutsAreas = dataLoaded ? Object.keys(data?.workScopes?.fitouts) : [];
    const ModularTypes = dataLoaded ? Object.keys(data?.workScopes?.modulars) : [];
    const ModularTypeAreas =
      dataLoaded && modularType ? Object.keys(data?.workScopes?.modulars[modularType]) : [];

    console.log('selectedModularType', modularSelectedarea);

    // Only render if data is loaded
    if (!dataLoaded) {
      return (
        <Grid
          container
          md={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Center the loading message
            height: '100vh' // Center vertically
          }}
        >
          <h3>Loading data...</h3>
        </Grid>
      );
    }

    // Render the tables with the loaded data
    return (
      <WorkscopeRoot>
        <Grid
          container
          style={{ display: 'flex', alignItems: 'center', padding: '30px', marginLeft: '20px' }}
        >
          <Tabs
            value={selectedTab}
            onChange={this.handleTabChange}
            style={{ color: '#0000FF', padding: '8px' }}
          >
            <Tab label="Fitout" value="fitouts" style={{ color: '#0000FF', padding: '8px' }} />
            <Tab label="Modular" value="modulars" style={{ color: '#0000FF', padding: '8px' }} />
            <Tab label="Furniture" value="furniture" style={{ color: '#0000FF', padding: '8px' }} />
            <Tab label="Products" value="products" style={{ color: '#0000FF', padding: '8px' }} />
          </Tabs>

          {selectedTab === 'fitouts' && (
            //drop down for Room / Area
            <Grid container md={12} alignItems="center">
              <Grid item md={2}>
                <h3>Room /Area :</h3>
              </Grid>

              <Grid item md={10}>
                <FormControl variant="outlined" sx={{ width: '30%' }}>
                  <InputLabel>Choose Room:</InputLabel>
                  <Select
                    label="Choose Room:"
                    name="fitoutSelectedarea"
                    defaultValue={this.state.selectedArea}
                    onChange={(event) => this.onAreaSelect(event, 'fitouts')}
                  >
                    {fitOutsAreas.map((area, index) =>
                      data.workScopes['fitouts'][area].services.length > 0 ? (
                        <MenuItem key={index} value={area} id={data.workScopes['fitouts'][area].id}>
                          {data.workScopes['fitouts'][area].area_name}
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {fitoutSelectedarea && (
                <Grid container md={12} alignItems="center">
                  <Grid item md={6}>
                    <Grid item md={6}>
                      <h3>Work Categories:</h3>
                    </Grid>

                    <Grid item md={6}>
                      <FormControl variant="outlined" sx={{ width: '90%' }}>
                        <InputLabel>Choose Work Category:</InputLabel>
                        <Select
                          defaultValue={this.state.selectedWorkCategory}
                          label="Choose Work Category:"
                          onChange={(event) => this.onWorkCategorySelect(event.target.value)}
                        >
                          {console.log(
                            'fitout selected Area',
                            data?.workScopes?.['fitouts'][fitoutSelectedarea]
                          )}
                          {data?.workScopes?.['fitouts'][fitoutSelectedarea].sows?.map(
                            (sow, index) => (
                              <MenuItem key={index} value={sow}>
                                {sow}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>{' '}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {selectedWorkCategory && (
                <ScopeView
                  data={elevationData}
                  projectId={data.id}
                  area_id={data?.workScopes?.['fitouts'][fitoutSelectedarea].id}
                  callback={() =>
                    this.getElevationDetails(
                      data?.workScopes?.['fitouts'][fitoutSelectedarea].id,
                      'fitouts'
                    )
                  }
                />
              )}
              {selectedWorkCategory &&
                data?.workScopes?.['fitouts'][fitoutSelectedarea]?.services.map(
                  (cfg, cfgIndex) =>
                    cfg.servicesbreakup &&
                    cfg.servicesbreakup.map((service, serviceIndex) => (
                      <ServiceView
                        service={service}
                        cfg={cfg.cfg}
                        area={fitoutSelectedarea}
                        elevation={cfg.elevation}
                        key={serviceIndex}
                        projectId={data.id}
                        callback={() => this.loadData(data.id)}
                      />
                    ))
                )}
            </Grid>
          )}

          {selectedTab === 'modulars' && (
            <Grid container item md={12} alignItems="center">
              <Grid item md={12}>
                <h3>Modulars</h3>
              </Grid>
              <Grid item md={6}>
                <FormControl variant="outlined" sx={{ width: '70%' }}>
                  <InputLabel>Select Modular Type:</InputLabel>
                  <Select
                    label="Modular Type:"
                    defaultValue={this.state.selectedArea}
                    onChange={(event) => this.modularTypeSelect(event.target.value)}
                  >
                    {ModularTypes.map((type, index) => (
                      <MenuItem key={index} value={type} id={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {modularType && (
                <Grid item md={6}>
                  <FormControl variant="outlined" sx={{ width: '70%' }}>
                    <InputLabel>Select Area:</InputLabel>
                    <Select
                      label="Area:"
                      name="modularSelectedarea"
                      defaultValue={''}
                      onChange={(event) => this.onAreaSelect(event, 'modulars')}
                    >
                      {ModularTypeAreas &&
                        ModularTypeAreas.map((areaname, index) => {
                          let area = data.workScopes.modulars[modularType][areaname];
                          //console.log('area', area);
                          if (area?.units?.length > 0) {
                            return (
                              <MenuItem key={index} value={area.area_name} id={area.id}>
                                {area.area_name}
                              </MenuItem>
                            );
                          }
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {modularSelectedarea && (
                <ScopeView
                  data={elevationData}
                  projectId={data.id}
                  area_id={
                    data?.workScopes?.modulars[this.state.modularType][modularSelectedarea].id
                  }
                  callback={() =>
                    this.getElevationDetails(
                      data?.workScopes?.modulars[this.state.modularType][modularSelectedarea].id,
                      this.state.modularType
                    )
                  }
                />
              )}
              {modularSelectedarea &&
                data.workScopes.modulars[this.state.modularType][modularSelectedarea].units.map(
                  (service, index) => (
                    <ModularListRow
                      key={index}
                      data={service}
                      showDetail={true}
                      displayCosting={false}
                    />
                  )
                )}
            </Grid>
          )}

          {selectedTab === 'furniture' && (
            //drop down for Room / Area
            <Grid container item md={12} alignItems="center"></Grid>
          )}
          {selectedTab === 'products' && (
            //drop down for Room / Area
            <Grid container item md={12} alignItems="center"></Grid>
          )}
        </Grid>
      </WorkscopeRoot>
    );
  }
}

export default EditWorkscope;
