import React, { Component } from 'react';
import { Grid, TableCell, TableRow, TableBody, Table, Chip, TableHead } from '@mui/material';
import FileView from 'app/views/bids/components/fileView';
import displayer from 'app/helpers/displayer';
import UploadFile from 'app/views/files/uploadFile';
import LoadCheckBox from './LoadCheckBox';
import LoadTextField from './LoadTextField';
import LoadButton from './LoadButton';
import LoadRadioGroup from './LoadRadioGroup';
import LoadDropDown from './LoadDropdown.jsx';
import LoadCheckList from './LoadCheckList.jsx';
import Postie from 'app/helpers/postie';
import FilterData from './FilterData';
import SingleTonTags from './SingleTonTags';
import $ from 'jquery';

class ConnectionDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = { filterType: [] };
  }
  componentDidMount() {
    console.log('ConnectionDataTable is called', this.props, this.props.apiCalls);
  }

  handleInputChange = (e) => {
    let selected = [];
    $('[name="' + e.target.name + '"]:checked').each((elem, input) => {
      selected.push(input.value);
    });
    let value = selected.join('|');
    //console.log('value', value);
    if (value === '') {
      value = 'None';
    }
    //console.log(e.target.name, selected.join('|'));
    let sarr = e.target.name.split('-');
    let elem = { target: { name: sarr[0], value: value } };
    this.props.callback(elem, 'update', parseInt(sarr[1].substring(0, sarr[1].length - 2)));
  };

  handleFile = (resp, type, identifier) => {
    let elem = { target: { name: type, value: resp.data.url, identifier: identifier } };
    //console.log('file added', resp);
    this.props.callback(elem, 'update', identifier);
  };

  render() {
    const { fields, data, filters } = this.props;
    //console.log('connectionDataTable', fields, data,filters);
    const filterKeys = filters ? Object.keys(filters) : [];
    const isSuggestion = this.props.showSuggesstion;
    const isConnect = this.props.isConnect;
    console.log('isSuggestion', isSuggestion, this.props);
    return (
      <Grid container>
        <Grid item md="12" xs="12">
          {fields?.map(
            (record) =>
              filterKeys.includes(record.dbname) && (
                <Chip
                  key={record.dbname}
                  label={`${record.label} : ${
                    filters[record.dbname].value
                      ? filters[record.dbname].value
                      : filters[record.dbname]
                  }`}
                ></Chip>
              )
          )}
        </Grid>
        <Grid item md="12" xs="12">
          <Table>
            {this.props.isApiFilterable || this.props.isConnect ? (
              <FilterData
                key={this.props.userFilters ? btoa(JSON.stringify(this.props.userFilters)) : data}
                fields={fields}
                Heading={this.props.heading}
                filterKeys={filterKeys}
                filterType={this.state.filterType}
                apiCalls={this.props.apiCalls}
                userFilters={this.props.userFilters}
                defaultValue={this.props.filters}
                FilterData={(event) => this.props.filterData(event)}
              />
            ) : (
              <TableHead>
                <TableRow>
                  {fields?.map(
                    (record) =>
                      !filterKeys.includes(record.dbname) && (
                        <TableCell key={`${record.dbname}`}>
                          <p>{record.label}</p>
                        </TableCell>
                      )
                  )}
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {data?.records &&
                data?.records?.map((item) =>
                  !item.alreadyMapped ? (
                    <TableRow key={item.id}>
                      {fields?.map(
                        (record) =>
                          !filterKeys.includes(record.dbname) && (
                            <TableCell key={`${item.id}-${record.dbname}`}>
                              {record.ftype?.map((ftype, index) => (
                                <div key={`${item.id}${record.dbname}${index}`}>
                                  {ftype.type === 'text' && <p>{item[record.dbname]}</p>}
                                  {ftype.type === 'textBox' && (
                                    <LoadTextField
                                      record={record}
                                      disabled={ftype.disabled || isSuggestion}
                                      name={record.dbname ? record.dbname : ftype.dbname}
                                      placeholder={ftype.placeholder}
                                      defaultValue={
                                        item[record.dbname ? record.dbname : ftype.dbname]
                                      }
                                      //callback={(e) => this.handleFunction(e, ftype.action, item.id)}
                                      callback={(e) =>
                                        this.props.callback(e, ftype.action, item.id)
                                      }
                                    />
                                  )}
                                  {ftype.type === 'dropdown' && (
                                    <LoadDropDown
                                      record={record}
                                      disabled={ftype.disabled || isSuggestion}
                                      defaultValue={
                                        item[record.dbname ? record.dbname : ftype.dbname]
                                      }
                                      //callback={(e) => this.handleFunction(e, ftype.action, item.id)}
                                      callback={(e) =>
                                        this.props.callback(e, ftype.action, item.id)
                                      }
                                      ftype={ftype}
                                    />
                                  )}
                                  {ftype.type === 'file' && item[record['dbname']] && (
                                    <FileView
                                      id={`file-${item.id}`}
                                      url={displayer.absolutingImageURl(item[record['dbname']])}
                                    />
                                  )}
                                  {ftype.type === 'file' && !ftype.disabled && !isSuggestion && (
                                    <UploadFile
                                      style={{ float: 'right' }}
                                      Filename={record['dbname']}
                                      displayTosection={false}
                                      areaName={''}
                                      type={record['dbname']}
                                      projectId={0}
                                      areaId={0}
                                      callback={(File) =>
                                        this.handleFile(File, record['dbname'], item.id)
                                      }
                                    />
                                  )}
                                  {ftype.type === 'checkList' && (
                                    <LoadCheckList
                                      record={record}
                                      ftype={ftype}
                                      optionChoosen={item[ftype.optionId]}
                                      //callback={(e) => this.handleInputChange(e)}
                                      callback={(e) =>
                                        this.props.callback(e, ftype.action, item.id)
                                      }
                                      disabled={ftype.disabled}
                                      selectedValues={
                                        item[record.dbname] ? item[record.dbname].split('|') : []
                                      }
                                      itemid={item['id']}
                                    />
                                  )}
                                  {ftype.type === 'list' && (
                                    <LoadRadioGroup
                                      //callback={(e) => this.handleFunction(e, ftype.action, item.id)}
                                      callback={(e) => this.handleInputChange(e)}
                                      disabled={ftype.disabled || isSuggestion}
                                      ftype={ftype}
                                    />
                                  )}

                                  {ftype.type === 'checkBox' && (
                                    <LoadCheckBox
                                      record={record}
                                      ftype={ftype}
                                      //callback={}
                                      callback={(e) => this.handleInputChange(e)}
                                      disabled={ftype.disabled || isSuggestion}
                                      selectedValues={
                                        item[record.dbname] ? item[record.dbname].split('|') : []
                                      }
                                      itemid={item['id']}
                                    />
                                  )}
                                  {ftype.type === 'checkList' && (
                                    <LoadCheckList
                                      record={record}
                                      ftype={ftype}
                                      optionChoosen={item[ftype.optionId]}
                                      callback={(e) => this.handleInputChange(e)}
                                      disabled={ftype.disabled || isSuggestion}
                                      selectedValues={
                                        item[record.dbname] ? item[record.dbname].split('|') : []
                                      }
                                      itemid={item['id']}
                                    />
                                  )}
                                  {ftype.type === 'textArea' && (
                                    <LoadTextField
                                      multiline={true}
                                      record={record}
                                      disabled={ftype.disabled || isSuggestion}
                                      name={record.dbname ? record.dbname : ftype.dbname}
                                      placeholder={ftype.placeholder}
                                      defaultValue={
                                        item[record.dbname ? record.dbname : ftype.dbname]
                                      }
                                      //callback={(e) => this.handleFunction(e, ftype.action, item.id)}
                                      callback={(e) =>
                                        this.props.callback(e, ftype.action, item.id)
                                      }
                                    />
                                  )}
                                  {ftype.type === 'link' && !isSuggestion && !isConnect && (
                                    <LoadButton
                                      ftype={ftype}
                                      callback={(e) => Postie.navigateLink(ftype.url + item.id)}
                                    />
                                  )}
                                  {ftype.type === 'number' && (
                                    <LoadTextField
                                      type="number"
                                      record={record}
                                      disabled={ftype.disabled || isSuggestion}
                                      name={record.dbname ? record.dbname : ftype.dbname}
                                      placeholder={ftype.placeholder}
                                      defaultValue={
                                        item[record.dbname ? record.dbname : ftype.dbname]
                                      }
                                      //callback={(e) => this.handleFunction(e, ftype.action, item.id)}
                                      callback={(e) =>
                                        this.props.callback(e, ftype.action, item.id)
                                      }
                                    />
                                  )}

                                  {ftype.type === 'autocomplete' && (
                                    <SingleTonTags
                                      options={this.state.filterType[record.dbname]?.options}
                                      label={record.label}
                                      placeholder={record.label}
                                      baseModel={record.dbname}
                                      defaultValue={item[record.dbname]}
                                      handleChange={(data) => {
                                        let elem = {
                                          target: { name: record.dbname, value: data }
                                        };
                                        this.props.callback(elem, ftype.action, item.id);
                                      }}
                                    />
                                  )}

                                  {ftype.type === 'Button' && !isConnect && !isSuggestion && (
                                    <LoadButton
                                      ftype={ftype}
                                      callback={(e) =>
                                        this.props.callback(e, ftype.action, item.id)
                                      }
                                    />
                                  )}

                                  {ftype.type === 'Button' && isSuggestion && (
                                    <LoadButton
                                      ftype={{
                                        type: 'Button',
                                        placeholder: 'Copy',
                                        action: 'copy',
                                        color: 'secondary',
                                        dbname: 'status'
                                      }}
                                      callback={(e) => this.props.callback(e, 'copy', item)}
                                    />
                                  )}

                                  {ftype.type === 'Button' && isConnect && !item.alreadyMapped && (
                                    <LoadButton
                                      ftype={{
                                        type: 'Button',
                                        placeholder: 'Connect',
                                        action: 'connect',
                                        color: 'secondary',
                                        dbname: 'status'
                                      }}
                                      callback={(e) => this.props.callback(e, item.id, item)}
                                    />
                                  )}
                                </div>
                              ))}
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  ) : null
                )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }
}
export default ConnectionDataTable;
