import React, { Component } from 'react';
import { FormControlLabel, FormControl, RadioGroup, Radio } from '@mui/material';
/* 
    How to render 
     <LoadRadioGroup
      record={record}
      ftype={ftype}
      callback={this.handleInputChange}
    />
 

    *** Defination of fields:
    record: Object {label:"Value to be Presented", dbName:"name in data object",ftype:"to have an object of format type"} 
    defaultValue: The value to be selected by default
    callback: "Event to occur on change of dropdown"
    ftype:"Particular field ftype Format type object
    {
      optionType:"Defines what type of Values are to be Populated['object'/'array']",
      optionMap: this Field is mandatory only when optionType=>object 
      {
        label: "Name of the field whose value should be printed as menu label"
        value: "Name of the Field whose value should be printed as manu value"
      }
    }
    "
 */

class LoadRadioGroup extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <FormControl component="fieldset">
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name={this.props.record.dbname}
          value={this.props.record.value || ''}
          onChange={(e) => this.props.callback(e)}
          color="primary"
        >
          {this.props.ftype.options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option}
              control={<Radio color="secondary" />}
              label={option}
              color="primary"
              disabled={this.props.disabled}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
}

export default LoadRadioGroup;
