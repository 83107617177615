import React, { Component } from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TextField,
  Paper,
  Chip
} from '@mui/material';
import config from '../../../constants/index.jsx';
import Auth from 'app/helpers/authorization';
import displayer from 'app/helpers/displayer';
import { AccountCircle, Email, IosShare, Phone } from '@mui/icons-material';
import DoughnutChart from 'app/views/dashboard/shared/Doughnut';
import Postie from 'app/helpers/postie.jsx';

class AddServiceInBoq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      formdata: [],
      boqtype: { name: '', value: '' },
      showDimensions: false,
      showQuantity: false
    };
  }
  onSelectBoqType = (e) => {
    const { name, value } = e.target;
    console.log('onBoqSelect', name, value);

    this.setState(
      {
        boqtype: { name: name, value: value }
      },
      () => {
        console.log('boqtype', this.state.boqtype);
      }
    );
  };
  onBoqSelect = (e) => {
    const { name, value } = e.target;
    console.log('onBoqSelect', name, value);

    if (name === 'uom' && (value === 'Sq.Ft' || value === 'R.Ft')) {
      this.setState({
        ...this.state,
        showDimensions: true,
        showQuantity: false
      });
    } else if (name === 'uom' && value === 'No.s') {
      this.setState({
        ...this.state,
        showQuantity: true,
        showDimensions: false
      });
    } else if (name === 'uom' && value === 'L.S') {
      this.setState({
        ...this.state,
        showQuantity: false,
        showDimensions: false
      });
    }

    this.setState(
      (prevState) => ({
        formdata: [...prevState.formdata, { name: name, value: value }]
      }),
      () => {
        console.log('onBoqSelect', this.state.formdata);
      }
    );
  };

  getElevationImage = () => {
    for (const view of this.props.serviceEstimateData.views) {
      const record = this.state.formdata.find((record) => record.value === view.name);
      if (record) {
        return view.image;
      }
    }
    return null; // Return null if no image is found
  };

  handleCreateEstimate = async (estimateType) => {
    const postData = {
      token: Auth.urlTokenizer(),
      projectId: this.props.projectId,
      estimate_work_type: estimateType
    };
    const url = config.BASE_API_URL + 'cms/estimate/create';
    const json = await Postie.sendAsynchrnousCall(postData, url);
    if (json.status === 'success') {
      console.log('json', json);
      return json;
    }
  };

  submitService = async () => {
    let estimateId =
      this.state.boqtype.value === 'modulars'
        ? this.props.modularId
        : this.state.boqtype.value === 'fitouts'
        ? this.props.fitoutId
        : this.props.furnitureId;
    if (!estimateId) {
      let type =
        this.state.boqtype.value === 'modulars'
          ? 3
          : this.state.boqtype.value === 'fitouts'
          ? 1
          : 2;
      let json = await this.handleCreateEstimate(type);
      estimateId = json.data.id;
    }
    if (estimateId) {
      let postData = {
        token: Auth.urlTokenizer(),
        projectId: this.props.projectId,
        estimateId: estimateId,

        image: this.getElevationImage(),
        areaId: this.props.serviceEstimateData.id,
        category: this.state.boqtype.value
      };

      let width = 1; // Default value if width is not present
      let length = 1; // Default value if length is not present
      let qtyPresent = false;

      this.state.formdata.forEach((record) => {
        postData[record.name] = record.value;
        if (record.name === 'qty') {
          qtyPresent = true;
        } else if (record.name === 'width') {
          width = parseFloat(record.value) || 1;
        } else if (record.name === 'length') {
          length = parseFloat(record.value) || 1;
        }
      });

      if (!qtyPresent) {
        postData['qty'] = width * length;
      }

      console.log('postData', postData);
      let url = config.BASE_API_URL + 'cms/estimate/addservice';
      let json = await Postie.sendAsynchrnousCall(postData, url);
      console.log('json', json);
      if (json.status === 'success') {
        this.props.closePopup();
        this.props.callBack();
      }
    }
  };

  // submitService = async () => {
  //   let postData = {
  //     token: Auth.urlTokenizer(),
  //     projectId: this.props.projectId,
  //     estimateId:
  //       this.state.boqtype.value === 'modulars'
  //         ? this.props.modularId
  //         : this.state.boqtype.value === 'fitouts'
  //         ? this.props.fitoutId
  //         : this.props.furnitureId,
  //     image: this.getElevationImage(),
  //     areaId: this.props.serviceEstimateData.id,
  //     category: this.state.boqtype.value
  //   };

  //   this.state.formdata.forEach((record) => {
  //     postData[record.name] = record.value;
  //   });

  //   console.log('postData', postData);
  //   let url = config.BASE_API_URL + 'cms/estimate/addservice';
  //   let json = await Postie.sendAsynchrnousCall(postData, url);
  //   console.log('json', json);
  //   if (json.status === 'success') {
  //     this.props.closePopup();
  //     this.props.callBack();
  //   }
  // };

  render() {
    const { serviceEstimateData, serviceData } = this.props;
    console.log('serviceEstimateData', serviceEstimateData);
    //console.log('estimate Id', this.props.serviceEstimateData?.services[0]?.estimate_id);

    return (
      <Dialog
        open={this.props.setOpenPopup}
        onClose={this.props.closePopup}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Add Service
          <Button onClick={this.props.closePopup} sx={{ float: 'right' }} color="secondary">
            Close
          </Button>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs="12" sm="12" md="6">
              <FormControl variant="outlined" style={{ width: '95%' }}>
                <InputLabel>BOQ Type *</InputLabel>
                <Select
                  label="Choose Boq"
                  name="category"
                  onChange={(e) => this.onSelectBoqType(e)}
                  defaultValue=""
                >
                  <MenuItem key="" value="">
                    select boq
                  </MenuItem>
                  <MenuItem key="" value="fitouts">
                    Fitouts
                  </MenuItem>
                  <MenuItem key="" value="modulars">
                    Modulars
                  </MenuItem>
                  <MenuItem key="" value="furniture">
                    Furniture
                  </MenuItem>
                  <MenuItem key="" value="product">
                    Product
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {this.state.boqtype.value && (
            <Grid container spacing={2} style={{ marginTop: '3px' }}>
              <Grid item xs="12" sm="12" md="6">
                <FormControl variant="outlined" style={{ width: '95%' }}>
                  <InputLabel>Work Category *</InputLabel>
                  <Select
                    label="Choose Area"
                    name="sow_id"
                    onChange={(e) => this.onBoqSelect(e)}
                    defaultValue=""
                  >
                    <MenuItem key="" value="">
                      select work category
                    </MenuItem>
                    {this.state.boqtype.value.trim() === 'fitouts' &&
                      this.props.fitoutCategories.map((caterories, index) => (
                        <MenuItem key={index} value={caterories.id}>
                          {caterories.sow}
                        </MenuItem>
                      ))}
                    {this.state.boqtype.value.trim() === 'modulars' &&
                      this.props.modularCategories.map((caterories, index) => (
                        <MenuItem key={index} value={caterories.id}>
                          {caterories.sow}
                        </MenuItem>
                      ))}
                    {this.state.boqtype.value.trim() === 'furniture' &&
                      this.props.furnitureCategories.map((caterories, index) => (
                        <MenuItem key={index} value={caterories.id}>
                          {caterories.sow}
                        </MenuItem>
                      ))}
                    {this.state.boqtype.value.trim() === 'product' &&
                      this.props.ProductCategories &&
                      this.props.ProductCategories.map((caterories, index) => (
                        <MenuItem key={index} value={caterories.id}>
                          {caterories.sow}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs="12" sm="12" md="6">
                <FormControl variant="outlined" style={{ width: '95%' }}>
                  <TextField
                    label="Service Name *"
                    type="text"
                    name="service_name"
                    onBlur={(e) => this.onBoqSelect(e)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs="12" sm="12" md="6">
                <FormControl variant="outlined" style={{ width: '95%' }}>
                  <InputLabel>Elevation *</InputLabel>
                  <Select
                    label="Choose elevation"
                    name="view"
                    onChange={(e) => this.onBoqSelect(e)}
                    defaultValue=""
                  >
                    <MenuItem key="" value="">
                      select view
                    </MenuItem>
                    {serviceEstimateData &&
                      serviceEstimateData?.views?.map((view, index) => (
                        <MenuItem key={index} value={view.name}>
                          {view.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs="12" sm="12" md="6">
                <FormControl variant="outlined" style={{ width: '95%' }}>
                  <InputLabel>UOM *</InputLabel>
                  <Select
                    label="Choose uom"
                    name="uom"
                    onChange={(e) => this.onBoqSelect(e)}
                    defaultValue=""
                  >
                    <MenuItem key="" value="">
                      select uom
                    </MenuItem>
                    <MenuItem key="" value="Sq.Ft">
                      Sq.Ft
                    </MenuItem>
                    <MenuItem key="" value="R.Ft">
                      R.Ft
                    </MenuItem>
                    <MenuItem key="" value="L.S">
                      L.S
                    </MenuItem>
                    <MenuItem key="" value="No.s">
                      No.s
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {this.state.showDimensions && (
                <>
                  <Grid item xs="12" sm="12" md="6">
                    <FormControl variant="outlined" style={{ width: '95%' }}>
                      <TextField
                        label="Length*"
                        id={`length`}
                        key={`length`}
                        type="number"
                        name="length"
                        onBlur={(e) => this.onBoqSelect(e)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs="12" sm="12" md="6">
                    <FormControl variant="outlined" style={{ width: '95%' }}>
                      <TextField
                        label="Depth/Height*"
                        id={`Depth/Height`}
                        key={`Depth/Height`}
                        type="number"
                        name="width"
                        onBlur={(e) => this.onBoqSelect(e)}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              {this.state.showQuantity && (
                <Grid item xs="12" sm="12" md="6">
                  <FormControl variant="outlined" style={{ width: '95%' }}>
                    <TextField
                      label="Quantity*"
                      id={`Quantity`}
                      key={`Quantity`}
                      type="number"
                      name="qty"
                      onBlur={(e) => this.onBoqSelect(e)}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xs="12" sm="12" md="6">
                <FormControl variant="outlined" style={{ width: '95%' }}>
                  <TextField
                    label="Rate*"
                    type="number"
                    name="rate"
                    onBlur={(e) => this.onBoqSelect(e)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs="12" sm="12" md="6">
                <FormControl variant="outlined" style={{ width: '95%' }}>
                  <TextField
                    label="Finish "
                    type="text"
                    name="finish"
                    onBlur={(e) => this.onBoqSelect(e)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs="12" sm="12" md="6">
                <FormControl variant="outlined" style={{ width: '95%' }}>
                  <TextField
                    label="Base Material "
                    type="text"
                    name="base_material"
                    onBlur={(e) => this.onBoqSelect(e)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs="12" sm="12" md="6">
                <FormControl variant="outlined" style={{ width: '95%' }}>
                  <TextField
                    label="Make "
                    type="text"
                    name="brand"
                    onBlur={(e) => this.onBoqSelect(e)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs="12" sm="12" md="6">
                <FormControl variant="outlined" style={{ width: '95%' }}>
                  <TextField
                    label="remark"
                    type="text"
                    name="remark"
                    onBlur={(e) => this.onBoqSelect(e)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs="12" sm="12" md="6">
                <FormControl variant="outlined" style={{ width: '95%' }}>
                  <TextField
                    label="Exclusions"
                    type="text"
                    name="exclusions"
                    onBlur={(e) => this.onBoqSelect(e)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closePopup} color="secondary">
            Close
          </Button>
          <Button onClick={this.submitService} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default AddServiceInBoq;
