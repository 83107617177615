const applogins = {
  // Local :::::
  // BASE_API_URL: 'http://localhost:8000/v2/',
  // BASE_URL: 'http://localhost:3000/',
  // payment_url: 'http://localhost:3001/',
  // piqqe_url: 'http://localhost:4000/',
  // piqqe_ext: '',
  //

  // Live :::::
  BASE_API_URL: 'https://api2.avdaat.biz/v2/',
  BASE_URL: 'https://nexus2.avdaat.biz/',
  payment_url: 'https://payments.avdaat.biz/',
  piqqe_url: 'https://www.piqqe.io/',
  //piqqe_url: 'http://localhost:4000/',
  // piqqe_ext: '.html',

  //
  //Staging :::::
  // BASE_API_URL: "http://test-api.interiorlanes.in/api/",
  //BASE_URL:'http://staging.interiorlanes.in/',

  FILES_API_URL: '',

  //MENU TYPE::::: Possible Values['TPA','LPA','STUDIO']
  // TPA =>Testing Provider Associate
  //LPA =>Lead Provider Asscociate//
  MENU_TYPE: 'STUDIO',

  Token_name: 'nexusAuthTokenv1',
  inspiration_Token: 'inspAuthTokenV'
};
export default applogins;
