import React, { Component } from 'react';
import { Grid, Button } from '@mui/material';
import config from '../../../constants/index.jsx';
import Postie from 'app/helpers/postie';
import Auth from '../../../helpers/authorization';
import displayer from 'app/helpers/displayer';
import ProjectAreas from './ProjectAreas.jsx';
import Scope from './Scope.jsx';

class BriefingCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      scopeData: ''
    };
  }

  componentDidMount = () => {
    const queryParams = displayer.getUrlParams('/project/view/');
    //console.log(queryParams);
    this.getScopeDetails(queryParams[0]);
    //this.getElevationDetails(queryParams[0]);
  };

  updateStage = async (projectId, currentStage, desiredStage) => {
    let stage = await displayer.updateProjectstage(projectId, currentStage, desiredStage);
    if (stage) {
      this.props.callback();
    }
  };

  getScopeDetails = async () => {
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url =
        config.BASE_API_URL + 'servicesforArea/' + this.props.data.id + '/' + Auth.urlTokenizer();
      let jsonData = await Postie.fetchUrl(url);
      //console.log('sata', jsonData);
      if (jsonData) {
        this.setState(
          {
            ...this.state,
            scopeData: jsonData,
            dataLoaded: true // Set dataLoaded to true when data is loaded
          },
          function () {
            console.log('Briefing call service  ', this.state.scopeData);
          }
        );
      }
    }
  };

  render() {
    const { data, selectedArea, projectId, status } = this.props;
    const { scopeData } = this.state;
    console.log('Briefing Call Data', data);

    return (
      <Grid container>
        <Grid item md={12}>
          {this.props.status < 2 && (
            <Button
              variant="contained"
              onClick={() => this.updateStage(projectId, status, 'presales_briefing_updated')}
              color="success"
              style={{ float: 'right' }}
            >
              Briefing Done and Updated
            </Button>
          )}
          {parseInt(this.props.status) === 3 && (
            <Button
              variant="contained"
              onClick={() => this.updateStage(projectId, status, 'presales_inspiration_completed')}
              color="secondary"
              style={{ float: 'right' }}
            >
              Inspiration Ready to Share
            </Button>
          )}
        </Grid>
        <Grid item md={2} direction="column" spacing={2}>
          <h5>Areas</h5>
          {data.areas.map((area, index) => (
            <Grid
              item
              md="12"
              key={index}
              spacing="2"
              style={{ textAlign: 'left', margin: '2px 10px 5px 10px' }}
            >
              <Button
                color="primary"
                variant={selectedArea === area.id ? 'contained' : 'outlined'}
                onClick={() => this.props.changeTab(area.id)}
              >
                {area.project_display_name}
              </Button>
            </Grid>
          ))}
        </Grid>
        <Grid item md={10} spacing={2}>
          {data.areas &&
            data.areas.map((area) => (
              <>
                {selectedArea === area.id && (
                  <Scope
                    key={area.id}
                    data={area}
                    projectId={projectId}
                    callback={() => this.props.callback()}
                    Inspiration={this.props.inspirations ? this.props.inspirations[0] : null}
                    updateStage={() =>
                      this.updateStage(projectId, status, 'presales_inspiration_created')
                    }
                  />
                )}
              </>
            ))}
        </Grid>
      </Grid>
    );
  }
}

export default BriefingCall;
