import React, { Component } from 'react';
import { Grid, Tabs, Tab, Box, Chip, Divider } from '@mui/material';
import { Button } from '@mui/material';
import config from '../../constants/index.jsx';
import Postie from 'app/helpers/postie';
import displayer from 'app/helpers/displayer';
import Estimates from './components/Estimates.jsx';
import Typography from '@mui/material/Typography';
import { RemoveRedEye, ThumbDownAlt, ThumbUpAlt, WhatsApp } from '@mui/icons-material';
import Auth from 'app/helpers/authorization.jsx';

class ProjectEstimateView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      dataLoaded: false,
      selectedMainTab: 'Overview',
      selectedTab: 'BriefingCall',
      selectedArea: '',
      selectedEstimateTab: 'Fitouts',
      ex_site_pictures: false
    };
  }

  componentDidMount = () => {
    const queryParams = displayer.getUrlParams('/project/estimateview/', false);
    //this.getCMSValidation(queryParams[1], queryParams[0]);
    localStorage.setItem(config.Token_name, queryParams[1]);
    this.getProjectDetails(queryParams[0], false);
  };

  shareOnWhatsapp = (record) => {
    let url = 'https://web.whatsapp.com/send?text=';

    let type =
      parseInt(record.project_status) <= 14
        ? 'Presales BOQ'
        : parseInt(record.project_status) <= 24
        ? 'DOP BOQ'
        : 'Execution BOQ';
    // console.log('shareOnWhatsapp', record.estimate_type, type);
    let text =
      '*' +
      type +
      ' for Project (' +
      record.id +
      ')* ' +
      config.BASE_URL +
      'combine/estimates/' +
      record.id;
    url += text;
    this.forwardEstimates('whatsapp', record, url);
  };

  async forwardEstimates(type, record, url) {
    var serviceUrl = config.BASE_API_URL + 'estimate/forward';

    const fitoutpostData = {
      estimate_id: this.state.data.fitout_boq?.id,
      project_id: this.state.data.fitout_boq?.project_id,
      authToken: localStorage.getItem(config.Token_name),
      forwarded_by: type
    };
    const modularpostData = {
      estimate_id: this.state.data.modular_boq?.id,
      project_id: this.state.data.modular_boq?.project_id,
      authToken: localStorage.getItem(config.Token_name),
      forwarded_by: type
    };
    const productpostData = {
      estimate_id: this.state.data.product_boq?.id,
      project_id: this.state.data.product_boq?.project_id,
      authToken: localStorage.getItem(config.Token_name),
      forwarded_by: type
    };
    const furniturepostData = {
      estimate_id: this.state.data.furniture_boq?.id || '',
      project_id: this.state.data.furniture_boq?.project_id || '',
      authToken: localStorage.getItem(config.Token_name),
      forwarded_by: type
    };
    const apiCalls = [];

    this.state.data.fitout_boq &&
      apiCalls.push(Postie.sendAsynchrnousCall(fitoutpostData, serviceUrl));

    this.state.data.modular_boq &&
      apiCalls.push(Postie.sendAsynchrnousCall(modularpostData, serviceUrl));
    this.state.data.product_boq &&
      apiCalls.push(Postie.sendAsynchrnousCall(productpostData, serviceUrl));
    this.state.data.furniture_boq &&
      apiCalls.push(Postie.sendAsynchrnousCall(furniturepostData, serviceUrl));

    console.log('apiCalls', apiCalls);

    const results = await Promise.all(apiCalls);
    // Check if all calls were successful
    if (results.every((result) => result?.status === 'success')) {
      // let stage = await displayer.updateProjectstage(
      //   this.state.data.id,
      //   this.state.data.project_status,
      //   parseInt(this.props.data.project_status) < 11 ? 'presales_boq_shared' : 'dop_boq_shared'
      // );
      // if (stage) {
      Postie.openLink(url);
      //this.props.callback();
      //   }
      // } else {
      //   Postie.showAlert(results);
    }
  }

  getProjectDetails = async (id, refereshed = true) => {
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url = config.BASE_API_URL + 'project/' + id + '/' + Auth.urlTokenizer();
      let jsonData = await Postie.fetchUrl(url);
      if (jsonData) {
        this.setState(
          {
            ...this.state,
            data: jsonData,
            dataLoaded: true, // Set dataLoaded to true when data is loaded
            selectedArea:
              this.state.selectedArea === '' ? jsonData.areas[0].id : this.state.selectedArea
          },
          function () {
            console.log('Project Details', this.state.data);
            if (refereshed) {
              window.parent.postMessage('refresh', '*');
            }
          }
        );
      }
    }
  };
  onMainTabChange = (event, newValue) => {
    this.setState({ selectedMainTab: newValue });
  };

  handleTabChange = (event, newValue) => {
    this.setState({ selectedTab: newValue }, function () {
      // let ex_site_pictures = this.allPictureUploaded(this.state.data.areas, 'ex_site_pictures');
      // let ex_plan_dwg = this.allPictureUploaded(this.state.data.areas, 'ex_plan_dwg');
      // let ex_elevations = this.allPictureUploaded(this.state.data.areas, 'ex_elevations');
    });
  };
  handleEstimateTabChange = (event, newValue) => {
    this.setState({ selectedEstimateTab: newValue });
  };

  openWorkscopeView = (id) => {
    let url = '/bid/workscope/' + id;
    console.log(url);
    Postie.openLink(url);
  };

  handleAreaChange = (event) => {
    const selectedArea = event;

    this.setState({ selectedArea: selectedArea }, function () {
      console.log('selected area', this.state.selectedArea);
    });
  };

  allPictureUploaded = async (areas, field) => {
    let result = await displayer.checkAllAreaImage(areas, field);
    this.setState({ ...this.state, [field]: result }, function () {
      console.log('allPictureUploaded', field, this.state[field], result);
    });
  };
  updateStage = async (projectId, currentStage, desiredStage) => {
    let stage = await displayer.updateProjectstage(projectId, currentStage, desiredStage);
    if (stage) {
      this.getProjectDetails(this.state.data.id);
    }
  };

  render() {
    const { data, dataLoaded, selectedEstimateTab } = this.state;
    // Only render if data is loaded
    if (!dataLoaded) {
      return (
        <Grid
          container
          md={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Center the loading message
            height: '100vh' // Center vertically
          }}
        >
          <h3>Loading data...</h3>
        </Grid>
      );
    }
    const total =
      parseFloat(this.state.data.fitout_boq?.budget || '0') +
      parseFloat(this.state.data.modular_boq?.budget || '0') +
      parseFloat(this.state.data.furniture_boq?.budget || '0') +
      parseFloat(this.state.data.product_boq?.budget || '0');

    return (
      <Grid container>
        <Grid item md={12}>
          <Box className="column">
            <Typography className="heading">
              Client Budget: <span style={{ float: 'right' }}>Rs.{data.project_budget}</span>
            </Typography>
            <Divider />
            <Typography className="heading">
              Fitout:
              {data.fitout_boq && (
                <Chip
                  label={
                    data.fitout_boq?.status === 1
                      ? 'Shared'
                      : data.fitout_boq?.status === 0
                      ? 'Not Shared'
                      : data.fitout_boq?.status === 2
                      ? 'Approved'
                      : data.fitout_boq?.status === 3
                      ? 'Change Requested'
                      : 'Rejected'
                  }
                  color={
                    data.fitout_boq?.status === 1
                      ? 'primary'
                      : data.fitout_boq?.status === 0
                      ? 'secondary'
                      : data.fitout_boq?.status === 2
                      ? 'success'
                      : 'error'
                  }
                ></Chip>
              )}
              <span style={{ float: 'right' }}>Rs.{data.fitout_boq?.budget || '0'}</span>
            </Typography>
            <Typography className="heading">
              Modular:
              {data.modular_boq && (
                <Chip
                  label={
                    data.modular_boq?.status === 1
                      ? 'Shared'
                      : data.modular_boq?.status === 0
                      ? 'Not Shared'
                      : data.modular_boq?.status === 2
                      ? 'Approved'
                      : data.modular_boq?.status === 3
                      ? 'Change Requested'
                      : 'Rejected'
                  }
                  color={
                    data.modular_boq?.status === 1
                      ? 'primary'
                      : data.modular_boq?.status === 0
                      ? 'secondary'
                      : data.modular_boq?.status === 2
                      ? 'success'
                      : 'error'
                  }
                ></Chip>
              )}
              <span style={{ float: 'right' }}>Rs.{data.modular_boq?.budget || '0'}</span>
            </Typography>
            <Typography className="heading">
              Furniture:
              {data.furniture_boq && (
                <Chip
                  label={
                    data.furniture_boq?.status === 1
                      ? 'Shared'
                      : data.furniture_boq?.status === 0
                      ? 'Not Shared'
                      : data.furniture_boq?.status === 2
                      ? 'Approved'
                      : data.furniture_boq?.status === 3
                      ? 'Change Requested'
                      : 'Rejected'
                  }
                  color={
                    data.furniture_boq?.status === 1
                      ? 'primary'
                      : data.furniture_boq?.status === 0
                      ? 'secondary'
                      : data.furniture_boq?.status === 2
                      ? 'success'
                      : 'error'
                  }
                ></Chip>
              )}
              <span style={{ float: 'right' }}>Rs.{data.furniture_boq?.budget || '0'}</span>
            </Typography>
            <Typography className="heading">
              Product:
              {data.product_boq && (
                <Chip
                  label={
                    data.product_boq?.status === 1
                      ? 'Shared'
                      : data.product_boq?.status === 0
                      ? 'Not Shared'
                      : data.product_boq?.status === 2
                      ? 'Approved'
                      : data.product_boq?.status === 3
                      ? 'Change Requested'
                      : 'Rejected'
                  }
                  color={
                    data.product_boq?.status === 1
                      ? 'primary'
                      : data.product_boq?.status === 0
                      ? 'secondary'
                      : data.product_boq?.status === 2
                      ? 'success'
                      : 'error'
                  }
                ></Chip>
              )}
              <span style={{ float: 'right' }}>Rs.{data.product_boq?.budget || '0'}</span>
            </Typography>
            <Divider />
            <Typography className="heading">
              Total: <span style={{ float: 'right' }}>Rs.{total}</span>
            </Typography>
            <Typography className="heading">
              {total > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => Postie.openLink('/combine/estimates/' + parseInt(data.id) + '/'+localStorage.getItem(config.Token_name))}
                  endIcon={<RemoveRedEye />}
                >
                  Combine BOQ
                </Button>
              )}
              {total > 0 && (
                <Button
                  style={{ marginLeft: '2px' }}
                  variant="contained"
                  color="success"
                  onClick={() => this.shareOnWhatsapp(data)}
                  endIcon={<WhatsApp />}
                >
                  Share
                </Button>
              )}

              {total > 0 && ['11', '13', '24'].includes(this.state.data.project_status) && (
                <Button
                  style={{ marginTop: '5px' }}
                  variant="contained"
                  color="success"
                  endIcon={<ThumbUpAlt />}
                  onClick={() => this.approveCombineBoq()}
                >
                  Approve BOQ's
                </Button>
              )}
              {total > 0 && ['11', '13', '24'].includes(this.state.data.project_status) && (
                <Button
                  style={{ marginTop: '5px' }}
                  variant="contained"
                  color="primary"
                  endIcon={<ThumbDownAlt />}
                  onClick={() => this.openCombineBoqChangePopup()}
                >
                  Change BOQ's
                </Button>
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid container item md={12} alignItems="center">
          <Tabs
            value={selectedEstimateTab}
            onChange={this.handleEstimateTabChange}
            style={{ color: '#0000FF', padding: '8px' }}
          >
            <Tab label="Fitouts" value="Fitouts" />
            <Tab label="Modular" value="Modular" />
            <Tab label="Products" value="Products" />
            <Tab label="Furniture" value="Furniture" />
          </Tabs>
          {selectedEstimateTab === 'Fitouts' && (
            <Grid container spacing="2" style={{ margin: '10px' }}>
              <h3>Fitouts Boq</h3>
              {data.estimates.length > 1 && (
                <Grid>
                  <Button
                    color="primary"
                    style={{ marginLeft: '900px' }}
                    variant="contained"
                    onClick={() => Postie.navigateLink(`/estimate/comparasion/${data.id}/fitouts`)}
                  >
                    Compare Fitout Boq
                  </Button>
                </Grid>
              )}

              <Estimates
                key={data.estimates}
                data={data.estimates}
                completeData={data}
                isModular={false}
                callback={() => this.getProjectDetails(this.state.data.id)}
                skpfile={data.skpfile}
                projectStatus={data.project_status}
              />
            </Grid>
          )}
          {selectedEstimateTab === 'Modular' && (
            <Grid container spacing="2" style={{ margin: '10px' }}>
              <h3>Modular Boq</h3>{' '}
              {data.modular_estimates.length > 1 && (
                <Grid>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: '900px' }}
                    onClick={() => Postie.navigateLink(`/estimate/comparasion/${data.id}/modular`)}
                  >
                    Compare Modular Boq
                  </Button>
                </Grid>
              )}
              <Estimates
                key={data.product_estimates}
                data={data.modular_estimates}
                completeData={data}
                isModular={true}
                callback={() => this.getProjectDetails(this.state.data.id)}
                skpfile={data.skpfile}
                projectStatus={data.project_status}
              />
            </Grid>
          )}
          {selectedEstimateTab === 'Products' && (
            <Grid container spacing="2" style={{ margin: '10px' }}>
              <h3>Products Boq</h3>
              {data.product_estimates.length > 1 && (
                <Grid>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: '900px' }}
                    onClick={() => Postie.navigateLink(`/estimate/comparasion/${data.id}/product`)}
                  >
                    Compare Product Boq
                  </Button>
                </Grid>
              )}
              <Estimates
                key={data.product_estimates}
                data={data.product_estimates}
                completeData={data}
                isModular={false}
                callback={() => this.getProjectDetails(this.state.data.id)}
                skpfile={data.skpfile}
                projectStatus={data.project_status}
              />
            </Grid>
          )}
          {selectedEstimateTab === 'Furniture' && (
            <Grid container spacing="2" style={{ margin: '10px' }}>
              <h3>Furniture Boq</h3>
              {data.furniture_estimates.length > 1 && (
                <Grid>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: '900px' }}
                    onClick={() =>
                      Postie.navigateLink(`/estimate/comparasion/${data.id}/furniture`)
                    }
                  >
                    Compare Furniture Boq
                  </Button>
                </Grid>
              )}
              <Estimates
                key={data.furniture_estimates}
                data={data.furniture_estimates}
                completeData={data}
                isModular={false}
                callback={() => this.getProjectDetails(this.state.data.id)}
                skpfile={data.skpfile}
                projectStatus={data.project_status}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default ProjectEstimateView;
