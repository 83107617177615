import React, { Component } from 'react';
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import FileView from 'app/views/bids/components/fileView';
import config from '../../constants/index.jsx';
import Postie from 'app/helpers/postie';
import displayer from 'app/helpers/displayer';
import Auth from 'app/helpers/authorization';
import ImageBox from './components/ImageBox.jsx';
import $ from 'jquery';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(3),
  color: theme.palette.text.primary
}));
const LoginScreen = (props) => (
  <Grid spacing={2}>
    <h3>
      {`Please Write Your 10 Digit Mobile Number ending with 
      ${props.regestered_number?.substring(
        props.regestered_number.length - 4,
        props.regestered_number.length
      )}`}
      :
    </h3>
    <TextField
      type="number"
      placeholder="Mobile Number"
      onChange={(e) => props.handleClientLogin(e)}
    />
    {props.loginFailure && (
      <p style={{ color: 'red' }}>
        {`Number Entered did not Match, Kinly Enter Registered Mobile Number ending with 
        ${props.regestered_number.substring(
          props.regestered_number.length - 4,
          props.regestered_number.length
        )}`}
      </p>
    )}
  </Grid>
);

const Section = (props) => (
  <div>
    {props.showSection && (
      <Item style={{ marginBottom: '10px' }}>
        <Grid container>
          <Grid item md="6" xs="12" style={{ marginBottom: '15px' }}>
            <h2>{props.data.name}</h2>
          </Grid>
          <Grid container md="6" xs="12" style={{ marginBottom: '15px' }}>
            <Grid item md="4">
              {props.currentSectionIndex > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => props.handlePreviousPage()}
                >
                  Previous
                </Button>
              )}
            </Grid>
            <Grid item md="4">
              {props.userType === 'Team' ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    Postie.navigateLink(
                      '/areainspiration/' + props.inspirationId + '/' + props.data.id
                    )
                  }
                >
                  Edit
                </Button>
              ) : (
                props.currentSectionIndex !== props.totalSection && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => props.handleSkipApproval(props.data.id)}
                  >
                    Skip Approval
                  </Button>
                )
              )}
            </Grid>
            <Grid item md="4">
              {!props.isFormSubmit ? (
                props.currentSectionIndex !== props.totalSection - 1 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    title="Please approve atleast one image"
                    disabled={!props.checkSectionApproved(props.data.images)}
                    onClick={() => props.handleNextPage()}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="success"
                    title="Please approve atleast one image"
                    disabled={!props.checkSectionApproved(props.data.images)}
                    onClick={() => props.submitInspiration()}
                  >
                    I am Done
                  </Button>
                )
              ) : (
                <Grid>
                  <h5>Thank you for Reviewing</h5>
                  <p>Our team would get in touch with you Soon...</p>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          {props.data &&
            props.data.images.map((record, index) => (
              <ImageBox
                record={record}
                key={index}
                selectedImages={[]}
                handleFile={(file) => this.handleFile(file)}
                forClient={props.forClient}
                updateRating={(e, recordId) => props.updateRating(e, recordId)}
                onApproveImage={(e, recordId) => props.onApproveImage(e, recordId)}
              />
            ))}
        </Grid>
      </Item>
    )}
  </div>
);
class InspirationDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      user_type: '',
      loginFailure: false,
      nounce: '',
      client_phone: '',
      rating: '',
      comment: '',
      status: '',
      currentSectionIndex: 0,
      showConfirmationDialog: false,
      showSkipApprovalDialog: false,
      selectedImageId: null,
      sectionId: '',
      submited: false
    };
  }

  handleNextSection = () => {
    // Increment the current section index when the "Next" button is clicked
    this.setState((prevState) => ({
      currentSectionIndex: prevState.currentSectionIndex + 1
    }));
  };

  handlePreviousSection = () => {
    // Decrement the current section index when the "Previous" button is clicked
    this.setState((prevState) => ({
      currentSectionIndex: prevState.currentSectionIndex - 1
    }));
  };

  componentDidMount = () => {
    const queryParams = displayer.getUrlParams('/');
    //console.log(queryParams);
    let type = queryParams[0];
    let startTour = false;
    this.loadData(queryParams[2]).then(
      (startTour =
        this.state.client_phone === this.state.data?.project?.client?.phone
          ? localStorage.getItem(config.Token_name)
            ? false
            : this.state.data.start_tour
          : false)
    );
    if (type.toLowerCase() === 'freezed') {
      this.setState(
        {
          freezed: true
        },
        function () {
          console.log('freezedset', this.state);
        }
      );
    }
  };

  async loadData(nounce) {
    console.log('nounce', nounce);
    var url = config.BASE_API_URL + 'project/inspiration/preview/' + nounce;
    const data = await Postie.fetchUrl(url);
    let approved = this.fetchApprovedImages(data);
    data.sections.map((record) => {
      record.images = this.formatImageObject(
        record.images,
        record.description,
        approved,
        nounce,
        data
      );
    });
    this.setState(
      {
        data: data,
        user_type: localStorage.getItem(config.Token_name) ? 'Team' : 'client',
        client_phone: localStorage.getItem(config.Token_name)
          ? data.project.client.phone
          : Auth.clientAlreadyLogin(),
        approved: approved,
        nounce: nounce,
        submited: false
      },
      function () {
        this.setState({
          ...this.state,
          loginFailure:
            this.state.client_phone !== this.state.data.project.client.phone &&
            this.state.client_phone !== ''
        });
      }
    );
  }

  handleClientLogin = (e) => {
    const { name, value } = e.target;
    console.log('handleClientLogin', value, value.length);

    if (value.length >= 10) {
      console.log('value Legth', value.length);
      localStorage.setItem(config.inspiration_Token, value);

      this.loadData(this.state.nounce);
    }
  };

  fetchApprovedImages(data) {
    let approved = [];
    data.sections.map((record) => {
      if (data.type !== 'scope') {
        //console.log('not scope',data.type) ;
        if (data.stage.toLowerCase() === 'dop') {
          record.area.dop_final_reference.map((frn) => approved.push(frn.gallery_id));
        } else {
          record.area.final_reference.map((frn) => approved.push(frn.gallery_id));
        }
      } else {
        //console.log('scope',data.type) ;
        data.project.final_reference.map((frn) => approved.push(frn.gallery_id));
      }
    });
    return approved;
  }

  formatImageObject(images, caption, approved, nounce = '', data) {
    let procImage = [];
    images.map((image) =>
      procImage.push({
        src: image.url,
        thumbnail: image.url,
        caption: caption,
        useForDemo: true,
        rating: image.ratings ? image.ratings : 0,
        id: image.id,
        comment: image.comment ? image.comment : '',
        gallery_id: image.image_id,
        remarks: image.remarks,
        is_approved: approved.includes(image.image_id),
        nounce: nounce,
        specifications: image.specifications,
        area: approved.includes(image.image_id)
          ? this.fetchImageArea(image.image_id, data.project.final_reference)
          : null
      })
    );
    return procImage;
  }

  fetchImageArea(gallery_id, approvedImages) {
    let area = [];
    if (approvedImages) {
      //console.log('fetchImageArea',approvedImages);
      approvedImages.length > 0 &&
        approvedImages.forEach((record) => {
          //console.log('fetchImageArea',record.gallery_id,gallery_id,(record.gallery_id===gallery_id));
          if (record.gallery_id === gallery_id) {
            area = record.area;
          }
        });
    }
    return area;
  }
  updateRating = async (e, recordId) => {
    const { name, value } = e.target;
    console.log('updateRating', value, recordId);
    if (this.state.user_type === 'client') {
      // $('.helpText').hide();
      // let helpElem = $('#help-' + id);
      // this.setState({ actionStatus: true }, function () {
      //   helpElem.show();
      // });
      let postData = {
        nounce: this.state.nounce,
        id: recordId,
        field: name,
        value: value,
        phone: this.state.client_phone

        // comment: $('#comment-' + id).val()
      };
      var servicesUrl = config.BASE_API_URL + 'gallery/rate/image';
      let json = await Postie.sendAsynchrnousCall(postData, servicesUrl);
      if (json.status === 'success') {
        this.setState({
          rating: json.data.ratings,
          comment: json.data.comment
        });
        this.loadData(this.status.nounce, true);
      } else {
        Postie.showAlert({
          json: {
            message: 'something went wrong'
          }
        });
      }
    } else {
      Postie.showAlert({
        json: {
          message: 'Sorry Team Member cannot update the Rating /Comments of the Client'
        }
      });
    }
  };
  openSkipApprovalPopup = async (sectionId) => {
    this.setState({
      showSkipApprovalDialog: true,
      sectionId: sectionId
    });
  };

  onApproveImage = async (e, recordId) => {
    this.setState({
      showConfirmationDialog: true,
      selectedImageId: recordId,
      imageEvent: e,
      imageRecordId: recordId
    });
  };
  handleConfirmYes = async () => {
    const { imageEvent, imageRecordId } = this.state;
    console.log('updateRating', imageRecordId);
    if (this.state.user_type === 'client') {
      let postData = {
        authToken: this.state.nounce,
        image_id: imageRecordId,
        clientSaving: 1
      };
      console.log('postData', postData);
      var servicesUrl = config.BASE_API_URL + 'project/inspiration/saveFinalImage';
      let json = await Postie.sendAsynchrnousCall(postData, servicesUrl);
      if (json.status === 'success') {
        this.setState({
          status: json.data.status,
          showConfirmationDialog: false,
          selectedImageId: null
        });
        this.loadData(this.state.nounce);
      } else {
        Postie.showAlert({
          json: {
            message: 'something went wrong'
          }
        });
      }
    }
  };
  handleConfirmNo = () => {
    this.setState({
      showConfirmationDialog: false,
      selectedImageId: null,
      showSkipApprovalDialog: false
    });
  };
  checkSectionApproved(images) {
    let setData = localStorage.getItem(config.Token_name) === null ? false : true;
    images.forEach((image) => {
      //console.log('checkSectionApproved',image);
      if (image.is_approved) {
        setData = true;
      }
    });
    return setData;
  }

  handleSkipApproval = async (e) => {
    const { name, value } = e.target;
    console.log('handleSkipApproval', value);
    this.setState(
      {
        ...this.state,
        skipcomment: value
      },
      function () {
        console.log('skipcomment', this.state.skipcomment);
      }
    );
  };

  submitSkipApproval = async () => {
    const { skipcomment, sectionId } = this.state;
    console.log('submitSkipApproval', skipcomment);
    if (skipcomment && skipcomment.length > 3) {
      let postData = {
        section_id: sectionId,
        comment: skipcomment
      };
      var servicesUrl =
        config.BASE_API_URL + 'project/inspiration/saveSectionComment/' + this.state.nounce;
      let json = await Postie.sendAsynchrnousCall(postData, servicesUrl);
      if (json.status === 'success') {
        this.setState({
          status: json.data.status,
          showConfirmationDialog: false,
          selectedImageId: null
        });
        this.handleNextSection();
        this.handleConfirmNo();
      } else {
        Postie.showAlert(
          'Error',
          'Reject Reason can not be left empty, Please Provide a valid Reason.',
          'error'
        );
      }
    }
  };

  submitInspiration = async () => {
    this.setState({ submited: true });
    var url = config.BASE_API_URL + 'project/submitinspiration/' + this.state.nounce;
    fetch(url)
      .then((res) => res.json())
      .then((json) => {
        this.loadData(this.state.nounce);
      });
  };

  render() {
    const { data, currentSectionIndex, showConfirmationDialog } = this.state;
    //console.log('Inspiration data', data);

    return (
      <Grid
        container
        spacing={2}
        style={{
          margin: '10px'
        }}
      >
        <Grid item md={3} xs="6">
          <FileView
            key={'logo'}
            id={'logo'}
            url={displayer.absolutingImageURl(data?.project?.organization?.logo)}
          />
        </Grid>
        <Grid item md={6} xs={0} style={{ textAlign: 'center' }}></Grid>
        <Grid item md={3} xs={6} style={{}}>
          <h4>{data?.project?.organization?.name}</h4>
          <p>{data?.project?.organization?.address}</p>
          <p>{data?.project?.organization?.contact_no}</p>
        </Grid>
        <Grid item md={12} xs={12}>
          <Item style={{ marginBottom: '10px' }}>
            <Grid container>
              <Grid item md="6" xs="12">
                <h1>Welcome {data?.project?.client?.fullname}</h1>
                {this.state.client_phone === data?.project?.client?.phone && <h2>Project Brief</h2>}
              </Grid>
              <Grid item md="6">
                {this.state.client_phone === data?.project?.client?.phone && (
                  <h3>#{data?.project?.id}</h3>
                )}
                {this.state.client_phone === data?.project?.client?.phone && (
                  <h4>{data?.project?.project_address}</h4>
                )}
                {this.state.client_phone === data?.project?.client?.phone && (
                  <p>{data?.project?.project_name}</p>
                )}
                {this.state.client_phone === data?.project?.client?.phone && (
                  <p>{data?.project?.project_description}</p>
                )}
                {this.state.client_phone !== data?.project?.client?.phone && (
                  <LoginScreen
                    handleClientLogin={(e) => this.handleClientLogin(e)}
                    regestered_number={data?.project?.client?.phone}
                    loginFailure={this.state.loginFailure}
                  />
                )}
              </Grid>
            </Grid>
          </Item>
        </Grid>
        {this.state.client_phone === data?.project?.client?.phone && (
          <Grid item md={12} xs={12}>
            <Item style={{ marginBottom: '10px' }}>
              <Grid container>
                <Grid item md="12">
                  <h3>Master Layout</h3>
                </Grid>
                <Grid item md="12" style={{ textAlign: 'center' }}>
                  {data?.project?.layouts.length > 0 && (
                    <FileView
                      key={'layout'}
                      id={'layout'}
                      url={displayer.absolutingImageURl(data.project.layouts[0].url)}
                      style={{ maxWidth: '90%' }}
                    />
                  )}
                </Grid>
              </Grid>
            </Item>
          </Grid>
        )}
        <Grid item md={12} xs={12}>
          {this.state.client_phone === data?.project?.client?.phone &&
            data.sections &&
            data.sections.map((section, index) => (
              <Section
                data={section}
                updateRating={(e, recordId) => this.updateRating(e, recordId)}
                onApproveImage={(e, recordId) => this.onApproveImage(e, recordId)}
                showSection={index === this.state.currentSectionIndex}
                currentSectionIndex={index}
                totalSection={data.sections.length}
                userType={this.state.user_type}
                forClient={true}
                checkSectionApproved={(images) => this.checkSectionApproved(images)}
                handleNextPage={() => this.handleNextSection()}
                handlePreviousPage={() => this.handlePreviousSection()}
                handleSkipApproval={(sectionId) => this.openSkipApprovalPopup(sectionId)}
                inspirationId={data?.id}
                submitInspiration={() => this.submitInspiration()}
                isFormSubmit={this.state.submited}
              />
            ))}
        </Grid>

        <Dialog open={this.state.showConfirmationDialog} onClose={this.handleConfirmNo} fullWidth>
          <DialogTitle>Confirmation On Selected Image:</DialogTitle>
          <DialogContent>
            <h4>Are you sure you want to approve this image?</h4>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={this.handleConfirmYes}>
              Yes
            </Button>
            <Button onClick={this.handleConfirmNo} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.showSkipApprovalDialog} onClose={this.handleConfirmNo} fullWidth>
          <DialogTitle>Pleae Provide reason why you are not Approving:</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              rows={5}
              multiline
              type="text"
              placeholder="Write Reason"
              name="skip_approval"
              onChange={(e) => this.handleSkipApproval(e)}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => this.submitSkipApproval()}>
              Submit
            </Button>
            <Button onClick={this.handleConfirmNo} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default InspirationDisplay;
