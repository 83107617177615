import React, { Component } from 'react';
import { Grid } from '@mui/material';
import displayer from 'app/helpers/displayer';

class FooterBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
  }

  render() {
    return (
      <Grid container spacing="2" style={{ padding: ' 0 40px' }}>
        <Grid item xs="12" sm="12" md="6">
          <h5>Terms & Condition</h5>
          <div dangerouslySetInnerHTML={{ __html: this.props.terms }} />
        </Grid>
        <Grid item xs="12" sm="12" md="6">
          <h5>Payment Terms</h5>
          <div dangerouslySetInnerHTML={{ __html: this.props.payment_terms }} />
        </Grid>
        <Grid item xs="12" sm="12" md="12" style={{ backgroundColor: '#B3A492' }}>
          <Grid container>
            <Grid item xs="12" sm="12" md="4" style={{ textAlign: 'center' }}>
              <img
                src={displayer.absolutingImageURl(this.props.organization?.logo)}
                alt={this.props.organization?.name}
                width="300px"
              />
            </Grid>
            <Grid
              item
              xs="12"
              sm="12"
              md="4"
              style={{ textAlign: 'center', color: '#FFFFFF', padding: '10px' }}
            >
              <h3>{this.props.organization?.name}</h3>
              <p>{this.props.organization?.address}</p>
            </Grid>
            <Grid
              item
              xs="12"
              sm="12"
              md="4"
              style={{ textAlign: 'center', color: '#FFFFFF', padding: '10px' }}
            >
              <h5>Call us </h5>
              <h4>{this.props.organization?.contact_no}</h4>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default FooterBox;
