import React, { Component } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Grid,
  Tabs,
  Tab,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Rating,
  Typography
} from '@mui/material';
import { Box, Button, Chip, Divider, Paper } from '@mui/material';
import FileView from '../../bids/components/fileView';
import displayer from 'app/helpers/displayer';

class ImageBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      selectedImages: [],
      areaId: ''
    };
    //console.log('statedata', this.state.data);
  }

  render() {
    const { record } = this.props;
    return (
      <Grid
        item
        md={3}
        sm={4}
        xs={6}
        key={record}
        style={{ border: '1px solid #ddd', borderRadius: '5px', margin: '5px 0px' }}
      >
        <Grid style={{ textAlign: 'center' }}>
          <FileView
            id={record.id}
            url={displayer.absolutingImageURl(record.src)}
            style={{ maxHeight: '250px', maxWidth: '90%', margin: '5px auto' }}
          />
        </Grid>
        {this.props.forClient ? (
          record.remarks && (
            <Paper elevation={3} style={{ padding: '5px', margin: '5px' }}>
              <h4> Designer Remarks for the Image </h4>
              <p>{record.remarks}</p>
            </Paper>
          )
        ) : (
          <TextField
            key={`image-remark-${record.id}`}
            label="Add Remark"
            variant="outlined"
            defaultValue={record.remarks}
            multiline
            name={`remarks`}
            rows={1}
            style={{ width: '90%' }}
            onBlur={(e) => this.props.addRemark(e, record)}
          />
        )}
        {this.props.forClient && (
          <Rating
            key={`image-rating-${record.id}`}
            name={`client_ratings`}
            defaultValue={record.rating}
            max={5}
            onChange={(e) => this.props.updateRating(e, record.id)}
          />
        )}
        {this.props.forClient && (
          <TextField
            key={`image-comment-${record.id}`}
            label="Comments"
            variant="outlined"
            defaultValue={record.comment}
            multiline
            name={`client_comment`}
            rows={1}
            style={{ width: '90%' }}
            onBlur={(e) => this.props.updateRating(e, record.id)}
          />
        )}
        {this.props.forClient && (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  key={`approved-image-${record?.id}[]`}
                  name={`approved-image-${record?.id}[]`}
                  value={''}
                  onChange={(e) => this.props.onApproveImage(e, record.id)}
                  color="primary"
                  defaultChecked={record.is_approved}
                  disabled={record.is_approved}
                  checked={this.props.defaultStatus?.some((file) => file.id === record.id)}
                />
              }
              label="Approved"
            />
          </FormGroup>
        )}
        {!this.props.forClient && (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  key={`fitout_scope-${record?.id}[]`}
                  name={`fitout_scope-${record?.id}[]`}
                  value={''}
                  onChange={() => this.props.handleFile(record)}
                  color="primary"
                  defaultChecked={this.props.selectedImages}
                  checked={this.props.selectedImages.some((file) => file.id === record.id)}
                />
              }
              label="Shortlisted"
            />
          </FormGroup>
        )}
        {!this.props.forClient &&
          record.caption &&
          record.caption
            .split(',')
            .map((tag, index) => <Chip label={tag} key={index} style={{ margin: '2px' }}></Chip>)}
      </Grid>
    );
  }
}

export default ImageBox;
