import React, { Component } from 'react';
import {
  Grid,
  Tab,
  Tabs,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  FormGroup
} from '@mui/material';
import Procurment from './Procurment';
import CutList from './CutList';

class Execution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMainTab: 'Modular',
      selectedSecondaryTab: 'Manufacturer',
      openAssignManufacturerPopup: false,
      openmanufactureconformation: false,
      heading: '',
      selectedProcurmentTab: 'Kitchen'
    };
  }

  onMainTabChange = (event, newValue) => {
    this.setState({ selectedMainTab: newValue });
  };

  onSecondryTabChange = (event, newValue) => {
    this.setState({ selectedSecondaryTab: newValue });
  };
  onProcurmentTabChange = (event, newValue) => {
    this.setState({ selectedProcurmentTab: newValue });
  };

  openAssignManufacturerPopup = (heading) => {
    this.setState({
      heading: heading,
      openAssignManufacturerPopup: true
    });
  };
  closeAssignManufacturerPopup = () => {
    this.setState({
      openAssignManufacturerPopup: false
    });
  };

  openmanufactureconformation = () => {
    this.setState({
      openmanufactureconformation: true
    });
  };
  closemanufactureconformation = () => {
    this.setState({
      openmanufactureconformation: false
    });
  };

  render() {
    const { selectedMainTab, selectedSecondaryTab, heading, selectedProcurmentTab } = this.state;
    return (
      <Grid container md={12}>
        <Tabs
          value={selectedMainTab}
          onChange={this.onMainTabChange}
          style={{ color: '#0000FF', padding: '8px' }}
        >
          <Tab label="Modular" value="Modular" />
          <Tab label="Furniture" value="Furniture" />
          <Tab label="Fitouts" value="Fitouts" />
          <Tab label="Product" value="Product" />
        </Tabs>
        {selectedMainTab === 'Modular' && (
          <Grid container alignItems="center">
            <Grid item md={12}>
              <Tabs
                value={selectedSecondaryTab}
                onChange={this.onSecondryTabChange}
                style={{ color: '#0000FF', padding: '8px' }}
              >
                <Tab label="Manufacturer" value="Manufacturer" />
                <Tab label="Procurment" value="Procurment" />
                <Tab label="Workscope" value="Workscope" />
              </Tabs>
            </Grid>
            {selectedSecondaryTab === 'Manufacturer' && (
              <Grid>
                <Grid item md={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ float: 'right', margin: '5px' }}
                    onClick={() => this.openAssignManufacturerPopup('Wardrobe')}
                  >
                    Assign Wordrobe Manufacture
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ float: 'right', margin: '5px' }}
                    onClick={() => this.openAssignManufacturerPopup('Storage')}
                  >
                    Assign Storage Manufacture
                  </Button>
                </Grid>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ padding: '8px' }}>Modular Type</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Contact</TableCell>
                      <TableCell>Address</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ padding: '8px' }}>
                        <span style={{ fontWeight: 'bold' }}>Kitchen</span>
                      </TableCell>
                      <TableCell>
                        <span style={{ fontWeight: 'bold' }}>mr.Abdul sattar</span>
                      </TableCell>
                      <TableCell>
                        <span style={{ fontWeight: 'bold' }}>9999111111</span>
                      </TableCell>
                      <TableCell>
                        <span style={{ fontWeight: 'bold' }}>Grater Noida</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </Grid>
            )}
            {selectedSecondaryTab === 'Procurment' && (
              <Grid item md={12}>
                <Tabs
                  value={selectedProcurmentTab}
                  onChange={this.onProcurmentTabChange}
                  style={{ color: '#0000FF', padding: '8px' }}
                >
                  <Tab label="Kitchen" value="Kitchen" />
                  <Tab label="Wardrobe" value="Wardrobe" />
                  <Tab label="Storage" value="Storage" />
                </Tabs>
              </Grid>
            )}
            {selectedProcurmentTab === 'Kitchen' &&
              selectedSecondaryTab !== 'Manufacturer' &&
              selectedSecondaryTab !== 'Workscope' && (
                <Grid item md={12}>
                  <Procurment />
                </Grid>
              )}
            {selectedProcurmentTab === 'Wardrobe' &&
              selectedSecondaryTab !== 'Manufacturer' &&
              selectedSecondaryTab !== 'Workscope' && (
                <Grid item md={12}>
                  <CutList />
                </Grid>
              )}
          </Grid>
        )}
        <Dialog
          open={this.state.openAssignManufacturerPopup}
          onClose={this.closeAssignManufacturerPopup}
          fullWidth
        >
          <DialogTitle>Please Assign {heading} Manufacturer</DialogTitle>
          <DialogContent>
            <Grid container md="12">
              <Grid item md="3">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={''}
                        color="primary"
                        checked={''}
                        disabled={''}
                        name={`approved`}
                        value={1}
                        //onChange={(event) => this.props.handleUpdateValidation(record, event)}
                      />
                    }
                    label="Abdul Sattar"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={''}
                        color="primary"
                        checked={''}
                        disabled={''}
                        name={`approved`}
                        value={1}
                        //onChange={(event) => this.props.handleUpdateValidation(record, event)}
                      />
                    }
                    label="Moh.Mukhtiyar"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={''}
                        color="primary"
                        checked={''}
                        disabled={''}
                        name={`approved`}
                        value={1}
                        //onChange={(event) => this.props.handleUpdateValidation(record, event)}
                      />
                    }
                    label="Mr.Cheku Jha"
                  />
                </FormGroup>
              </Grid>
              <Grid item md="3">
                <h5>9999111111</h5>
                <h5>9999111111</h5>
                <h5>9999111111</h5>
              </Grid>
              <Grid item md="3">
                <h5>Electrician</h5>
                <h5>Electrician</h5>
                <h5>Electrician</h5>
              </Grid>
              <Grid item md="3">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.openmanufactureconformation()}
                >
                  Assign
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '2px' }}
                  onClick={() => this.openmanufactureconformation()}
                >
                  Assign
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '2px' }}
                  onClick={() => this.openmanufactureconformation()}
                >
                  Assign
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ float: 'right' }}
              variant="contained"
              color="primary"
              onClick={() => this.closeAssignManufacturerPopup()}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openmanufactureconformation}
          onClose={this.closemanufactureconformation}
          fullWidth
        >
          <DialogTitle>Conformation !!</DialogTitle>
          <DialogContent>
            <Grid container md="12">
              <h3>Are You Sure You Want to Assign {heading} Manufacturer</h3>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={this.closemanufactureconformation}>
              No
            </Button>
            <Button variant="contained" color="primary" style={{ marginTop: '2px' }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default Execution;
