import React from 'react';
import { useTheme } from '@mui/material';
import ReactEcharts from 'echarts-for-react';

const DoughnutChart = ({
  height,
  color = [
    '#4793AF',
    '#FFC470',
    '#DD5746',
    '#8B322C',
    '#F3CA52',
    '#F6E9B2',
    '#0A6847',
    '#7ABA78',
    '#F97300',
    '#F8D082',
    '#FF76CE',
    '#94FFD8',
    '#75A47F',
    '#FFEC9E',
    '#B99470'
  ],
  name = 'Fic Rate',
  actualData = [
    { value: 65, name: 'Google' },
    { value: 20, name: 'Facebook' },
    { value: 15, name: 'Others' }
  ],
  displayTotal
}) => {
  const theme = useTheme();

  const option = {
    legend: {
      show: true,
      itemGap: 10,
      icon: 'square',
      top: 0,
      right: '70%',
      bottom: 50,
      textStyle: {
        color: theme.palette.text.secondary,
        fontSize: 13,
        fontFamily: 'roboto'
      }
    },
    tooltip: {
      show: false,
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    xAxis: [
      {
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        }
      }
    ],

    series: [
      {
        name: name,
        type: 'pie',
        radius: ['30%', '67.55%'],
        center: ['60%', '45%'],
        avoidLabelOverlap: false,
        hoverOffset: 5,
        stillShowZeroSum: false,
        label: {
          normal: {
            show: false,
            position: 'top', // shows the description data to center, turn off to show in right side
            textStyle: {
              color: theme.palette.text.secondary,
              fontSize: 13,
              fontFamily: 'roboto'
            },
            formatter: '{a}'
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: '14',
              fontWeight: 'normal',
              color: 'rgba(15, 21, 77, 1)'
            },
            formatter: '{b} \n{c} ({d}%) '
          }
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        data: actualData,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };
  console.log('actualData', actualData);
  //console.log('totalCost', totalCost);
  const totalCost = actualData.reduce((record, index) => record + parseFloat(index.value), 0);
  return (
    <div style={{ border: '1px solid #dddddd', padding: '10px' }}>
      <h3 style={{ marginLeft: '10px' }}>{name}</h3>
      <ReactEcharts
        style={{ height: height }}
        option={{
          ...option,
          color: [...color]
        }}
      />
      {displayTotal && <h4>Total:{Number(totalCost).toLocaleString()}</h4>}
    </div>
  );
};

export default DoughnutChart;
