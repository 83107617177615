import React, { Component } from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import config from '../../constants/index.jsx';
import Postie from '../../helpers/postie';
import Auth from '../../helpers/authorization';
import ConnectionDataTable from './components/ConnectionDataTable.jsx';
import Create from './create.jsx';

class ConnectModule extends Component {
  constructor(props) {
    super(props);
    console.log('ConnectModule', this.props);
    this.state = {
      open: false,
      data: [],
      baseModuleId: 0,
      name: '',
      filterText: '',
      filters: {}
      //apiCalls: this.props.apiCalls
    };
  }

  onFilter = (event) => {
    const { value } = event.target;
    this.setState({ filterText: value });
  };

  componentDidMount() {}
  loadData = async (identifier) => {
    console.log('getConnection');
    let connectDataUrl =
      config.BASE_API_URL + this.props.apiCall.loadDataUrl + identifier + '/' + Auth.urlTokenizer();
    let postData = { filters: this.state.filters };
    let reply = await Postie.sendAsynchrnousCall(postData, connectDataUrl);
    if (reply.status === 'success') {
      let json = reply.data;
      this.setState(
        {
          ...this.state,
          data: json.records,
          open: true,
          name: this.props.apiCall.connection,
          baseModuleId: identifier,
          apiCalls: json.apiCalls,
          connectFields: json.fields
        },
        function () {
          console.log('connection state', this.state);
        }
      );
    } else {
      Postie.showAlert(reply);
    }
  };

  handleOpen = (id) => {
    this.loadData(id);
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  loadConnectedVendors = async (id) => {
    const Url = config.BASE_API_URL + 'arena/connected/vendor/' + id + '/' + Auth.urlTokenizer();
    //console.log(Url);
    let jsonData = Postie.fetchUrl(Url);
    return jsonData;
  };

  handleFieldChange = async (event, mapId) => {
    const { name, value } = event.target;
    if (value.length > 0) {
      let servicesUrl = config.BASE_API_URL + 'arena/updatevendormapping';
      let postData = {
        authToken: localStorage.getItem(config.Token_name),
        field: name,
        map_id: mapId,
        value: value
      };
      let reply = await Postie.sendAsynchrnousCall(postData, servicesUrl);
      if (reply.status === 'success') {
        this.loadData();
      } else {
        Postie.showAlert(reply);
      }
    }
  };
  addConnection = async (event, identifier, connectId, data) => {
    const apiCall = this.state.apiCalls['connect'];
    console.log('addConnection', event, identifier, connectId, data);

    let servicesUrl = config.BASE_API_URL + apiCall.url;
    let keyData = Object.keys(apiCall?.mandatory);
    let postData = {};
    for (var i = 0; i < keyData.length; i++) {
      if (apiCall?.mandatory[keyData[i]] === 'token') {
        postData[[keyData[i]]] = localStorage.getItem(config.Token_name);
      } else if (['identifier', 'connectId'].includes(apiCall?.mandatory[keyData[i]])) {
        postData[[keyData[i]]] =
          apiCall?.mandatory[keyData[i]] === 'identifier' ? identifier : connectId;
      } else {
        postData[[keyData[i]]] = data[apiCall?.mandatory[keyData[i]]];
      }
    }
    let reply = await Postie.sendAsynchrnousCall(postData, servicesUrl);
    if (reply.status === 'success') {
      this.props.callback();
      event.target.remove();
      this.loadData();
    } else {
      Postie.showAlert(reply);
    }
  };
  createConnect = async (event, data, identifier) => {
    let postData = data;
    postData['authToken'] = localStorage.getItem(config.Token_name);
    let serviceUrl = config.BASE_API_URL + this.state.apiCalls['create'].url;
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      let connectionData = reply.data.data.records[0];
      this.addConnection(event, identifier, connectionData.id, connectionData);
      this.handleClose();
    } else {
      Postie.showAlert(reply);
    }
  };
  filterdata = (event) => {
    let filters = this.state.filters;
    let { name, value } = event.target;
    if (isNaN(value) && value.toLowerCase() === 'none') {
      delete filters[name];
    } else {
      filters[name] = value;
    }
    this.setState({ ...this.state, filters: filters }, function () {
      this.loadData(this.props.baseModuleId);
    });
  };
  processFilters = () => {
    let filters = [];
  };

  render() {
    const { apiCalls, connectFields } = this.state;
    const { fields } = this.props;
    console.log('apiCalls:', apiCalls, this.props);

    return (
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={() => this.handleOpen(this.props.baseModuleId)}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginLeft: '20px'
          }}
        >
          Connect {this.props.ConnectModule}
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="lg"
          key={this.props.baseModuleId}
        >
          <DialogTitle>
            Connect {this.props.ConnectModule}
            {apiCalls && (
              <Create
                heading={this.props.ConnectModule}
                fields={connectFields}
                apiCall={apiCalls['create']}
                add={(data) => this.createConnect({}, data, this.props.baseModuleId)}
                defaultValue={this.props.defaultValue}
              />
            )}
          </DialogTitle>
          <DialogContent>
            <ConnectionDataTable
              key={this.state.data}
              data={this.state.data}
              fields={fields}
              isConnect={true}
              showSuggesstion={false}
              apiCalls={this.state.apiCalls}
              callback={(e, connectionId, data) =>
                this.addConnection(e, this.props.baseModuleId, connectionId, data)
              }
              heading={this.props.ConnectModule}
              filters={this.props.defaultValue}
              userFilters={this.state.filters}
              filterData={(event) => this.filterdata(event)}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default ConnectModule;
