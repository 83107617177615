import React, { Component } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, TextField } from '@mui/material';
import {
  Box,
  Button,
  Chip,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import displayer from 'app/helpers/displayer';
import { styled } from '@mui/material';
import TeamInfo from './TeamInfo';
import Postie from 'app/helpers/postie';
import Auth from 'app/helpers/authorization';
import swal from 'sweetalert';
import config from '../../../constants/index.jsx';
import { NextPlan, Update } from '@mui/icons-material';
import { RemoveRedEye, ThumbDownAlt, ThumbUpAlt, WhatsApp } from '@mui/icons-material';

const AccordionRoot = styled(Box)(({ theme }) => ({
  width: '100%',
  '& .heading': {
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12)
    }
  },
  '& .secondaryHeading': {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12)
    }
  },
  '& .icon': {
    width: 20,
    height: 20,
    verticalAlign: 'bottom'
  },
  '& .details': { alignItems: 'center' },
  '& .column': { flexBasis: '33.33%' },
  '& .helper': {
    padding: theme.spacing(1, 2),
    borderLeft: `2px solid ${theme.palette.divider}`
  },
  '& .link': {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': { textDecoration: 'underline' }
  }
}));

class LeadView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      followup: {},
      openCombineBoqChangePopup: false
    };
  }
  openProjectView(id) {
    let url = '/project/view/' + id;
    console.log(url);
    Postie.navigateLink(url);
  }

  handleValueChange(event) {
    const { name, value } = event.target;
    this.setState(
      {
        followup: { ...this.state.followup, [name]: value }
      },
      function () {
        console.log('followup', name, value, this.state.followup);
      }
    );
  }
  addFollowup = async (projectId, currentStage, client) => {
    let postData = this.state.followup;
    postData['project_id'] = projectId;
    postData['authToken'] = localStorage.getItem(config.Token_name);
    postData['type'] = currentStage === '5' ? 'Presales Client Meeting' : 'Dop Client Meeting';
    postData['organiser_id'] = Auth.getLoggedInUserData('id');

    let url = config.BASE_API_URL + 'project/followup';
    let jsonData = await Postie.sendAsynchrnousCall(postData, url);
    if (jsonData.status === 'success') {
      let stage = await displayer.updateProjectstage(
        projectId,
        currentStage,
        currentStage === '5' ? 'presales_meeting_alligned' : 'dop_material_meeting_alligned'
      );

      let gcurl =
        'https://www.google.com/calendar/render?action=TEMPLATE&text=' +
        postData.type.replaceAll(' ', '+') +
        client +
        '&dates=' +
        postData.date.replaceAll('-', '') +
        'T' +
        postData.time.replace(':', '') +
        '00Z/' +
        postData.date.replaceAll('-', '') +
        'T' +
        (parseInt(postData.time.replace(':', '')) + 200) +
        '00Z&details=' +
        postData.comment +
        '&location=Event+Location';

      this.props.callback();
      if (stage) {
        Postie.openLink(gcurl);
      }
    } else {
      Postie.showAlert(jsonData);
    }
  };

  shareOnWhatsapp = (record) => {
    let url = 'https://web.whatsapp.com/send?text=';

    let type =
      parseInt(record.project_status) <= 14
        ? 'Presales BOQ'
        : parseInt(record.project_status) <= 24
        ? 'DOP BOQ'
        : 'Execution BOQ';
    // console.log('shareOnWhatsapp', record.estimate_type, type);
    let text =
      '* ' +
      type +
      ' for Project (' +
      record.project_id +
      ') *' +
      config.BASE_URL +
      'combine/estimate/' +
      record.project_id;
    url += text;
    this.forwardEstimates('whatsapp', record, url);
  };

  async forwardEstimates(type, record, url) {
    var serviceUrl = config.BASE_API_URL + 'estimate/forward';

    const fitoutpostData = {
      estimate_id: this.props.data.fitout_boq?.id,
      project_id: this.props.data.fitout_boq?.project_id,
      authToken: localStorage.getItem(config.Token_name),
      forwarded_by: type
    };
    const modularpostData = {
      estimate_id: this.props.data.modular_boq?.id,
      project_id: this.props.data.modular_boq?.project_id,
      authToken: localStorage.getItem(config.Token_name),
      forwarded_by: type
    };
    const productpostData = {
      estimate_id: this.props.data.product_boq?.id,
      project_id: this.props.data.product_boq?.project_id,
      authToken: localStorage.getItem(config.Token_name),
      forwarded_by: type
    };
    const furniturepostData = {
      estimate_id: this.props.data.furniture_boq?.id || '',
      project_id: this.props.data.furniture_boq?.project_id || '',
      authToken: localStorage.getItem(config.Token_name),
      forwarded_by: type
    };
    const apiCalls = [];

    this.props.data.fitout_boq &&
      apiCalls.push(Postie.sendAsynchrnousCall(fitoutpostData, serviceUrl));

    this.props.data.modular_boq &&
      apiCalls.push(Postie.sendAsynchrnousCall(modularpostData, serviceUrl));
    this.props.data.product_boq &&
      apiCalls.push(Postie.sendAsynchrnousCall(productpostData, serviceUrl));
    this.props.data.furniture_boq &&
      apiCalls.push(Postie.sendAsynchrnousCall(furniturepostData, serviceUrl));

    console.log('apiCalls', apiCalls);

    const results = await Promise.all(apiCalls);
    // Check if all calls were successful
    if (results.every((result) => result?.status === 'success')) {
      let stage = await displayer.updateProjectstage(
        this.props.data.id,
        this.props.data.project_status,
        parseInt(this.props.data.project_status) < 11 ? 'presales_boq_shared' : 'dop_boq_shared'
      );
      if (stage) {
        Postie.openLink(url);
        this.props.callback();
      }
    } else {
      Postie.showAlert(results);
    }
  }

  approveCombineBoq = async () => {
    swal({
      title: 'Are you sure?',
      text: 'By clicking Ok you will confirm that  Combine BOQ is Approved',
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then(async (approved) => {
      if (approved) {
        try {
          const fitoutpostData = {
            estimate_id: this.props.data.fitout_boq?.id,
            project_id: this.props.data.fitout_boq?.project_id,
            authToken: localStorage.getItem(config.Token_name)
          };
          const modularpostData = {
            estimate_id: this.props.data.modular_boq?.id,
            project_id: this.props.data.modular_boq?.project_id,
            authToken: localStorage.getItem(config.Token_name)
          };
          const productpostData = {
            estimate_id: this.props.data.product_boq?.id,
            project_id: this.props.data.product_boq?.project_id,
            authToken: localStorage.getItem(config.Token_name)
          };
          const furniturepostData = {
            estimate_id: this.props.data.furniture_boq?.id || '',
            project_id: this.props.data.furniture_boq?.project_id || '',
            authToken: localStorage.getItem(config.Token_name)
          };

          const apiCalls = [];

          this.props.data.fitout_boq &&
            apiCalls.push(
              Postie.sendAsynchrnousCall(fitoutpostData, config.BASE_API_URL + 'estimate/approve')
            );

          this.props.data.modular_boq &&
            apiCalls.push(
              Postie.sendAsynchrnousCall(modularpostData, config.BASE_API_URL + 'estimate/approve')
            );
          this.props.data.product_boq &&
            apiCalls.push(
              Postie.sendAsynchrnousCall(productpostData, config.BASE_API_URL + 'estimate/approve')
            );
          this.props.data.furniture_boq?.length > 0 &&
            apiCalls.push(
              Postie.sendAsynchrnousCall(
                furniturepostData,
                config.BASE_API_URL + 'estimate/approve'
              )
            );

          console.log('apiCalls', apiCalls);

          const results = await Promise.all(apiCalls);
          console.log('result', results);

          // Check if all calls were successful
          if (results.every((result) => result?.status === 'success')) {
            let desiredStage = this.props.data.fitout_boq
              ? parseInt(this.props.data.fitout_boq?.estimate_type) === 1
                ? 'presales_boq_approved'
                : 'dop_boq_approved'
              : 'presales_boq_approved';

            desiredStage = this.props.data.modular_boq
              ? parseInt(this.props.data.modular_boq?.estimate_type) === 1
                ? desiredStage
                : 'dop_boq_approved'
              : desiredStage;

            desiredStage = this.props.data.furniture_boq
              ? parseInt(this.props.data.furniture_boq?.estimate_type) === 1
                ? desiredStage
                : 'dop_boq_approved'
              : desiredStage;

            desiredStage = this.props.data.product_boq
              ? parseInt(this.props.data.product_boq?.estimate_type) === 1
                ? desiredStage
                : 'dop_boq_approved'
              : desiredStage;

            const stage = await displayer.updateProjectstage(
              this.props.data.id,
              this.props.data.project_status,
              desiredStage
            );

            if (stage) {
              this.props.callback();
            }
          } else {
            // Handle errors here
            results.forEach((result) => Postie.showAlert(result));
          }
        } catch (error) {
          Postie.showAlert(error);
          console.error(error);
        }
      }
    });
  };
  changeCombineBoq = async () => {
    swal({
      title: 'Are you sure?',
      text: 'By clicking Ok you will confirm that  Combine BOQ is Approved',
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then(async (approved) => {
      if (approved) {
        try {
          const fitoutpostData = {
            estimate_id: this.props.data.fitout_boq?.id,
            project_id: this.props.data.fitout_boq?.project_id,
            authToken: localStorage.getItem(config.Token_name)
          };
          const modularpostData = {
            estimate_id: this.props.data.modular_boq?.id,
            project_id: this.props.data.modular_boq?.project_id,
            authToken: localStorage.getItem(config.Token_name)
          };
          const productpostData = {
            estimate_id: this.props.data.product_boq?.id,
            project_id: this.props.data.product_boq?.project_id,
            authToken: localStorage.getItem(config.Token_name)
          };
          const furniturepostData = {
            estimate_id: this.props.data.furniture_boq?.id || '',
            project_id: this.props.data.furniture_boq?.project_id || '',
            authToken: localStorage.getItem(config.Token_name)
          };

          const apiCalls = [];

          this.props.data.fitout_boq &&
            apiCalls.push(
              Postie.sendAsynchrnousCall(fitoutpostData, config.BASE_API_URL + 'estimate/approve')
            );

          this.props.data.modular_boq &&
            apiCalls.push(
              Postie.sendAsynchrnousCall(modularpostData, config.BASE_API_URL + 'estimate/approve')
            );
          this.props.data.product_boq &&
            apiCalls.push(
              Postie.sendAsynchrnousCall(productpostData, config.BASE_API_URL + 'estimate/approve')
            );
          this.props.data.furniture_boq?.length > 0 &&
            apiCalls.push(
              Postie.sendAsynchrnousCall(
                furniturepostData,
                config.BASE_API_URL + 'estimate/approve'
              )
            );

          console.log('apiCalls', apiCalls);

          const results = await Promise.all(apiCalls);
          console.log('result', results);

          // Check if all calls were successful
          if (results.every((result) => result?.status === 'success')) {
            let desiredStage = this.props.data.fitout_boq
              ? parseInt(this.props.data.fitout_boq?.estimate_type) === 1
                ? 'presales_boq_approved'
                : 'dop_boq_approved'
              : 'presales_boq_approved';

            desiredStage = this.props.data.modular_boq
              ? parseInt(this.props.data.modular_boq?.estimate_type) === 1
                ? desiredStage
                : 'dop_boq_approved'
              : desiredStage;

            desiredStage = this.props.data.furniture_boq
              ? parseInt(this.props.data.furniture_boq?.estimate_type) === 1
                ? desiredStage
                : 'dop_boq_approved'
              : desiredStage;

            desiredStage = this.props.data.product_boq
              ? parseInt(this.props.data.product_boq?.estimate_type) === 1
                ? desiredStage
                : 'dop_boq_approved'
              : desiredStage;

            const stage = await displayer.updateProjectstage(
              this.props.data.id,
              this.props.data.project_status,
              desiredStage
            );

            if (stage) {
              this.props.callback();
            }
          } else {
            // Handle errors here
            results.forEach((result) => Postie.showAlert(result));
          }
        } catch (error) {
          Postie.showAlert(error);
          console.error(error);
        }
      }
    });
  };
  openCombineBoqChangePopup = () => {
    this.setState({
      openCombineBoqChangePopup: true
    });
  };
  closeCombineBoqChangePopup = () => {
    this.setState({
      openCombineBoqChangePopup: false
    });
  };
  onChangeBoq = (event) => {
    const { value } = event.target;
    this.setState(
      {
        ...this.state,
        combineChangeComment: value
      },
      function () {
        console.log('combineChangeComment', this.state.combineChangeComment);
      }
    );
  };

  submitCombineBoqChange = async () => {
    const { combineChangeComment } = this.state;
    const fitoutpostData = {
      estimate_id: this.props.data.fitout_boq?.id,
      project_id: this.props.data.fitout_boq?.project_id,
      reason: combineChangeComment,
      authToken: localStorage.getItem(config.Token_name)
    };
    const modularpostData = {
      estimate_id: this.props.data.modular_boq?.id,
      project_id: this.props.data.modular_boq?.project_id,
      reason: combineChangeComment,
      authToken: localStorage.getItem(config.Token_name)
    };
    const productpostData = {
      estimate_id: this.props.data.product_boq?.id,
      project_id: this.props.data.product_boq?.project_id,
      reason: combineChangeComment,
      authToken: localStorage.getItem(config.Token_name)
    };
    const furniturepostData = {
      estimate_id: this.props.data.furniture_boq?.id || '',
      project_id: this.props.data.furniture_boq?.project_id || '',
      reason: combineChangeComment,
      authToken: localStorage.getItem(config.Token_name)
    };

    const apiCalls = [];

    this.props.data.fitout_boq &&
      apiCalls.push(
        Postie.sendAsynchrnousCall(fitoutpostData, config.BASE_API_URL + 'estimate/change')
      );

    this.props.data.modular_boq &&
      apiCalls.push(
        Postie.sendAsynchrnousCall(modularpostData, config.BASE_API_URL + 'estimate/change')
      );
    this.props.data.product_boq &&
      apiCalls.push(
        Postie.sendAsynchrnousCall(productpostData, config.BASE_API_URL + 'estimate/change')
      );
    this.props.data.furniture_boq?.length > 0 &&
      apiCalls.push(
        Postie.sendAsynchrnousCall(furniturepostData, config.BASE_API_URL + 'estimate/change')
      );

    console.log('apiCalls', apiCalls);

    const results = await Promise.all(apiCalls);
    console.log('result', results);

    // Check if all calls were successful
    if (results.every((result) => result?.status === 'success')) {
      let desiredStage = this.props.data.fitout_boq
        ? parseInt(this.props.data.fitout_boq?.estimate_type) === 1
          ? 'presales_boq_change'
          : 'dop_boq_change'
        : 'presales_boq_change';

      desiredStage = this.props.data.modular_boq
        ? parseInt(this.props.data.modular_boq?.estimate_type) === 1
          ? desiredStage
          : 'dop_boq_change'
        : desiredStage;

      desiredStage = this.props.data.furniture_boq
        ? parseInt(this.props.data.furniture_boq?.estimate_type) === 1
          ? desiredStage
          : 'dop_boq_change'
        : desiredStage;

      desiredStage = this.props.data.product_boq
        ? parseInt(this.props.data.product_boq?.estimate_type) === 1
          ? desiredStage
          : 'dop_boq_change'
        : desiredStage;

      const stage = await displayer.updateProjectstage(
        this.props.data.id,
        this.props.data.project_status,
        desiredStage
      );

      if (stage) {
        this.closeCombineBoqChangePopup();
        this.props.callback();
      }
    } else {
      // Handle errors here
      results.forEach((result) => Postie.showAlert(result));
    }
  };
  render() {
    const { data, teams } = this.props;
    console.log('ListView Data', data);

    const total =
      parseFloat(data.fitout_boq?.budget || '0') +
      parseFloat(data.modular_boq?.budget || '0') +
      parseFloat(data.furniture_boq?.budget || '0') +
      parseFloat(data.product_boq?.budget || '0');
    return (
      <AccordionRoot>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Box className="column ">
                  <Typography className="heading">{data.id}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box className="column">
                  <Typography className="heading">{data?.client?.fullname}</Typography>
                </Box>
                <Box className="column">
                  <Typography className="heading">{data?.client?.phone}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box className="column">
                  <Typography className="heading">{data?.project_address}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box className="column">
                  <Typography className="heading">
                    <Chip label={data.status.status_name} color="success" icon={<Update />} />
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </AccordionSummary>
          <Divider />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <AccordionDetails className="details">
                <Typography className="heading">Client Details:</Typography>
                <Typography className="heading">{data?.client?.fullname}</Typography>
                <Typography className="heading">{data?.client?.phone}</Typography>
                <Typography className="heading">{data?.client?.address}</Typography>
                <Typography className="heading">
                  Carpet Area: {data.project_area ? data.project_area : null} sq.ft
                </Typography>
                <Typography className="heading">
                  Super Area: {data.super_area ? data.super_area : null} sq.ft
                </Typography>
              </AccordionDetails>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AccordionDetails className="details">
                <TeamInfo
                  team={data.colabrators}
                  dl={data.design_lead}
                  id={data.interior_designer}
                  otherMembers={teams}
                  projectId={data.id}
                  callback={() => this.props.callback()}
                />
                <Chip label={`Next Step`} icon={<NextPlan />} color="secondary" />
                {data.design_lead ? (
                  <p style={{ color: 'red' }}>{data.status.next_step}</p>
                ) : (
                  <p style={{ color: 'red' }}>Please Assign Account Manager (Design Lead)</p>
                )}
              </AccordionDetails>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AccordionDetails className="details">
                <Box className="column">
                  <Typography className="heading">Follow up Date</Typography>
                  <h3 className="heading">
                    {data.followup?.type} on {data.followup?.date} {data.followup?.time}
                  </h3>
                  <h3>{data.followup?.comment} </h3>
                  <p style={{ textAlign: 'right' }}>
                    Last Updated:{displayer.dateFormater(data.followup?.created_at)}
                  </p>
                  {(data.project_status === '5' || data.project_status === '17') && (
                    <Grid item md="12">
                      <TextField
                        key={'followup-date'}
                        type="date"
                        onChange={(e) => this.handleValueChange(e)}
                        placeholder={`choose date `}
                        name={'date'}
                        fullWidth
                      ></TextField>
                      <TextField
                        key={'followup-time'}
                        type="time"
                        onChange={(e) => this.handleValueChange(e)}
                        placeholder={`choose time `}
                        name={'time'}
                        fullWidth
                      ></TextField>
                      <TextField
                        key={'followup_comment'}
                        type="text"
                        onChange={(e) => this.handleValueChange(e)}
                        placeholder={`comment for follow up `}
                        name="comment"
                        fullWidth
                      ></TextField>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          this.addFollowup(data.id, data.project_status, data.client.fullname)
                        }
                      >
                        Add Follow Up
                      </Button>
                    </Grid>
                  )}
                </Box>
              </AccordionDetails>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AccordionDetails className="details">
                <Box className="column">
                  <Typography className="heading">
                    Client Budget: <span style={{ float: 'right' }}>Rs.{data.project_budget}</span>
                  </Typography>
                  <Divider />
                  <Typography className="heading">
                    Fitout:
                    {data.fitout_boq && (
                      <Chip
                        label={
                          data.fitout_boq?.status === 1
                            ? 'Shared'
                            : data.fitout_boq?.status === 0
                            ? 'Not Shared'
                            : data.fitout_boq?.status === 2
                            ? 'Approved'
                            : data.fitout_boq?.status === 3
                            ? 'Change Requested'
                            : 'Rejected'
                        }
                        color={
                          data.fitout_boq?.status === 1
                            ? 'primary'
                            : data.fitout_boq?.status === 0
                            ? 'secondary'
                            : data.fitout_boq?.status === 2
                            ? 'success'
                            : 'error'
                        }
                      ></Chip>
                    )}
                    <span style={{ float: 'right' }}>Rs.{data.fitout_boq?.budget || '0'}</span>
                  </Typography>
                  <Typography className="heading">
                    Modular:
                    {data.modular_boq && (
                      <Chip
                        label={
                          data.modular_boq?.status === 1
                            ? 'Shared'
                            : data.modular_boq?.status === 0
                            ? 'Not Shared'
                            : data.modular_boq?.status === 2
                            ? 'Approved'
                            : data.modular_boq?.status === 3
                            ? 'Change Requested'
                            : 'Rejected'
                        }
                        color={
                          data.modular_boq?.status === 1
                            ? 'primary'
                            : data.modular_boq?.status === 0
                            ? 'secondary'
                            : data.modular_boq?.status === 2
                            ? 'success'
                            : 'error'
                        }
                      ></Chip>
                    )}
                    <span style={{ float: 'right' }}>Rs.{data.modular_boq?.budget || '0'}</span>
                  </Typography>
                  <Typography className="heading">
                    Furniture:
                    {data.furniture_boq && (
                      <Chip
                        label={
                          data.furniture_boq?.status === 1
                            ? 'Shared'
                            : data.furniture_boq?.status === 0
                            ? 'Not Shared'
                            : data.furniture_boq?.status === 2
                            ? 'Approved'
                            : data.furniture_boq?.status === 3
                            ? 'Change Requested'
                            : 'Rejected'
                        }
                        color={
                          data.furniture_boq?.status === 1
                            ? 'primary'
                            : data.furniture_boq?.status === 0
                            ? 'secondary'
                            : data.furniture_boq?.status === 2
                            ? 'success'
                            : 'error'
                        }
                      ></Chip>
                    )}
                    <span style={{ float: 'right' }}>Rs.{data.furniture_boq?.budget || '0'}</span>
                  </Typography>
                  <Typography className="heading">
                    Product:
                    {data.product_boq && (
                      <Chip
                        label={
                          data.product_boq?.status === 1
                            ? 'Shared'
                            : data.product_boq?.status === 0
                            ? 'Not Shared'
                            : data.product_boq?.status === 2
                            ? 'Approved'
                            : data.product_boq?.status === 3
                            ? 'Change Requested'
                            : 'Rejected'
                        }
                        color={
                          data.product_boq?.status === 1
                            ? 'primary'
                            : data.product_boq?.status === 0
                            ? 'secondary'
                            : data.product_boq?.status === 2
                            ? 'success'
                            : 'error'
                        }
                      ></Chip>
                    )}
                    <span style={{ float: 'right' }}>Rs.{data.product_boq?.budget || '0'}</span>
                  </Typography>
                  <Divider />
                  <Typography className="heading">
                    Total: <span style={{ float: 'right' }}>Rs.{total}</span>
                  </Typography>
                  <Typography className="heading">
                    {total > 0 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => Postie.openLink('/combine/estimates/' + parseInt(data.id))}
                        endIcon={<RemoveRedEye />}
                      >
                        Combine BOQ
                      </Button>
                    )}
                    {total > 0 && (
                      <Button
                        style={{ marginLeft: '2px' }}
                        variant="contained"
                        color="success"
                        onClick={() => this.shareOnWhatsapp(this.props.data)}
                        endIcon={<WhatsApp />}
                      >
                        Share
                      </Button>
                    )}

                    {total > 0 && ['11', '13', '24'].includes(this.props.data.project_status) && (
                      <Button
                        style={{ marginTop: '5px' }}
                        variant="contained"
                        color="success"
                        endIcon={<ThumbUpAlt />}
                        onClick={() => this.approveCombineBoq()}
                      >
                        Approve BOQ's
                      </Button>
                    )}
                    {total > 0 && ['11', '13', '24'].includes(this.props.data.project_status) && (
                      <Button
                        style={{ marginTop: '5px' }}
                        variant="contained"
                        color="primary"
                        endIcon={<ThumbDownAlt />}
                        onClick={() => this.openCombineBoqChangePopup()}
                      >
                        Change BOQ's
                      </Button>
                    )}
                  </Typography>
                </Box>
              </AccordionDetails>
            </Grid>

            <Dialog
              open={this.state.openCombineBoqChangePopup}
              onClose={this.closeCombineBoqChangePopup}
            >
              <DialogTitle>Please Enter the Reason for updating the BOQ</DialogTitle>
              <DialogContent>
                <TextField
                  label="Reason"
                  name="Reason"
                  onChange={(e) => this.onChangeBoq(e)}
                  fullWidth
                  required // Mark the field as required
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeCombineBoqChangePopup} color="primary">
                  Close
                </Button>
                <Button onClick={() => this.submitCombineBoqChange()} color="primary">
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>

          <Divider />

          <AccordionActions>
            {this.props.view ? (
              <Button size="small" variant="outlined" onClick={() => this.openProjectView(data.id)}>
                View
              </Button>
            ) : null}
            <Button size="small" variant="outlined">
              Mark Inactive
            </Button>
          </AccordionActions>
        </Accordion>
      </AccordionRoot>
    );
  }
}

export default LeadView;
