import React, { Component } from 'react';
import { Grid, Paper } from '@mui/material';
import { Box, Button, Chip, Divider } from '@mui/material';
import RowComponent from './components/RowComponent.jsx';
import config from '../../constants/index.jsx';
import Auth from 'app/helpers/authorization';
import Postie from 'app/helpers/postie';
import displayer from 'app/helpers/displayer';
import Details from '../crudoperations/details.jsx';

class CfgDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      estimateId: '',
      areaId: ''
    };
  }

  componentDidMount = () => {
    const queryParams = displayer.getUrlParams('/estimate/');
    this.setState({
      estimateId: queryParams[0],
      areaId: queryParams[1],
      type: queryParams[2],
      typeId: queryParams[3]
    });
  };
  addCfg = async () => {
    let postData = {
      authToken: localStorage.getItem(config.Token_name),
      estimate_id: this.state.estimateId,
      area_id: this.state.areaId,
      cfg_id: this.state.typeId
    };
    let serviceUrl = config.BASE_API_URL + 'estimate/addcfg';
    let jsonData = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (jsonData.status === 'success') {
      Postie.navigateLink('/project/estimate/display/' + this.state.estimateId);
    }
  };

  render() {
    const { data, dataLoaded } = this.state;
    console.log('Estimate View Data', data);
    // Only render if data is loaded
    return (
      <Grid container>
        <Grid item md="12" xs="12" sm="12">
          <h3>Add CFG To Estimate</h3>
        </Grid>
        <Grid item md="12" xs="12" sm="12">
          {this.state.type && <Details type={this.state?.type} typeId={this.state?.typeId} />}
        </Grid>
        <Grid item md="12" xs="12" sm="12" style={{ textAlign: 'center' }}>
          <Button variant="outlined" onClick={() => this.addCfg()}>
            Add To Estimate
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default CfgDetail;
