import React, { Component } from 'react';
import {
  Grid,
  Paper,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
//import { Box,Button,Divider,styled} from '@mui/material';
import { Box,Button,Divider} from '@mui/material';
import config from '../../constants/index.jsx';
import Auth from 'app/helpers/authorization';
import Postie from 'app/helpers/postie';
import displayer from 'app/helpers/displayer';
import FooterBox from './components/Footer.jsx';
import {Email, Phone } from '@mui/icons-material';
import ComparisonBoqRowComponent from './components/ComparisionBoqRowComponent.jsx';

// const Title = styled('span')(() => ({
//   fontSize: '1rem',
//   fontWeight: '500',
//   marginRight: '.5rem',
//   textTransform: 'capitalize'
// }));

// const SubTitle = styled('span')(({ theme }) => ({
//   fontSize: '0.875rem',
//   color: theme.palette.text.secondary
// }));

const legendStyle = {
  show: true,
  itemGap: 20,
  icon: 'circle',
  bottom: 0,
  textStyle: {
    color: '#333333',
    fontSize: 13,
    fontFamily: 'roboto'
  }
};
const tooltip = {
  show: false,
  trigger: 'item',
  formatter: '{a} <br/>{b}: {c} ({d}%)'
};
const xaxis = [
  {
    axisLine: {
      show: false
    },
    splitLine: {
      show: false
    }
  }
];
const yaxis = [
  {
    axisLine: {
      show: false
    },
    splitLine: {
      show: false
    }
  }
];

const DisplayEstimate = (props) => (
  <Paper style={{ padding: '8px', backgroundColor: '#FAF3F0' }} elevation={7}>
    <h3 style={{ textAlign: 'center' }}>{props.title}</h3>
    <Divider style={{ marginBottom: '3px' }} />

    <Grid container spacing={2}>
      <Grid item md="6" xs="6" sm="6">
        Net Cost:
      </Grid>
      <Grid item md="6" xs="6" sm="6" style={{ textAlign: 'right' }}>
        &#8377; {props.net_cost}
      </Grid>
      <Grid item md="6" xs="6" sm="6">
        Design consultation Cost:
      </Grid>
      <Grid item md="6" xs="6" sm="6" style={{ textAlign: 'right' }}>
        &#8377; {props.design_fee}
      </Grid>
      {props.discount > 0 && (
        <Grid item md="6" xs="6" sm="6">
          Discount{' '}
          {props.discount_type.toLowerCase() === 'percent'
            ? `(${props.discount_percent})`
            : `(Fixed)`}
        </Grid>
      )}
      {props.discount > 0 && (
        <Grid item md="6" xs="6" sm="6" style={{ textAlign: 'right' }}>
          &#8377; {props.discount}
        </Grid>
      )}
    </Grid>
    <Divider style={{ margin: '3px 0' }} />
    <Grid container spacing={2}>
      <Grid item md="6" xs="6" sm="6">
        <h4>Total:</h4>
        {props.total && (
          <Button size="small" variant="outlined" onClick={() => props.setOpenPiePopup()}>
            View Distribution
          </Button>
        )}
      </Grid>

      <Grid item md="6" xs="6" sm="6" style={{ textAlign: 'right' }}>
        <h4>&#8377; {props.total}</h4>
      </Grid>
    </Grid>
  </Paper>
);

// const AccordionRoot = styled(Box)(({ theme }) => ({
//   width: '100%',
//   marginBottom: '10px',
//   '& .heading': {
//     fontSize: theme.typography.pxToRem(15),
//     [theme.breakpoints.down('sm')]: {
//       fontSize: theme.typography.pxToRem(12)
//     }
//   },
//   '& .secondaryHeading': {
//     color: theme.palette.text.secondary,
//     fontSize: theme.typography.pxToRem(15),
//     [theme.breakpoints.down('sm')]: {
//       fontSize: theme.typography.pxToRem(12)
//     }
//   },
//   '& .icon': {
//     width: 20,
//     height: 20,
//     verticalAlign: 'bottom'
//   },
//   '& .details': { alignItems: 'center' },
//   '& .column': { flexBasis: '33.33%' },
//   '& .helper': {
//     padding: theme.spacing(1, 2),
//     borderLeft: `2px solid ${theme.palette.divider}`
//   },
//   '& .link': {
//     textDecoration: 'none',
//     color: theme.palette.primary.main,
//     '&:hover': { textDecoration: 'underline' }
//   }
// }));

class BoqComparision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      projectData: '',
      estimateId: '',
      fitoutsData: '',
      modularData: '',
      ProductData: '',
      furnitureData: '',
      fitoutId: '',
      modularId: '',
      productId: '',
      furnitureId: '',
      options: {},
      total: {},
      estimates: {
        sections: []
      },
      estimateData: [],
      selectedModularTab: 0,
      setOpenPiePopup: false,
      chartData: [],
      unCheckedData: [],
      filter: '',
      scopeFilter: {
        name: '',
        value: ''
      },
      FitoutCatagories: '',
      ModularCatagories: '',
      FurnitureCatagories: '',
      setEditMode: false,
      isClient: true,
      openAddServicePopup: { show: false, estimateData: {} },
      fromBoqData: '',
      fromBoqId: '',
      fromBoqestimates: {
        sections: []
      },
      fromBoqTotal: '',
      toBoqData: '',
      toBoqId: '',
      toBoqestimates: {
        sections: []
      },
      toBoqTotal: '',
      finalData: {},
      type: '',
      boqType: 'estimates'
    };
  }

  componentDidMount = () => {
    const queryParams = displayer.getUrlParams('/estimate/comparasion/');
    this.getProjectDetails(queryParams[0], queryParams[1]);
    this.getWorkCategories();
  };
  callBackFunction = () => {
    const queryParams = displayer.getUrlParams('/combine/estimates/');
    this.getProjectDetails(queryParams[0]);
  };

  getProjectDetails = async (id, type) => {
    let userLogged = await Auth.isUserLoggedIn();
    let url = config.BASE_API_URL + 'project/' + id + '/' + Auth.urlTokenizer();
    let jsonData = await Postie.fetchUrl(url);
    let loggedInUserRole = Auth.getUserProjectRole(jsonData.colabrators);
    this.setState({
      ...this.state,
      projectData: jsonData,
      dataLoaded: true, // Set dataLoaded to true when data is loaded
      isClient: !(userLogged && loggedInUserRole.role !== 'client'),
      type: type,
      boqType:
        type === 'modular'
          ? 'modular_estimates'
          : type === 'furniture'
          ? 'furniture_estimates'
          : type === 'product'
          ? 'product_estimates'
          : 'estimates'
    });
  };
  getFitoutEstimates = async (id) => {
    let url = config.BASE_API_URL + 'estimate/' + id;
    let jsonData = await Postie.fetchUrl(url);
    let sectionData = this.state.estimates.sections;
    let total = {};
    jsonData.sections.forEach((section) => {
      if (section.services.length > 0) {
        let services = section.services;
        // delete section['services'];

        if (!sectionData.hasOwnProperty(section.project_display_name)) {
          sectionData[section.project_display_name] = [];
          sectionData[section.project_display_name]['area'] = section;
        }
        sectionData[section.project_display_name]['fitout_services'] = services;
        if (!total.hasOwnProperty(section.project_display_name)) {
          total[section.project_display_name] = {
            fitouts: 0,
            modulars: 0,
            furniture: 0,
            product: 0
          };
        }
        services.forEach((service) => {
          total[section.project_display_name]['fitouts'] =
            parseFloat(total[section.project_display_name]['fitouts']) + parseFloat(service.rate);
        });
      }
    });
    this.setState(
      {
        fitoutsData: jsonData,
        estimateId: jsonData.id,
        estimates: { ...this.state.estimates, sections: sectionData },
        total: total,
        dataLoaded: true // Set dataLoaded to true when data is loaded
      },
      function () {
        this.generateChartOption();
      }
    );
    return jsonData;
  };

  handleTabChange = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };
  handleModularTabChange = (event, newValue) => {
    this.setState({ selectedModularTab: newValue });
  };

  generateChartOption = async () => {
    let total = 0;
    let fitoutbudget = 0;
    let modularbudget = 0;
    let productbudget = 0;
    let furniturebudget = 0;
    if (this.state.fitoutsData) {
      fitoutbudget = parseFloat(this.state.fitoutsData.budget);
      total = total + fitoutbudget;
    }
    if (this.state.modularData) {
      modularbudget = parseFloat(this.state.modularData.budget);
      total = total + modularbudget;
    }
    if (this.state.ProductData) {
      productbudget = parseFloat(this.state.ProductData.budget);
      total = total + productbudget;
    }
    if (this.state.furnitureData) {
      furniturebudget = parseFloat(this.state.furnitureData.budget);
      total = total + furniturebudget;
    }

    let fitoutPercent = 100;
    let modularPercent = 0;
    let furniturePercent = 0;
    let productPercent = 0;
    if (total > 0) {
      fitoutPercent = ((fitoutbudget / total) * 100).toFixed(2);
      modularPercent = ((modularbudget / total) * 100).toFixed(2);
      productPercent = ((productbudget / total) * 100).toFixed(2);
      furniturePercent = ((furniturebudget / total) * 100).toFixed(2);
    }

    const option = {
      legend: legendStyle,
      tooltip: tooltip,
      xAxis: xaxis,
      yAxis: yaxis,
      color: ['red', 'green', 'orange', 'blue'],
      series: [
        {
          name: 'Costing Share',
          type: 'pie',
          radius: ['45%', '72.55%'],
          center: ['50%', '50%'],
          avoidLabelOverlap: false,
          hoverOffset: 5,
          stillShowZeroSum: false,
          label: {
            normal: {
              show: false,
              position: 'center', // shows the description data to center, turn off to show in right side
              textStyle: {
                color: '#333333',
                fontSize: 13,
                fontFamily: 'roboto'
              },
              formatter: '{a}'
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: '14',
                fontWeight: 'normal'
                // color: "rgba(15, 21, 77, 1)"
              },
              formatter: '{b} \n{c} ({d}%)'
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: [
            {
              value: Math.ceil(fitoutPercent),
              name: 'Fitout Estimate'
            },
            {
              value: Math.ceil(modularPercent),
              name: 'Modular Estimate'
            },
            { value: Math.ceil(productPercent), name: 'Product Estimate' },
            { value: Math.ceil(furniturePercent), name: 'Furniture Estimate' }
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    this.setState({ ...this.state, options: option });
  };

  getPieChartData = async (id) => {
    let url = config.BASE_API_URL + 'cms/estimate/graphicaldata/' + id;
    let jsonData = await Postie.fetchUrl(url);
    if (jsonData) {
      this.setState(
        {
          ...this.state,
          estimateData: jsonData
        },
        function () {
          //console.log('estimateData', this.state.estimateData);
        }
      );
    }
  };

  setOpenPiePopup = async (estimateId) => {
    this.getPieChartData(estimateId);
    let data = await this.state.estimateData;
    if (data) {
      this.setState({ setOpenPiePopup: true });
    }
  };
  closeOpenPiePopup = () => {
    this.setState({ setOpenPiePopup: false });
  };

  updateDistribution = (record, value) => {
    if (value === false) {
      this.setState(
        (prevState) => ({
          unCheckedData: [...prevState.unCheckedData, record]
        }),
        () => {
          //console.log('state updated record', this.state.unCheckedData);
        }
      );
    } else if (value === true) {
      this.setState(
        (prevState) => ({
          unCheckedData: prevState.unCheckedData.filter((data) => data !== record)
        }),
        () => {
          //console.log('state updated record', this.state.unCheckedData);
        }
      );
    }
  };

  getChartData = () => {
    let estimateData = this.state.estimateData;
    let removedData = this.state.unCheckedData;
    let chartData = [];

    estimateData.forEach((record) => {
      if (!removedData.includes(record)) {
        chartData.push({
          name: record.name + '(' + record.UOM + ')',
          value: record.value
        });
      }
    });

    return chartData;
  };
  getWorkCategories = async () => {
    let url = config.BASE_API_URL + 'cms/estimate/workcategories/' + Auth.urlTokenizer();
    const json = await Postie.fetchUrl(url);
    const fitouts = [];
    const modulars = [];
    const furniture = [];
    json.forEach((record) => {
      if (record.boq_type.trim().toLowerCase() === 'fitouts') {
        fitouts.push(record);
      }
      if (record.boq_type.trim().toLowerCase() === 'modular') {
        modulars.push(record);
      }
      if (record.boq_type.trim().toLowerCase() === 'furniture') {
        furniture.push(record);
      }
    });
    this.setState(
      (prevState) => ({
        FitoutCatagories: fitouts
      }),
      () => {
        //console.log('FitoutCatagories', this.state.FitoutCatagories);
      }
    );
    this.setState(
      (prevState) => ({
        ModularCatagories: modulars
      }),
      () => {
        //console.log('ModularCatagories', this.state.ModularCatagories);
      }
    );
    this.setState(
      (prevState) => ({
        FurnitureCatagories: furniture
      }),
      () => {
        //console.log('FurnitureCatagories', this.state.FurnitureCatagories);
      }
    );
  };
  onAreaFilter = (e) => {
    const {value } = e.target;

    //this.setState({ filter: value });
    document
      .getElementById(value)
      .scrollIntoView({ block: 'start', behavior: 'smooth', top: '20%' });
  };
  onScopeFilter = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        scopeFilter: {
          name: name,
          value: value
        }
      },
      function () {
        //console.log('scopeFilter', this.state.scopeFilter);
      }
    );
  };
  onEditBoq = async () => {
    const queryParams = displayer.getUrlParams('/combine/estimates/');
    //let userLogged = await Auth.isUserLoggedIn();
    let Username = Auth.getLoggedInUserData('member_name');
    let fitoutReason = `${Username} has initiated the change in Fitout BOQ (${
      this.state.fitoutsData.id
    }) on ${displayer.currentDate(0)}`;
    let modularReason = `${Username} has initiated the change in Modular BOQ (${
      this.state.modularData.id
    }) on ${displayer.currentDate(0)}`;
    let productReason = `${Username} has initiated the change in Product BOQ (${
      this.state.ProductData.id
    }) on ${displayer.currentDate(0)}`;
    let furnitureReason = `${Username} has initiated the change in Furniture BOQ (${
      this.state.furnitureData.id
    }) on ${displayer.currentDate(0)}`;

    let postDataArray = [];

    if (this.state.fitoutsData.id) {
      postDataArray.push({
        estimate_id: this.state.fitoutsData.id,
        project_id: queryParams[0],
        reason: fitoutReason,
        authToken: localStorage.getItem(config.Token_name)
      });
    }

    if (this.state.modularData.id) {
      postDataArray.push({
        estimate_id: this.state.modularData.id,
        project_id: queryParams[0],
        reason: modularReason,
        authToken: localStorage.getItem(config.Token_name)
      });
    }

    if (this.state.ProductData.id) {
      postDataArray.push({
        estimate_id: this.state.ProductData.id,
        project_id: queryParams[0],
        reason: productReason,
        authToken: localStorage.getItem(config.Token_name)
      });
    }

    if (this.state.furnitureData.id) {
      postDataArray.push({
        estimate_id: this.state.furnitureData.id,
        project_id: queryParams[0],
        reason: furnitureReason,
        authToken: localStorage.getItem(config.Token_name)
      });
    }

    let promises = postDataArray.map(async (postData) => {
      var servicesUrl = config.BASE_API_URL + 'estimate/change';
      return Postie.sendAsynchrnousCall(postData, servicesUrl);
    });

    let responses = await Promise.all(promises);
    if (responses) {
      this.getProjectDetails(queryParams[0]);
      this.getWorkCategories();
      this.setState({
        setEditMode: true
      });
    }
  };
  onCloseEditBoq = () => {
    this.setState({
      setEditMode: false
    });
  };

  addServiceInBoq = (serviceEstimateData) => {
    this.setState(
      {
        openAddServicePopup: { show: true, estimateData: serviceEstimateData }
      },
      function () {
        // console.log('fitout boq Id', this.state.fitoutsData);
        // console.log('Modular boq Id', this.state.modularData.id);
        // console.log('product boq Id', this.state.ProductData.id);
        // console.log('furniture boq Id', this.state.furnitureData.id);
      }
    );
  };
  closeServicePopup = () => {
    this.setState({
      openAddServicePopup: { show: false, estimateData: {} }
    });
  };
  getEstimateData = async (e) => {
    const { value } = e.target;
    let url = config.BASE_API_URL + 'estimate/' + value;
    let jsonData = await Postie.fetchUrl(url);
    let sectionData = this.state.estimates.sections;
    jsonData.sections.forEach((section) => {
      if (section.services.length > 0) {
        let services = section.services;
        // delete section['services'];

        if (!sectionData.hasOwnProperty(section.project_display_name)) {
          sectionData[section.project_display_name] = [];
          sectionData[section.project_display_name]['area'] = section;
        }
        sectionData[section.project_display_name]['services'] = services;
      }
    });
    return jsonData;
  };
  selectFromBoqFilter = async (e) => {
    const jsonData = await this.getEstimateData(e);
    this.setState(
      {
        fromBoqData: jsonData,
        fromBoqId: jsonData.id,
        dataLoaded: true // Set dataLoaded to true when data is loaded
      },
      function () {
        //console.log('fromBoqData', this.state.fromBoqData);
        //this.generateChartOption();
      }
    );
    return jsonData;
  };

  selectToBoqFilter = async (e) => {
    const jsonData = await this.getEstimateData(e);
    this.setState(
      {
        toBoqData: jsonData,
        toBoqId: jsonData.id,
        dataLoaded: true // Set dataLoaded to true when data is loaded
      },
      function () {
        //console.log('toBoqData', this.state.toBoqData);
        //this.generateChartOption();
        this.processComparison();
      }
    );

    return jsonData;
  };

  processDeleteData = (tobePushedData, boq1lineItem, areaId) => {
    const tobePushed = tobePushedData[areaId];
    const pushedSowIds = Object.keys(tobePushed);
    console.log(
      ' compare processDeleteData totaldata',
      tobePushedData,
      'area specific data',
      tobePushed,
      boq1lineItem,
      areaId
    );
    if (pushedSowIds.includes(boq1lineItem.sow_id)) {
      const pushedRefId = Object.keys(tobePushed[boq1lineItem.sow_id]);
      if (pushedRefId.includes(boq1lineItem.ref_id)) {
        const pushedUOM = Object.keys(tobePushed[boq1lineItem.sow_id][boq1lineItem.ref_id]);
        if (pushedUOM.includes(boq1lineItem.uom)) {
          let deleteFlag = true;
          tobePushed[boq1lineItem.sow_id][boq1lineItem.ref_id][boq1lineItem.uom].forEach(
            (pushService) => {
              if (pushService.cost === boq1lineItem.cost) {
                deleteFlag = false;
              }
            }
          );
          if (deleteFlag) {
            tobePushed[boq1lineItem.sow_id][boq1lineItem.ref_id][boq1lineItem.uom].push(
              boq1lineItem
            );
          }
        } else {
          tobePushed[boq1lineItem.sow_id][boq1lineItem.ref_id][boq1lineItem.uom].push(boq1lineItem);
        }
      } else {
        tobePushed[boq1lineItem.sow_id][boq1lineItem.ref_id] = {
          [boq1lineItem.uom]: [boq1lineItem]
        };
      }
    } else {
      tobePushed[boq1lineItem.sow_id] = {
        [boq1lineItem.ref_id]: {
          [boq1lineItem.uom]: [boq1lineItem]
        }
      };
    }
    tobePushedData[areaId] = tobePushed;
    return tobePushedData;
  };
  markDeletDataIChanged = (tobePushedData, boq1lineItem, areaId) => {
    const tobePushed = tobePushedData[areaId];
    if (tobePushed[boq1lineItem.sow_id]) {
      if (tobePushed[boq1lineItem.sow_id][boq1lineItem.ref_id]) {
        if (tobePushed[boq1lineItem.sow_id][boq1lineItem.ref_id][boq1lineItem.uom]) {
          let data = tobePushed[boq1lineItem.sow_id][boq1lineItem.ref_id][boq1lineItem.uom];
          let changedIndex = -1;
          data.forEach((record, index) => {
            if (record.id === boq1lineItem.id) {
              changedIndex = index;
            }
          });
          console.log(' comapre markDeletDataIChanged tobePushed changeIndex:', changedIndex);
          if (changedIndex !== -1) {
            data.splice(changedIndex, 1);
            tobePushed[boq1lineItem.sow_id][boq1lineItem.ref_id][boq1lineItem.uom] = data;
          }
        }
      }
    }
    console.log(
      'markDeletDataIChanged tobePushedData:',
      tobePushedData,
      'areaId',
      areaId,
      'lineitem',
      boq1lineItem,
      'tobePushed',
      tobePushed
    );
    tobePushedData[areaId] = tobePushed;
    return tobePushedData;
  };
  processComparison = async () => {
    if (this.state.fromBoqData) {
      let fromData = this.state.fromBoqData;
      let toData = this.state.toBoqData;
      let finalData = [];

      //console.log('got inside processComparison');
      //gettting area from from Data and adding to finaldata
      toData.sections.forEach((area) => {
        if (area.services.length > 0) {
          area.services.forEach((service) => {
            service.servicesbreakup.forEach((sb) => {
              sb['comment'] = 'Added New Service';
            });
            service.productsbreakup.forEach((sb) => {
              sb['comment'] = 'Added New Service';
            });
          });
          finalData[area.id] = area;
        }
      });

      //Procesing change update
      let boq1DeletedAreaServices = {};
      fromData.sections.forEach((area) => {
        if (Object.keys(finalData).includes(area.id.toString())) {
          //area exists in new boq;
          //console.log('check for area id', Object.keys(finalData), area.id.toString());
          //read services for boq1
          console.log(
            'check for area services',
            finalData[area.id.toString()].services,
            area.services
          );
          let maxServiceLength = finalData[area.id.toString()].services.length;
          if (area.services.length > finalData[area.id.toString()].services.length) {
            maxServiceLength = area.services.length;
          }
          boq1DeletedAreaServices[area.id.toString] = [];
          for (let i = 0; i < maxServiceLength; i++) {
            //logic to check the area services has been deleted
            if (!finalData[area.id.toString()].services[i]) {
              boq1DeletedAreaServices[area.id.toString].push(area.services[i]);
            }
          }
          area.services.forEach((boq1Service) => {
            finalData[area.id.toString()].services.forEach((boq2Service) => {
              boq2Service.servicesbreakup.forEach((boq2lineItem, index) => {
                boq1Service.servicesbreakup.forEach((boq1lineItem) => {
                  if (boq2lineItem.sow_id === boq1lineItem.sow_id) {
                    if (
                      boq2lineItem.ref_id.trim().toLowerCase() ===
                      boq1lineItem.ref_id.trim().toLowerCase()
                    ) {
                      if (
                        boq2lineItem.uom.trim().toLowerCase() ===
                        boq1lineItem.uom.trim().toLowerCase()
                      ) {
                        if (boq2lineItem.cost === boq1lineItem.cost) {
                          if (boq2lineItem.rate === boq1lineItem.rate) {
                            if (boq2lineItem.base_material === boq1lineItem.base_material) {
                              if (boq2lineItem.finish === boq1lineItem.finish) {
                                if (boq2lineItem.brand !== boq1lineItem.brand) {
                                  boq2lineItem['comment'] =
                                    'Brand  has been changed from ' +
                                    boq1lineItem.brand +
                                    ' to ' +
                                    boq2lineItem.brand;
                                } else {
                                  boq2lineItem['comment'] = 'No change in Service';
                                }
                              } else {
                                boq2lineItem['comment'] =
                                  'Finish has been changed from ' +
                                  boq1lineItem.finish +
                                  ' to ' +
                                  boq2lineItem.finish;
                                if (boq2lineItem.brand !== boq1lineItem.brand) {
                                  boq2lineItem['comment'] +=
                                    '. Brand has been changed from ' +
                                    boq1lineItem.brand +
                                    ' to ' +
                                    boq2lineItem.brand;
                                }
                              }
                            } else {
                              boq2lineItem['comment'] =
                                'Base Material has been changed from ' +
                                boq1lineItem.base_material +
                                ' to ' +
                                boq2lineItem.base_material;

                              if (boq2lineItem.finish === boq1lineItem.finish) {
                                if (boq2lineItem.brand !== boq1lineItem.brand) {
                                  boq2lineItem['comment'] +=
                                    '. Brand  has been changed from ' +
                                    boq1lineItem.brand +
                                    ' to ' +
                                    boq2lineItem.brand;
                                }
                              } else {
                                boq2lineItem['comment'] +=
                                  '. Finish has been changed from ' +
                                  boq1lineItem.finish +
                                  ' to ' +
                                  boq2lineItem.finish;
                                if (boq2lineItem.brand !== boq1lineItem.brand) {
                                  boq2lineItem['comment'] +=
                                    '. Brand  has been changed from ' +
                                    boq1lineItem.brand +
                                    ' to ' +
                                    boq2lineItem.brand;
                                }
                              }
                            }
                          } else {
                            if (boq2lineItem.rate > boq1lineItem.rate) {
                              boq2lineItem['comment'] =
                                'Rate has been increased by' +
                                (boq2lineItem.rate - boq1lineItem.rate) +
                                '/' +
                                boq2lineItem.uom;
                              boq2lineItem['comment'] +=
                                'Qty has been decreased by' + (boq1lineItem.qty - boq2lineItem.qty);
                            } else {
                              boq2lineItem['comment'] =
                                'Rate has been decreased by' +
                                (boq1lineItem.rate - boq2lineItem.rate) +
                                '/' +
                                boq2lineItem.uom;
                              boq2lineItem['comment'] +=
                                'Qty has been increased by' + (boq2lineItem.qty - boq1lineItem.qty);
                            }
                          }
                        } else {
                          //check for rate change
                          if (boq2lineItem.rate === boq1lineItem.rate) {
                            //check for qty change
                            if (boq2lineItem.qty > boq1lineItem.qty) {
                              boq2lineItem['comment'] =
                                'Qty has been increased by' + (boq2lineItem.qty - boq1lineItem.qty);
                            } else {
                              boq2lineItem['comment'] =
                                'Qty has been decreased by' + (boq1lineItem.qty - boq2lineItem.qty);
                            }
                          } else {
                            if (boq2lineItem.rate > boq1lineItem.rate) {
                              boq2lineItem['comment'] =
                                'Rate has been increased by' +
                                (boq2lineItem.rate - boq1lineItem.rate) +
                                '/' +
                                boq2lineItem.uom;
                            } else {
                              boq2lineItem['comment'] =
                                'Rate has been decreased by' +
                                (boq1lineItem.rate - boq2lineItem.rate) +
                                '/' +
                                boq2lineItem.uom;
                            }
                          }
                        }
                      } else {
                        boq2lineItem['comment'] =
                          'UOM has been changed from' + boq1lineItem.uom + ' to' + boq2lineItem.uom;
                      }
                    }
                  }
                });
              });
            });
          });
        } else if (area.services.length > 0) {
          area.services.forEach((service) => {
            service.servicesbreakup.forEach((sb) => {
              sb['comment'] = 'Area has been Removed';
            });
            service.productsbreakup.forEach((sb) => {
              sb['comment'] = 'Area has been Removed';
            });
          });
          finalData.push({ [area.id]: area });
        }
      });

      //Check for deleted Services
      // final data area get
      fromData.sections.forEach((area) => {
        console.log('Deleted', finalData[area.id.toString()], area.id.toString());
        if (finalData[area.id.toString()]) {
          const finalServices = finalData[area.id.toString()].services;
          const fromServices = Object.keys(area.services);
          fromServices.forEach((serviceIndex) => {
            console.log('serviceIndex', serviceIndex);
            const fromlineBreakup = area.services[serviceIndex].servicesbreakup;
            if (finalServices[serviceIndex]) {
              const finalServiceBreakup = finalServices[serviceIndex].servicesbreakup;
              fromlineBreakup.forEach((boq1lineItem) => {
                let pushedToData = false;
                let maxServiceLength = fromlineBreakup.length;
                if (maxServiceLength < finalServiceBreakup.length) {
                  maxServiceLength = finalServiceBreakup.length;
                }

                for (let i = 0; i < maxServiceLength; i++) {
                  if (finalServiceBreakup[i]) {
                    let boq2lineItem = finalServiceBreakup[i];
                    if (boq2lineItem.sow_id === boq1lineItem.sow_id) {
                      pushedToData = false;
                      if (
                        boq2lineItem.ref_id.trim().toLowerCase() ===
                        boq1lineItem.ref_id.trim().toLowerCase()
                      ) {
                        pushedToData = false;
                        break;
                      } else {
                        console.log(
                          'boq 1 has refId mismatch',
                          boq1lineItem.sow_id,
                          boq2lineItem.sow_id,
                          boq1lineItem,
                          boq2lineItem,
                          i
                        );
                        pushedToData = true;
                      }
                    } else {
                      pushedToData = true;
                    }
                  } else {
                    pushedToData = true;
                    break;
                  }
                }
                console.log('finalServiceBreakup', finalServiceBreakup, pushedToData, boq1lineItem);
                if (pushedToData) {
                  boq1lineItem['comment'] = 'Deleted from Boq 1';
                  finalServices[serviceIndex].servicesbreakup.push(boq1lineItem);
                }
              });
            } else {
              fromlineBreakup.forEach((boq1lineItem) => {
                boq1lineItem['comment'] = 'Deleted from Boq 1';
                finalServices[serviceIndex].servicesbreakup.push(boq1lineItem);
              });
            }
          });
        }
      });
      //run loop for final data area  and get services from final data and check for boq 1 area  has the service
      console.log('boq1DeletedAreaServices', boq1DeletedAreaServices);
      this.setState({ ...this.state, finalData: finalData }, function () {
        //console.log('processComparison', this.state.finalData);
      });
    } else {
      alert('Please select the first boq from first dropdown');
      this.setState({
        toBoqData: ''
      });
    }
  };
  render = () => {
    const {
      projectData,
      fitoutsData,
      dataLoaded,
      fromBoqData,
      toBoqData,
    } = this.state;
    //const sections = estimates.sections;
    const sections = Object.keys(this.state.finalData);
    //console.log('finalData', sections);
    // console.log('toBoqData', toBoqData, toBoqestimates.sections);
    //console.log('project Data', projectData);

    if (!dataLoaded) {
      return (
        <Grid
          container
          md={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Center the loading message
            height: '100vh' // Center vertically
          }}
        >
          <h3>Loading data...</h3>
        </Grid>
      );
    }

    return (
      <Grid container style={{ margin: '10px' }}>
        <Grid container spacing="2">
          <Grid item xs="12" sm="12" md="6">
            <Paper style={{ padding: '10px', margin: '5px' }}>
              <h4 style={{ textAlign: 'center' }}>Project Information</h4>
              <p>
                <span style={{ textSize: '20' }}>Project #: </span>
                {projectData?.id}
              </p>
              <p>
                <span style={{ textSize: '20' }}>Location: </span>
                {projectData?.project_address}
              </p>
              <p>
                <span style={{ textSize: '20' }}>Type: </span>
                {projectData?.project_type}
              </p>
            </Paper>
          </Grid>
          <Grid item xs="12" sm="12" md="6">
            <Paper style={{ padding: '8px', margin: '5px' }}>
              <h4 style={{ textAlign: 'center' }}>Client Information</h4>
              <h5>{projectData?.client?.fullname}</h5>
              <p>
                <Phone fontSize="2px" style={{ marginRight: '8px' }} />
                {projectData?.client?.phone}
              </p>
              <p>
                <Email fontSize="2px" style={{ marginRight: '8px' }} />
                {projectData?.client?.email}
              </p>
            </Paper>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md="12" xs="12" sm="12" style={{ padding: ' 0 40px', marginBottom: '20px' }}>
            <h4>Boq Comparison</h4>
            <Grid container spacing={2}>
              <Grid item xs="12" sm="12" md="6">
                <FormControl variant="outlined" style={{ width: '90%' }}>
                  <InputLabel>From boq</InputLabel>
                  <Select
                    label="Choose boq"
                    name="fromboq"
                    onChange={(e) => this.selectFromBoqFilter(e)}
                    defaultValue=""
                  >
                    {projectData &&
                      projectData[this.state.boqType]?.map((boq, index) => (
                        <MenuItem key={index} value={boq.id}>
                          id- {boq.id} Total &#8377; {boq.budget}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs="12" sm="12" md="6">
                <FormControl variant="outlined" style={{ width: '90%' }}>
                  <InputLabel>To boq</InputLabel>
                  <Select
                    label="Choose boq"
                    name="toboq"
                    onChange={(e) => this.selectToBoqFilter(e)}
                    defaultValue=""
                  >
                    {projectData &&
                      projectData[this.state.boqType]?.map((boq, index) => (
                        <MenuItem key={index} value={boq.id}>
                          id- {boq.id} Total &#8377; {boq.budget}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md="12" xs="12" sm="12" style={{ padding: ' 0 40px', marginBottom: '20px' }}>
            <Grid container spacing={2}>
              <Grid item xs="12" sm="12" md="6">
                <DisplayEstimate
                  title={`${this.state.type} BOQ 1`}
                  total={fromBoqData ? fromBoqData.budget : 0.0}
                  net_cost={fromBoqData ? fromBoqData.net_cost : 0.0}
                  discount={fromBoqData ? fromBoqData.discount : 0.0}
                  design_fee={fromBoqData ? fromBoqData.consultation_cost : 0.0}
                  discount_percent={fromBoqData?.discount_percent}
                  discount_type={fromBoqData?.discount_type}
                  setOpenPiePopup={() => this.setOpenPiePopup(fromBoqData.id)}
                />
              </Grid>
              <Grid item xs="12" sm="12" md="6">
                <DisplayEstimate
                  title={`${this.state.type} BOQ 2`}
                  total={toBoqData ? toBoqData.budget : 0.0}
                  net_cost={toBoqData ? toBoqData.net_cost : 0.0}
                  discount={toBoqData ? toBoqData.discount : 0.0}
                  design_fee={toBoqData ? toBoqData.consultation_cost : 0.0}
                  discount_percent={toBoqData?.discount_percent}
                  discount_type={toBoqData?.discount_type}
                  setOpenPiePopup={() => this.setOpenPiePopup(toBoqData.id)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} style={{ padding: ' 0 40px' }}>
            <Box style={{ margin: '15px' }}>Attachment “A” – Pricing Detail and Breakout.</Box>
          </Grid>
          <Grid item md={12} style={{ padding: ' 0 40px' }}>
            {sections &&
              sections.map((area, index) => (
                <Grid
                  id={this.state.finalData[area]['id']}
                  className={area}
                  conatiner
                  key={`${area}`}
                  style={{
                    padding: '10px',
                    margin: '10px 0',
                    backgroundColor: '#E3E1D9'
                  }}
                >
                  <Grid item md="12">
                    <h3>
                      {this.state.finalData[area].project_display_name} (Floor{' '}
                      {this.state.finalData[area]['floor']})
                    </h3>
                  </Grid>
                  <Grid item md="12">
                    {this.state.finalData[area] && this.state.finalData[area].services && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          {/*console.log('final data', this.state.finalData)*/}
                          {this.state.finalData[area].services.map((cfg, index) => (
                            <ComparisonBoqRowComponent
                              key={index}
                              data={cfg}
                              area={this.state.finalData[area].project_display_name}
                              estimateId={this.state.estimateId}
                              is_editable={false}
                              scopeFilterData={this.state.scopeFilter}
                              setEditMode={this.state.setEditMode}
                              fitoutCategories={this.state.FitoutCatagories}
                              modularCategories={this.state.ModularCatagories}
                              furnitureCategories={this.state.FurnitureCatagories}
                              projectId={projectData.id}
                              serviceEstimateData={this.state.finalData[area]}
                              callBack={() => this.callBackFunction()}
                            />
                          ))}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <FooterBox
          terms={fitoutsData.project?.organization?.terms}
          payment_terms={fitoutsData.project?.organization?.payment_terms}
          organization={fitoutsData.project?.organization}
        />
      </Grid>
    );
  };
}

export default BoqComparision;
