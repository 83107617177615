import React, { Component } from 'react';
import {
  Grid,
  Tabs,
  Tab,
  TextField,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material';
import { Box, Button, Divider } from '@mui/material';
import Auth from '../../../helpers/authorization';
import displayer from 'app/helpers/displayer';
import FileView from 'app/views/bids/components/fileView';
import UploadFile from 'app/views/files/uploadFile.jsx';
import config from '../../../constants/index.jsx';
import Postie from 'app/helpers/postie';
import Switch from '@mui/material/Switch';
import AddIcon from '@mui/icons-material/Add';
import $ from 'jquery';
import ScopeView from 'app/views/leadView/components/ScopeView';
import Scope from './Scope';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

class ProjectAreas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'Dimensions',
      checkedScope: '',
      field: { width: '', depth: '', height: '' },
      projectId: '', // Add projectId and areaId to the state
      areaId: '',
      elevationA: '',
      commonA: 0, // Initialize to 0
      commonB: 0, // Initialize to 0
      commonC: 0, // Initialize to 0
      commonD: 0, // Initialize to 0
      exclusion: {
        elevation: '',
        type: '',
        left_distance: '',
        bottom_distance: '',
        length: '',
        height: ''
      },
      exclusions: [], // Add a state variable to store exclusions
      selectedArea: '', // Initialize to an empty string
      selectedAreaId: '',
      data: this.props.data,
      design_layout: '',
      project_display_name: '',
      status: '',
      scopeData: '',
      elevationData: ''
    };
    //console.log('statedata', this.state.data);
  }
  componentDidMount = () => {
    const queryParams = displayer.getUrlParams('/project/view/');
    //console.log(queryParams);
    this.getScopeDetails(queryParams[0]);
    this.getElevationDetails(queryParams[0]);
  };

  getScopeDetails = async () => {
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url =
        config.BASE_API_URL + 'servicesforArea/' + this.props.data.id + '/' + Auth.urlTokenizer();
      let jsonData = await Postie.fetchUrl(url);
      //console.log('sata', jsonData);
      if (jsonData) {
        this.setState(
          {
            ...this.state,
            scopeData: jsonData,
            dataLoaded: true // Set dataLoaded to true when data is loaded
          },
          function () {
            console.log('Scope Data ', this.state.scopeData);
          }
        );
      }
    }
  };
  getElevationDetails = async () => {
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url =
        config.BASE_API_URL +
        'elevationsforArea/' +
        this.props.data.id +
        '/' +
        'fitouts' +
        '/' +
        Auth.urlTokenizer();
      let jsonData = await Postie.fetchUrl(url);
      //console.log('sata', jsonData);
      if (jsonData) {
        this.setState(
          {
            ...this.state,
            elevationData: jsonData,
            dataLoaded: true // Set dataLoaded to true when data is loaded
          },
          function () {
            console.log('Elevation  Data ', this.state.elevationData);
          }
        );
      }
    }
  };
  handleTabChange = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };

  handleScopeCheck = (e) => {
    let selected = [];
    $('[name="' + e.target.name + '"]:checked').each((elem, input) => {
      selected.push(input.value);
    });
    let value = selected.join('|');
    console.log('value', value);
    if (value === '') {
      value = 'None';
    }
    console.log(e.target.name, selected.join('|'));
    let sarr = e.target.name.split('-');
    //let elem = { target: { name: sarr[0], value: value } };
    //this.handleFunction(elem, 'update', parseInt(sarr[1].substring(0, sarr[1].length - 2)));
    this.onUpdateProject(sarr[0], value);
  };

  handleDimensionChange = (property, event) => {
    this.onUpdateProject(property, event.target.value);
  };

  handleSwitchChange = (property, event) => {
    // Update the specific property in the state as 0 or 1 based on the switch's checked status
    const value = event.target.checked ? 1 : 0;
    this.setState({ [property]: value }, function () {
      console.log('switch Value', property, value);
    });
    this.onUpdateProject(property, value);
  };

  onUpdateProject = async (property, value) => {
    // Here you can send the data to your API
    //const { projectId, areaId, property, value } = this.state;
    //console.log('field', field);
    const stringValue = value.toString();

    const authToken = localStorage.getItem(config.Token_name);

    const postData = {
      authToken,
      field: property,
      value: stringValue,
      project_id: this.props.projectId,
      area_id: this.props.data.id
    };
    // console.log('update product', postData, this.state);
    let serviceUrl = config.BASE_API_URL + 'project/updatearea';
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      this.props.callback();
    }
  };

  onSaveDimensions = async () => {
    // Here you can send the data to your API
    const { field } = this.state;
    //console.log('field', field);

    const authToken = localStorage.getItem(config.Token_name);

    const postData = {
      authToken,
      height: field.height,
      length: field.depth,
      width: field.width,
      project_id: this.props.projectId,
      area_id: this.props.data.id
    };
    // console.log('update product', postData, this.state);
    let serviceUrl = config.BASE_API_URL + 'project/updateAreaDimension';
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      Postie.showAlert(reply);
    }
  };

  handleAddExclusion = (property, value) => {
    // Update the specific property in the field object
    this.setState(
      (prevState) => ({
        exclusion: {
          ...prevState.exclusion,
          [property]: value
        }
      }),
      function () {
        console.log('exclusions', property, value);
      }
    );
  };

  onSaveElevation = async () => {
    const {exclusion } = this.state;

    const authToken = localStorage.getItem(config.Token_name);

    const postData = {
      authToken,
      length: exclusion.length,
      bottom_distance: exclusion.bottom_distance,
      elevation: exclusion.elevation,
      height: exclusion.height,
      left_distance: exclusion.left_distance,
      type: exclusion.type,
      project_id: this.props.projectId,
      area_id: this.props.data.id
    };
    // console.log('update product', postData, this.state);
    let serviceUrl = config.BASE_API_URL + 'project/exclusion/add';
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      this.props.callback();
    }
    this.setState((prevState) => ({
      exclusions: [...prevState.exclusions, this.state.exclusion]
    }));
  };

  onDeleteExclusion = async (id) => {
    const authToken = localStorage.getItem(config.Token_name);

    const postData = {
      authToken,

      project_id: this.props.projectId,
      exclusion_id: id
    };
    // console.log('update product', postData, this.state);
    let serviceUrl = config.BASE_API_URL + 'project/exclusion/delete';
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      this.props.callback();
    }
  };
  handleFile = async (resp, type) => {
    if (type === 'design_layout') {
      this.setState({
        ...this.state,
        design_layout: resp.data.url
      });
    }
    // let stage = await displayer.updateProjectstage(
    //   this.props.projectId,
    //   this.props.projectStatus,
    //   parseInt(estimate_type) === 1 ? 'presales_boq_shared' : 'dop_boq_shared'
    // );
    // if (stage) {
    //   this.props.callback();
    // }
    this.props.callback();
  };

  openEditWorkscope = (id) => {
    let url = '/bid/edit/workscope/' + id;
    console.log(url);
    Postie.openLink(url);
  };

  render() {
    const {
      selectedTab,
      exclusion,
      elevationData
    } = this.state;

    //console.log('Project Areas Data', this.props.data);
    //console.log('Project All Areas', this.props.allAreas);
    //console.log('elevations Data', elevationData);
    return (
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'column',

          padding: '0px',
          marginLeft: '10px'
        }}
      >
        <Grid
          item
          md={12}
          style={{
            marginLeft: '10px'
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={this.handleTabChange}
            style={{ color: '#0000FF', padding: '8px' }}
          >
            <Tab label="Scope" value="Scope" />
            <Tab label="Dimensions" value="Dimensions" />
            <Tab label="Settings" value="Settings" />
            <Tab label="Elevation" value="Elevation" />
            {this.props.showfiles && <Tab label="Druaghting Files" value="Files" />}
            {this.props.showfiles && <Tab label="Record Mom" value="record_mom" />}
            {this.props.showfiles && <Tab label="First set Render" value="First_set_Render" />}
            {this.props.showfiles && <Tab label="Second Set Render" value="Second_Set_Render" />}
          </Tabs>

          {selectedTab === 'Scope' && (
            <Scope key={this.props.data} data={this.props.data} projectId={this.props.projectId} />
          )}
          {selectedTab === 'Dimensions' && (
            //drop down for Room / Area
            <Grid>
              <Box>
                <h2>{this.props.data.project_display_name}</h2>
                <h3>Dimensions</h3>
              </Box>
              <Grid
                md={12}
                container
                alignItems="center"
                style={{
                  marginLeft: '10px'
                }}
              >
                <Grid item md={4}>
                  <TextField
                    key={this.props.data}
                    type="text"
                    label="Width (mm)"
                    name="Width of Unit"
                    defaultValue={this.props.data.width}
                    onChange={(e) => this.handleDimensionChange('width', e)}
                    required // Mark the field as required
                  />
                </Grid>
                <Grid item md={4}>
                  {' '}
                  <TextField
                    type="number"
                    label="Depth / Length (mm)"
                    name="Depth of Unit "
                    defaultValue={this.props.data.length}
                    onChange={(e) => this.handleDimensionChange('length', e)}
                    required // Mark the field as required
                  />
                </Grid>
                <Grid item md={4}>
                  {' '}
                  <TextField
                    type="number"
                    label="Height (mm)"
                    name="Height of Unit"
                    defaultValue={this.props.data.height}
                    onChange={(e) => this.handleDimensionChange('height', e)}
                    required // Mark the field as required
                  />
                </Grid>
              </Grid>
              {this.props.data.length > 0 &&
                this.props.data.width > 0 &&
                this.props.data.height > 0 && (
                  <Grid
                    md={12}
                    container
                    alignItems="center"
                    style={{
                      marginLeft: '10px',
                      marginTop: '10px'
                    }}
                  >
                    <Grid item md={3}>
                      <h5>{`Choose Elevation A`}</h5>
                      <FormControl variant="outlined" style={{ width: '120px' }}>
                        <Select
                          onChange={(e) => this.handleDimensionChange('elevationA', e)}
                          defaultValue={this.props.data.elevationA}
                          name="elevationA"
                        >
                          <MenuItem value={this.props.data.length}>
                            {this.props.data.length}X{this.props.data.height}
                          </MenuItem>
                          <MenuItem value={this.props.data.width}>
                            {this.props.data.width}X{this.props.data.height}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={2}>
                      <h5>Is Elevation A Common?</h5>
                      <Switch
                        {...label}
                        checked={this.props.data.commonA === 1}
                        onChange={(e) => this.handleSwitchChange('commonA', e)}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <h5>Is Elevation B Common?</h5>
                      <Switch
                        {...label}
                        checked={this.props.data.commonB === 1}
                        onChange={(e) => this.handleSwitchChange('commonB', e)}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <h5>Is Elevation C Common?</h5>
                      <Switch
                        {...label}
                        checked={this.props.data.commonC === 1}
                        onChange={(e) => this.handleSwitchChange('commonC', e)}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <h5>Is Elevation D Common?</h5>
                      <Switch
                        {...label}
                        checked={this.props.data.commonD === 1}
                        onChange={(e) => this.handleSwitchChange('commonD', e)}
                      />
                    </Grid>
                  </Grid>
                )}

              {this.props.data.length > 0 &&
                this.props.data.width > 0 &&
                this.props.data.height > 0 && <h3> Add Exclusions</h3>}
              {this.props.data.length > 0 &&
                this.props.data.width > 0 &&
                this.props.data.height > 0 && (
                  <Grid
                    container
                    style={{
                      marginLeft: '10px',
                      marginTop: '10px'
                    }}
                  >
                    <Grid item md={1}>
                      <h5>Elevation</h5>
                      <FormControl variant="outlined" style={{ width: '80%' }}>
                        <Select
                          onChange={(e) => this.handleAddExclusion('elevation', e.target.value)}
                          defaultValue={this.props.data.elevation}
                          value={exclusion.elevation}
                          label="Choose Area"
                          name="elevation"
                        >
                          <MenuItem value="">Choose</MenuItem>
                          <MenuItem value="A">A</MenuItem>
                          <MenuItem value="B">B</MenuItem>
                          <MenuItem value="C">C</MenuItem>
                          <MenuItem value="D">D</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={2}>
                      <h5>Exclusion type</h5>
                      <FormControl variant="outlined" style={{ width: '90%' }}>
                        <Select
                          onChange={(e) => this.handleAddExclusion('type', e.target.value)}
                          defaultValue={this.props.data.type}
                          value={exclusion.type}
                          label="Choose Area"
                          name="type"
                        >
                          <MenuItem value="">Choose</MenuItem>
                          <MenuItem value="Door">Door</MenuItem>
                          <MenuItem value="Opening">Opening</MenuItem>
                          <MenuItem value="Window">Window</MenuItem>
                          <MenuItem value="Shafts">Shafts</MenuItem>
                          <MenuItem value="Railing">Railing</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={2}>
                      <h5>Distance from left (mm)</h5>
                      <TextField
                        type="number"
                        label="Left Distance"
                        name="Left Distance"
                        defaultValue={this.props.data.left_distance}
                        value={exclusion.left_distance}
                        onChange={(e) => this.handleAddExclusion('left_distance', e.target.value)}
                        style={{ width: '90%' }}
                        required // Mark the field as required
                      />
                    </Grid>
                    <Grid item md={2}>
                      <h5>Distance from bottom (mm)</h5>
                      <TextField
                        type="number"
                        label="Bottom Distance"
                        name="Bottom Distance"
                        defaultValue={this.props.data.bottom_distance}
                        value={exclusion.bottom_distance}
                        onChange={(e) => this.handleAddExclusion('bottom_distance', e.target.value)}
                        style={{ width: '90%' }}
                        required // Mark the field as required
                      />
                    </Grid>
                    <Grid item md={2}>
                      <h5>Length(mm)</h5>
                      <TextField
                        type="number"
                        label="Length"
                        name="Length"
                        defaultValue={this.props.data.length}
                        value={exclusion.length}
                        onChange={(e) => this.handleAddExclusion('length', e.target.value)}
                        required // Mark the field as required
                      />
                    </Grid>
                    <Grid item md={2}>
                      <h5>height(mm)</h5>
                      <TextField
                        style={{ width: '90%' }}
                        type="number"
                        label="height"
                        name="height"
                        defaultValue={this.props.data.height}
                        value={exclusion.height}
                        onChange={(e) => this.handleAddExclusion('height', e.target.value)}
                        required // Mark the field as required
                      />
                    </Grid>
                    <Grid item md={1} style={{ width: '90%' }}>
                      <h5>ADD</h5>
                      <Button variant="contained" color="primary" onClick={this.onSaveElevation}>
                        <AddIcon />
                      </Button>
                    </Grid>
                  </Grid>
                )}
              {this.props.data.length > 0 &&
                this.props.data.width > 0 &&
                this.props.data.height > 0 && <h3>Exclusions</h3>}
              {this.props.data.exclusions &&
                this.props.data.exclusions.map((exclusion, index) => (
                  <Grid
                    container
                    style={{
                      marginLeft: '10px',
                      marginTop: '10px'
                    }}
                  >
                    <Grid item md={1} xs={12}>
                      <h5>Elevation</h5>
                      <Box>{exclusion.elevation}</Box>
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <h5> type</h5>
                      <Box>{exclusion.type}</Box>
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <h5>Distance from left</h5>
                      <Box>{exclusion.left_distance} (mm)</Box>
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <h5>Distance from bottom</h5>
                      <Box>{exclusion.bottom_distance}(mm)</Box>
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <h5>Length</h5>
                      <Box>{exclusion.length}(mm)</Box>
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <h5>height</h5>
                      <Box>{exclusion.height}(mm)</Box>
                    </Grid>
                    <Grid item md={1} style={{ width: '90%' }} xs={12}>
                      <h5>Action</h5>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: 'red' }}
                        onClick={() => this.onDeleteExclusion(exclusion.id)}
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          )}
          {selectedTab === 'Elevation' && (
            //drop down for Room / Area
            <Grid
              container
              item
              md={12}
              alignItems="center"
              style={{
                marginLeft: '10px'
              }}
            >
              <h2>{this.props.data.project_display_name}</h2>
              <h3>Elevation</h3>
              <ScopeView data={elevationData} />
            </Grid>
          )}
          {selectedTab === 'Files' && (
            //drop down for Room / Area
            <Grid
              container
              alignItems="center"
              style={{
                marginLeft: '10px'
              }}
            >
              <h2>{this.props.data.project_display_name}</h2>
              <h3>Draughting Files</h3>
              <Grid item md="12">
                <h3>Existing Site Pictures</h3>
                <UploadFile
                  filename="Existing Site Pictures"
                  displayTosection={false}
                  areaName=""
                  type="ex_site_pictures"
                  projectId={this.props.projectId}
                  areaId={this.props.data.id}
                  callback={(File) => this.handleFile(File, 'ex_site_pictures')}
                  fullWidth
                />
                <Grid container spacing={2}>
                  {this.props.data.ex_site_pictures &&
                    this.props.data.ex_site_pictures.length > 0 &&
                    this.props.data.ex_site_pictures.map((record, index) => (
                      <Grid item md="3">
                        <FileView
                          key={index}
                          id={record.id}
                          url={displayer.absolutingImageURl(record.url)}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              <Grid item md="6">
                <h3>Existing Elevations</h3>
                <h5>Plan</h5>
                {this.props.data.ex_elevations ? (
                  <FileView
                    key={this.props.data.ex_elevations.id}
                    id={this.props.data.ex_elevations.id}
                    url={displayer.absolutingImageURl(this.props.data.ex_elevations?.url)}
                  />
                ) : (
                  'No File Uploaded Yet'
                )}
                <UploadFile
                  filename="Existing Elevations"
                  displayTosection={false}
                  areaName=""
                  type="ex_elevations"
                  projectId={this.props.projectId}
                  areaId={this.props.data.id}
                  callback={(File) => this.handleFile(File, 'ex_elevations')}
                  fullWidth
                />
              </Grid>
              <Grid item md="6">
                <h5>Dwg</h5>
                {this.props.data.ex_plan_dwg ? (
                  <FileView
                    key={this.props.data.ex_plan_dwg.id}
                    id={this.props.data.ex_plan_dwg.id}
                    url={displayer.absolutingImageURl(this.props.data.ex_plan_dwg?.url)}
                  />
                ) : (
                  'No File Uploaded Yet'
                )}
                <UploadFile
                  filename="Dwg"
                  displayTosection={false}
                  areaName=""
                  type="ex_plan_dwg"
                  projectId={this.props.projectId}
                  areaId={this.props.data.id}
                  callback={(File) => this.handleFile(File, 'ex_plan_dwg')}
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
          {selectedTab === 'record_mom' && (
            //drop down for Room / Area
            <Grid
              container
              alignItems="center"
              style={{
                marginLeft: '10px'
              }}
            >
              <h2>{this.props.data.project_display_name}</h2>
              <h3 style={{ marginLeft: '5px' }}>Record Mom</h3>
              <Grid item md="12">
                <TextField
                  key={this.props.data}
                  multiline
                  rows="20"
                  type="text"
                  label="Record Mom"
                  name="Record Mom"
                  defaultValue={this.props.data?.mom}
                  onChange={(e) => this.handleDimensionChange('mom', e)}
                  required // Mark the field as required
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
          {selectedTab === 'First_set_Render' && (
            <div>
              <h2>{this.props.data.project_display_name}</h2>

              <Grid
                container
                alignItems="center"
                style={{
                  marginLeft: '10px'
                }}
              >
                <Grid item md="6">
                  <h3>First set Render</h3>
                  <UploadFile
                    filename="First set Render"
                    displayTosection={false}
                    areaName=""
                    type="designs"
                    projectId={this.props.projectId}
                    areaId={this.props.data.id}
                    callback={(File) => this.handleFile(File, 'designs')}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    style={{ marginLeft: '10px' }}
                    onClick={() => this.openEditWorkscope(this.props.projectId)}
                  >
                    Upload Renders
                  </Button>
                  {/* <h3>Final Renders</h3>
                  {this.props.data.final_designs &&
                    this.props.data.final_designs.length > 0 &&
                    this.props.data.final_designs.map((record, index) => (
                      <FileView
                        key={index}
                        id={record.id}
                        url={displayer.absolutingImageURl(record.url)}
                      />
                    ))} */}
                </Grid>
                {/* <Grid item md="12">
                  {data?.areas &&
                    data.areas.map(
                      (area, index) =>
                        area.sections &&
                        area.sections && (
                          <Paper
                            key={index}
                            style={{
                              margin: '10px 40px',
                              padding: '40px',
                              backgroundColor: '#FAF3F0'
                            }}
                          >
                            {area.services.length > 0 &&
                              area.services.map((cfg, index) => (
                                <RowComponent
                                  key={index}
                                  data={cfg}
                                  area={area.project_display_name}
                                  estimateId={this.state.estimateId}
                                  is_editable={this.state.is_editable}
                                />
                              ))}
                          </Paper>
                        )
                    )}
                </Grid> */}

                <Grid item md={12}>
                  <h3>Renders Uploaded</h3>
                  {this.props.data.designs &&
                    this.props.data.designs.length > 0 &&
                    this.props.data.designs.map((record, index) => (
                      <FileView
                        key={index}
                        id={record.id}
                        url={displayer.absolutingImageURl(record.url)}
                      />
                    ))}
                </Grid>
              </Grid>
            </div>
          )}
          {selectedTab === 'Second_Set_Render' && (
            //drop down for Room / Area
            <Grid
              container
              alignItems="center"
              style={{
                marginLeft: '10px'
              }}
            >
              <h2>{this.props.data.project_display_name}</h2>
              <h3>Second Set Render</h3>
              <UploadFile
                filename="Second Set Render"
                displayTosection={false}
                areaName=""
                type="design_2"
                projectId={this.props.projectId}
                areaId={this.props.data.id}
                callback={(File) => this.handleFile(File, 'design_2')}
                fullWidth
              />
              <Grid item md={6}>
                <h3>Final Renders</h3>
                {this.props.data.final_design_2 &&
                  this.props.data.final_design_2.length > 0 &&
                  this.props.data.final_design_2.map((record, index) => (
                    <FileView
                      key={index}
                      id={record.id}
                      url={displayer.absolutingImageURl(record.url)}
                    />
                  ))}
              </Grid>
              <Grid item md={6}>
                <h3>Renders Uploaded</h3>
                {this.props.data.design_2 &&
                  this.props.data.design_2.length > 0 &&
                  this.props.data.design_2.map((record, index) => (
                    <FileView
                      key={index}
                      id={record.id}
                      url={displayer.absolutingImageURl(record.url)}
                    />
                  ))}
              </Grid>
            </Grid>
          )}
          {selectedTab === 'Settings' && (
            <Grid container style={{ marginLeft: '10px' }}>
              <Grid item md={12} xs="12" sm="12">
                <h2>{this.props.data.project_display_name}</h2>
                <h3>Settings</h3>
              </Grid>
              <Grid item md={3} xs="12" sm="12">
                <h5>Name:</h5>
                <TextField
                  key={this.props.data}
                  type="text"
                  label="Name"
                  name="Nameof Area"
                  defaultValue={this.props.data?.project_display_name}
                  onChange={(e) => this.handleDimensionChange('project_display_name', e)}
                  required // Mark the field as required
                />
              </Grid>
              <Grid item md={3} xs="12" sm="12">
                <h5>Active:</h5>
                <Switch
                  {...label}
                  checked={this.props.data.status === 1}
                  onChange={(e) => this.handleSwitchChange('status', e)}
                />
              </Grid>
              <Grid item md={3} xs="12" sm="12">
                <h5>Display Order:</h5>
                <FormControl variant="outlined">
                  <Select
                    onChange={(e) => this.handleDimensionChange('display_order', e)}
                    defaultValue={this.props.data?.display_order}
                    name="display_order"
                  >
                    {this.props.allAreas &&
                      this.props.allAreas.map(
                        (record, index) =>
                          record.id !== this.props.data.id && (
                            <MenuItem key={index} value={record.display_order}>
                              After {record.project_display_name}
                            </MenuItem>
                          )
                      )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} xs="12" sm="12">
                <h5>Layout:</h5>
                <UploadFile
                  filename="Design Layout"
                  displayTosection={false}
                  areaName=""
                  type="design_layouts"
                  projectId={this.props.projectId}
                  areaId={this.props.data.id}
                  callback={(File) => this.handleFile(File, 'design_layouts')}
                  fullWidth
                />
                <Divider style={{ marginBottom: '5px' }} />
                {this.props.data?.design_layout && (
                  <FileView
                    key={this.props.data.id}
                    id={this.props.data.id}
                    url={displayer.absolutingImageURl(this.props.data.design_layout.url)}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default ProjectAreas;
