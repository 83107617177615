import React, { Component } from 'react';
import { Grid } from '@mui/material';
import FileView from 'app/views/bids/components/fileView';
import displayer from 'app/helpers/displayer';

class DisplayRemark extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data } = this.props;
    //console.log('DisplayRemark data', data);
    return (
      <Grid container>
        <Grid
          item
          md={3}
          xs={12}
          style={{ padding: '2px', marginRight: '2px', textAlign: 'center' }}
        >
          {data.image && (
            <FileView key={`nobaseImage`} id={''} url={displayer.absolutingImageURl(data.image)} />
          )}
        </Grid>
        <Grid item md={9} xs={12} style={{ padding: '1px', marginLeft: '2px' }}>
          <h5 style={{ marginTop: '2px', fontSize: '14px' }}>{data.checkname}</h5>
        </Grid>
        <Grid item md="6" xs="12"></Grid>
        <Grid item md="6" xs="12">
          <small>--{data.author.member_name}</small>
        </Grid>
      </Grid>
    );
  }
}
export default DisplayRemark;
