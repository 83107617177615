import React, { Component } from 'react';
import TeamInfo from 'app/views/common/components/TeamInfo';
import {
  Grid,
  Button,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
  Chip
} from '@mui/material';
import displayer from 'app/helpers/displayer';
import Postie from 'app/helpers/postie';
import Auth from 'app/helpers/authorization';
import config from 'app/constants/index';

class TeamList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
      Roles: '',
      updatedRole: '',
      addedColaborator: ''
    };
  }

  componentDidMount = () => {
    const queryParams = displayer.getUrlParams('/project/view/');
    console.log(queryParams);
    this.loadData(queryParams[0]);
  };

  loadData = async () => {
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url = config.BASE_API_URL + 'configuration/getprojectroles';
      let jsonData = await Postie.fetchUrl(url);
      //console.log('sata', jsonData);
      if (jsonData) {
        this.setState(
          {
            ...this.state,
            Roles: jsonData
          },
          function () {
            console.log('colaborators Data', this.state);
          }
        );
      }
    }
  };
  onUpdateRole = async (event, id) => {
    console.log('onSelectRole', event);
    let role = event.target.value;
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url = config.BASE_API_URL + 'project/updatecolabrator';
      let postData = {
        authToken: localStorage.getItem(config.Token_name),
        colabrator_id: id,
        project_id: this.props.projectId,
        role: role
      };
      let jsonData = await Postie.sendAsynchrnousCall(postData, url);
      //console.log('sata', jsonData);
      if (jsonData.status === 'success') {
        this.setState(
          {
            ...this.state,
            updatedRole: jsonData.data
          },
          function () {
            //console.log('uploadFile Load Data', this.state.updateElevation);
            this.props.callback();
          }
        );
      } else {
        Postie.showAlert(jsonData);
      }
    }
  };
  onAddColaborator = async (id) => {
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url =
        config.BASE_API_URL +
        'addcolabrator/' +
        id +
        '/' +
        this.props.projectId +
        '/' +
        Auth.urlTokenizer();
      let jsonData = await Postie.fetchUrl(url);
      //console.log('sata', jsonData);
      if (jsonData) {
        this.setState(
          {
            ...this.state,
            addedColaborator: jsonData
          },
          function () {
            console.log('colaborators Data', this.addedColaborator);
            this.props.callback();
          }
        );
      }
    }
  };

  openPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };
  render() {
    const { team, otherMembers } = this.props;
    //console.log('team', team);

    return (
      <div>
        <TeamInfo key={`dl`} team={this.props.dl} detail={true} />
        <TeamInfo key={`id`} team={this.props.id} detail={true} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {team.map((member, index) => (
            <TeamInfo key={member} team={member} dontdisplay={index > 3} />
          ))}
          {team.length > 3 ? (
            <div
              style={{
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                background: '#DDDDDD',
                color: '#000000',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '22px',
                marginRight: '10px'
              }}
            >
              +{team.length - 3}
            </div>
          ) : null}
          <div
            onClick={this.openPopup}
            style={{
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              background: '#007bff',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '22px'
            }}
          >
            +
          </div>
        </div>
        <Dialog open={this.state.isPopupOpen} onClose={this.closePopup} fullWidth>
          <DialogTitle>Colaborators</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <h2>Colaborators Name</h2>
                {team?.map((member, index) => (
                  <Grid container alignItems="center" key={index}>
                    <Grid item xs={6}>
                      <h5>{member?.detail?.member_name}</h5>
                    </Grid>
                    <Grid item md={6}>
                      {member?.is_admin === 1 || member?.is_lead === 1 ? (
                        <Chip
                          label={member?.is_lead ? 'Account Manger' : 'Admin'}
                          color={member?.is_lead ? 'primary' : 'secondary'}
                        />
                      ) : (
                        <FormControl variant="outlined" sx={{ width: '60%' }}>
                          <InputLabel>Choose Role:</InputLabel>
                          <Select
                            key={index}
                            label="Choose Role:"
                            name="Choose Role"
                            defaultValue={member.role}
                            onChange={(event) => this.onUpdateRole(event, member.id)}
                          >
                            {this.state.Roles &&
                              this.state.Roles.map((role, index) => (
                                <MenuItem key={index} value={role.code} id={role.code}>
                                  {role.label}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <h2>Team To be Added</h2>

                {otherMembers?.map((member, index) => (
                  <Grid container alignItems="center" key={index}>
                    <Grid item xs={6}>
                      <h5>{member?.member_name}</h5>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        value={member.member_name}
                        onClick={() => this.onAddColaborator(member.id)}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closePopup} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default TeamList;
