import React, { Component, useState } from 'react';
import { Grid, Modal, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import DisplayRemark from './DisplayRemark';
import { Button } from 'reactstrap';

class DisplayValidationReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false
    };
  }

  openPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  render() {
    const { data } = this.props;
    //console.log('DisplayValidationReport data', data);

    return (
      <Grid container spacing={2}>
        <DisplayRemark data={data[0]} />
        {data.length > 1 && (
          <Button variant="contained" color="success" onClick={this.openPopup}>
            + {data.length - 1} Validations Points
          </Button>
        )}

        <Dialog open={this.state.isPopupOpen} onClose={this.closePopup} maxWidth="md">
          <DialogContent>
            <Grid container spacing={2}>
              {data && data.map((record, index) => <DisplayRemark data={record} index={index} />)}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closePopup} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default DisplayValidationReport;
