import React, { Component } from 'react';
import {
  Grid,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import UploadFile from 'app/views/files/uploadFile';
import $ from 'jquery';
import FileView from 'app/views/bids/components/fileView';
import displayer from 'app/helpers/displayer';
import Postie from 'app/helpers/postie';
import Auth from 'app/helpers/authorization';
import config from 'app/constants/index';

class ReportIssue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //data: this.props.data,
      ValidationPoint: '',
      isPopupOpen: false,
      isIssuePopupOpen: false,
      issueFileName: '',
      issueRemark: '',
      issueFile: null,
      validationData: {
        remarks: '',
        recheck: 0,
        image: '',
        area_id: this.props.areaId,
        elevation: this.props.elevation,
        dwg_name: this.props.dwg_name,
        dwg_id: this.props.dwg_id,
        action_type: this.props.action_type
      }
    };
  }

  handleFile = (resp, type) => {
    if (type === 'validation_file') {
      this.setState({
        ...this.state,
        validationData: { ...this.state.validationData, image: resp.data.url }
      });
    }
    //this.props.callback(this.state.selectedAreaId, resp.data.url);
  };

  handleChange(event) {
    let { name, value } = event.target;

    let vald = value;
    if (name === 'recheck') {
      vald = $(event.target).prop('checked') ? 1 : 0;
    }
    console.log('handleChange', name, vald);
    this.setState({
      ...this.state,
      validationData: { ...this.state.validationData, [name]: vald }
    });
  }

  onSubmitIssue = async () => {
    // console.log('handleUploadFile', dwgId);
    let record = this.state.validationData;
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url = config.BASE_API_URL + 'drawing/reportIssue';
      let postData = record;
      postData['authToken'] = localStorage.getItem(config.Token_name);
      let jsonData = await Postie.sendAsynchrnousCall(postData, url);
      //console.log('sata', jsonData);
      if (jsonData.status === 'success') {
        this.setState(
          {
            ...this.state,
            validationData: jsonData.data
          },
          function () {
            //console.log('uploadFile Load Data', this.state.updateElevation);
            this.props.closeIssuePopup();
          }
        );
      } else {
        Postie.showAlert(jsonData);
      }
    }
  };

  render() {
    const { data } = this.props;
    console.log('Report Issue data', data, this.props.areaId);
    return (
      <Dialog open={this.props.isIssuePopupOpen} onClose={this.props.closeIssuePopup} maxWidth="md">
        <DialogTitle>Report Issue for DWG</DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  defaultValue={this.state.validationData?.remarks}
                  fullWidth
                  multiline
                  rows={4}
                  name={'remarks'}
                  onBlur={(event) => this.handleChange(event)}
                  placeholder="Enter Remarks"
                />
              </FormControl>
              <h5 style={{ color: 'red' }}>
                Note:For Multiple Remarks, Please Press Enter and Write next remark.
              </h5>
              <FormControlLabel
                key={`index1`}
                control={<Checkbox color="primary" />}
                name="recheck"
                label="Need To Recheck"
                onChange={(event) => this.handleChange(event)}
              />
            </Grid>
            <Grid item md={12}>
              {this.state.validationData?.image && (
                <FileView
                  height="50%"
                  width="50%"
                  id="image"
                  url={displayer.absolutingImageURl(this.state.validationData?.image)}
                />
              )}
              <UploadFile
                filename="validation_file"
                displayTosection={false}
                areaName=""
                type="validation_file"
                projectId={this.props.projectId}
                areaId={this.props.areaId}
                callback={(File) => this.handleFile(File, 'validation_file')}
                showAlert={false}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeIssuePopup} color="primary">
            Close
          </Button>
          <Button onClick={this.onSubmitIssue} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default ReportIssue;
