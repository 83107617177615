import React, { Component } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import TableHead from '@mui/material/TableHead';
import FileView from 'app/views/bids/components/fileView';
import displayer from 'app/helpers/displayer';
import Postie from 'app/helpers/postie';
import Auth from 'app/helpers/authorization';
import config from 'app/constants/index';
import ValidationPopup from './ValidationPopup';
import ReUploadFile from './ReUploadFile';
import DisplayValidationReport from './DisplayValidationReport';
import UploadFile from 'app/views/files/uploadFile';

const DwgRow = (props) => (
  <TableRow>
    <TableCell style={{ padding: '25px' }}>{props.data.type}</TableCell>{' '}
    <TableCell style={{ padding: '25px' }}>
      {props.data.prev_url && (
        <a
          href={displayer.absolutingImageURl(props.data.prev_url)}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button variant="outlined">Old PDF</Button>
        </a>
      )}
      {props.data.url ? (
        <a
          href={displayer.absolutingImageURl(props.data.url)}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button variant="outlined">PDF</Button>
        </a>
      ) : (
        <Button
          variant="contained"
          onClick={() =>
            props.uploadFile(props.data.type, props.data.id, 'url', props.data.validations)
          }
        >
          Upload
        </Button>
      )}
    </TableCell>
    <TableCell style={{ padding: '25px' }}>
      {props.data.url ? (
        props.data.designer_approved < 2 ? (
          <FormGroup>
            <FormControlLabel
              key={`index`}
              control={
                <Checkbox
                  name={''}
                  value={''}
                  onClick={() =>
                    props.openPopup(props.data.type, 'designer_approved', props.data.id)
                  }
                  color="primary"
                  disabled={props.data.designer_approved}
                  checked={props.data.designer_approved}
                />
              }
              label="Approved"
            />
          </FormGroup>
        ) : (
          <Button
            variant="contained"
            onClick={() =>
              props.uploadFile(
                props.data.type,
                props.data.id,
                'designer_approved',
                props.data.validations
              )
            }
          >
            Upload
          </Button>
        )
      ) : null}
    </TableCell>{' '}
    <TableCell style={{ padding: '25px' }}>
      {props.data.designer_approved === 1 ? (
        props.data.site_validated < 2 ? (
          <FormGroup>
            <FormControlLabel
              key={`index`}
              control={
                <Checkbox
                  name={''}
                  value={props.ValidationPoint}
                  onClick={() => props.openPopup(props.data.type, 'site_validated', props.data.id)}
                  color="primary"
                  disabled={props.data.site_validated}
                  checked={props.data.site_validated}
                />
              }
              label="Approved"
            />
          </FormGroup>
        ) : (
          <Button
            variant="contained"
            onClick={() =>
              props.uploadFile(
                props.data.type,
                props.data.id,
                'site_validated',
                props.data.validations
              )
            }
          >
            Upload
          </Button>
        )
      ) : null}
    </TableCell>
    <TableCell style={{ padding: '25px' }}>
      {props.data.validations && props.data.validations.length > 0 && (
        <DisplayValidationReport data={props.data.validations} />
      )}

      {/* <a
        style={{ color: 'blue', cursor: 'pointer' }}
        onClick={(e) => props.openGenerateReport(e)}
        download
        target="_blank"
        rel="noopener noreferrer"
      >
        Validation Report
      </a> */}
    </TableCell>
  </TableRow>
);

const ElevationRow = (props) => {
  const dwgTypes = props.data.dwgs ? Object.keys(props.data.dwgs) : [];
  console.log('props Data', props.data);
  return (
    <Grid container>
      <Grid item md="1" style={{ textAlign: 'center', padding: '5px' }}>
        <h4>{props.elevation}</h4>
      </Grid>

      <Grid item md="2" style={{ textAlign: 'center', padding: '5px' }}>
        {props.data && props.data.screenshot && (
          <FileView
            key={`nobaseImage`}
            id={props.data?.id}
            url={displayer.absolutingImageURl(props.data.screenshot)}
          />
        )}
        {props.data && props.data.screenshot && (
          <FormGroup>
            <FormControlLabel
              key={`index`}
              control={
                <Checkbox
                  name={''}
                  color="primary"
                  disabled={true}
                  checked={props.data.client_approved}
                />
              }
              label="Client Approved"
            />
          </FormGroup>
        )}
        {props.data && props.data.screenshot && props.data.client_approved !== 1 && (
          <UploadFile
            filename="Renders"
            displayTosection={false}
            areaName=""
            type="renders"
            projectId={props.projectId}
            areaId={props.data?.area_id}
            callback={(File) => props.handleFile(File, props.data?.area_id)}
            showAlert={false}
            fullWidth
          />
        )}
      </Grid>
      <Grid item md="9" style={{ padding: '5px' }}>
        {props.data && props.data.screenshot && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ padding: '25px' }}>Type</TableCell>{' '}
                <TableCell style={{ padding: '25px' }}>File</TableCell>{' '}
                <TableCell style={{ padding: '25px' }}>AM</TableCell>{' '}
                <TableCell style={{ padding: '25px' }}>Site Vadidation</TableCell>
                <TableCell style={{ padding: '25px' }}> Report</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dwgTypes.map((type, index) => (
                <DwgRow
                  data={props.data.dwgs[type]}
                  type={type}
                  openGenerateReport={(e) => props.openGenerateReport(e)}
                  uploadFile={(name, dwgId, field, validations) =>
                    props.uploadFile(name, dwgId, field, validations)
                  }
                  openPopup={(name, field, dwgId) => props.openPopup(name, field, dwgId)}
                />
              ))}
            </TableBody>
          </Table>
        )}
      </Grid>
    </Grid>
  );
};

class ScopeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //data: this.props.data,
      ValidationPoint: '',
      isPopupOpen: false,
      isIssuePopupOpen: false,
      issueFileName: '',
      issueRemark: '',
      issueFile: null,
      validationData: '',
      updateElevation: '',
      updateStatus: ''
    };
  }

  loadValidationPopData = async (
    name,
    field,
    type,
    elevation,
    areaId,
    dwgId,
    actionOn,
    validations
  ) => {
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url = config.BASE_API_URL + 'drawing/getChecklist';
      let postData = {
        authToken: localStorage.getItem(config.Token_name),
        dwg_name: name,
        type: type,
        field: field,
        area_id: areaId,
        elevation: elevation
      };
      let jsonData = await Postie.sendAsynchrnousCall(postData, url);
      //console.log('sata', jsonData);
      if (jsonData.status === 'success') {
        this.setState(
          {
            ...this.state,
            validationData: jsonData.data
          },
          function () {
            //console.log('presales leads Data', this.state);
            this.openPopup(name, field, type, elevation, dwgId, actionOn, validations);
          }
        );
      } else {
        Postie.showAlert(jsonData);
      }
    }
  };

  handleUpdateValidation = async (record, event) => {
    //console.log('handleUpdateValidation', record, event);
    const { name, value } = event.target;
    if (value.length > 0) {
      let userLogged = await Auth.isUserLoggedIn();
      if (userLogged) {
        let url = config.BASE_API_URL + 'drawing/validate';

        if (value === '1' && name === 'approved' && record.remarks === '') {
          record.remarks = 'approve';
        }
        // console.log(
        //   'remarks',
        //   record.remarks,
        //   record,
        //   value === 1,
        //   name === 'approved',
        //   record.remarks === '',
        //   value
        // );
        record[name] = value;
        let postData = record;
        postData['authToken'] = localStorage.getItem(config.Token_name);
        let jsonData = await Postie.sendAsynchrnousCall(postData, url);
        //console.log('sata', jsonData);
        if (jsonData.status === 'success') {
          this.setState(
            {
              ...this.state,
              validationData: jsonData.data
            },
            function () {
              //console.log('presales leads Data', this.state);
            }
          );
        } else {
          Postie.showAlert(jsonData);
        }
      }
    }
  };

  checkValidationPoint = (value) => {
    this.setState({ ValidationPoint: value }, function () {
      //console.log('ValidationPoint', value);
    });
  };

  handleIssueFileNameChange = (event) => {
    this.setState({ issueFileName: event.target.value });
  };

  handleIssueRemarkChange = (event) => {
    this.setState({ issueRemark: event.target.value });
  };

  handleIssueFileChange = (event) => {
    this.setState({ issueFile: event.target.files[0] });
  };

  openPopup = (name, field, type, elevation, dwgId, actionOn, validations) => {
    console.log('openPopup', field);
    this.setState(
      {
        ...this.state,
        approvalPopup: {
          show: true,
          name: name,
          field: field,
          type: type,
          elevation: elevation,
          id: dwgId,
          actionOn: actionOn,
          validations: validations
        }
      },
      function () {
        // console.log('openpopup data', this.state.approvalPopup);
      }
    );
  };

  closePopup = () => {
    this.setState(
      {
        ...this.state,
        approvalPopup: { show: false, name: '', field: '', type: '', elevation: '', actionOn: '' }
      },
      function () {
        this.props.callback();
      }
    );
  };

  generateReport = async () => {
    //console.log('Navigating to Validation Report');
    await Postie.navigateLink('/file/report');
  };

  handleUploadFile = async (areaId, fileurl, dwgId, name, elevation, actionOn) => {
    // console.log('handleUploadFile', dwgId);
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url = config.BASE_API_URL + 'drawing/updateFile';
      let postData = {
        authToken: localStorage.getItem(config.Token_name),
        dwg_id: dwgId,
        dwg_name: name,
        area_id: areaId,
        elevation: elevation,
        url: fileurl,
        action_type: actionOn
      };
      let jsonData = await Postie.sendAsynchrnousCall(postData, url);
      //console.log('sata', jsonData);
      if (jsonData.status === 'success') {
        this.setState(
          {
            ...this.state,
            updateElevation: jsonData.data
          },
          function () {
            //console.log('uploadFile Load Data', this.state.updateElevation);
            this.closePopup();
          }
        );
      } else {
        Postie.showAlert(jsonData);
      }
    }
  };
  handleUpdateStatus = async () => {
    console.log('handleUpdateStatus', this.state.approvalPopup);
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url = config.BASE_API_URL + 'drawing/updateStatus';
      let postData = {
        authToken: localStorage.getItem(config.Token_name),
        dwg_id: this.state?.approvalPopup?.id,
        dwg_name: this.state?.approvalPopup?.name,
        area_id: this.props.area_id,
        elevation: this.state?.approvalPopup?.elevation,
        action_type: this.state?.approvalPopup?.field
      };
      let jsonData = await Postie.sendAsynchrnousCall(postData, url);
      //console.log('sata', jsonData);
      if (jsonData.status === 'success') {
        this.setState(
          {
            ...this.state,
            updateStatus: jsonData.data
          },
          function () {
            //console.log('uploadFile Load Data', this.state.updateElevation);
            this.closePopup();
          }
        );
      } else {
        Postie.showAlert(jsonData);
      }
    }
  };

  handleFile = (file, areaId, elevation) => {
    console.log('file', file);

    this.setState(
      {
        ...this.state,
        uploadedRender: file.data.url,
        areaId: areaId,
        elevation: elevation
      },
      function () {
        this.submitRenderUpload();
      }
    );
  };

  submitRenderUpload = async () => {
    const { uploadedRender, areaId, elevation } = this.state;
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url = config.BASE_API_URL + 'elevations/update';
      let postData = {
        authToken: localStorage.getItem(config.Token_name),
        area_id: areaId,
        elevation: elevation,
        field: 'screenshot',
        value: uploadedRender
      };
      let jsonData = await Postie.sendAsynchrnousCall(postData, url);
      //console.log('sata', jsonData);
      if (jsonData.status === 'success') {
        this.setState(
          {
            ...this.state,
            updateElevation: jsonData.data
          },
          function () {
            //console.log('uploadFile Load Data', this.state.updateElevation);
            this.props.callback();
          }
        );
      } else {
        Postie.showAlert(jsonData);
      }
    }
  };
  render() {
    const { data, projectId } = this.props;
    //console.log('Elevation Data ScopreView', this.props);
    console.log('approval Type', this.state?.approvalPopup?.field);

    return (
      <Grid container style={{ margin: '10px' }}>
        <Grid
          item
          md="12"
          sm="12"
          xs="12"
          style={{ backgroundColor: '#FAF3F0', marginBottom: '5px' }}
        >
          <Grid container spacing="2">
            <Grid item md={1} xs={12} sm={12} style={{ textAlign: 'center' }}>
              <h4>Elevation</h4>
            </Grid>
            <Grid item md={2} xs={12} sm={12} style={{ textAlign: 'center' }}>
              <h4>Render</h4>
            </Grid>
            <Grid item md={9} xs={12} sm={12} style={{ textAlign: 'center' }}>
              <h4>Drawings</h4>
            </Grid>
          </Grid>
        </Grid>

        {data && data.A && (
          <Grid
            item
            md="12"
            sm="12"
            xs="12"
            style={{ backgroundColor: '#FAF3F0', marginBottom: '5px' }}
          >
            <Grid container spacing="2">
              <ElevationRow
                elevation="A"
                data={data.A}
                projectId={projectId}
                uploadFile={(name, dwgId, field, validations) =>
                  this.loadValidationPopData(
                    name,
                    'url',
                    'checklist',
                    'A',
                    data.A.area_id,
                    dwgId,
                    field,
                    validations
                  )
                }
                openPopup={(name, field, dwgId) =>
                  this.loadValidationPopData(
                    name,
                    field,
                    'post_checklist',
                    'A',
                    data.A.area_id,
                    dwgId,
                    []
                  )
                }
                openGenerateReport={(e) => this.generateReport(e)}
                handleFile={(file, areaId) => this.handleFile(file, areaId, 'A')}
              />
            </Grid>
          </Grid>
        )}
        {data && data.B && (
          <Grid
            item
            md="12"
            sm="12"
            xs="12"
            style={{ backgroundColor: '#FAF3F0', marginBottom: '5px' }}
          >
            <Grid container spacing="2">
              <ElevationRow
                elevation="B"
                data={data.B}
                projectId={projectId}
                uploadFile={(name, dwgId, field, validations) =>
                  this.loadValidationPopData(
                    name,
                    'url',
                    'checklist',
                    'B',
                    data.B.area_id,
                    dwgId,
                    field,
                    validations
                  )
                }
                openPopup={(name, field, dwgId) =>
                  this.loadValidationPopData(
                    name,
                    field,
                    'post_checklist',
                    'B',
                    data.B.area_id,
                    dwgId,
                    []
                  )
                }
                openGenerateReport={(e) => this.generateReport(e)}
                handleFile={(file, areaId) => this.handleFile(file, areaId, 'B')}
              />
            </Grid>
          </Grid>
        )}
        {data && data.C && (
          <Grid
            item
            md="12"
            sm="12"
            xs="12"
            style={{ backgroundColor: '#FAF3F0', marginBottom: '5px' }}
          >
            <Grid container spacing="2">
              <ElevationRow
                elevation="C"
                data={data.C}
                projectId={projectId}
                uploadFile={(name, dwgId, field, validations) =>
                  this.loadValidationPopData(
                    name,
                    'url',
                    'checklist',
                    'C',
                    data.C.area_id,
                    dwgId,
                    field,
                    validations
                  )
                }
                openPopup={(name, field, dwgId) =>
                  this.loadValidationPopData(
                    name,
                    field,

                    'post_checklist',
                    'C',
                    data.C.area_id,
                    dwgId,
                    []
                  )
                }
                openGenerateReport={(e) => this.generateReport(e)}
                handleFile={(file, areaId) => this.handleFile(file, areaId, 'C')}
              />
            </Grid>
          </Grid>
        )}
        {data && data.D && (
          <Grid
            item
            md="12"
            sm="12"
            xs="12"
            style={{ backgroundColor: '#FAF3F0', marginBottom: '5px' }}
          >
            <Grid container spacing="2">
              <ElevationRow
                elevation="D"
                data={data.D}
                projectId={projectId}
                uploadFile={(name, dwgId, field, validations) =>
                  this.loadValidationPopData(
                    name,
                    'url',
                    'checklist',
                    'D',
                    data.D.area_id,
                    dwgId,
                    field,
                    validations
                  )
                }
                openPopup={(name, field, dwgId) =>
                  this.loadValidationPopData(
                    name,
                    field,
                    'post_checklist',
                    'D',
                    data.D.area_id,
                    dwgId,
                    []
                  )
                }
                openGenerateReport={(e) => this.generateReport(e)}
                handleFile={(file, areaId) => this.handleFile(file, areaId, 'D')}
              />
            </Grid>
          </Grid>
        )}
        {data && data.Roof && (
          <Grid
            item
            md="12"
            sm="12"
            xs="12"
            style={{ backgroundColor: '#FAF3F0', marginBottom: '5px' }}
          >
            <Grid container spacing="2">
              {' '}
              <ElevationRow
                elevation="Roof"
                data={data.Roof}
                projectId={projectId}
                uploadFile={(name, dwgId, field, validations) =>
                  this.loadValidationPopData(
                    name,
                    'url',
                    'checklist',
                    'Roof',
                    data.Roof.area_id,
                    dwgId,
                    field,
                    validations
                  )
                }
                openPopup={(name, field, dwgId) =>
                  this.loadValidationPopData(
                    name,
                    field,
                    'post_checklist',
                    'Roof',
                    data.Roof.area_id,
                    dwgId,
                    []
                  )
                }
                openGenerateReport={(e) => this.generateReport(e)}
                handleFile={(file, areaId) => this.handleFile(file, areaId, 'Roof')}
              />
            </Grid>
          </Grid>
        )}
        {data && data.Floor && (
          <Grid
            item
            md="12"
            sm="12"
            xs="12"
            style={{ backgroundColor: '#FAF3F0', marginBottom: '5px' }}
          >
            <Grid container spacing="2">
              <ElevationRow
                elevation="Floor"
                data={data.Floor}
                projectId={projectId}
                uploadFile={(name, dwgId, field, validations) =>
                  this.loadValidationPopData(
                    name,
                    'url',
                    'checklist',
                    'Floor',
                    data.Floor.area_id,
                    dwgId,
                    field,
                    validations
                  )
                }
                openPopup={(name, field, dwgId) =>
                  this.loadValidationPopData(
                    name,
                    field,
                    'post_checklist',
                    'Floor',
                    data.Floor.area_id,
                    dwgId,
                    []
                  )
                }
                openGenerateReport={(e) => this.generateReport(e)}
                handleFile={(file, areaId) => this.handleFile(file, areaId, 'Floor')}
              />
            </Grid>
          </Grid>
        )}
        <Dialog
          open={this.state?.approvalPopup?.show}
          onClose={this.closePopup}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            {this.state?.approvalPopup?.field === 'designer_approved'
              ? 'Designer Approval on'
              : this.state?.approvalPopup?.field === 'url'
              ? 'Uploading'
              : 'Site validation on'}{' '}
            {this.state?.approvalPopup?.name} For Elevation {this.state?.approvalPopup?.elevation}
          </DialogTitle>
          {/* <DialogContent>{renderCheckboxes()}</DialogContent> */}
          <DialogContent>
            {this.state?.approvalPopup?.field === 'url' ? (
              <ReUploadFile
                data={this.state.validationData}
                validations={this.state?.approvalPopup?.validations}
                projectId={projectId}
                areaId={this.props.area_id}
                field={this.state?.approvalPopup?.actionOn}
                callback={(areaId, fileurl, actionOn) =>
                  this.handleUploadFile(
                    areaId,
                    fileurl,
                    this.state?.approvalPopup?.id,
                    this.state?.approvalPopup?.name,
                    this.state?.approvalPopup?.elevation,
                    actionOn
                  )
                }
                handleUpdateValidation={(record, value) =>
                  this.handleUpdateValidation(record, value)
                }
              />
            ) : (
              <ValidationPopup
                data={this.state.validationData}
                projectId={projectId}
                areaId={this.props.area_id}
                elevation={this.state?.approvalPopup?.elevation}
                dwg_name={this.state?.approvalPopup?.name}
                dwg_id={this.state?.approvalPopup?.id}
                action_type={this.state?.approvalPopup?.field}
                handleUpdateValidation={(record, value) =>
                  this.handleUpdateValidation(record, value)
                }
                closePopUp={() => this.closePopup()}
                handleUpdateStatus={() => this.handleUpdateStatus()}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleUpdateStatus()} color="primary">
              Check & Approve
            </Button>
            <Button onClick={this.closePopup} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default ScopeView;
