import React, { Component } from 'react';
import { Grid, FormControl } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Postie from 'app/helpers/postie';
import config from '../../constants/index.jsx';
import swal from 'sweetalert';
import FileView from '../bids/components/fileView';
import displayer from 'app/helpers/displayer';
import UploadFile from '../files/uploadFile';
import Auth from 'app/helpers/authorization';
import Rud from './rud';
import Connection from './connection';
import LoadTextField from './components/LoadTextField';
import LoadDropDown from './components/LoadDropdown.jsx';
import LoadRadioGroup from './components/LoadRadioGroup.jsx';
import LoadCheckBox from './components/LoadCheckBox.jsx';
import SingleTonTags from './components/SingleTonTags.jsx';
import $ from 'jquery';

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Fields: [],
      Data: [],
      apiCalls: [],
      children: [],
      filterValues: {},
      connection: {
        makeConnection: false,
        data: [],
        editedData: []
      },
      value: 1,
      filters: {},
      filterType: {},
      connectionId: ''
    };
  }

  componentDidMount() {
    this.loadData(this.props.type, this.props.typeId);
  }
  async getPossibleValues(crud, field, label) {
    let jsonData = await this.getPossibleOptions(crud, field);
    if (jsonData) {
      const updatedOptions = {
        ...this.state.filterType,
        [field]: {
          type: 'dropdown',
          placeholder: label,
          action: 'update',
          dbname: field,
          optionType: 'array',
          options: jsonData
        }
      };

      this.setState({ ...this.state, filterType: updatedOptions }, function () {
        // console.log('getPossibleValues', this.state.filterType);
      });
    }
  }

  async getPossibleOptions(crud, field) {
    const url = config.BASE_API_URL + 'possibleValues/' + crud.toLowerCase() + '/' + field;
    //c//onsole.log('url', url);
    let filters = {};
    //console.log('defaultValue', this.props.defaultValue);
    let ParentKeys = this.props.defaultValue ? Object.keys(this.props.defaultValue) : [];
    //console.log('ParentKeys', ParentKeys);
    let findPossibleValues = ParentKeys.length === 0;
    //console.log('findPossibleValues', findPossibleValues);
    const postData = {
      authToken: localStorage.getItem(config.Token_name),
      filters: filters
    };
    ParentKeys &&
      ParentKeys.forEach((key) => {
        //console.log(key, this.props.defaultValue[key].value);
        postData.filters[key] = this.props.defaultValue[key].value;
        findPossibleValues = true;
      });
    //console.log('findPossibleValues2', findPossibleValues, postData);

    if (findPossibleValues) {
      const reply = await Postie.sendAsynchrnousCall(postData, url);

      if (reply.status === 'success') {
        return reply.data;
      } else {
        return [];
      }
    }
  }

  loadData = async (type, id, navigate = '') => {
    let url = config.BASE_API_URL + 'crud/detail/' + type + '/' + id + '/' + Auth.urlTokenizer();
    if (navigate !== '') {
      url = navigate;
    }
    let jsonData = await Postie.fetchUrl(url);
    console.log('sata', jsonData);
    if (jsonData) {
      let filters = {};
      let Childs = Object.keys(jsonData.children);

      Childs &&
        Childs.forEach((child) => {
          let pkv = jsonData.children[child].parentValue
            ? Object.keys(jsonData.children[child].parentValue)
            : [];
          pkv &&
            pkv.forEach((key) => {
              if (!filters[child]) {
                filters[child] = {};
              }
              filters[child][key] = jsonData.children[child].parentValue[key]?.value;
            });
        });

      this.setState(
        {
          ...this.state,
          heading: type.toUpperCase() + ':' + id,
          Fields: jsonData.fields,
          Data: jsonData.detail,
          apiCalls: jsonData.apiCalls,
          children: jsonData.children,
          connectionId: id,
          type: type,
          filters: filters
        },
        function () {
          console.log('loadData', this.state);
          let excludedFiltrerType = [
            'Button',
            'file',
            'link',
            'tags',
            'dropdown',
            'checkBox',
            'checkList'
          ];
          let Fields = this.state.Fields;
          Fields &&
            Fields.forEach((field, index) => {
              if (!excludedFiltrerType.includes(field.ftype[0].type)) {
                this.getPossibleValues(type, field.dbname, field.label);
              }
            });
        }
      );
    }
  };

  handleFilterChange = (event, fieldName) => {
    const { filterValues } = this.state;
    this.setState({
      filterValues: {
        ...filterValues,
        [fieldName]: event.target.value
      }
    });
  };

  applyFilter = (data) => {
    const { filterValues } = this.state;
    if (Object.keys(filterValues).length === 0) {
      return data;
    }
    return data.filter((item) =>
      Object.keys(filterValues).every((fieldName) =>
        item[fieldName].toString().toLowerCase().includes(filterValues[fieldName].toLowerCase())
      )
    );
  };
  handleFunction = (event, type, identifier) => {
    console.log('handleFunction called with type:', type);
    if (type.length > 0) {
      event.target['identifier'] = identifier;
      switch (type) {
        case 'create':
          this.createFunctionality(this.state.apiCalls['create'], event);
          break;
        case 'update':
          console.log(this.state.apiCalls['update']);
          this.updateFunctionality(this.state.apiCalls['update'], event);
          break;
        case 'delete':
          this.deleteFunctionality(this.state.apiCalls['update'], identifier);
          break;
        case 'connect':
          this.connectFunctionality(this.state.apiCalls['connect'], identifier);
          break;
        default:
          console.log('default Block Called');
          Postie.navigateLink(this.state.apiCalls[type]);
          break;
      }
    }
  };

  updateFunctionality = async (apiCall, event) => {
    if (apiCall.type === 'post') {
      if (event.target.value !== '' && event.target.value !== '\n') {
        let postData = this.generatePostData(apiCall, event);
        console.log('postData', postData);
        let serviceUrl = config.BASE_API_URL + apiCall.url;
        let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
        if (reply.status === 'success') {
          this.loadData(this.state.type, this.state.connectionId);
        } else {
          Postie.showAlert(reply);
        }
      }
    } else {
    }
  };
  createFunctionality = async (data) => {
    console.log('createFunctionality', data);
    let postData = data;
    postData['authToken'] = localStorage.getItem(config.Token_name);
    let serviceUrl = config.BASE_API_URL + this.state.apiCalls['create'].url;
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      this.setState({ ...this.state, Data: reply.data.data });
    } else {
      Postie.showAlert(reply);
    }
  };
  deleteFunctionality = (apiCall, identifier) => {
    swal({
      title: 'Are you sure?',
      text: 'You will not be able to recover data once deleted!',
      icon: 'warning',
      buttons: ['No, cancel it!', 'Yes, I am sure!'],
      dangerMode: true
    }).then((isConfirm) => {
      if (isConfirm) {
        let data = { target: { name: 'status', value: '0', identifier: identifier } };
        this.updateFunctionality(apiCall, data);
      }
    });
  };
  connectFunctionality = async (apicall, identifier) => {};
  generatePostData = (apiCall, event) => {
    console.log('generatePostData', apiCall, event);
    let keyData = Object.keys(apiCall?.mandatory);
    let postData = {};
    for (var i = 0; i < keyData.length; i++) {
      if (apiCall.mandatory[keyData[i]] === 'token') {
        postData[[keyData[i]]] = localStorage.getItem(config.Token_name);
      } else {
        postData[[keyData[i]]] = event.target[apiCall.mandatory[keyData[i]]];
      }
    }
    return postData;
  };

  handleFile = (resp, type) => {
    if (type === 'skp_file') {
      this.setState({
        ...this.state,
        skp_File: resp.data.url
      });
    } else if (type === 'base_image') {
      this.setState({
        ...this.state,
        base_Image: resp.data.url
      });
    }
    console.log('file added', resp);
    this.updateFunctionality(resp, type);
  };

  handleInputChange = (e) => {
    let selected = [];
    $('[name="' + e.target.name + '"]:checked').each((elem, input) => {
      selected.push(input.value);
    });

    let value = selected.join('|');
    console.log('value', value);
    if (value === '') {
      value = 'None';
    }

    console.log(e.target.name, value);

    let sarr = e.target.name.split('-');
    let elem = { target: { name: sarr[0], value: value } };
    this.handleFunction(elem, 'update', parseInt(sarr[1].substring(0, sarr[1].length - 2)));
  };

  renderStepContent = (stepIndex) => {
    //const { skp_File, base_Image } = this.state;
    // const { productDetails, connectedBrands } = this.state;
    // const { product } = this.props;
    switch (stepIndex) {
      default:
        return null;
    }
  };
  handleChange = (event, newValue) => {
    this.setState({ ...this.state, value: newValue });
  };
  filterData(event, childName) {
    let childFilters = this.state.filters[childName] ? this.state.filters[childName] : {};
    if (isNaN(event.target.value) && event.target.value.toLowerCase() === 'none') {
      delete childFilters[event.target.name];
    } else {
      childFilters[event.target.name] = event.target.value;
    }

    this.setState(
      {
        ...this.state,
        filters: {
          ...this.state.filters,
          [childName]: childFilters
        }
      },
      async function () {
        this.filterCall(this.state.type.toLowerCase(), this.state.connectionId, childName);
      }
    );
  }

  async filterCall(type, connectionId, childName, pageNo = 1) {
    let url =
      config.BASE_API_URL + 'crud/filterchild/' + type + '/' + connectionId + '/' + childName;
    let postData = {
      authToken: localStorage.getItem(config.Token_name),
      filters: this.state.filters,
      pageno: pageNo
    };
    let jsonData = await Postie.sendAsynchrnousCall(postData, url);
    console.log('filterCall', jsonData);
    if (jsonData.status.toLowerCase() === 'success') {
      this.setState(
        {
          ...this.state,
          Fields: jsonData.data.fields,
          Data: jsonData.data.detail,
          apiCalls: jsonData.data.apiCalls,
          children: jsonData.data.children
        },
        function () {
          console.log('loadData2', this.state);
        }
      );
    }
  }
  filterPage(pageNo, child) {
    this.setState({ ...this.state, pageNo: pageNo }, function () {
      this.filterCall(
        this.state.type.toLowerCase(),
        this.state.connectionId,
        child.toLowerCase(),
        pageNo
      );
    });
  }

  getFilters(parentValues) {
    let filters = this.state.filters;
    let parentFields = Object.keys(parentValues);
    parentFields.forEach((field) => {
      if (!filters[field]) {
        filters[field] = parentValues[field].value;
      }
    });
    return filters;
  }

  render() {
    let { Fields, Data, children } = this.state;
    console.log('Filelds', Data);
    const childNames = Object.keys(this.state.children);

    return (
      <Grid container>
        <Grid item md={12} style={{ marginLeft: '20px' }}>
          <Grid container>
            <Grid item md={6}>
              <h1>{this.state.heading}</h1>
            </Grid>
          </Grid>

          <hr />

          <Grid container spacing={2}>
            {Data &&
              Fields?.map((record) => (
                <>
                  {record.label.toLowerCase() !== 'actions' && (
                    <Grid item md={6} xs={12}>
                      <label style={{ fontWeight: 'bold' }}>{record.label}</label>
                      {record.ftype?.map((ftype, index) => (
                        <FormControl fullWidth key={`${record.dbname}${index}`}>
                          {ftype.type === 'text' && <p>{Data[record.dbname]}</p>}
                          {ftype.type === 'textBox' && (
                            <LoadTextField
                              style={{ width: '50%', fontSize: '12px' }}
                              record={record}
                              name={record.dbname ? record.dbname : ftype.dbname}
                              placeholder={ftype.placeholder}
                              defaultValue={Data[record.dbname]}
                              callback={(e) => this.handleFunction(e, ftype.action, Data.id)}
                            />
                          )}
                          {ftype.type === 'dropdown' && (
                            <LoadDropDown
                              record={record}
                              defaultValue={Data[record.dbname]}
                              callback={(e) => this.handleInputChange}
                              ftype={ftype}
                            />
                          )}
                          {ftype.type === 'file' && Data[record['dbname']] && (
                            <FileView
                              id={`file-${Data.id}`}
                              url={displayer.absolutingImageURl(Data[record['dbname']])}
                            />
                          )}
                          {ftype.type === 'file' && (
                            <UploadFile
                              style={{ float: 'right' }}
                              Filename={'logo'}
                              displayTosection={false}
                              areaName={''}
                              type={'logo'}
                              projectId={0}
                              areaId={0}
                              callback={(File) => this.handleFile(File, 'skp_file')}
                            />
                          )}
                          {ftype.type === 'list' && (
                            <LoadRadioGroup
                              callback={(e) => this.handleInputChange}
                              ftype={ftype}
                            />
                          )}
                          {ftype.type === 'checkBox' && (
                            <LoadCheckBox
                              record={record}
                              ftype={ftype}
                              callback={(e) => this.handleInputChange(e)}
                              selectedValues={
                                Data[record.dbname] ? Data[record.dbname].split('|') : []
                              }
                              itemid={Data['id']}
                            />
                          )}
                          {ftype.type === 'textArea' && (
                            <LoadTextField
                              multiline={true}
                              rows={4}
                              record={record}
                              name={record.dbname ? record.dbname : ftype.dbname}
                              placeholder={ftype.placeholder}
                              defaultValue={Data[record.dbname]}
                              callback={(e) => this.handleFunction(e, ftype.action, Data.id)}
                            />
                          )}
                          {ftype.type === 'number' && (
                            <LoadTextField
                              type="number"
                              record={record}
                              name={record.dbname ? record.dbname : ftype.dbname}
                              placeholder={ftype.placeholder}
                              defaultValue={Data[record.dbname ? record.dbname : ftype.dbname]}
                              callback={(e) => this.handleFunction(e, ftype.action, Data.id)}
                            />
                          )}
                          {ftype.type === 'autocomplete' && (
                            <SingleTonTags
                              options={this.state.filterType[record.dbname]?.options}
                              label={record.label}
                              placeholder={record.label}
                              baseModel={record.dbname}
                              defaultValue={Data[record.dbname] ? Data[record.dbname] : ''}
                              handleChange={(data) => {
                                let elem = {
                                  target: { name: record.dbname, value: data }
                                };
                                this.handleFunction(elem, ftype.action, Data.id);
                              }}
                            />
                          )}
                        </FormControl>
                      ))}
                    </Grid>
                  )}
                </>
              ))}
          </Grid>
        </Grid>
        <Box
          sx={{ width: '100%', typography: 'body1' }}
          style={{
            marginLeft: '20px'
          }}
        >
          <TabContext value={this.state.value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={this.handleChange} aria-label="">
                {childNames.map((child, index) => (
                  <Tab key={index} label={child.toUpperCase()} value={index + 1} />
                ))}
              </TabList>
            </Box>
            {childNames &&
              childNames.map((child, index) => (
                <TabPanel key={index} value={index + 1}>
                  <Grid item md={12} key={index}>
                    {this.state.children[child].type &&
                    this.state.children[child].type === 'connect' ? (
                      <Connection
                        key={this.state.children[child]}
                        heading={child.toUpperCase()}
                        fields={this.state.children[child].fields}
                        data={this.state.children[child].data}
                        apiCalls={this.state.children[child].apiCalls}
                        connectionId={this.state.connectionId}
                        callback={() => this.loadData(this.state.type, this.state.connectionId)}
                        defaultValue={
                          this.state.children[child].parentValue
                            ? this.state.children[child].parentValue
                            : ''
                        }
                        filterData={(e) => this.filterData(e, child.toLowerCase())}
                      />
                    ) : (
                      <Rud
                        key={this.state.children[child]}
                        heading={child.toUpperCase()}
                        Fields={children[child].fields}
                        data={children[child].data}
                        apiCalls={children[child].apiCalls}
                        callback={() => this.loadData(this.state.type, this.state.connectionId)}
                        addDifferent={this.state.children[child].addDifferent}
                        filterData={(e) => this.filterData(e, child.toLowerCase())}
                        filterValues={this.getFilters(children[child].parentValue)}
                        defaultValue={
                          this.state.children[child].parentValue
                            ? this.state.children[child].parentValue
                            : ''
                        }
                        //moduleType={}
                        changePage={(desiredPage, child) => this.filterPage(desiredPage, child)}
                      />
                    )}
                  </Grid>
                </TabPanel>
              ))}
          </TabContext>
        </Box>
      </Grid>
    );
  }
}

export default Details;
