import React, { Component } from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TextField,
  Paper,
  Chip
} from '@mui/material';
import config from '../../../constants/index.jsx';
import Auth from 'app/helpers/authorization';
import displayer from 'app/helpers/displayer';
import { AccountCircle, Email, IosShare, Phone } from '@mui/icons-material';
import DoughnutChart from 'app/views/dashboard/shared/Doughnut';
import Postie from 'app/helpers/postie.jsx';

class EditServiceInBoq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      formdata: [],
      boqtype: {
        name: '',
        value: this.props.serviceData ? this.props.serviceData.workcategory?.boq_type : ''
      },
      showDimensions: ['Sq.Ft', 'R.Ft'].includes(this.props.serviceData?.uom),
      showQuantity: ['No.s'].includes(this.props.serviceData?.uom)
    };
  }
  onSelectBoqType = (e) => {
    const { name, value } = e.target;
    console.log('onBoqSelect', name, value);

    this.setState(
      {
        boqtype: { name: name, value: value }
      },
      () => {
        console.log('boqtype', this.state.boqtype);
      }
    );
  };
  onBoqSelect = (e) => {
    const { name, value } = e.target;
    console.log('onBoqSelect', name, value);

    this.setState(
      (prevState) => ({
        formdata: [...prevState.formdata, { name: name, value: value }]
      }),
      () => {
        console.log('onBoqSelect', this.state.formdata);
      }
    );
  };

  getElevationImage = () => {
    for (const view of this.props.serviceEstimateData.views) {
      const record = this.state.formdata.find((record) => record?.value === view.name);
      if (record) {
        return view.image;
      }
    }
    return null; // Return null if no image is found
  };

  submitService = async () => {
    let postData = {
      token: Auth.urlTokenizer(),
      projectId: this.props.projectId,
      estimateId: this.props.serviceEstimateData.services[0].estimate_id,
      image: this.getElevationImage(),
      areaId: this.props.serviceEstimateData.id,
      category: this.state.boqtype?.value
    };

    this.state.formdata.forEach((record) => {
      postData[record.name] = record?.value;
    });

    console.log('postData', postData);
    let url = config.BASE_API_URL + 'cms/estimate/addservice';
    let json = await Postie.sendAsynchrnousCall(postData, url);
    console.log('json', json);
    if (json.status === 'success') {
      this.props.closePopup();
      this.props.callBack();
    }
  };

  onUpdateService = async (e, serviceId) => {
    const { name, value } = e.target;
    if (name === 'uom' && (value === 'Sq.Ft' || value === 'R.Ft')) {
      this.setState({
        ...this.state,
        showDimensions: true,
        showQuantity: false
      });
    } else if (name === 'uom' && value === 'No.s') {
      this.setState({
        ...this.state,
        showQuantity: true,
        showDimensions: false
      });
    } else if (name === 'uom' && value === 'L.S') {
      this.setState({
        ...this.state,
        showQuantity: false,
        showDimensions: false
      });
    }

    let postData = {
      token: Auth.urlTokenizer(),
      name: name,
      serviceId: serviceId,
      value: value
    };
    let url = config.BASE_API_URL + 'cms/estimate/updateservice';
    let json = await Postie.sendAsynchrnousCall(postData, url);
    console.log('json', json);
    if (json.status === 'success') {
      this.props.callBack();
    }
  };

  render() {
    const { serviceEstimateData, serviceData, editType } = this.props;
    console.log('serviceData', serviceData);
    // console.log(
    //   'serviceData',
    //   serviceData,
    //   this.props.serviceData.workcategory?.boq_type === 'Fitouts '
    // );

    return (
      <Dialog
        open={this.props.setOpenPopup}
        onClose={this.props.closePopup}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Edit {editType}{' '}
          <Button onClick={this.props.closePopup} sx={{ float: 'right' }} color="secondary">
            Close
          </Button>
        </DialogTitle>
        <DialogContent>
          {editType.toLowerCase() === 'service' && (
            <>
              <Grid container spacing={2}>
                <Grid item xs="12" sm="12" md="6">
                  <FormControl variant="outlined" style={{ width: '95%' }}>
                    <InputLabel>BOQ Type *</InputLabel>
                    <Select
                      label="Choose Boq"
                      name="category"
                      onChange={(e) => this.onUpdateService(e, serviceData.id)}
                      disabled={this.state.boqtype}
                      defaultValue={
                        this.state.boqtype ? this.state.boqtype?.value?.toLowerCase().trim() : ''
                      }
                    >
                      <MenuItem key="" value="">
                        select boq
                      </MenuItem>
                      <MenuItem key="" value="fitouts">
                        Fitouts
                      </MenuItem>
                      <MenuItem key="" value="modulars">
                        Modulars
                      </MenuItem>
                      <MenuItem key="" value="furniture">
                        Furniture
                      </MenuItem>
                      <MenuItem key="" value="product">
                        Product
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: '3px' }}>
                <Grid item xs="12" sm="12" md="6">
                  <FormControl variant="outlined" style={{ width: '95%' }}>
                    <InputLabel>Work Category *</InputLabel>
                    <Select
                      label="Choose Area"
                      name="sow_id"
                      key={this.state.boqtype?.value}
                      onChange={(e) => this.onUpdateService(e, serviceData.id)}
                      defaultValue={this.props.serviceData.sow_id}
                    >
                      <MenuItem key="" value="">
                        select work category
                      </MenuItem>
                      {this.state.boqtype?.value?.toLowerCase().trim() === 'fitouts' &&
                        this.props.fitoutCategories?.map((caterories, index) => (
                          <MenuItem key={index} value={caterories.id}>
                            {caterories.sow}
                          </MenuItem>
                        ))}
                      {this.state.boqtype?.value?.trim() === 'modulars' &&
                        this.props.modularCategories?.map((caterories, index) => (
                          <MenuItem key={index} value={caterories.id}>
                            {caterories.sow}
                          </MenuItem>
                        ))}
                      {this.state.boqtype?.value?.trim() === 'furniture' &&
                        this.props.furnitureCategories?.map((caterories, index) => (
                          <MenuItem key={index} value={caterories.id}>
                            {caterories.sow}
                          </MenuItem>
                        ))}
                      {this.state.boqtype?.value?.trim() === 'product' &&
                        this.props.ProductCategories &&
                        this.props.ProductCategories?.map((caterories, index) => (
                          <MenuItem key={index} value={caterories.id}>
                            {caterories.sow}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs="12" sm="12" md="6">
                  <FormControl variant="outlined" style={{ width: '95%' }}>
                    <TextField
                      label="Service Name *"
                      type="text"
                      name="ref_id"
                      defaultValue={serviceData?.ref_id}
                      onBlur={(e) => this.onUpdateService(e, serviceData.id)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs="12" sm="12" md="6">
                  <FormControl variant="outlined" style={{ width: '95%' }}>
                    <InputLabel>Elevation *</InputLabel>
                    <Select
                      label="Choose elevation"
                      name="view"
                      onChange={(e) => this.onUpdateService(e, serviceData.id)}
                      defaultValue={serviceEstimateData?.services[0].elevation}
                    >
                      <MenuItem key="" value="">
                        select view
                      </MenuItem>
                      {serviceEstimateData &&
                        serviceEstimateData?.views?.map((view, index) => (
                          <MenuItem key={index} value={view?.name}>
                            {view?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs="12" sm="12" md="6">
                  <FormControl variant="outlined" style={{ width: '95%' }}>
                    <InputLabel>UOM *</InputLabel>
                    <Select
                      label="Choose uom"
                      name="uom"
                      onChange={(e) => this.onUpdateService(e, serviceData.id)}
                      defaultValue={serviceData?.uom}
                    >
                      <MenuItem key="" value="">
                        select uom
                      </MenuItem>
                      <MenuItem key="" value="Sq.Ft">
                        Sq.Ft
                      </MenuItem>
                      <MenuItem key="" value="R.Ft">
                        R.Ft
                      </MenuItem>
                      <MenuItem key="" value="L.S">
                        L.S
                      </MenuItem>
                      <MenuItem key="" value="No.s">
                        No.s
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {this.state.showDimensions && (
                  <Grid item xs="12" sm="12" md="6">
                    <FormControl variant="outlined" style={{ width: '95%' }}>
                      <TextField
                        label="Length*"
                        id={`length`}
                        key={`length`}
                        defaultValue={serviceData?.length}
                        type="number"
                        name="length"
                        onBlur={(e) => this.onUpdateService(e, serviceData.id)}
                      />
                    </FormControl>
                  </Grid>
                )}
                {this.state.showDimensions && (
                  <Grid item xs="12" sm="12" md="6">
                    <FormControl variant="outlined" style={{ width: '95%' }}>
                      <TextField
                        label="Depth/Height*"
                        id={`Depth/Height`}
                        key={`Depth/Height`}
                        defaultValue={serviceData?.width}
                        type="number"
                        name="width"
                        onBlur={(e) => this.onUpdateService(e, serviceData.id)}
                      />
                    </FormControl>
                  </Grid>
                )}
                {this.state.showQuantity && (
                  <Grid item xs="12" sm="12" md="6">
                    <FormControl variant="outlined" style={{ width: '95%' }}>
                      <TextField
                        label="Quantity*"
                        type="number"
                        defaultValue={serviceData?.qty}
                        name="qty"
                        onBlur={(e) => this.onUpdateService(e, serviceData.id)}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs="12" sm="12" md="6">
                  <FormControl variant="outlined" style={{ width: '95%' }}>
                    <TextField
                      label="Rate*"
                      type="number"
                      name="rate"
                      defaultValue={serviceData?.rate}
                      onBlur={(e) => this.onUpdateService(e, serviceData.id)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs="12" sm="12" md="6">
                  <FormControl variant="outlined" style={{ width: '95%' }}>
                    <TextField
                      label="Finish "
                      type="text"
                      name="finish"
                      defaultValue={serviceData?.finish}
                      onBlur={(e) => this.onUpdateService(e, serviceData.id)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs="12" sm="12" md="6">
                  <FormControl variant="outlined" style={{ width: '95%' }}>
                    <TextField
                      label="Base Material "
                      type="text"
                      name="base_material"
                      defaultValue={serviceData?.base_material}
                      onBlur={(e) => this.onUpdateService(e, serviceData.id)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs="12" sm="12" md="6">
                  <FormControl variant="outlined" style={{ width: '95%' }}>
                    <TextField
                      label="Make "
                      type="text"
                      name="brand"
                      defaultValue={serviceData?.brand}
                      onBlur={(e) => this.onUpdateService(e, serviceData.id)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs="12" sm="12" md="6">
                  <FormControl variant="outlined" style={{ width: '95%' }}>
                    <TextField
                      label="remark"
                      type="text"
                      name="remark"
                      defaultValue={serviceData?.remark}
                      onBlur={(e) => this.onUpdateService(e, serviceData.id)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs="12" sm="12" md="6">
                  <FormControl variant="outlined" style={{ width: '95%' }}>
                    <TextField
                      label="Exclusions"
                      type="text"
                      name="exclusions"
                      defaultValue={serviceData?.exclusions}
                      onBlur={(e) => this.onUpdateService(e, serviceData.id)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}
          {editType.toLowerCase() === 'product' && (
            <>
              <h4>Update the Quantity of product</h4>
              <Grid item xs="12" sm="12" md="12">
                <FormControl variant="outlined" style={{ width: '95%' }}>
                  <TextField
                    label="Quantity*"
                    type="number"
                    defaultValue={serviceData?.qty}
                    name="qty"
                    onBlur={(e) => this.onUpdateService(e, serviceData.id)}
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closePopup} color="secondary">
            Submit
          </Button>
          {/* <Button onClick={this.submitService} color="primary">
            Submit
          </Button> */}
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditServiceInBoq;
