import React, { Component } from 'react';
import {
  Tabs,
  Tab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import FileView from 'app/views/bids/components/fileView';
import displayer from 'app/helpers/displayer';
import TeamInfo from 'app/views/common/components/TeamInfo';

const tableContainerStyle = {
  border: '1px solid black',
  marginBottom: '10px',
  colour: 'blue'
};

class WorkscopeHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      currentTab: 'pdf1' // Default tab
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({ currentTab: newValue });
  };

  render() {
    const { data } = this.props;
    return (
      <div>
        <h1>Workscope</h1>
        <TableContainer style={tableContainerStyle}>
          <div style={{ backgroundColor: '#FAF3F0', color: '#000000', textAlign: 'center' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ padding: '8px' }}>Project Id</TableCell>
                  <TableCell>Client Name</TableCell>
                  <TableCell>Project Address</TableCell>
                  <TableCell>Project Stage</TableCell>
                  <TableCell>Helpline No</TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell style={{ padding: '8px' }}>
                    <span style={{ fontWeight: 'bold' }}>{data.id}</span>
                  </TableCell>
                  <TableCell>
                    <span style={{ fontWeight: 'bold' }}>{data?.client?.fullname}</span>
                  </TableCell>
                  <TableCell>
                    <span style={{ fontWeight: 'bold' }}>{data.project_address}</span>
                  </TableCell>
                  <TableCell>
                    <span style={{ fontWeight: 'bold' }}>{data?.status?.status_name}</span>
                  </TableCell>
                  <TableCell style={{ color: '#0000FF', padding: '8px' }}>
                    <span style={{ fontWeight: 'bold' }}>{this.props.helpline_no}</span>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </div>
        </TableContainer>
        <TableContainer style={tableContainerStyle}>
          <div style={{ backgroundColor: '#FAF3F0', color: '#000000' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ padding: '8px' }}>Execution Team</TableCell>
                  <TableCell>Account Manager</TableCell>
                  <TableCell>Stage Start Date</TableCell>
                  <TableCell>Stage Completion Date</TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell style={{ padding: '8px' }}>
                    <span style={{ fontWeight: 'bold' }}>
                      {data?.project_managers.map((colabrator, index) => (
                        <TeamInfo team={colabrator} detail={true} key={index} />
                      ))}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ fontWeight: 'bold' }}>
                      {/* {data?.colabrators.map((colabrator, index) =>
                        colabrator.role === 'OM' ? colabrator.detail?.member_name : null
                      )} */}
                      {data.design_lead?.detail?.member_name || null}
                    </span>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <span style={{ fontWeight: 'bold' }}>{data.contract_start_date}</span>
                  </TableCell>
                  <TableCell>
                    <span style={{ fontWeight: 'bold' }}>{data.contract_end_date}</span>{' '}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </div>
        </TableContainer>
      </div>
    );
  }
}

export default WorkscopeHeader;
