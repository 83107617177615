import React, { Component } from 'react';
import { Grid, FormGroup, FormControlLabel, Checkbox, Button, TextField } from '@mui/material';
import Auth from '../../../helpers/authorization';
import config from '../../../constants/index.jsx';
import Postie from 'app/helpers/postie';
import $ from 'jquery';

const DataDisplay = (props) => {
  const [searchText, setSearchText] = React.useState('');

  const filteredData = props.data?.filter((scope) =>
    scope.sow.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <Grid container spacing="2">
      <Grid item md="12">
        <FormGroup style={{ width: '90%' }}>
          <TextField
            type="text"
            placeholder={`Search ${props.scopeType}`}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </FormGroup>

        <FormGroup>
          {filteredData?.map((scope, data) => (
            <Grid item md="12" key={data}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={`${props.scopeType}-${data.id}[]`}
                    value={scope.sow}
                    onChange={(e) => props.handleScopeCheck(e)}
                    color="primary"
                    disabled={props.disabled}
                    checked={
                      props.area[props.scopeType] &&
                      props.area[props.scopeType].split('|').includes(scope.sow)
                    }
                  />
                }
                label={scope.sow}
              />
              {props.area[props.scopeType] &&
                props.area[props.scopeType].split('|').includes(scope.sow) && (
                  <Grid item md="12">
                    <TextField
                      key={scope.sow.id}
                      type="text"
                      defaultValue={props.getScopeDefaultRemark(
                        scope.id,
                        props.area[props.remarkType]
                      )}
                      placeholder={`remarks for ${scope.sow} `}
                      name={scope.sow.id}
                      onBlur={(event) => props.onSubmitRemarks(event, props.scopeType, scope.id)}
                    >
                      ={scope?.remark}
                    </TextField>
                    {props.inspirationAction.toLowerCase() === 'create' ? (
                      <Button onClick={() => props.createInspiration()}>Create Inspiration</Button>
                    ) : (
                      <Button
                        onClick={() =>
                          Postie.navigateLink(
                            '/areainspiration/' + props.inspirationId + '/' + props.area.id
                          )
                        }
                      >
                        Update Inspiration
                      </Button>
                    )}
                  </Grid>
                )}
            </Grid>
          ))}
        </FormGroup>
      </Grid>
    </Grid>
  );
};

class Scope extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scopeData: {},
      dataLoaded: false,
      projectInfo: []
    };

    //console.log('statedata', this.state.data);
  }
  componentDidMount = () => {
    this.getScopeDetails();
  };

  getScopeDetails = async () => {
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url =
        config.BASE_API_URL + 'servicesforArea/' + this.props.data.id + '/' + Auth.urlTokenizer();
      let jsonData = await Postie.fetchUrl(url);
      //console.log('sata', jsonData);
      if (jsonData) {
        this.setState(
          {
            ...this.state,
            scopeData: jsonData,
            dataLoaded: true // Set dataLoaded to true when data is loaded
          },
          function () {
            console.log('Scope Data ', this.state.scopeData);
          }
        );
      }
    }
  };
  handleTabChange = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };

  handleScopeCheck = (e) => {
    let selected = [];
    $('[name="' + e.target.name + '"]:checked').each((elem, input) => {
      selected.push(input.value);
    });
    let value = selected.join('|');
    //console.log('value', value);
    if (value === '') {
      value = 'None';
    }
    //console.log(e.target.name, selected.join('|'));
    let sarr = e.target.name.split('-');
    //let elem = { target: { name: sarr[0], value: value } };
    //this.handleFunction(elem, 'update', parseInt(sarr[1].substring(0, sarr[1].length - 2)));
    this.onUpdateProject(sarr[0], value);
  };

  handleDimensionChange = (property, event) => {
    this.onUpdateProject(property, event.target.value);
  };

  handleSwitchChange = (property, event) => {
    // Update the specific property in the state as 0 or 1 based on the switch's checked status
    const value = event.target.checked ? 1 : 0;
    this.setState({ [property]: value }, function () {
      console.log('switch Value', property, value);
    });
    //this.onUpdateProject(property, value);
  };

  onUpdateProject = async (property, value) => {
    const stringValue = value.toString();

    const authToken = localStorage.getItem(config.Token_name);

    const postData = {
      authToken,
      field: property,
      value: stringValue,
      project_id: this.props.projectId,
      area_id: this.props.data.id
    };
    // console.log('update product', postData, this.state);
    let serviceUrl = config.BASE_API_URL + 'project/updatearea';
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      this.props.callback();
    }
  };

  onSubmitRemarks = async (event, scopeType, sowId) => {
    const { name, value } = event.target;
    console.log('onSubmitRemarks', name, value);
    const authToken = localStorage.getItem(config.Token_name);
    const postData = {
      authToken,
      project_id: this.props.projectId,
      area_id: this.props.data.id,
      type: scopeType,
      remark: value,
      sow_id: sowId
    };
    // console.log('update product', postData, this.state);
    let serviceUrl = config.BASE_API_URL + 'project/updatearearemark';
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      this.props.callback();
    }
  };
  createInspiration = async (openarea = true, areaId = 0) => {
    let postData = {
      project_id: this.props.projectId,
      authToken: localStorage.getItem(config.Token_name),
      type: 'area'
    };
    var servicesUrl = config.BASE_API_URL + 'project/inspiration';
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    };
    const resp = await fetch(servicesUrl, requestOptions);
    const json = await resp.json();
    if (json.status === 'success') {
      // let inspirationType =
      //   json.data.type === 'renders'
      //     ? 'render_inspirations'
      //     : json.data.stage === 'DOP'
      //     ? 'dop_inspirations'
      //     : 'inspirations';
      let stage = await this.props.updateStage();
      if (stage) {
        Postie.navigateLink(`/areainspiration/${json.data.id}/${this.props.data.id}`);
      }
    } else {
      Postie.showAlert('Error', 'Some thing went wrong!!', 'error');
    }
  };

  getScopeDefaultRemark = (sowId, remarks) => {
    let ret = '';
    remarks.length > 0 &&
      remarks.forEach((remark) => {
        if (remark.sow_id === sowId) {
          ret = remark.remark;
        }
      });
    return ret;
  };

  render() {
    const { scopeData } = this.state;

    return (
      <Grid
        container
        style={{
          marginLeft: '10px'
        }}
      >
        <Grid item md={12} xs="12" sm="12">
          <h2>{this.props.data.project_display_name}</h2>
        </Grid>
        <Grid item md={4} xs="12" sm="12">
          {' '}
          <h3>Fitout Scope</h3>
          <DataDisplay
            data={scopeData.fitouts}
            area={this.props.data}
            scopeType={'fitout_scope'}
            remarkType={'fitout_scope_remarks'}
            inspirationId={this.props.Inspiration?.id}
            inspirationAction={this.props.Inspiration ? 'update' : 'Create'}
            handleScopeCheck={(e) => this.handleScopeCheck(e)}
            onSubmitRemarks={(event, scopeType, sowId) =>
              this.onSubmitRemarks(event, scopeType, sowId)
            }
            createInspiration={() => this.createInspiration()}
            getScopeDefaultRemark={(sowId, remarkType) =>
              this.getScopeDefaultRemark(sowId, remarkType)
            }
          />
        </Grid>
        <Grid item md={4} xs="12" sm="12">
          <h3>Modular Scope</h3>
          <DataDisplay
            data={scopeData.modulars}
            area={this.props.data}
            scopeType={'modular_scope'}
            remarkType={'modular_scope_remarks'}
            handleScopeCheck={(e) => this.handleScopeCheck(e)}
            inspirationId={this.props.Inspiration?.id}
            inspirationAction={this.props.Inspiration ? 'update' : 'Create'}
            onSubmitRemarks={(event, scopeType, sowId) =>
              this.onSubmitRemarks(event, scopeType, sowId)
            }
            createInspiration={() => this.createInspiration()}
            getScopeDefaultRemark={(sowId, remarkType) =>
              this.getScopeDefaultRemark(sowId, remarkType)
            }
          />
        </Grid>
        <Grid item md={4} xs="12" sm="12">
          <h3>Furniture Scope</h3>
          <DataDisplay
            data={scopeData.furniture}
            area={this.props.data}
            scopeType={'furniture_scope'}
            remarkType={'furniture_scope_remarks'}
            inspirationId={this.props.Inspiration?.id}
            inspirationAction={this.props.Inspiration ? 'update' : 'Create'}
            handleScopeCheck={(e) => this.handleScopeCheck(e)}
            onSubmitRemarks={(event, scopeType, sowId) =>
              this.onSubmitRemarks(event, scopeType, sowId)
            }
            createInspiration={() => this.createInspiration()}
            getScopeDefaultRemark={(sowId, remarkType) =>
              this.getScopeDefaultRemark(sowId, remarkType)
            }
          />
        </Grid>
      </Grid>
    );
  }
}

export default Scope;
