import React, { Component } from 'react';
import { Grid, Button, FormControl } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import UploadFile from '../files/uploadFile';
import LoadDropDown from './components/LoadDropdown';
import LoadTextField from './components/LoadTextField';
import LoadCheckBox from './components/LoadCheckBox';
import LoadRadioGroup from './components/LoadRadioGroup';
import SingleTonTags from './components/SingleTonTags';
import $ from 'jquery';
import Connection from './connection.jsx';
import Postie from 'app/helpers/postie';
import config from '../../constants/index.jsx';

const LoadFieldCol = (props) => (
  <Grid item md={4} xs={12} key={`create-${props.kd}`}>
    {props.fields.map(
      (field) =>
        props.kd === field.dbname && (
          <LoadFieldTemplate
            key={field}
            field={field}
            handleInputChange={props.handleInputChange}
            handleFile={props.handleFile}
            defaultValue={
              props.defaultValue && props.defaultValue[field.dbname]
                ? props.defaultValue[field.dbname].value
                  ? props.defaultValue[field.dbname].value
                  : props.defaultValue[field.dbname]
                : ''
            }
            filters={props.filters}
            disabled={props.defaultValue && props.defaultValue[field.dbname]}
          />
        )
    )}
  </Grid>
);

const LoadFieldTemplate = (props) =>
  props.field.ftype[0].type === 'textBox' || props.field.ftype[0].type === 'text' ? (
    <FormControl fullWidth>
      <LoadTextField
        record={props.field}
        name={props.field.dbname}
        style={{ mb: 1 }}
        placeHolder={props.field.label}
        multiline={false}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        callback={(event) => props.handleInputChange(event)}
      />
    </FormControl>
  ) : props.field.ftype[0].type === 'checkBox' ? (
    <LoadCheckBox
      record={props.field}
      ftype={props.field.ftype[0]}
      callback={props.handleInputChange}
      defaultValue={props.defaultValue}
      disabled={props.disabled}
    />
  ) : props.field.ftype[0].type === 'file' ? (
    <UploadFile
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      Filename={'logo'}
      displayTosection={false}
      areaName={''}
      type={'logo'}
      projectId={0}
      areaId={0}
      callback={(File) => props.handleFile(File, 'skp_file')}
    />
  ) : props.field.ftype[0].type === 'list' ? (
    <LoadRadioGroup
      record={props.field}
      ftype={props.field.ftype[0]}
      callback={this.handleInputChange}
      defaultValue={props.defaultValue}
      disabled={props.disabled}
    />
  ) : props.field.ftype[0].type === 'dropdown' ? (
    <LoadDropDown
      record={props.field}
      defaultValue={props.defaultValue}
      callback={(event) => props.handleInputChange(event)}
      placeholder={props.field.label}
      ftype={props.field.ftype[0]}
      disabled={props.disabled}
    />
  ) : props.field.ftype[0].type === 'textArea' ? (
    <LoadTextField
      record={props.field}
      name={props.field.dbname}
      style={{ mb: 1 }}
      placeholder={
        props.field.ftype[0].placeholder !== ''
          ? props.field.ftype[0].placeholder
          : props.field.label
      }
      multiline={true}
      defaultValue={props.defaultValue}
      disabled={props.disabled}
      callback={(event) => props.handleInputChange(event)}
    />
  ) : props.field.ftype[0].type === 'autocomplete' ? (
    <SingleTonTags
      options={
        props.filters && props.filters[props.field.dbname]
          ? props.filters[props.field.dbname].options
          : []
      }
      label={props.field.label}
      placeholder={props.field.ftype[0].placeholder}
      baseModel={props.field.dbname}
      defaultValue={props.defaultValue}
      handleChange={(data) => {
        let elem = {
          target: { name: props.field.dbname, value: data }
        };
        props.handleInputChange(elem);
      }}
    />
  ) : props.field.ftype[0].type === 'number' ? (
    <LoadTextField
      record={props.field}
      name={props.field.dbname}
      style={{ mb: 1 }}
      placeholder={props.field.label}
      multiline={false}
      disabled={props.disabled}
      type="number"
      defaultValue={props.defaultValue}
      callback={(event) => props.handleInputChange(event)}
    />
  ) : null;

class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      fields: this.props.fields,
      apiCall: this.props.apiCall,
      suggestionFilter: {},
      suggestions: [],
      formdata: {}
    };
  }
  componentDidMount() {
    console.log('Create Props', this.props.defaultValue);
    if (this.props.apiCall?.suggestions) {
      let Keys = this.props.defaultValue ? Object.keys(this.props.defaultValue) : [];
      console.log('keys', Keys);
      Keys.forEach((field) => {
        if (this.props.apiCall.suggestfilter.includes(field)) {
          this.showSugesstions(field, this.props.defaultValue[field].value);
        }
      });
    }
  }

  handleInputChange = (event) => {
    const { name, value, type } = event.target;
    let newValue = value;
    if (type === 'checkbox') {
      newValue = [];
      var checkboxes = $('input[name="' + name + '"]');
      //console.log(name, checkboxes);
      checkboxes.each(function () {
        if (this.checked) {
          newValue.push(this.value);
        }
      });
    }
    //console.log(newValue);

    this.setState(
      { ...this.state, formdata: { ...this.state.formdata, [name]: newValue } },

      function () {
        console.log('filled values', this.state.formdata);
        if (this.props.apiCall.suggestions) {
          this.showSugesstions(name, newValue);
        }
      }
    );
  };

  async showSugesstions(name, value) {
    if (this.props.apiCall.suggestfilter.includes(name)) {
      let filters = this.state.suggestionFilter;
      if (value !== '' && value.toLowerCase() !== 'none') {
        filters[name] = value;
      } else {
        delete filters[name];
      }
      this.setState({ ...this.state, suggestionFilter: filters }, async function () {
        let url = config.BASE_API_URL + this.props.apiCall.filterUrl;
        let postData = {
          authToken: localStorage.getItem(config.Token_name),
          filters: this.state.suggestionFilter
        };
        let reply = await Postie.sendAsynchrnousCall(postData, url);
        if (reply.status === 'success') {
          this.setState({ ...this.state, suggestions: reply.data }, function () {
            console.log('data', this.state.suggestions);
          });
        }
      });
    }
  }

  handleFile = (resp, type) => {
    console.log('handleFile', type);
    if (type === 'skp_file') {
      this.setState({
        ...this.state,
        skp_File: resp.data.url
      });
    } else if (type === 'base_image') {
      this.setState({
        ...this.state,
        base_Image: resp.data.url
      });
    }
    console.log('file added', resp);
    this.handleInputChange(resp, type);
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = () => {
    let data = this.state.formdata;
    if (this.props.defaultValue) {
      let keys = Object.keys(this.props.defaultValue);
      keys.forEach((k) => {
        data[k] = this.props.defaultValue[k].value
          ? this.props.defaultValue[k].value
          : this.props.defaultValue[k];
      });
      data['filtered_values'] = keys;
    }
    console.log('handle submit', data);
    this.props.add(data);
    this.setState({ open: false }, function () {});
  };

  copyUnit = (data) => {
    let defaultkeys = Object.keys(this.props.defaultValue);
    let formdata = this.state.formdata;
    console.log('copyUnit', defaultkeys, this.state.fields, formdata, data);
    this.state.fields.forEach((field) => {
      if (!defaultkeys.includes(field.ftype[0].dbname) && data[field.ftype[0].dbname]) {
        //console.log('field',field.ftype[0].dbname,data[field.ftype[0].dbname]);
        formdata[field.ftype[0].dbname] = data[field.ftype[0].dbname];
      }
    });
    console.log('copyUnit', defaultkeys, this.state.fields, formdata);
    this.setState({ ...this.state, formdata: formdata }, function () {
      this.handleSubmit();
    });
  };

  render() {
    const { fields, apiCall } = this.state;
    console.log('create data', fields, apiCall);

    const keyData = apiCall ? Object.keys(apiCall?.mandatory) : [];
    return (
      <Grid item md="12">
        <Button variant="contained" color="primary" onClick={this.handleOpen}>
          <AddIcon />
          Create {this.props.heading.replace(/_/g, ' ')}
        </Button>

        <Dialog open={this.state.open} onClose={this.handleClose} fullWidth maxWidth="lg">
          <DialogTitle>Create {this.props.heading.replace(/_/g, ' ')}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {keyData.map(
                (kd) =>
                  kd !== 'authToken' && (
                    <LoadFieldCol
                      key={kd}
                      kd={kd}
                      mandatory={apiCall.mandatory}
                      fields={fields}
                      product={this.state}
                      handleInputChange={(e) => this.handleInputChange(e)}
                      defaultValue={this.props.defaultValue}
                      filters={this.props.filters}
                    />
                  )
              )}
            </Grid>

            <Button variant="contained" color="primary" onClick={() => this.handleSubmit()}>
              Submit
            </Button>
            {this.props.apiCall?.suggestions && (
              <Grid container>
                <Grid item md="12" xs="12">
                  <Connection
                    key={this.state.suggestionFilter}
                    heading={`Suggestion`}
                    fields={fields}
                    data={this.state.suggestions}
                    apiCalls={this.props.apiCall}
                    connectionId={0}
                    callback={(data) => this.copyUnit(data)}
                    defaultValue={this.state.suggestionFilter}
                    showSuggesstion={this.props.apiCall.suggestions}
                  />
                </Grid>
              </Grid>
            )}
          </DialogContent>
        </Dialog>
      </Grid>
    );
  }
}

export default Create;
