import React, { Component } from 'react';
import { Grid, Button, TextField } from '@mui/material';

/* 
    How to render 
   <NexusPagination
    displayNote={data.display_note}
    currentPage={data.currentPage}
    totalPages={data.totalpages}
    handleFunction={(desiredPage) => this.props.changePage(desiredPage)}
  />
 

    *** Defination of fields:
    displayNote: "takes String to display with count shared by api "
    current Page: "takes string value to display current page "
    total Pages: "takes string  values to diaplay the total no of pages"
    handleFunction: "callback function to change page data and load the desired page  content"
 */

class NexusPagination extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <Grid item md={12} style={{ textAlign: 'center', marginTop: '20px' }}>
        <Grid item md={12}>
          <h4>{this.props.displayNote}</h4>
        </Grid>
        <Grid item md={12}>
          <Button
            disabled={parseInt(this.props.currentPage) === 1}
            onClick={() => this.props.handleFunction(parseInt(this.props.currentPage) - 1)}
          >
            Previous Page
          </Button>
          <span style={{ margin: '0 10px' }}>
            Page
            <TextField
              key={this.props.currentPage}
              sx={{ width: '75px' }}
              placeholder={``}
              type="number"
              min={1}
              max={this.props.totalPages}
              defaultValue={this.props.currentPage}
              onChange={(e) => this.props.handleFunction(e.target.value)}
            />
            of {this.props.totalPages}
          </span>
          <Button
            disabled={parseInt(this.props.currentPage) === parseInt(this.props.totalPages)}
            onClick={() => this.props.handleFunction(parseInt(this.props.currentPage) + 1)}
          >
            Next Page
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default NexusPagination;
