import React, { Component } from 'react';
import { Grid, Tabs, Tab, Select, MenuItem, FormControl } from '@mui/material';
import { Button } from '@mui/material';
import config from '../../constants/index.jsx';
import Auth from 'app/helpers/authorization';
import Postie from 'app/helpers/postie';
import displayer from 'app/helpers/displayer';
import FileView from '../bids/components/fileView.jsx';
import LeadView from './components/LeadView';
import ProjectAreas from './components/ProjectAreas.jsx';
import BriefingCall from './components/BriefingCall.jsx';
import InspirationList from '../Inspiration/components/InspirationList.jsx';
import Estimates from './components/Estimates.jsx';
//import Workscope from '../leadView/Workscope.jsx';
import TeamChat from './components/TeamChat.jsx';
import Execution from './components/Execution.jsx';

class ProjectView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      dataLoaded: false,
      selectedMainTab: 'Overview',
      selectedTab: 'BriefingCall',
      selectedArea: '',
      selectedEstimateTab: 'Fitouts',
      ex_site_pictures: false
    };
  }

  componentDidMount = () => {
    const queryParams = displayer.getUrlParams('/project/view/');
    //console.log(queryParams);
    this.getProjectDetails(queryParams[0]);
  };

  getProjectDetails = async (id) => {
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url = config.BASE_API_URL + 'project/' + id + '/' + Auth.urlTokenizer();
      let jsonData = await Postie.fetchUrl(url);
      //console.log('sata', jsonData);
      if (jsonData) {
        this.setState(
          {
            ...this.state,
            data: jsonData,
            dataLoaded: true, // Set dataLoaded to true when data is loaded
            selectedArea:
              this.state.selectedArea === '' ? jsonData.areas[0].id : this.state.selectedArea
          },
          function () {
            console.log('Project Details', this.state.data);
          }
        );
      }
    }
  };
  onMainTabChange = (event, newValue) => {
    this.setState({ selectedMainTab: newValue });
  };

  handleTabChange = (event, newValue) => {
    this.setState({ selectedTab: newValue }, function () {
      // let ex_site_pictures = this.allPictureUploaded(this.state.data.areas, 'ex_site_pictures');
      // let ex_plan_dwg = this.allPictureUploaded(this.state.data.areas, 'ex_plan_dwg');
      // let ex_elevations = this.allPictureUploaded(this.state.data.areas, 'ex_elevations');
    });
  };
  handleEstimateTabChange = (event, newValue) => {
    this.setState({ selectedEstimateTab: newValue });
  };

  openWorkscopeView = (id) => {
    let url = '/bid/workscope/' + id;
    //console.log(url);
    Postie.openLink(url);
  };

  handleAreaChange = (event) => {
    const selectedArea = event;

    this.setState({ selectedArea: selectedArea }, function () {
      console.log('selected area', this.state.selectedArea);
    });
  };

  allPictureUploaded = async (areas, field) => {
    let result = await displayer.checkAllAreaImage(areas, field);
    this.setState({ ...this.state, [field]: result }, function () {
      console.log('allPictureUploaded', field, this.state[field], result);
    });
  };
  updateStage = async (projectId, currentStage, desiredStage) => {
    let stage = await displayer.updateProjectstage(projectId, currentStage, desiredStage);
    if (stage) {
      this.getProjectDetails(this.state.data.id);
    }
  };

  render() {
    const { data, dataLoaded, selectedTab, selectedArea, selectedMainTab, selectedEstimateTab } =
      this.state;
    console.log('furniture estimates', data.furniture_estimates);

    // Only render if data is loaded
    if (!dataLoaded) {
      return (
        <Grid
          container
          md={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Center the loading message
            height: '100vh' // Center vertically
          }}
        >
          <h3>Loading data...</h3>
        </Grid>
      );
    }

    return (
      <Grid container>
        <Grid item md={12} style={{ margin: '10px' }}>
          <LeadView
            key={data}
            data={data}
            teams={data.teams}
            view={false}
            callback={() => this.getProjectDetails(this.state.data.id)}
          />
        </Grid>
        <Grid item md={12}>
          {' '}
          <Tabs
            value={selectedMainTab}
            onChange={this.onMainTabChange}
            style={{ color: '#0000FF', padding: '8px' }}
          >
            <Tab label="OverView" value="Overview" />
            <Tab label="Presales" value="Presales" />
            <Tab label="Dop" value="Dop" />
            {/*
            <Tab label="Handover" value="Handover" />*/}
            <Tab label="Inspiration" value="Inspiration" />
            <Tab label="Estimates" value="Estimates" />
            <Tab label="Execution" value="Execution" />
            <Tab label="Chats" value="Chats" />
          </Tabs>
        </Grid>
        {selectedMainTab === 'Overview' && (
          <Grid container>
            <Grid
              container
              alignItems="center"
              md={12}
              style={{ marginLeft: '30px', marginBottom: '30px' }}
            >
              <Grid item md="12">
                <h4>Builder Plan / Architectural Plan</h4>
                {data.layouts &&
                  data.layouts.map((layout, index) => (
                    <Grid item md={3}>
                      <FileView
                        key={index}
                        id={layout.id}
                        url={displayer.absolutingImageURl(layout.url)}
                      />
                    </Grid>
                  ))}
              </Grid>
              <Grid item md="6">
                <h4>Bird Eye View Layout</h4>
                {data?.skpimage && (
                  <FileView
                    key={'sketchupLayout'}
                    id={data?.skpimage?.id}
                    url={displayer.absolutingImageURl(data?.skpimage?.url)}
                  />
                )}
              </Grid>
              <Grid item md="6">
                {data.project_status === '6' ? (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => Postie.openLink('SecondSketchupExe')}
                  >
                    Open Sketchup and Import Areas
                  </Button>
                ) : (
                  <FileView
                    key={'sketchupLayout'}
                    id={data?.skpfile?.id}
                    url={displayer.absolutingImageURl(data?.skpfile?.url)}
                  />
                )}
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              alignItems="center"
              style={{ marginLeft: '30px', marginBottom: '30px' }}
            >
              <h2>Inspiration</h2>
              {data.inspirations.length > 0 && (
                <InspirationList
                  data={[data.inspirations[0]]}
                  projectId={data.id}
                  status={data.project_status}
                  callback={() => this.getProjectDetails(this.state.data.id)}
                />
              )}
            </Grid>

            <Grid container item md={12} alignItems="center">
              <Tabs
                value={selectedEstimateTab}
                onChange={this.handleEstimateTabChange}
                style={{ color: '#0000FF', padding: '8px' }}
              >
                <Tab label="Fitouts" value="Fitouts" />
                <Tab label="Modular" value="Modular" />
                <Tab label="Products" value="Products" />
                <Tab label="Furniture" value="Furniture" />
              </Tabs>
              {selectedEstimateTab === 'Fitouts' && (
                <Grid container spacing="2" style={{ margin: '10px' }}>
                  <h3>Fitouts Boq</h3>
                  {data.estimates[0] ? (
                    <Estimates
                      data={[data.estimates[0]]}
                      isModular={false}
                      overViewEstimates={true}
                      callback={() => this.getProjectDetails(this.state.data.id)}
                      skpfile={data.skpfile}
                      projectStatus={data.project_status}
                    />
                  ) : (
                    <h5>No Record Found</h5>
                  )}
                </Grid>
              )}
              {selectedEstimateTab === 'Modular' && (
                <Grid container spacing="2" style={{ margin: '10px' }}>
                  <h3>Modular Boq</h3>
                  {data.modular_estimates.length > 0 ? (
                    <Estimates
                      data={[data.modular_estimates[0]]}
                      isModular={true}
                      overViewEstimates={true}
                      callback={() => this.getProjectDetails(this.state.data.id)}
                      skpfile={data.skpfile}
                      projectStatus={data.project_status}
                    />
                  ) : (
                    <h5>No Record Found</h5>
                  )}
                </Grid>
              )}
              {selectedEstimateTab === 'Products' && (
                <Grid container spacing="2" style={{ margin: '10px' }}>
                  <h3>Products Boq</h3>
                  {data.product_estimates.length > 0 ? (
                    <Estimates
                      data={[data.product_estimates[0]]}
                      isModular={false}
                      overViewEstimates={true}
                      callback={() => this.getProjectDetails(this.state.data.id)}
                      skpfile={data.skpfile}
                      projectStatus={data.project_status}
                    />
                  ) : (
                    <h5>No Record Found</h5>
                  )}
                </Grid>
              )}
              {selectedEstimateTab === 'Furniture' && (
                <Grid container spacing="2" style={{ margin: '10px' }}>
                  <h3>Furniture Boq</h3>
                  {data.furniture_estimates.length > 0 ? (
                    <Estimates
                      data={[data.furniture_estimates[0]]}
                      isModular={false}
                      overViewEstimates={true}
                      callback={() => this.getProjectDetails(this.state.data.id)}
                      skpfile={data.skpfile}
                      projectStatus={data.project_status}
                    />
                  ) : (
                    <h5>No Record Found</h5>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid container item md={12} alignItems="center" style={{ marginLeft: '30px' }}>
              <h2>WorkScope </h2>

              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: '10px' }}
                onClick={() => this.openWorkscopeView(data.id)}
              >
                Open Workscope Link
              </Button>
            </Grid>
            <Grid container item md={12} alignItems="center" style={{ marginLeft: '30px' }}>
              <h2>Execution </h2>

              <Button color="primary" variant="contained" style={{ marginLeft: '10px' }}>
                Open Execution Link
              </Button>
            </Grid>
          </Grid>
        )}
        {selectedMainTab === 'Presales' && (
          <Grid item md={12}>
            <Tabs
              value={selectedTab}
              onChange={this.handleTabChange}
              style={{ color: '#0000FF', padding: '8px' }}
            >
              <Tab label="BriefingCall" value="BriefingCall" />
              <Tab label="Area" value="Area" />
            </Tabs>
            {selectedTab === 'BriefingCall' && (
              <Grid>
                <BriefingCall
                  key={this.state.selectedArea}
                  data={data}
                  selectedArea={this.state.selectedArea}
                  changeTab={(areaId) => this.handleAreaChange(areaId)}
                  projectId={data.id}
                  callback={() => this.getProjectDetails(this.state.data.id)}
                  status={data.project_status}
                  inspirations={data.inspirations}
                />
              </Grid>
            )}

            {selectedTab === 'Area' && (
              //drop down for Room / Area
              <Grid
                container
                item
                md={12}
                alignItems="center"
                style={{
                  marginLeft: '10px'
                }}
              >
                <h3
                  style={{
                    marginLeft: '10px'
                  }}
                >
                  Areas
                </h3>
                <FormControl variant="outlined" style={{ width: '30%' }}>
                  <Select
                    onChange={(e) => this.handleAreaChange(e.target.value)}
                    defaultValue={this.state.selectedArea}
                    label="Choose Area"
                    name="Choose Area"
                  >
                    <MenuItem value="">Choose</MenuItem>
                    {data.areas &&
                      data.areas.map(
                        (area, index) =>
                          (area.fitout_scope || area.modular_scope || area.furniture_scope) && (
                            <MenuItem key={index} value={area.id}>
                              {area.project_area_name}
                            </MenuItem>
                          )
                      )}
                  </Select>
                </FormControl>
                {data.areas &&
                  data.areas.map((area) => (
                    <>
                      {selectedArea === area.id && (
                        <ProjectAreas
                          data={area}
                          projectId={data.id}
                          allAreas={data.areas}
                          key={selectedArea}
                          callback={() => this.getProjectDetails(data.id)}
                          showfiles={false}
                          projectStatus={data.project_status}
                        />
                      )}
                    </>
                  ))}
              </Grid>
            )}
          </Grid>
        )}
        {selectedMainTab === 'Dop' && (
          <Grid item md={12}>
            <Tabs
              value={selectedTab}
              onChange={this.handleTabChange}
              style={{ color: '#0000FF', padding: '8px' }}
            >
              <Tab label="Area" value="Area" />
              <Tab label="Workscope" value="Workscope" />
            </Tabs>

            {selectedTab === 'Area' && (
              //drop down for Room / Area
              <Grid
                container
                item
                md={12}
                alignItems="center"
                style={{
                  marginLeft: '10px'
                }}
              >
                <h3
                  style={{
                    marginLeft: '10px'
                  }}
                >
                  Areas
                </h3>

                <FormControl variant="outlined" style={{ width: '30%' }}>
                  <Select
                    onChange={(e) => this.handleAreaChange(e.target.value)}
                    defaultValue={this.state.selectedArea}
                    label="Choose Area"
                    name="Choose Area"
                  >
                    <MenuItem value="">Choose</MenuItem>
                    {data.areas &&
                      data.areas.map(
                        (area, index) =>
                          (area.fitout_scope || area.modular_scope || area.furniture_scope) && (
                            <MenuItem key={index} value={area.id}>
                              {area.project_area_name}
                            </MenuItem>
                          )
                      )}
                  </Select>
                </FormControl>
                {parseInt(data.project_status) === 14 ? (
                  this.state.ex_site_pictures ? (
                    <Button
                      variant="contained"
                      onClick={() =>
                        this.updateStage(
                          data.id,
                          data.project_status,
                          'dop_existing_site_pictures_uploaded'
                        )
                      }
                      color="success"
                      style={{ float: 'right', marginLeft: '40px' }}
                    >
                      Added All Existing Site Pictures
                    </Button>
                  ) : null
                ) : null}
                {parseInt(data.project_status) === 15 ? (
                  this.state.ex_plan_dwg ? (
                    <Button
                      variant="contained"
                      onClick={() =>
                        this.updateStage(data.id, data.project_status, 'dop_updating_plan')
                      }
                      color="success"
                      style={{ float: 'right', marginLeft: '40px' }}
                    >
                      Updated Plan For All Areas
                    </Button>
                  ) : null
                ) : null}
                {parseInt(data.project_status) === 18 ? (
                  this.state.ex_elevations ? (
                    <Button
                      variant="contained"
                      onClick={() =>
                        this.updateStage(data.id, data.project_status, 'dop_mom_updated')
                      }
                      color="success"
                      style={{ float: 'right', marginLeft: '40px' }}
                    >
                      Updated Recorded Materials For Every Area
                    </Button>
                  ) : null
                ) : null}
                {parseInt(data.project_status) === 19 && (
                  <Button
                    variant="contained"
                    onClick={() =>
                      this.updateStage(data.id, data.project_status, 'dop_created_first_render')
                    }
                    color="success"
                    style={{ float: 'right', marginLeft: '40px' }}
                  >
                    First Render Set Ready
                  </Button>
                )}
                {parseInt(data.project_status) === 21 && (
                  <Button
                    variant="contained"
                    onClick={() =>
                      this.updateStage(
                        data.id,
                        data.project_status,
                        'dop_created_second_set_renders'
                      )
                    }
                    color="success"
                    style={{ float: 'right', marginLeft: '40px' }}
                  >
                    Created Second Set Render
                  </Button>
                )}
                {parseInt(data.project_status) === 24 && (
                  <Button
                    variant="contained"
                    onClick={() =>
                      this.updateStage(data.id, data.project_status, 'dop_data_colated')
                    }
                    color="success"
                    style={{ float: 'right', marginLeft: '40px' }}
                  >
                    Data Collated
                  </Button>
                )}

                {data.areas &&
                  data.areas.map((area) => (
                    <>
                      {selectedArea === area.id && (
                        <ProjectAreas
                          data={area}
                          projectId={data.id}
                          allAreas={data.areas}
                          key={selectedArea}
                          callback={() => this.getProjectDetails(data.id)}
                          showfiles={true}
                          projectStatus={data.project_status}
                        />
                      )}
                    </>
                  ))}
              </Grid>
            )}
            {selectedTab === 'Workscope' && (
              <Grid>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ alignItems: 'center' }}
                  onClick={() => this.openWorkscopeView(data.id)}
                >
                  Open Workscope Link
                </Button>
              </Grid>
            )}
          </Grid>
        )}
        {selectedMainTab === 'Inspiration' && (
          <InspirationList
            data={data.inspirations}
            projectId={data.id}
            status={data.project_status}
            callback={() => this.getProjectDetails(this.state.data.id)}
          />
        )}
        {selectedMainTab === 'Estimates' && (
          //drop down for Room / Area
          <Grid container >
            <Grid item md={12}>
              {data?.estimates?.length === 0 && (
                  <Button
                      type="button"
                      onClick={() =>
                          Postie.openLink(
                              `${config.piqqe_url}/my-projects/boq${config.piqqe_ext}?index=${data.id}&token=${Auth.piqqeTokenizer()}`
                          )
                      }
                      variant="outlined"
                      color="secondary"
                  >
                      Generate Presales Boq
                  </Button>
              )}
              {data?.estimates.length > 0 &&
              data?.project_status > 13 &&
              data.estimates[0].estimate_type === 1 && (
                  <Button
                      type="button"
                      onClick={() =>
                          Postie.navigateLink(
                              `/my-projects/boq${config.piqqe_ext}?index=${data.id}`
                          )
                      }
                      variant="outlined"
                                        color="secondary"
                  >
                      Generate DOP Boq
                  </Button>
              )}
            </Grid>
            <Grid item md={12}>
              <Tabs
                value={selectedEstimateTab}
                onChange={this.handleEstimateTabChange}
                style={{ color: '#0000FF', padding: '8px' }}
              >
                <Tab label="Fitouts" value="Fitouts" />
                <Tab label="Modular" value="Modular" />
                <Tab label="Products" value="Products" />
                <Tab label="Furniture" value="Furniture" />
              </Tabs>
              {selectedEstimateTab === 'Fitouts' && (
                <Grid container spacing="2" style={{ margin: '10px' }}>
                  <Grid>
                    <h3>Fitouts Boq</h3>
                  </Grid>
                  {data.estimates.length > 1 && (
                    <Grid>
                      <Button
                        color="primary"
                        style={{ marginLeft: '900px' }}
                        variant="contained"
                        onClick={() =>
                          Postie.navigateLink(`/estimate/comparasion/${data.id}/fitouts`)
                        }
                      >
                        Compare Fitout Boq
                      </Button>
                    </Grid>
                  )}

                  {data.estimates && (
                    <Estimates
                      key={data.estimates}
                      data={data.estimates}
                      completeData={data}
                      isModular={false}
                      callback={() => this.getProjectDetails(this.state.data.id)}
                      skpfile={data.skpfile}
                      projectStatus={data.project_status}
                    />
                  )}
                </Grid>
              )}
              {selectedEstimateTab === 'Modular' && (
                <Grid container spacing="2" style={{ margin: '10px' }}>
                  <h3>Modular Boq</h3>
                  {data.modular_estimates.length > 1 && (
                    <Grid>
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ marginLeft: '900px' }}
                        onClick={() =>
                          Postie.navigateLink(`/estimate/comparasion/${data.id}/modular`)
                        }
                      >
                        Compare Modular Boq
                      </Button>
                    </Grid>
                  )}

                  {data.modular_estimates.length > 0 && (
                    <Estimates
                      key={data.modular_estimates}
                      data={data.modular_estimates}
                      completeData={data}
                      isModular={true}
                      callback={() => this.getProjectDetails(this.state.data.id)}
                      skpfile={data.skpfile}
                      projectStatus={data.project_status}
                    />
                  )}
                </Grid>
              )}
              {selectedEstimateTab === 'Products' && (
                <Grid container spacing="2" style={{ margin: '10px' }}>
                  <h3>Products Boq</h3>
                  {data.product_estimates.length > 1 && (
                    <Grid>
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ marginLeft: '900px' }}
                        onClick={() =>
                          Postie.navigateLink(`/estimate/comparasion/${data.id}/product`)
                        }
                      >
                        Compare Product Boq
                      </Button>
                    </Grid>
                  )}

                  {data.product_estimates && (
                    <Estimates
                      key={data.product_estimates}
                      data={data.product_estimates}
                      completeData={data}
                      isModular={false}
                      callback={() => this.getProjectDetails(this.state.data.id)}
                      skpfile={data.skpfile}
                      projectStatus={data.project_status}
                    />
                  )}
                </Grid>
              )}
              {selectedEstimateTab === 'Furniture' && (
                <Grid container spacing="2" style={{ margin: '10px' }}>
                  <h3>Furniture Boq</h3>
                  {data.furniture_estimates.length > 1 && (
                    <Grid>
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ marginLeft: '900px' }}
                        onClick={() =>
                          Postie.navigateLink(`/estimate/comparasion/${data.id}/furniture`)
                        }
                      >
                        Compare Furniture Boq
                      </Button>
                    </Grid>
                  )}

                  {data.furniture_estimates && (
                    <Estimates
                      key={data.furniture_estimates}
                      data={data.furniture_estimates}
                      completeData={data}
                      isModular={false}
                      overViewEstimates={true}
                      callback={() => this.getProjectDetails(this.state.data.id)}
                      skpfile={data.skpfile}
                      projectStatus={data.project_status}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {selectedMainTab === 'Execution' && (
          <Grid container md={12} alignItems="center">
            <Execution />
          </Grid>
        )}
        {selectedMainTab === 'Chats' && (
          //drop down for Room / Area
          <Grid container item md={12} alignItems="center">
            <TeamChat />
          </Grid>
        )}
      </Grid>
    );
  }
}

export default ProjectView;
