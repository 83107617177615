import React, { Component } from 'react';
import {
  Tabs,
  Tab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import FileView from 'app/views/bids/components/fileView';
import displayer from 'app/helpers/displayer';

class FileReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      currentTab: 'pdf1' // Default tab
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({ currentTab: newValue });
  };

  renderTable = () => {
    const { currentTab } = this.state;

    // Data for the two tabs
    const tabData = {
      pdf1: [
        {
          Date: '2023-09-23',
          Author: 'Sonal Rohtagi',
          Type: 'checkList',
          Status: 'Approved',
          Point: 'Predefined CHeckList 1',
          Remark: 'My remarks',
          Image: 'image1.jpg'
        },
        {
          Date: '2023-09-23',
          Author: 'Sonal Rohtagi',
          Type: 'checkList',
          Status: 'Approved',
          Point: 'Predefined CHeckList 1',
          Remark: 'My remarks',
          Image: 'image1.jpg'
        },
        {
          Date: '2023-09-23',
          Author: 'Sonal Rohtagi',
          Type: 'checkList',
          Status: 'Approved',
          Point: 'Predefined CHeckList 1',
          Remark: 'My remarks',
          Image: 'image1.jpg'
        }
      ],
      pdf2: [
        {
          Date: '2023-09-24',
          Author: 'Sonal Rohtagi',
          Type: 'checkList',
          Status: 'Rejected',
          Point: 'Predefined CHeckList 1',
          Remark: 'My remarks',
          Image: 'image2.jpg'
        }
      ]
    };

    const columns = ['Date', 'Author', 'Type', 'Status', 'Point', 'Remark', 'Image'];

    return (
      <TableContainer component={Paper}>
        <Table style={{ marginLeft: '10px' }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tabData[currentTab].map((row, index) => (
              <TableRow key={index}>
                {columns.map((column) => (
                  <TableCell key={column}>{row[column]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  render() {
    const { currentTab } = this.state;

    return (
      <div style={{ marginLeft: '10px' }}>
        <h1>On Site Validation Report for DWGS in Elevation A</h1>
        <Tabs value={currentTab} onChange={this.handleTabChange}>
          <Tab label="PDF 1" value="pdf1" />
          <Tab label="PDF 2" value="pdf2" />
        </Tabs>
        {this.renderTable()}
      </div>
    );
  }
}

export default FileReport;
