import React, { Component } from 'react';
import { Grid} from '@mui/material';
import { Divider, TextField } from '@mui/material';
//import { Box,Divider, TextField } from '@mui/material';
//import { styled } from '@mui/material';
import config from '../../../constants/index.jsx';
import Auth from 'app/helpers/authorization';
import displayer from 'app/helpers/displayer';
import Postie from 'app/helpers/postie';
import swal from 'sweetalert';

// const AccordionRoot = styled(Box)(({ theme }) => ({
//   width: '100%',
//   '& .heading': {
//     fontSize: theme.typography.pxToRem(15),
//     [theme.breakpoints.down('sm')]: {
//       fontSize: theme.typography.pxToRem(12)
//     }
//   },
//   '& .secondaryHeading': {
//     color: theme.palette.text.secondary,
//     fontSize: theme.typography.pxToRem(15),
//     [theme.breakpoints.down('sm')]: {
//       fontSize: theme.typography.pxToRem(12)
//     }
//   },
//   '& .icon': {
//     width: 20,
//     height: 20,
//     verticalAlign: 'bottom'
//   },
//   '& .details': { alignItems: 'center' },
//   '& .column': { flexBasis: '33.33%' },
//   '& .helper': {
//     padding: theme.spacing(1, 2),
//     borderLeft: `2px solid ${theme.palette.divider}`
//   },
//   '& .link': {
//     textDecoration: 'none',
//     color: theme.palette.primary.main,
//     '&:hover': { textDecoration: 'underline' }
//   }
// }));

const ComparisionBoqBreakupRow = (props) => (
  <Grid
    container
    spacing={2}
    style={{ borderBottom: '1px Solid', maxWidth: '98%', overflowX: 'auto' }}
  >
    {console.log('  props', props)}
    {props.service.type === 'service' && (
      <Grid item xs={12} sm={6} md={2}>
        {props.service.type === 'service' && <h5>Type(#{props.service.sow_id})</h5>}
        {props.service.type === 'service' && <p>{props.service.workcategory?.sow}</p>}
        {props.service.remark && props.service.type === 'service' ? (
          <p style={{ color: 'red' }}>Remark:{props.service.remark}</p>
        ) : null}
      </Grid>
    )}
    <Grid item xs={12} sm={6} md={props.service.type === 'service' ? 2 : 3}>
      <h5 className="capitalize">{props.service.type} Name</h5>
      <p>
        {props.service.type === 'service'
          ? props.service.service
            ? props.service.service.name
            : props.service.ref_id
          : props.service.product
          ? props.service.product.name
          : props.service.ref_id}
      </p>
      {props.service.base_material && <p>Base Material:{props.service.base_material}</p>}
      {props.service.brand && <p>Make:{props.service.brand}</p>}
      {props.service.finish && <p>Finish:{props.service.finish}</p>}
    </Grid>
    <Grid item xs={12} sm={6} md={1}>
      <h5>Area / Qty</h5>
      <p>
        {props.service.qty} &nbsp;
        {props.service.type === 'service' ? props.service.uom : 'No.s'}
      </p>
    </Grid>
    <Grid item xs={12} sm={6} md={1}>
      <h5>Rate </h5>
      {props.is_editable ? (
        <TextField
          key={props.service.id}
          type="number"
          min={0}
          defaultValue={props.service.rate}
          onChange={(e) => this.handlerateChange(e, props.service.id)}
          placeholder={'Enter Rate'}
          name={'rate'}
          fullWidth
        ></TextField>
      ) : (
        <p>&#8377;{props.service.rate}</p>
      )}
    </Grid>
    <Grid item xs={12} sm={6} md={props.setEditMode ? 1 : 2}>
      <h5>Cost :</h5>
      <p>&#8377;{props.service.cost}</p>
    </Grid>
    <Grid item xs={12} sm={6} md={2}>
      <h5>Exclusions</h5>
      <p>{props.service.exclusions}</p>
    </Grid>
    <Grid item xs={12} sm={6} md={2}>
      <h5>Summary</h5>
      <p>{props.service.comment}</p>
    </Grid>
  </Grid>
);
class ComparisonBoqRowComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      data1: '',
      setOpenServicePopup: false,
      serviceData: '',
      editType: ''
    };
  }

  onEditService = (service, type) => {
    this.setState({
      serviceData: service,
      setOpenServicePopup: true,
      editType: type
    });
  };
  closeServicePopup = () => {
    this.setState({
      setOpenServicePopup: false
    });
  };

  onDeleteService = async (service) => {
    const willDelete = await swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this Service?',
      icon: 'warning',
      buttons: ['No, cancel it!', 'Yes, I am sure!'],
      dangerMode: true
    });

    if (willDelete) {
      swal('Deleted!', 'Your service has been deleted!', 'success');
      let postData = {
        token: Auth.urlTokenizer(),
        name: 'status',
        serviceId: service.id,
        value: '0'
      };
      let url = config.BASE_API_URL + 'cms/estimate/updateservice';
      let json = await Postie.sendAsynchrnousCall(postData, url);
      console.log('json', json);
      if (json.status === 'success') {
        this.props.callBack();
      }
    }
  };

  render() {
    const { data } = this.props;
    //console.log('servicesbreakup', data.servicesbreakup);
    console.log('serviceEstimateData', data);
    return (
      <Grid container spacing={2} style={{ margin: '5px' }}>
        <Grid container spacing={2} item xs={12} sm={12} md={12}>
          {data.servicesbreakup &&
            data.servicesbreakup.map((service, index) => (
              <>
                {(service.workcategory?.sow === this.props.scopeFilterData.value ||
                  this.props.scopeFilterData.value === '') && (
                  <ComparisionBoqBreakupRow
                    key={index}
                    service={service}
                    estimateId={this.props.estimateId}
                    is_editable={this.props.is_editable}
                    elevationImage={displayer.absolutingImageURl(data.cfg.image)}
                    setEditMode={this.props.setEditMode}
                    onEditService={() => this.onEditService(service, 'service')}
                    onDeleteService={() => this.onDeleteService(service)}
                  />
                )}
                <Divider />
              </>
            ))}
          {data.productsbreakup &&
            data.productsbreakup.map(
              (product, index) =>
                (product.workcategory?.sow === this.props.scopeFilterData.value ||
                  this.props.scopeFilterData.value === '') && (
                  <ComparisionBoqBreakupRow
                    key={index}
                    service={product}
                    estimateId={this.props.estimateId}
                    is_editable={false}
                    elevationImage={displayer.absolutingImageURl(product.remark)}
                    setEditMode={this.props.setEditMode}
                    onEditService={() => this.onEditService(product, 'product')}
                    onDeleteService={() => this.onDeleteService(product)}
                  />
                )
            )}
        </Grid>
      </Grid>
    );
  }
}

export default ComparisonBoqRowComponent;
