import React, { Component } from 'react';
import { TextField, FormControl, InputLabel } from '@mui/material';
/* 
    How to render 
   <LoadTextField
      record={record}
      name={record.dbname}
      style={{ width: '90%' }}
      type={'text'}
      placeHolder={`Filter ${record.label}`}
      multiline={false}
      defaultValue={this.props.filterValues[record.dbname] || ''}
      callback={(event) => this.props.filterData(event)}
    />

    *** Defination of fields:
    record: Object {label:"Value to be Presented", dbName:"name in data object",ftype:"to have an object of format type"} 
    name:specifies the name of the field in database
    type="default value is text , can have values[text|number]"
    style= "*Not Mandatory* if any custom styling is to be called"
    placeHolder: "Text to be diaplayed as place Holder"
    multiline="default value is false ,make true if you want textarea to be displayed"
    defaultValue: The value to be selected by default
    callback: "Event to occur on change of dropdown"
   
 */

class LoadTextField extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {this.props.disabled ? (
          <p>{this.props.defaultValue}</p>
        ) : (
          <FormControl variant="outlined" sx={{ m: 1, width: '100%' }}>
            <InputLabel>{this.props.placeholder}</InputLabel>
            <TextField
              style={
                this.props.style && this.props.style.length > 1
                  ? this.props.style
                  : { width: '90%' }
              }
              multiline={this.props.multiline}
              rows={4}
              type={this.props.type}
              // expandable={true}
              label={this.props.placeHolder}
              name={this.props.name}
              defaultValue={this.props.defaultValue}
              onChange={(e) => this.props.callback(e)}
              margin="normal"
            />
          </FormControl>
        )}
      </>
    );
  }
}

export default LoadTextField;
