import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import FileReport from './views/leadView/components/FileReport';
import QualifiedLeads from './views/presales/QualifiedLeads';
import PresalesLeads from './views/presales/PresalesLeads';
import AddNewLead from './views/presales/AddLead';
import ProjectView from './views/presales/ProjectView';
import EstimateView from './views/Invoice/EstimateView';
import ModularEstimate from './views/Invoice/ModularEstimates';
import PresalesInspiration from './views/Inspiration/Inspiration';
import InspirationDisplay from './views/Inspiration/InspirationDisplay';
import DopLeads from './views/dop/DopLeads';
import EditEstimates from './views/Invoice/components/EditEstimates';
import EditModularEstimates from './views/Invoice/components/EditModularEstimates';
import CfgDetail from './views/Invoice/CfgDetail';
import CombineBoq from './views/Invoice/CombineBoq';
import EditWorkscope from './views/leadView/EditWorkscope';
import ProjectEstimateView from './views/presales/ProjectEstimateView';
import BoqComparision from './views/Invoice/BoqComparision';

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('app/views/sessions/JwtRegister')));
const ForgotPassword = Loadable(lazy(() => import('app/views/sessions/ForgotPassword')));
const Workscope = Loadable(lazy(() => import('app/views/leadView/Workscope')));

// echart page
//const AppEchart = Loadable(lazy(() => import('app/views/charts/echarts/AppEchart')));

// dashboard page
const Analytics = Loadable(lazy(() => import('app/views/dashboard/Analytics')));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      //...materialRoutes,
      // dashboard route
      {
        path: '/dashboard/default',
        element: <Analytics />,
        auth: authRoles.admin
      },

      {
        path: '/file/report',
        element: <FileReport />,
        auth: authRoles.admin
      },
      {
        path: '/leads/qualified',
        element: <QualifiedLeads />,
        auth: authRoles.admin
      },
      {
        path: '/leads/presales',
        element: <PresalesLeads />,
        auth: authRoles.admin
      },
      {
        path: '/leads/dop',
        element: <DopLeads />,
        auth: authRoles.admin
      },
      {
        path: '/add/newlead/:id',
        element: <AddNewLead />,
        auth: authRoles.admin
      },
      {
        path: '/project/view/:id',
        element: <ProjectView />,
        auth: authRoles.admin
      },
      {
        path: '/estimate/change/:id',
        element: <EditEstimates />,
        auth: authRoles.admin
      },
      {
        path: '/estimate/:estimateid/:areaId/CFG/:id',
        element: <CfgDetail />
      },
      {
        path: '/modular/estimate/change/:id',
        element: <EditModularEstimates />,
        auth: authRoles.admin
      }
      // {
      //   path: '/contracts/dip',
      //   element: <ListView />,
      //   auth: authRoles.admin
      // },
      // {
      //   path: '/contracts/execution',
      //   element: <ListView />,
      //   auth: authRoles.admin
      // },
      // {
      //   path: '/contracts/handover',
      //   element: <ListView />,
      //   auth: authRoles.admin
      // },
      // {
      //   path: '/bids/inactive',
      //   element: <ListView />,
      //   auth: authRoles.admin
      // },
      // {
      //   path: '/arena/productList',
      //   element: <ProductList />,
      //   auth: authRoles.admin
      // },
      // {
      //   path: '/arena/products/add',
      //   element: <AddProduct />,
      //   auth: authRoles.admin
      // },
      // {
      //   path: '/arena/product/view',
      //   element: <ProductView />,
      //   auth: authRoles.admin
      // },
      // {
      //   path: '/treeView',
      //   element: <TreeView />,
      //   auth: authRoles.admin
      // },

      // // e-chart rooute
      // {
      //   path: '/charts/echarts',
      //   element: <AppEchart />,
      //   auth: authRoles.editor
      // }
    ]
  },

  // session pages route
  { path: '/session/404', element: <NotFound /> },
  { path: '/session/signin', element: <JwtLogin /> },
  { path: '/session/signup', element: <JwtRegister /> },
  { path: '/session/forgot-password', element: <ForgotPassword /> },

  {
    path: '/project/modular/estimate/:id',
    element: <ModularEstimate />
  },
  {
    path: '/project/estimate/display/:id',
    element: <EstimateView />
  },
  {
    path: '/project/estimateview/:id/:token',
    element: <ProjectEstimateView />
  },
  {
    path: '/bid/workscope/:id',
    element: <Workscope />
  },
  {
    path: '/bid/edit/workscope/:id',
    element: <EditWorkscope />
  },
  {
    path: '/:type/presentation/:id',
    element: <InspirationDisplay />
  },
  {
    path: '/areainspiration/:inspirationId/:id',
    element: <PresalesInspiration />
  },
  {
    path: '/combine/estimates/:id',
    element: <CombineBoq />
  },
  {
    path: '/combine/estimates/:id/:token',
    element: <CombineBoq />
  },
  {
    path: '/estimate/comparasion/:id/:type',
    element: <BoqComparision />
  },

  { path: '/', element: <Navigate to="dashboard/default" /> },
  { path: '/', element: <Navigate to="arenaProducts/productView" /> },
  { path: '/', element: <Navigate to="arena/addProduct" /> },
  { path: '*', element: <NotFound /> }
];

export default routes;
