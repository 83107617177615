import React, { Component } from 'react';
import { Grid, Paper } from '@mui/material';
import { Box, Button, Chip, Divider } from '@mui/material';
import RowComponent from './components/RowComponent.jsx';
import config from '../../constants/index.jsx';
import Auth from 'app/helpers/authorization';
import Postie from 'app/helpers/postie';
import displayer from 'app/helpers/displayer';
import HeaderDetails from './components/Header.jsx';
import FooterBox from './components/Footer.jsx';
import Create from '../crudoperations/create.jsx';

class EstimateView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      estimateId: '',
      scopeFilter: {
        name: '',
        value: ''
      }
    };
  }

  componentDidMount = () => {
    const queryParams = displayer.getUrlParams('/project/estimate/display/');
    //console.log(queryParams);
    this.getProjectDetails(queryParams[0]);
  };

  getProjectDetails = async (id) => {
    let userLogged = await Auth.isUserLoggedIn();

    let url = config.BASE_API_URL + 'estimate/' + id;
    let jsonData = await Postie.fetchUrl(url);
    //console.log('sata', jsonData);
    if (jsonData) {
      this.setState(
        {
          ...this.state,
          data: jsonData,
          estimateId: jsonData.id,
          dataLoaded: true, // Set dataLoaded to true when data is loaded
          is_editable: userLogged && jsonData.status == 0
        },
        async function () {
          console.log('Estimate View', this.state.estimateId);
          if (this.state.is_editable) {
            let url = config.BASE_API_URL + 'crud/cfg/1/NzQtMTcwMDk5OTYzNw==';
            let postData = {};
            let jsonData = await Postie.sendAsynchrnousCall(postData, url);
            if (jsonData.status === 'success') {
              this.setState({ ...this.state, cfgData: jsonData.data }, function () {
                console.log('cfgdata', this.state.cfgData);
              });
            }
          }
        }
      );
    }
  };

  createFunctionality = async (data) => {
    console.log('createFunctionality', data);
    let postData = data;
    let areaId = data.area_id;
    delete postData['area_id'];
    postData['authToken'] = localStorage.getItem(config.Token_name);
    let serviceUrl = config.BASE_API_URL + this.state.cfgData.apiCalls['create'].url;
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      console.log('reply', reply);
      Postie.openLink(
        '/estimate/' +
          this.state.estimateId +
          '/' +
          areaId +
          '/CFG/' +
          reply.data.data.records[0].id
      );
      if (this.state.cfgData.apiCalls['create'].postSubmit) {
        this.postCreate(postData, areaId);
      }
      this.setState({ ...this.state, Data: reply.data.data, updatedstate: true });
    } else {
      Postie.showAlert(reply);
    }
  };
  postCreate = async (postData, areaId) => {
    let serviceUrl = config.BASE_API_URL + this.state.apiCalls['create'].postSubmit;
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    console.log('reply', reply);
    if (reply.status === 'success') {
      console.log('reply', reply);
      Postie.openLink('/estimate/createCFG/' + areaId + '/' + reply.data.id);
    } else {
      Postie.showAlert(reply);
    }
  };

  render() {
    const { data, dataLoaded, estimateId } = this.state;
    console.log('Estimate View Data', data, estimateId);
    // Only render if data is loaded
    if (!dataLoaded) {
      return (
        <Grid
          container
          md={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Center the loading message
            height: '100vh' // Center vertically
          }}
        >
          <h3>Loading data...</h3>
        </Grid>
      );
    }

    return (
      <Grid container style={{ margin: '10px' }}>
        <HeaderDetails boq_name={data.label} data={data.project} budget={data.budget} />
        <Box style={{ margin: '10px', padding: '0 40px' }}>
          Attachment “A” – Pricing Detail and Breakout.
        </Box>
        <Grid container spacing="2">
          {data?.sections &&
            data.sections.map(
              (area, index) =>
                area.services.length > 0 && (
                  <Grid item md={12} xs={12}>
                    <Paper
                      key={index}
                      style={{ margin: '40px', padding: '40px', backgroundColor: '#FAF3F0' }}
                    >
                      {area.services.length > 0 && (
                        <div>
                          {/* {this.state.is_editable &&
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ float: 'right' }}
                          onClick={() =>
                            Postie.navigateLink('/estimate/change/' + this.state.estimateId)
                          }
                        >
                          Edit
                        </Button>
                        this.state.cfgData && (
                          <Create
                            heading={'CFG'}
                            fields={this.state?.cfgData?.fields}
                            apiCall={this.state?.cfgData?.apiCalls['create']}
                            //handleFile={(file, type) => this.props.handleFile(file, type)}
                            add={(data) => this.createFunctionality(data)}
                            defaultValue={{ area_id: area.id }}
                            filters={this.state.filterType}
                          />
                        )
                        } */}
                          <h3>#{area.project_display_name}</h3>
                        </div>
                      )}
                      {area.services.length > 0 &&
                        area.services.map((cfg, index) => (
                          <RowComponent
                            key={index}
                            data={cfg}
                            area={area.project_display_name}
                            estimateId={this.state.estimateId}
                            is_editable={false}
                            scopeFilterData={{
                              name: '',
                              value: ''
                            }}
                            setEditMode={false}
                            fitoutCategories={''}
                            modularCategories={''}
                            furnitureCategories={''}
                            projectId={data.project_id}
                            // areaImages={this.getViewImages(sections[area]['area']['views'])}
                            // serviceEstimateData={sections[area]['area']}
                            callBack={() => this.callBackFunction()}
                          />
                        ))}
                    </Paper>
                  </Grid>
                )
            )}
        </Grid>
        <Grid container style={{ backgroundColor: '#D4E2D4', margin: '10px', margin: '0 40px' }}>
          <Grid item md={9}></Grid>
          <Grid item md={3}>
            <Grid container>
              <Grid item xs={6} sm={6} md={6} style={{ marginBottom: '5px' }}>
                <h4>Net Cost : </h4>
              </Grid>
              <Grid item xs={6} sm={6} md={6} style={{ marginBottom: '5px', paddingRight: '20px' }}>
                <h4 style={{ textAlign: 'right' }}>
                  &#8377;{displayer.amountReadable(data.net_cost)}
                </h4>
              </Grid>

              {data.discount > 0 && (
                <Grid item xs={6} sm={6} md={6} style={{ marginBottom: '5px' }}>
                  <h4>
                    Discount (
                    {data.discount_type === 'fixed' ? 'Fixed' : data.discount_percent + '%'}) :
                  </h4>
                </Grid>
              )}
              {data.discount > 0 && (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  style={{ marginBottom: '5px', paddingRight: '20px' }}
                >
                  <h4 style={{ textAlign: 'right' }}>
                    &#8377;{displayer.amountReadable(data.discount)}
                  </h4>
                </Grid>
              )}

              <Grid item xs={6} sm={6} md={6} style={{ marginBottom: '5px' }}>
                <h4>Project Handling Fees:</h4>
              </Grid>
              <Grid item xs={6} sm={6} md={6} style={{ marginBottom: '5px', paddingRight: '20px' }}>
                <h4 style={{ textAlign: 'right' }}>
                  {' '}
                  &#8377;{displayer.amountReadable(data.consultation_cost)}
                </h4>
              </Grid>

              <Grid item xs={6} sm={6} md={6} style={{ marginBottom: '5px' }}>
                <h3>Total :</h3>
              </Grid>
              <Grid item xs={6} sm={6} md={6} style={{ marginBottom: '5px', paddingRight: '20px' }}>
                <h3 style={{ textAlign: 'right' }}>
                  &#8377; {displayer.amountReadable(data.budget)}
                </h3>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <FooterBox
          terms={data.project.organization.terms}
          payment_terms={data.payment_terms}
          organization={data.project.organization}
        />
      </Grid>
    );
  }
}

export default EstimateView;
