import React, { Component } from 'react';
import { Grid, Table, TableBody, TableCell, TableRow, TableHead } from '@mui/material';
import { Button } from '@mui/material';
import Postie from 'app/helpers/postie';
import displayer from 'app/helpers/displayer.jsx';
import config from '../../../constants/index.jsx';
import { WhatsApp } from '@mui/icons-material';

class InspirationList extends Component {
  constructor(props) {
    super(props);
  }

  shareOnWhatsapp = (record) => {
    let url = 'https://web.whatsapp.com/send?text=';
    let text =
      '*' + record.stage.tolowercase === 'presales'
        ? record.stage + ' Design'
        : record.type.tolowercase === 'renders'
        ? 'First Render'
        : record.type.tolowercase === 'render_final'
        ? 'Final Render'
        : 'Design';
    text +=
      ' inspiration for Project (' +
      record.project_id +
      ') version-' +
      record.version +
      '*' +
      record.short_url;
    url += text;
    this.forwardInspiration(record.type, record.id, url);
  };
  async forwardInspiration(type, inspId, url) {
    let postData = {
      authToken: localStorage.getItem(config.Token_name),
      inspiration_id: inspId,
      forwarded_by: type
    };
    var servicesUrl = config.BASE_API_URL + 'project/inspiration/forward';

    let json = await Postie.sendAsynchrnousCall(postData, servicesUrl);
    console.log('forwardInspiration', json);
    if (json.status === 'success') {
      let stage = await displayer.updateProjectstage(
        this.props.projectId,
        this.props.status,
        'presales_inspiration_shared'
      );
      if (stage) {
        Postie.openLink(url);
        this.props.callback();
      }
    } else {
      Postie.showAlert(json);
    }
  }

  sendId = async (id) => {
    let postData = {
      inspiration_id: id,
      authToken: localStorage.getItem(config.Token_name)
    };
    var servicesUrl = config.BASE_API_URL + 'project/inspiration/createlink';
    let json = await Postie.sendAsynchrnousCall(postData, servicesUrl);
    Postie.showAlert(json);
    if (json.status === 'success') {
      this.props.callback();
    }
  };

  render() {
    return (
      <Grid container spacing={2} style={{ margin: '10px' }}>
        <Grid item md={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <h5>ID</h5>
                </TableCell>
                <TableCell>
                  <h5>Type</h5>
                </TableCell>
                <TableCell>
                  <h5>Url</h5>
                </TableCell>
                <TableCell>
                  <h5>Action</h5>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.data.length > 0 &&
                this.props.data.map((record, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <h5>{record?.id}</h5>
                    </TableCell>
                    <TableCell>
                      <h5>{record?.stage}</h5>
                    </TableCell>
                    <TableCell>{record?.short_url ? record?.short_url : record?.url}</TableCell>

                    <TableCell>
                      {record?.url && record?.short_url ? (
                        <Button
                          color="success"
                          variant="contained"
                          endIcon={<WhatsApp />}
                          onClick={() => this.shareOnWhatsapp(record)}
                        >
                          Share on
                        </Button>
                      ) : (
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => this.sendId(record.id)}
                        >
                          Generate short Url
                        </Button>
                      )}

                      {record?.url && (
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ marginLeft: '10px' }}
                          onClick={() => Postie.openLink('/area/presentation/' + record.url)}
                        >
                          View
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }
}

export default InspirationList;
