import React, { Component } from 'react';
import { Button } from '@mui/material';
/*
<FileView
  id={`file-${item.id}`}
  url={displayer.absolutingImageURl(item[record['dbname']])}
/>
 */
class FileView extends Component {
  constructor(props) {
    super(props);
  }
  OpenFile(url) {
    var win = window.open(url, '_blank');
    if (win) {
      //Browser has allowed it to be opened
      win.focus();
    } else {
      //Browser has blocked it
      alert('Please allow popups for this website');
    }
  }
  fileType(url) {
    if (
      url.toLowerCase().indexOf('.jpg') > -1 ||
      url.toLowerCase().indexOf('.png') > -1 ||
      url.toLowerCase().indexOf('.jpeg') > -1 ||
      url.toLowerCase().indexOf('.webp') > -1 ||
      url.toLowerCase().indexOf('.jfif') > -1
    ) {
      return 'image';
    } else {
      let file = url.split('.');
      return file[file.length - 1];
    }
  }
  handleDownload = (Url) => {
    console.log('handleDownload', Url);
    // Replace 'your_file_url' with the actual URL of your file
    const fileUrl = Url;

    // Replace 'desired_filename.txt' with your desired file name
    const fileName = 'file_' + this.props.id;

    // Create a link element
    const link = document.createElement('a');

    // Set the href attribute to the file URL
    link.href = fileUrl;

    // Set the download attribute with the desired file name
    link.download = fileName;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger a click event on the link
    link.click();

    // Remove the link from the document body
    document.body.removeChild(link);
  };
  render() {
    return (
      <div className="img-thumbnail" onClick={() => this.OpenFile(this.props.url)}>
        {this.fileType(this.props.url) === 'image' ? (
          <img
            src={this.props.url}
            style={this.props.style ? this.props.style : { width: '100%' }}
            alt={this.props.url}
          />
        ) : (
          <p className="text-center p-2">
            <Button variant="outlined">{this.fileType(this.props.url).toUpperCase()}</Button>
          </p>
        )}
      </div>
    );
  }
}
export default FileView;
