import React, { Component } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import FileUpload from './fileUpload';
import config from '../../constants/index';
import Postie from 'app/helpers/postie';

/*
<UploadFile 
  Filename={"logo"}
  displayTosection={false}
  areaName={""}
  type={"logo"}
  projectId={0}
  areaId={0}

  />

 */

const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  '& .closeButton': {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <DialogTitleRoot disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className="closeButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleRoot>
  );
};

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  '&.root': { padding: theme.spacing(2) }
}));

class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleFileUpload = (file) => {
    let response = JSON.parse(file.xhr.response);
    if (response.status === 'success') {
      this.props.callback(response);
    }
    {
      this.props.showAlert && Postie.showAlert(response);
    }
    this.handleClose();
  };
  render() {
    return (
      <div>
        <Button
          variant={this.props.variant ? this.props.variant : 'outlined'}
          color={this.props.color ? this.props.color : 'secondary'}
          onClick={this.handleClickOpen}
        >
          Upload {this.props.filename}
        </Button>

        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          maxWidth="md" // Set the maxWidth to control the width of the dialog box
          fullWidth // This ensures that the dialog takes up the full width
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Upload {this.props.filename}{' '}
            {this.props.displayTosection ? `to ${this.props.areaName}` : null}
          </DialogTitle>

          <DialogContent dividers></DialogContent>
          <FileUpload
            type={this.props.type}
            projectId={this.props.projectId}
            saveURL={
              config.BASE_API_URL +
              'project/files/upload/' +
              this.props.type +
              '/' +
              this.props.projectId +
              '/' +
              this.props.areaId
            }
            callback={(file) => this.handleFileUpload(file)}
            showAlert={this.props.showAlert}
          />
        </Dialog>
      </div>
    );
  }
}

export default UploadFile;
