import React, { Component } from 'react';
import TeamInfo from 'app/views/common/components/TeamInfo';
import {
  Grid,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  Select,
  MenuItem
} from '@mui/material';
import displayer from 'app/helpers/displayer';
import Postie from 'app/helpers/postie';
import Auth from 'app/helpers/authorization';
import config from 'app/constants/index';
import {
  Discount,
  Edit,
  RemoveRedEye,
  ThumbDownAlt,
  ThumbUpAlt,
  RemoveCircleOutlineSharp,
  WhatsApp
} from '@mui/icons-material';
import swal from 'sweetalert';
import FileView from 'app/views/bids/components/fileView';
import Authorization from 'app/helpers/authorization';

class Estimates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
      Roles: '',
      updatedRole: '',
      addedColaborator: '',
      openChangeBoq: false,
      boqchange: '',
      openAddDiscount: '',
      discount_type: 'fixed'
    };
  }

  openEditEstimate = (projectId, estimateId, is_modular) => {
    let url = config.piqqe_url + 'my-projects/boq' + config.piqqe_ext + '?index=' + projectId;
    if (is_modular) {
      url = config.BASE_URL + '/project/modular/estimate/' + estimateId;
    }
    console.log(url);
    Postie.openLink(url);
  };
  openEstimates = (id, is_modular) => {
    let url = config.BASE_URL + 'project/estimate/display/' + id;
    if (is_modular) {
      //url = config.BASE_URL + 'project/modular/estimate/' + id;
      url = config.BASE_URL + 'project/estimate/display/' + id;
    }
    console.log(url);
    Postie.openLink(url);
  };

  shareOnWhatsapp = (record) => {
    let url = 'https://web.whatsapp.com/send?text=';

    let type =
      parseInt(record.estimate_type) === 1
        ? 'Presales BOQ'
        : parseInt(record.estimate_type) === 2
        ? 'DOP BOQ'
        : 'Execution BOQ';
    console.log('shareOnWhatsapp', record.estimate_type, type);
    let text = '* ' + type + ' for Project (' + record.project_id + ') *' + record.invoice_url;
    url += text;
    this.forwardEstimates('whatsapp', record.project_id, record.id, record.estimate_type, url);
  };

  async forwardEstimates(type, projectId, estimateId, estimate_type, url) {
    let postData = {
      authToken: localStorage.getItem(config.Token_name),
      project_id: projectId,
      estimate_id: estimateId,
      forwarded_by: type
    };
    var servicesUrl = config.BASE_API_URL + 'estimate/forward';

    let json = await Postie.sendAsynchrnousCall(postData, servicesUrl);
    //console.log('forwardEstimates', json);
    if (json.status === 'success') {
      let stage = await displayer.updateProjectstage(
        projectId,
        this.props.projectStatus,
        parseInt(estimate_type) === 1 ? 'presales_boq_shared' : 'dop_boq_shared'
      );
      if (stage) {
        Postie.openLink(url);
        this.props.callback();
      }
    } else {
      Postie.showAlert(json);
    }
  }

  onApproveBoq = async (estimate_id, estimate_type, project_id) => {
    swal({
      title: 'Are you sure?',
      text: 'By clicking Ok you will confirm that BOQ is Approved and Project is in DIP Stage',
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then(async (approved) => {
      if (approved) {
        let postData = {
          estimate_id: estimate_id,
          project_id: project_id,
          authToken: localStorage.getItem(config.Token_name)
        };
        var servicesUrl = config.BASE_API_URL + 'estimate/approve';
        let json = await Postie.sendAsynchrnousCall(postData, servicesUrl);
        if (json.status === 'success') {
          // let stage = await displayer.updateProjectstage(
          //   project_id,
          //   this.props.projectStatus,
          //   parseInt(estimate_type) === 1 ? 'presales_boq_approved' : 'dop_boq_approved'
          // );
          // if (stage) {
          //   this.props.callback();
          // }
          this.props.callback();
        } else {
          Postie.showAlert(json);
        }
      }
    });
  };
  async sendToClient(project_id, estimate_type, estimate_id) {
    let postData = {
      project_id: project_id,
      authToken: localStorage.getItem(config.Token_name),
      estimate_id: estimate_id
    };
    var servicesUrl = config.BASE_API_URL + 'estimate/generate';
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    };
    const resp = await fetch(servicesUrl, requestOptions);
    const json = await resp.json();
    if (json.status === 'success') {
      let stage = await displayer.updateProjectstage(
        project_id,
        this.props.projectStatus,
        parseInt(estimate_type) === 1 ? 'presales_boq_created' : 'dop_boq_created'
      );
      console.log('stage', stage);
      if (stage) {
        this.props.callback();
      }
    } else {
      swal('Error', 'Some thing went wrong!!', 'error');
    }
  }

  openChangeBoq = (projectId, estimateId, estimate_type) => {
    this.setState({
      openChangeBoq: true,
      projectId: projectId,
      estimateId: estimateId,
      estimate_type: estimate_type
    });
  };
  closeChangeBoq = () => {
    this.setState({
      openChangeBoq: false
    });
  };

  onChangeBoq = (event) => {
    const { name, value } = event.target;
    //console.log('submitBoqChange', value);
    this.setState(
      {
        ...this.state,
        boqchange: value
      },
      function () {
        console.log('boqchange', this.state.boqchange);
      }
    );
  };
  submitBoqChange = async () => {
    const { boqchange, projectId, estimateId, estimate_type } = this.state;
    let postData = {
      estimate_id: estimateId,
      project_id: projectId,
      reason: boqchange,
      authToken: localStorage.getItem(config.Token_name)
    };
    console.log('postData', postData);
    var servicesUrl = config.BASE_API_URL + 'estimate/change';
    console.log('postData', postData, servicesUrl);
    let json = await Postie.sendAsynchrnousCall(postData, servicesUrl);
    if (json.status === 'success') {
      this.closeChangeBoq();
      let stage = await displayer.updateProjectstage(
        projectId,
        this.props.projectStatus,
        parseInt(estimate_type) === 1 ? 'presales_boq_change' : 'dop_boq_change'
      );
      console.log('stage', stage);
      if (stage) {
        this.props.callback();
      }
    }
    //else {
    //   Postie.showAlert(json);
    // }
  };
  openAddDiscount = (projectId, estimateId) => {
    this.setState({
      openAddDiscount: true,
      projectId: projectId,
      estimateId: estimateId
    });
  };
  closeAddDiscount = () => {
    this.setState({
      openAddDiscount: false
    });
  };

  handleApplyCoupen = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        ...this.state,
        [name]: value
      },
      function () {
        console.log('Apploed Coupon', this.state);
      }
    );
  };

  submitApplyDiscount = async () => {
    const { projectId, estimateId, discount_type, discount_value } = this.state;
    let postData = {
      estimate_id: estimateId,
      project_id: projectId,
      discount_type: discount_type,
      discount_value: discount_value,
      authToken: localStorage.getItem(config.Token_name)
    };
    var servicesUrl = config.BASE_API_URL + 'coupon/apply';
    console.log('postData', postData, servicesUrl);
    let json = await Postie.sendAsynchrnousCall(postData, servicesUrl);
    if (json.status === 'success') {
      this.closeAddDiscount();
      this.props.callback();
    } else {
      Postie.showAlert(json);
    }
  };
  ResetDiscount = async (projectId, estimateId) => {
    swal({
      title: 'Are you sure?',
      text: 'By clicking Ok you will confirm that you want to remove the discount',
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then(async (approved) => {
      if (approved) {
        this.setState(
          {
            ...this.state,
            discount_type: 'percent',
            discount_value: 0,
            projectId: projectId,
            estimateId: estimateId
          },
          function () {
            this.submitApplyDiscount();
          }
        );
      }
    });
  };

  render() {
    const { record } = this.props;
    console.log(
      'Estimate record',
      this.props.data,
      this.props.completeData,
      Authorization.getUserProjectRole(this.props.completeData?.colabrators).role
    );
    return (
      <Grid container spacing="2">
        <Grid item md="12">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <h5>label</h5>
                </TableCell>
                <TableCell>
                  <h5>status</h5>
                </TableCell>
                <TableCell>
                  <h5>Net Cost</h5>
                </TableCell>
                <TableCell>
                  <h5>Consultation Cost </h5>
                </TableCell>
                <TableCell>
                  <h5>Discount </h5>
                </TableCell>
                <TableCell>
                  <h5>Budget </h5>
                </TableCell>
                <TableCell>
                  <h5>Action</h5>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.data &&
                this.props.data.map((record) => (
                  <TableRow key={record}>
                    <TableCell>
                      <h5>{record.label}</h5>
                      <p>#{record.id}</p>
                    </TableCell>
                    <TableCell>
                      <Button variant="outlined" color="primary" style={{ marginBottom: '5px' }}>
                        {record.estimate_type === 1
                          ? 'Presales'
                          : record.estimate_type === '2'
                          ? 'DOP'
                          : 'Execution'}
                      </Button>
                      <Button
                        style={{ marginLeft: '2px' }}
                        variant="contained"
                        color={
                          record.status === 1
                            ? 'primary'
                            : record.status === 0
                            ? 'warning'
                            : record.status === 2
                            ? 'success'
                            : 'error'
                        }
                      >
                        {record.status === 1
                          ? 'Shared'
                          : record.status === 0
                          ? 'Not Shared'
                          : record.status === 2
                          ? 'Approved'
                          : record.status === 3
                          ? 'Change Requested'
                          : 'Rejected'}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <text>{record.net_cost}</text>
                    </TableCell>
                    <TableCell>
                      <text>{record.consultation_cost}</text>
                    </TableCell>

                    <TableCell>
                      <text>{record.discount}</text>
                    </TableCell>
                    <TableCell>
                      <text>{record.budget}</text>
                    </TableCell>
                    <TableCell>
                      {record.invoice_url ? (
                        <Button
                          color="success"
                          variant="contained"
                          endIcon={<WhatsApp />}
                          onClick={() => this.shareOnWhatsapp(record)}
                        >
                          Share on
                        </Button>
                      ) : (
                        record.status === 0 &&
                        Authorization.getUserProjectRole(this.props.completeData?.colabrators)
                          .role != 'client' && (
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={() =>
                              this.sendToClient(record.project_id, record.estimate_type, record.id)
                            }
                          >
                            Generate Url
                          </Button>
                        )
                      )}
                      {record.status === 1 && (
                        <Button
                          style={{ margin: '2px' }}
                          color="success"
                          variant="contained"
                          endIcon={<ThumbUpAlt />}
                          onClick={() =>
                            this.onApproveBoq(record.id, record.estimate_type, record.project_id)
                          }
                        >
                          Approve
                        </Button>
                      )}

                      {record.status === 1 && (
                        <Button
                          color="primary"
                          variant="contained"
                          endIcon={<ThumbDownAlt />}
                          onClick={() =>
                            this.openChangeBoq(record.project_id, record.id, record.estimate_type)
                          }
                        >
                          Change
                        </Button>
                      )}
                      {record.status === 3 && (
                        <FileView
                          key={'estimatesketchupLayout'}
                          id={this.props?.skpfile?.id}
                          url={displayer.absolutingImageURl(this.props?.skpfile?.url)}
                        />
                      )}
                      {record.status === 0 &&
                      Authorization.getUserProjectRole(this.props.completeData?.colabrators).role !=
                        'client' &&
                      parseInt(record.discount, 10) <= 0 ? (
                        <Button
                          style={{ marginTop: '2px' }}
                          color="primary"
                          variant="contained"
                          endIcon={<Discount />}
                          onClick={() => this.openAddDiscount(record.project_id, record.id)}
                        >
                          Discount
                        </Button>
                      ) : (
                        record.status === 0 &&
                        Authorization.getUserProjectRole(this.props.completeData?.colabrators)
                          .role != 'client' && (
                          <Button
                            style={{ marginTop: '2px' }}
                            color="error"
                            variant="contained"
                            endIcon={<RemoveCircleOutlineSharp />}
                            onClick={() => this.ResetDiscount(record.project_id, record.id)}
                          >
                            Discount
                          </Button>
                        )
                      )}
                      {record.status === 0 &&
                        Authorization.getUserProjectRole(this.props.completeData?.colabrators)
                          .role != 'client' && (
                          <Button
                            style={{ marginTop: '2px' }}
                            color="warning"
                            variant="outlined"
                            endIcon={<Edit />}
                            onClick={() =>
                              this.openEditEstimate(
                                record.project_id,
                                record.id,
                                this.props.isModular
                              )
                            }
                          >
                            Edit
                          </Button>
                        )}

                      {
                        <Button
                          color="primary"
                          variant="outlined"
                          style={{ marginTop: '2px' }}
                          endIcon={<RemoveRedEye />}
                          onClick={() => this.openEstimates(record.id, this.props.isModular)}
                        >
                          View
                        </Button>
                      }
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Grid>
        <Dialog open={this.state.openChangeBoq} onClose={this.closeChangeBoq}>
          <DialogTitle>Please Enter the Reason for updating the BOQ</DialogTitle>
          <DialogContent>
            <TextField
              label="Reason"
              name="Reason"
              onChange={(e) => this.onChangeBoq(e)}
              fullWidth
              required // Mark the field as required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeChangeBoq} color="primary">
              Close
            </Button>
            <Button onClick={() => this.submitBoqChange()} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.openAddDiscount} onClose={this.closeAddDiscount}>
          <DialogTitle>Please Enter the Discount Value</DialogTitle>
          <DialogContent>
            <FormControl variant="outlined" style={{ width: '30%' }}>
              <Select
                defaultValue={'fixed'}
                name="discount_type"
                onChange={(e) => this.handleApplyCoupen(e)}
              >
                <MenuItem value="fixed">Fixed</MenuItem>
                <MenuItem value="percent">Percent</MenuItem>
              </Select>
            </FormControl>

            <TextField
              type="number"
              label="Discount Value"
              name="discount_value"
              onChange={(e) => this.handleApplyCoupen(e)}
              required // Mark the field as required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeAddDiscount} color="primary">
              Close
            </Button>
            <Button onClick={() => this.submitApplyDiscount()} color="primary">
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default Estimates;
