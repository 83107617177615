import React, { Component } from 'react';
import { Grid, TextField, TableCell, TableRow, TableBody, Table } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import Postie from 'app/helpers/postie';
import config from '../../constants/index.jsx';
import swal from 'sweetalert';
import FileView from '../bids/components/fileView';
import displayer from 'app/helpers/displayer';
import UploadFile from '../files/uploadFile';
import ConnectModule from './connectModule';
import Auth from 'app/helpers/authorization';
import NexusPagination from './components/nexuspagination';
import LoadCheckBox from './components/LoadCheckBox';
import LoadTextField from './components/LoadTextField';
import LoadButton from './components/LoadButton';
import LoadRadioGroup from './components/LoadRadioGroup';
import LoadDropDown from './components/LoadDropdown.jsx';
import LoadCheckList from './components/LoadCheckList.jsx';
import ConnectionDataTable from './components/ConnectionDataTable.jsx';
import $ from 'jquery';
class Connection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Fields: this.props.fields,
      Data: this.props.data,
      apiCalls: this.props.apiCalls,
      filterValues: {},
      connection: {
        makeConnection: false,
        data: [],
        editedData: []
      }
    };
  }
  componentDidMount() {
    console.log('connection is called', this.props);
  }

  handleFilterChange = (event, fieldName) => {
    const { filterValues } = this.state;
    this.setState({
      filterValues: {
        ...filterValues,
        [fieldName]: event.target.value
      }
    });
  };
  handleInputChange = (e) => {
    let selected = [];
    $('[name="' + e.target.name + '"]:checked').each((elem, input) => {
      selected.push(input.value);
    });
    let value = selected.join('|');
    console.log('value', value);
    if (value === '') {
      value = 'None';
    }
    let sarr = e.target.name.split('_');
    let elem = { target: { name: sarr[0], value: value } };
    this.handleFunction(elem, 'update', parseInt(sarr[1].substring(0, sarr[1].length - 2)));
  };

  applyFilter = (data) => {
    const { filterValues } = this.state;
    if (Object.keys(filterValues).length === 0) {
      return data;
    }
    return data.filter((item) =>
      Object.keys(filterValues).every((fieldName) =>
        item[fieldName].toString().toLowerCase().includes(filterValues[fieldName].toLowerCase())
      )
    );
  };
  handleFunction = (event, type, identifier) => {
    console.log('handleFunction called with type:', type);
    if (type.length > 0) {
      event.target['identifier'] = identifier;
      switch (type) {
        case 'update':
          console.log(this.state.apiCalls['update']);
          this.updateFunctionality(this.state.apiCalls['update'], event);
          break;
        case 'copy':
          this.props.callback(identifier);
          break;
        case 'delete':
          this.deleteFunctionality(this.state.apiCalls['update'], identifier);
          break;
        default:
          console.log('default Block Called');
          Postie.navigateLink(this.state.apiCalls[type]);
          break;
      }
    }
  };

  updateFunctionality = async (apiCall, event) => {
    console.log('event', event);
    if (apiCall.type === 'post') {
      if (event.target.value !== '' && event.target.value !== '\n') {
        let postData = this.generatePostData(apiCall, event);
        console.log('postData', postData);
        let serviceUrl = config.BASE_API_URL + apiCall.url;
        let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
        if (reply.status === 'success') {
          this.props.callback();
        } else {
          Postie.showAlert(reply);
        }
      }
    } else {
    }
  };
  deleteFunctionality = (apiCall, identifier) => {
    swal({
      title: 'Are you sure?',
      text: 'The connection would be removed',
      icon: 'warning',
      buttons: ['No, cancel it!', 'Yes, I am sure!'],
      dangerMode: true
    }).then((isConfirm) => {
      if (isConfirm) {
        let data = { target: { name: 'status', value: '0', identifier: identifier } };
        this.updateFunctionality(apiCall, data);
      }
    });
  };
  connectFunctionality = async (apicall, identifier) => {};
  generatePostData = (apiCall, event) => {
    console.log('generatePostData', apiCall, event);
    let keyData = Object.keys(apiCall?.mandatory);
    let postData = {};
    for (var i = 0; i < keyData.length; i++) {
      if (apiCall.mandatory[keyData[i]] === 'token') {
        postData[[keyData[i]]] = localStorage.getItem(config.Token_name);
      } else {
        postData[[keyData[i]]] = event.target[apiCall.mandatory[keyData[i]]];
      }
    }
    return postData;
  };

  processSuggestionFields = (data) => {
    const retfields = data;
    retfields.forEach((field) => {
      if (field.dbname !== 'status') {
        field.disabled = true;
        field.ftype.forEach(($record) => {
          $record.disabled = true;
        });
      } else {
        field.ftype = [
          {
            type: 'Button',
            placeholder: 'Copy',
            action: 'copy',
            color: 'secondary',
            dbname: 'status'
          }
        ];
      }
    });
    return retfields;
  };

  render() {
    const { fields, data, connection } = this.props;
    console.log('Fields', fields, this.props);
    return (
      <Grid container>
        <Grid
          item
          md={12}
          style={{ display: 'flex', alignItems: 'center', padding: '30px', marginLeft: '20px' }}
        >
          <Grid item md={6}>
            <h1>{this.props.heading}</h1>
          </Grid>
          <Grid item md={6}>
            {this.state.apiCalls['connect'] && (
              <ConnectModule
                key={`connect-${this.props.connectionId}`}
                baseModule={this.props.heading}
                ConnectModule={this.state.apiCalls['connect'].connection}
                baseModuleId={this.props.connectionId}
                apiCall={this.state.apiCalls['connect']}
                callback={() => this.props.callback()}
                fields={fields}
                data={data}
                defaultValue={this.props.defaultValue}
              />
            )}
          </Grid>
        </Grid>

        <Grid item md={12} style={{ padding: '30px' }}>
          <ConnectionDataTable
            data={data}
            /*fields={this.props.apiCalls.suggestions ? this.processSuggestionFields(fields) : fields}*/
            fields={fields}
            callback={(e, action, id) => this.handleFunction(e, action, id)}
            filters={this.props.defaultValue}
            showSuggesstion={this.props.showSuggesstion}
            isConnect={false}
            heading={this.props.heading}
            apiCalls={this.state.apiCalls}
            filterData={(event) => this.props.filterData(event)}
          />
          <NexusPagination
            key={data}
            displayNote={data?.display_note}
            currentPage={data?.currentPage}
            totalPages={data?.totalpages}
            handleFunction={(desiredPage) => this.props.changePage(desiredPage)}
          />
        </Grid>
      </Grid>
    );
  }
}

export default Connection;
