import React, { Component } from 'react';
import { Button, Grid, TextField, TableCell, TableRow, TableBody, Table } from '@mui/material';
import LoadDropDown from './LoadDropdown';
import TableHead from '@mui/material/TableHead';
import staticData from 'app/helpers/staticdata';

/* 
    How to render 
     <LoadButton
        ftype={ftype}
        callback={(e) => this.handleFunction(e, ftype.action, item.id)}
      />

    *** Defination of fields:
    
    callback: "Event to occur on click  of button"
    ftype:"Particular field ftype Format type object
    {
      optionType:"Defines what type of Values are to be Populated['object'/'array']",
      optionMap: this Field is mandatory only when optionType=>object 
      {
        label: "Name of the field whose value should be printed as menu label"
        value: "Name of the Field whose value should be printed as manu value"
      }
      color:"primary by default"
    }
    "
 */
class FilterData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: '',
      filterType: ''
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { fields, apiCalls, Heading } = this.props;
    if (!fields) return;

    const excludedFiltrerType = ['Button', 'file', 'link'];
    const promises = [];

    fields.forEach((field) => {
      if (!excludedFiltrerType.includes(field.ftype[0].type)) {
        const crud = apiCalls['connect']?.filter_module || Heading.toLowerCase();
        promises.push(this.getAllFilters(crud, field.dbname, field.label));
      }
    });

    await Promise.all(promises);

    console.log('All filters loaded:', this.state.filterType);
  };

  getAllFilters = async (crud, dbname, label) => {
    const ftype = await this.getPossibleValues(crud, dbname, label);
    this.setState((prevState) => ({
      filterType: {
        ...prevState.filterType,
        [dbname]: ftype
      }
    }));
  };

  possibleFilters = () => {
    const { userFilters, defaultValue } = this.props;
    const filters = { ...userFilters };
    Object.keys(defaultValue).forEach((key) => {
      filters[key] = defaultValue[key].value || defaultValue[key];
    });
    return filters;
  };

  async getPossibleValues(crud, field, label) {
    const filters = this.possibleFilters();
    const jsonData = await staticData.getPossibleOptions(crud, field, filters);

    if (jsonData) {
      return {
        type: 'dropdown',
        placeholder: label,
        action: 'update',
        dbname: field,
        optionType: 'array',
        options: jsonData
      };
    }

    return {};
  }
  render() {
    const { fields, filterKeys, filterType } = this.props;
    console.log('FilterData', fields, filterKeys, filterType);
    return (
      <TableHead>
        <TableRow>
          {fields?.map(
            (record) =>
              !filterKeys.includes(record.dbname) && (
                // <TableCell
                //   key={`filter-${record.dbname ? record.dbname : record.ftype[0].dbname}`}
                // >
                //   <p>{record.label}</p>
                // </TableCell>
                <TableCell key={`filter-${record.dbname ? record.dbname : record.ftype[0].dbname}`}>
                  {['Button', 'file', 'link'].includes(record.ftype[0].type) ? (
                    <p>{record.label}</p>
                  ) : record.ftype[0].type === 'dropdown' ||
                    record.ftype[0].type === 'tags' ||
                    record.ftype[0].type === 'checkBox' ? (
                    <LoadDropDown
                      key={this.state.filterType}
                      record={record}
                      defaultValue={
                        this.props.userFilters[record.dbname]
                          ? this.props.userFilters[record.dbname]
                          : ''
                      }
                      callback={(event) => this.props.FilterData(event)}
                      ftype={
                        this.state.filterType[record.dbname]
                          ? this.state.filterType[record.dbname]
                          : []
                      }
                    />
                  ) : (
                    <LoadDropDown
                      key={this.state.filterType}
                      record={record}
                      defaultValue={
                        this.props.userFilters[record.dbname]
                          ? this.props.userFilters[record.dbname]
                          : ''
                      }
                      callback={(event) => this.props.FilterData(event)}
                      ftype={
                        this.state.filterType[record.dbname]
                          ? this.state.filterType[record.dbname]
                          : []
                      }
                    />
                  )}
                </TableCell>
              )
          )}
        </TableRow>
      </TableHead>
    );
  }
}

export default FilterData;
