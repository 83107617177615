import React, { Component } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Table, TableBody, TableCell, TableRow, TableHead, Tabs, Tab } from '@mui/material';
import { Box, Button, Chip, Divider } from '@mui/material';
import config from '../../constants/index.jsx';
import Auth from 'app/helpers/authorization';
import Postie from 'app/helpers/postie';
import displayer from 'app/helpers/displayer';
import HeaderDetails from './components/Header.jsx';
import UnitSummary from './components/UnitSummay.jsx';
import ModularListRow from './components/ModularListRow.jsx';
import FooterBox from './components/Footer.jsx';

class ModularEstimate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      selectedTab: 0
    };
  }

  componentDidMount = () => {
    const queryParams = displayer.getUrlParams('/project/modular/estimate/');
    //console.log(queryParams);
    this.getModularEstimatesData(queryParams[0]);
  };

  getModularEstimatesData = async (id) => {
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url = config.BASE_API_URL + 'modularestimate/get/' + id;
      let jsonData = await Postie.fetchUrl(url);
      //console.log('sata', jsonData);
      if (jsonData) {
        this.setState(
          {
            ...this.state,
            data: jsonData,
            dataLoaded: true, // Set dataLoaded to true when data is loaded
            is_editable: userLogged && jsonData.status == 0
          },
          function () {
            //console.log('Modular Estimates Data', this.state.data);
          }
        );
      }
    }
  };

  handleTabChange = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };

  render() {
    const { data, dataLoaded, selectedTab } = this.state;
    console.log('state data', data.modules);
    // Only render if data is loaded
    if (!dataLoaded) {
      return (
        <Grid
          container
          md={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Center the loading message
            height: '100vh' // Center vertically
          }}
        >
          <h3>Loading data...</h3>
        </Grid>
      );
    }

    return (
      <Grid container style={{ marginLeft: '30px' }}>
        <HeaderDetails boq_name={data.label} data={data.project} budget={data.budget} />
        <Grid item md={12} style={{ padding: ' 0 40px' }}>
          <Tabs
            value={selectedTab}
            onChange={this.handleTabChange}
            style={{ color: '#0000FF', padding: '8px' }}
          >
            <Tab label="Kitchen" />
            <Tab label="Wardrobe" />
            <Tab label="Storage" />
          </Tabs>
        </Grid>
        {selectedTab === 0 && (
          <Grid container style={{ marginLeft: '5px', padding: ' 0 40px' }}>
            <Grid item md="12">
              <h2>Kitchen</h2>

              <Button
                variant="contained"
                color="primary"
                style={{ float: 'right' }}
                //onClick={() => Postie.navigateLink('/modular/estimate/change/' + data.id)}
              >
                Edit
              </Button>

              <Box>Attachment “A” – Pricing Detail and Breakout.</Box>
            </Grid>

            <Grid container style={{ backgroundColor: '#FAF3F0', marginRight: '20px' }}>
              <Grid item md="12" style={{ marginRight: '20px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }}>
                        <h5>Area</h5>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <h5>UNIT TYPE</h5>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <h5>COMPLETE UNIT(S) </h5>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <h5>COST </h5>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {data &&
                    data.modules.map((module, index) => (
                      <TableBody key={index}>
                        {module.kitchen.units.length > 0 &&
                          module.kitchen.units.map((unit, index) => (
                            <UnitSummary
                              area={module.project_display_name}
                              data={unit}
                              key={index}
                            />
                          ))}
                      </TableBody>
                    ))}
                </Table>
              </Grid>
            </Grid>
            {data &&
              data?.modules.map((module, index) => (
                <div key={index}>
                  {console.log('module', module.kitchen)}
                  {module.kitchen &&
                    module.kitchen.modules.length > 0 &&
                    module.kitchen.modules.map((service, index) => (
                      <ModularListRow
                        key={index}
                        data={service}
                        showDetail={false}
                        displayCosting={true}
                      />
                    ))}
                </div>
              ))}
          </Grid>
        )}
        {selectedTab === 2 && (
          <Grid container style={{ marginLeft: '5px', padding: ' 0 40px' }}>
            <Grid item md="12">
              <h2>Storage</h2>
              <Box>Attachment “A” – Pricing Detail and Breakout.</Box>
            </Grid>

            <Grid container style={{ margin: '10px', backgroundColor: '#FAF3F0' }}>
              <Grid item md="12" style={{ marginLeft: '20px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }}>
                        <h5>Area</h5>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <h5>UNIT TYPE</h5>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <h5>COMPLETE UNIT(S) </h5>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <h5>COST </h5>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {data &&
                    data.modules.map((module, index) => (
                      <TableBody key={index}>
                        {module.storage.units.length > 0 &&
                          module.storage.units.map((unit, index) => (
                            <UnitSummary
                              area={module.project_display_name}
                              data={unit}
                              key={index}
                            />
                          ))}
                      </TableBody>
                    ))}
                </Table>
              </Grid>
            </Grid>
            {data &&
              data?.modules.map((module, index) => (
                <div key={index}>
                  {console.log('storage', module.storage)}
                  {module.storage &&
                    module.storage.modules.length > 0 &&
                    module.storage.modules.map((service, index) => (
                      <ModularListRow key={index} data={service} />
                    ))}
                </div>
              ))}
          </Grid>
        )}
        {selectedTab === 1 && (
          <Grid container style={{ marginLeft: '5px', padding: ' 0 40px' }}>
            <Grid item md="12">
              <h2>Wardrobe</h2>
              <Box>Attachment “A” – Pricing Detail and Breakout.</Box>
            </Grid>

            <Grid container style={{ margin: '10px', backgroundColor: '#FAF3F0' }}>
              <Grid item md="12" style={{ marginLeft: '20px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }}>
                        <h5>Area</h5>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <h5>UNIT TYPE</h5>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <h5>COMPLETE UNIT(S) </h5>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <h5>COST </h5>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {data &&
                    data.modules.map((module, index) => (
                      <TableBody key={index}>
                        {module.wardrobe.units.length > 0 &&
                          module.wardrobe.units.map((unit, index) => (
                            <UnitSummary
                              area={module.project_display_name}
                              data={unit}
                              key={index}
                            />
                          ))}
                      </TableBody>
                    ))}
                </Table>
              </Grid>
            </Grid>
            {data &&
              data?.modules.map((module, index) => (
                <div key={index}>
                  {console.log('wardrobe', module.wardrobe)}
                  {module.wardrobe &&
                    module.wardrobe.modules.length > 0 &&
                    module.wardrobe.modules.map((service, index) => (
                      <ModularListRow key={index} data={service} />
                    ))}
                </div>
              ))}
          </Grid>
        )}

        <Grid container style={{ backgroundColor: '#D4E2D4', margin: '10px', margin: '0 40px' }}>
          <Grid item md={9}>
            <Grid container spacing={2} style={{ margin: '10px' }}>
              <Grid item xs={12} sm={12} md={4}>
                <h4> Kitchen Cost (K)</h4>
                <p>&#8377;{displayer.amountReadable(data.kitchen_cost)}</p>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <h4> Wardrobe Cost (W)</h4>
                <p>&#8377;{displayer.amountReadable(data.wardrobe_cost)}</p>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <h4> Storage Cost (S)</h4>
                <p>&#8377;{displayer.amountReadable(data.storage_cost)}</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3}>
            <Grid container>
              <Grid item xs={6} sm={6} md={6} style={{ marginBottom: '5px' }}>
                <h4>Net Cost (K+W+S) : </h4>
              </Grid>
              <Grid item xs={6} sm={6} md={6} style={{ marginBottom: '5px', paddingRight: '20px' }}>
                <h4 style={{ textAlign: 'right' }}>
                  &#8377;{displayer.amountReadable(data.net_cost)}
                </h4>
              </Grid>

              {data.discount > 0 && (
                <Grid item xs={6} sm={6} md={6} style={{ marginBottom: '5px' }}>
                  <h4>
                    Discount (
                    {data.discount_type === 'fixed' ? 'Fixed' : data.discount_percent + '%'}) :
                  </h4>
                </Grid>
              )}
              {data.discount > 0 && (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  style={{ marginBottom: '5px', paddingRight: '20px' }}
                >
                  <h4 style={{ textAlign: 'right' }}>
                    &#8377;{displayer.amountReadable(data.discount)}
                  </h4>
                </Grid>
              )}

              <Grid item xs={6} sm={6} md={6} style={{ marginBottom: '5px' }}>
                <h4>Project Handling Fees:</h4>
              </Grid>
              <Grid item xs={6} sm={6} md={6} style={{ marginBottom: '5px', paddingRight: '20px' }}>
                <h4 style={{ textAlign: 'right' }}>
                  {' '}
                  &#8377;{displayer.amountReadable(data.consultation_cost)}
                </h4>
              </Grid>

              <Grid item xs={6} sm={6} md={6} style={{ marginBottom: '5px' }}>
                <h3>Total :</h3>
              </Grid>
              <Grid item xs={6} sm={6} md={6} style={{ marginBottom: '5px', paddingRight: '20px' }}>
                <h3 style={{ textAlign: 'right' }}>
                  &#8377; {displayer.amountReadable(data.budget)}
                </h3>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <FooterBox
          terms={data.project.organization.modular_terms}
          payment_terms={data.project.organization.modular_payment_terms}
          organization={data.project.organization}
        />
      </Grid>
    );
  }
}

export default ModularEstimate;
