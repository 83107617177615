import React, { Component } from 'react';
import { Grid, Tab, Tabs, Button, Box, Divider, FormControlLabel, Checkbox } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material';

const AccordionRoot = styled(Box)(({ theme }) => ({
  width: '100%',
  '& .heading': {
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12)
    }
  },
  '& .secondaryHeading': {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12)
    }
  },
  '& .icon': {
    width: 20,
    height: 20,
    verticalAlign: 'bottom'
  },
  '& .details': { alignItems: 'center' },
  '& .column': { flexBasis: '33.33%' },
  '& .helper': {
    padding: theme.spacing(1, 2),
    borderLeft: `2px solid ${theme.palette.divider}`
  },
  '& .link': {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': { textDecoration: 'underline' }
  }
}));

class CutList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openOrderNow: false,
      openMark: false
    };
  }

  render() {
    return (
      <AccordionRoot>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <Grid container>
              <Grid item md={3}>
                KBU
              </Grid>
              <Grid item md={3}>
                Carcass
              </Grid>
              <Grid item md={3}>
                Kitchen : : B
              </Grid>
              <Grid item md={3}>
                S no. #1
              </Grid>
            </Grid>
          </AccordionSummary>
          <Divider />

          <AccordionActions>
            <Grid container>
              <Grid item md={1}>
                Black Ply
              </Grid>
              <Grid item md={1}>
                Century HDHMR
                <FormControlLabel
                  control={
                    <Checkbox
                      name={``}
                      value={''}
                      //onChange={(e) => props.handleScopeCheck(e)}
                      color="primary"
                      disabled={''}
                      checked={''}
                    />
                  }
                  label="Laminate Paisted"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name={``}
                      value={''}
                      //onChange={(e) => props.handleScopeCheck(e)}
                      color="primary"
                      disabled={''}
                      checked={''}
                    />
                  }
                  label="Cutting Done"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name={``}
                      value={''}
                      //onChange={(e) => props.handleScopeCheck(e)}
                      color="primary"
                      disabled={''}
                      checked={''}
                    />
                  }
                  label="Edge Bending Done"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name={``}
                      value={''}
                      //onChange={(e) => props.handleScopeCheck(e)}
                      color="primary"
                      disabled={''}
                      checked={''}
                    />
                  }
                  label="Label Paisting"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name={``}
                      value={''}
                      //onChange={(e) => props.handleScopeCheck(e)}
                      color="primary"
                      disabled={''}
                      checked={''}
                    />
                  }
                  label="Validate"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name={``}
                      value={''}
                      //onChange={(e) => props.handleScopeCheck(e)}
                      color="primary"
                      disabled={''}
                      checked={''}
                    />
                  }
                  label="Procure"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name={``}
                      value={''}
                      //onChange={(e) => props.handleScopeCheck(e)}
                      color="primary"
                      disabled={''}
                      checked={''}
                    />
                  }
                  label="Transport"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name={``}
                      value={''}
                      //onChange={(e) => props.handleScopeCheck(e)}
                      color="primary"
                      disabled={''}
                      checked={''}
                    />
                  }
                  label="Received"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name={''}
                      value={''}
                      //onChange={(e) => props.handleScopeCheck(e)}
                      color="primary"
                      disabled={''}
                      checked={''}
                    />
                  }
                  label="Installed"
                />
              </Grid>
              <Grid item md={1}>
                Width
              </Grid>
              <Grid item md={1}>
                Length
              </Grid>
              <Grid item md={1}>
                Qty
              </Grid>
              <Grid item md={1}>
                BSL 1
              </Grid>
              <Grid item md={1}>
                E3 Laminates 808 MT/SF
              </Grid>
              <Grid item md={1}>
                E3 Laminates 808 MT/SF
              </Grid>
              <Grid item md={1}>
                -
              </Grid>
              <Grid item md={1}>
                Kitchen::B-KBU-Carcass-Back Ply-BSL1
              </Grid>
            </Grid>
          </AccordionActions>
        </Accordion>
      </AccordionRoot>
    );
  }
}

export default CutList;
