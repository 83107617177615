import React, { Component } from 'react';
import { Grid, Paper, Chip } from '@mui/material';
import displayer from 'app/helpers/displayer';
import { AccountCircle, Email, IosShare, Phone } from '@mui/icons-material';
import DoughnutChart from 'app/views/dashboard/shared/Doughnut';

class HeaderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
  }

  render() {
    const { data, dataLoaded, selectedTab } = this.props;
    console.log('header data', data);

    return (
      <Grid container spacing="2" style={{ padding: ' 0 40px' }}>
        <Grid
          item
          xs="12"
          sm="12"
          md="12"
          style={{ textAlign: 'center', backgroundColor: '#B3A492' }}
        >
          <img
            src={displayer.absolutingImageURl(this.props.data?.organization?.logo)}
            alt={this.props.data?.organization?.name}
            width="300px"
          />
        </Grid>
        <Grid item xs="12" sm="12" md="12">
          <h4>Hello {this.props.data?.client?.fullname}</h4>
          <p>
            Thank you for the opportunity to provide you with a quotation for the below referenced
            project. Please see Attachment “A” – Pricing Detail and Breakout Of
            <b> {this.props.boq_name}</b>.
          </p>
        </Grid>
        <Grid container spacing="2">
          <Grid item xs="12" sm="12" md="6">
            <Paper style={{ padding: '8px', margin: '5px' }}>
              <h4 style={{ textAlign: 'center' }}>Client Information</h4>
              <h5>{this.props.data?.client?.fullname}</h5>
              <p>
                <Phone fontSize="2px" style={{ marginRight: '8px' }} />
                {this.props.data?.client?.phone}
              </p>
              <p>
                <Email fontSize="2px" style={{ marginRight: '8px' }} />
                {this.props.data?.client?.email}
              </p>
            </Paper>
            <Paper style={{ padding: '35px', margin: '5px' }}>
              <h4 style={{ textAlign: 'center' }}>Project Information</h4>
              <p>
                <span style={{ textSize: '20' }}>Project #: </span>
                {this.props.data?.id}
              </p>
              <p>
                <span style={{ textSize: '20' }}>Location: </span>
                {this.props.data?.project_address}
              </p>
              <p>
                <span style={{ textSize: '20' }}>Type: </span>
                {this.props.data?.project_type}
              </p>
            </Paper>
          </Grid>
          <Grid item xs="12" sm="12" md="6">
            <Paper style={{ padding: '8px', margin: '5px' }}>
              {this.props.totalCost && (
                <h2 style={{ textAlign: 'center', padding: '6px' }}>
                  Total : &#8377; {displayer.amountReadable(this.props.totalCost.toFixed(2))}
                </h2>
              )}

              {/* <p>
                <span style={{ textSize: '20' }}>Area #: </span>
                {this.props.data?.project_area} sqft.
              </p> */}
              {/*in this commented code json parse giving error so i used this code withoutjson parse*/}
              {/* {Object.keys(JSON.parse(this.props.data.project_name)).map((key, index) => (
                <Chip
                  key={index}
                  label={`${key}:${JSON.parse(this.props.data.project_name)[key]}`}
                ></Chip>
              ))} */}
              {Object.keys(parseInt(this.props.data?.project_name)).map((key, index) => (
                <Chip key={index} label={`${key}:${this.props.data.project_name[key]}`}></Chip>
              ))}
              <DoughnutChart
                height={'250px'}
                name="Cost Distribution"
                actualData={[
                  { value: parseFloat(this.props.fitoutcost), name: 'Fitouts' },
                  { value: parseFloat(this.props.modularCost), name: 'Modulars' },
                  { value: parseFloat(this.props.furnitureCost), name: 'Furniture' },
                  { value: parseFloat(this.props.productCost), name: 'Product' }
                ]}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default HeaderDetails;
