import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import FileView from 'app/views/bids/components/fileView';
import {
  Grid,
  Button,
  Box,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup
} from '@mui/material';
import Paper from '@mui/material/Paper';
import displayer from 'app/helpers/displayer';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import { upperCase } from 'lodash';
import config from '../../../constants/index.jsx';
import Postie from 'app/helpers/postie';

const AccordionRoot = styled(Box)(({ theme }) => ({
  width: '100%',
  '& .heading': {
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12)
    }
  },
  '& .secondaryHeading': {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12)
    }
  },
  '& .icon': {
    width: 20,
    height: 20,
    verticalAlign: 'bottom'
  },
  '& .details': { alignItems: 'center' },
  '& .column': { flexBasis: '33.33%' },
  '& .helper': {
    padding: theme.spacing(1, 2),
    borderLeft: `2px solid ${theme.palette.divider}`
  },
  '& .link': {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': { textDecoration: 'underline' }
  }
}));

const Container = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
  gap: '16px'
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

const ContractorDisplay = (props) => (
  <Grid container>
    <Grid item md="6">
      <div
        style={{
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          background: '#6A75C9',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '15px',
          margin: '20px'
        }}
      >
        {displayer.abrivatedName(props.contractor.name)}
      </div>
    </Grid>
    <Grid item md="6">
      <h5 style={{ color: 'black' }}>{props.contractor.name}</h5>
      <p style={{ color: 'black' }}>{props.contractor.mobile}</p>
      <p>{props.contractor.type.toUpperCase()}</p>
    </Grid>
  </Grid>
);

class ScopeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isAddContractorOpen: false
    };
  }

  openAddContractorDialog = () => {
    this.setState({ isAddContractorOpen: true });
  };

  closeAddContractorDialog = () => {
    this.setState({ isAddContractorOpen: false });
  };

  handleContractorSelection = (contractor) => {
    // Call your API function here with the selected contractor data
    console.log('Selected Contractor:', contractor);
    // Add your API call here
  };

  onUpdateService = async (property, value) => {
    const authToken = localStorage.getItem(config.Token_name);

    const postData = {
      authToken,
      field: property,
      value: value,
      service_id: this.props.service.id,
      project_id: this.props.projectId
    };
    // console.log('update product', postData, this.state);
    let serviceUrl = config.BASE_API_URL + 'project/service/update';
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      this.props.callback();
    }
  };
  render() {
    const { service, cfg, elevation, area } = this.props;
    //console.log('ServiceView Data', service);

    return (
      <Grid>
        <AccordionRoot style={{ marginBottom: '10px' }}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1c-header"
            >
              <Container>
                <Item>
                  <FileView
                    key={`nobaseImage`}
                    id={service?.id}
                    url={displayer.absolutingImageURl(cfg.image)}
                  />
                  <h5 style={{ color: 'black' }}>{cfg.name}</h5>
                  <p>#{service.id}</p>
                </Item>
                <Item>
                  <h3 style={{ color: 'black' }}>{service.service?.name}</h3>
                  <h5 style={{ color: 'black' }}>{cfg.work_category?.sow}</h5>
                  <h5 style={{ color: 'black' }}>Location: {area}</h5>
                  <h5 style={{ color: 'black' }}>Elevation: {elevation}</h5>
                </Item>
                <Item>
                  <h5 style={{ color: 'black' }}>
                    Area: {service.qty}
                    {service.uom}
                  </h5>
                  <h5 style={{ color: 'black' }}>
                    Status:
                    {service.work_status && service.work_status > 0
                      ? service.work_status >= 100
                        ? 'Completed'
                        : 'In Progress'
                      : 'Not Started'}
                  </h5>
                  <h5 style={{ color: 'red' }}>Remark :</h5>
                  <p style={{ color: 'red' }}>{service.remark}</p>
                </Item>
                <Item>
                  <h5 style={{ color: 'black' }}>Start Date:</h5>
                  <input
                    type="date"
                    defaultValue={service.start_date}
                    onChange={(e) => this.onUpdateService('start_date', e.target.value)}
                  />
                  <h5 style={{ color: 'black' }}>End Date:</h5>
                  <input
                    type="date"
                    onChange={(e) => this.onUpdateService('start_date', e.target.value)}
                  />
                </Item>
                <Item>
                  {service.contractor ? (
                    <ContractorDisplay
                      contractor={{
                        name: 'Md. Abdul Sattar',
                        mobile: '9999111111',
                        type: 'electrician'
                      }}
                    />
                  ) : (
                    <Button
                      color="warning"
                      variant="contained"
                      onClick={this.openAddContractorDialog}
                    >
                      Add Contractor
                    </Button>
                  )}
                </Item>
              </Container>
            </AccordionSummary>
            <Divider />
            <Container>
              <Item>
                {' '}
                <h3>Subtask</h3>
                <FormGroup>
                  <FormControlLabel
                    key={`index`}
                    control={
                      <Checkbox
                        name={''}
                        value={this.state.checkedScope}
                        onChange={(event) => this.handleScopeCheck(event.target.value)}
                        color="primary"
                        disabled={this.props.disabled}
                      />
                    }
                    label="Client Approved"
                  />
                </FormGroup>
              </Item>
            </Container>
            <Divider />
          </Accordion>
        </AccordionRoot>
        <Dialog
          open={this.state.isAddContractorOpen}
          onClose={this.closeAddContractorDialog}
          fullWidth
        >
          <DialogTitle>Add Contractor</DialogTitle>
          <DialogContent>
            {/* List of contractors */}
            {/* {contractors.map((contractor) => ( */}
            <Grid container md="12">
              <Grid item md="4">
                <div key={''}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Md. Abdul Sattar"
                      />
                      <FormControlLabel value="male" control={<Radio />} label="Md. Abdul Sattar" />
                      <FormControlLabel
                        value="other"
                        control={<Radio />}
                        label="Md. Abdul Sattar"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </Grid>
              <Grid item md="4">
                <h5>9999111111</h5>
                <h5>9999111111</h5>
                <h5>9999111111</h5>
              </Grid>
              <Grid item md="4">
                <h5>Electrician</h5>
                <h5>Electrician</h5>
                <h5>Electrician</h5>
              </Grid>
            </Grid>
            {/* ))} */}
          </DialogContent>
        </Dialog>
      </Grid>
    );
  }
}

export default ScopeView;
