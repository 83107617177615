import React, { Component } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Button
} from '@mui/material';
import TableHead from '@mui/material/TableHead';
import UploadFile from 'app/views/files/uploadFile';
import Paper from '@mui/material/Paper';
import DisplayValidationReport from './DisplayValidationReport';

class ReUploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      checkedCheckboxes: [],
      selectedAreaId: null,
      drawing: '',
      elevation: ''
    };
  }

  handleCheckboxChange = (checkname, area_id, elevation, record, event) => {
    // Update the checkedCheckboxes state based on the checkbox change
    this.setState((prevState) => {
      const isChecked = prevState.checkedCheckboxes.includes(checkname);

      if (isChecked) {
        return {
          checkedCheckboxes: prevState.checkedCheckboxes.filter((name) => name !== checkname),
          selectedAreaId: null,
          elevation: null // Clear the selected area_id when unchecking
        };
      } else {
        return {
          checkedCheckboxes: [...prevState.checkedCheckboxes, checkname],
          selectedAreaId: area_id,
          elevation: elevation // Set the selected area_id when checking
        };
      }
    });
    this.props.handleUpdateValidation(record, event);
  };

  handleFile = (resp, type, field) => {
    if (type === 'drawing') {
      this.setState({
        ...this.state,
        drawing: resp.data.url
      });
    }
    this.props.callback(this.props.areaId, resp.data.url, field);
  };

  render() {
    const { data, checkedCheckboxes, selectedAreaId } = this.state;
    console.log('ReUploadFile data', data, this.props.validations);

    // Check if all checkboxes are checked
    const allCheckboxesChecked = data.every((record) =>
      checkedCheckboxes.includes(record.checkname)
    );

    return (
      <Grid>
        <TableContainer component={Paper}>
          <Grid container spacing="2">
            <Grid item md={6} xs={12}>
              {data.length > 0 ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Checkpoint</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      data.map((record, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ verticalAlign: 'top' }}>
                            <FormGroup>
                              <div>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      key={index}
                                      color="primary"
                                      name={`approved`}
                                      value={1}
                                      checked={checkedCheckboxes.includes(record.checkname)}
                                      onChange={(event) =>
                                        this.handleCheckboxChange(
                                          record.checkname,
                                          record.area_id,
                                          record.elevation,
                                          record,
                                          event
                                        )
                                      }
                                      // onChange={(event) =>
                                      // this.props.handleUpdateValidation(record, event)
                                      //}
                                    />
                                  }
                                  label={record.checkname}
                                />
                              </div>
                            </FormGroup>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              ) : null}

              {allCheckboxesChecked && (
                <UploadFile
                  filename="Drawing"
                  displayTosection={false}
                  areaName=""
                  type="drawing"
                  projectId={this.props.projectId}
                  areaId={this.props.areaId}
                  callback={(File) => this.handleFile(File, data.elevation, this.props.field)}
                  showAlert={false}
                  fullWidth
                />
              )}
            </Grid>
            <Grid item md={6} style={{ padding: '35px' }}>
              {this.props.validations && this.props.validations.length > 0 && (
                <DisplayValidationReport data={this.props.validations} />
              )}
            </Grid>
          </Grid>
        </TableContainer>
      </Grid>
    );
  }
}

export default ReUploadFile;
