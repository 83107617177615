import React, { Component } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import config from '../../constants/index.jsx';
import Postie from 'app/helpers/postie';
import displayer from 'app/helpers/displayer';
import Auth from 'app/helpers/authorization';

class AddNewLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectData: {
        roomType: '',
        source: '',
        startDate: '', // Add missing properties to projectData state
        buildType: '',
        area: '',
        budget: '',
        description: '',
        scope: {},
        projectType: '',
        type: '',
        floors: '',
        location: ''
      },
      validationErrors: {},
      selectedDate: null, // Initialize selectedDate state property
      isFormFilled: false // Add a new state variable to track form completion
    };
  }

  componentDidMount = () => {
    const queryParams = displayer.getUrlParams('/add/newlead/');
    //console.log(queryParams);
    this.loadData(queryParams[0]);
  };

  loadData = async (id) => {
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url = config.BASE_API_URL + 'clients/' + id + '/' + Auth.urlTokenizer();
      let jsonData = await Postie.fetchUrl(url);
      //console.log('sata', jsonData);
      if (jsonData) {
        this.setState(
          {
            ...this.state,
            data: jsonData,
            dataLoaded: true // Set dataLoaded to true when data is loaded
          },
          function () {
            //console.log('loadData', this.state);
          }
        );
      }
    }
  };

  handleProjectDetails = (e, fieldName) => {
    const { value } = e.target;
    this.setState(
      (prevState) => ({
        projectData: {
          ...prevState.projectData,
          [fieldName]: value
        }
      }),
      () => {
        //console.log('Updated projectData:', this.state.projectData); // Log the updated projectData

        // Check if all fields are filled
        const {
          roomType,
          source,
          startDate,
          buildType,
          area,
          budget,
          description,
          scope,
          type,
          floors,
          location,
          projectType
        } = this.state.projectData;
        const isFormFilled =
          roomType &&
          source &&
          startDate &&
          buildType &&
          area &&
          budget &&
          description &&
          scope &&
          type &&
          floors &&
          location &&
          projectType;
        this.setState({ isFormFilled }); // Update the isFormFilled state
      }
    );
  };

  handleDateChange = (date) => {
    // Update the selectedDate state when a date is selected
    this.setState({ startDate: date });
  };

  validateForm = () => {
    const {
      name,
      phone,
      email,
      address,
      roomType,
      source,
      startDate,
      buildType,
      area,
      budget,
      description,
      scope,
      projectType,
      type,
      floors,
      location
    } = this.state.projectData;
    const errors = {};

    if (!source) {
      errors.source = 'source is required';
    }

    if (!floors) {
      errors.floors = 'floors is required';
    }

    if (!type) {
      errors.type = ' Property type is required';
    }

    if (!budget) {
      errors.budget = 'budget is required';
    }
    if (!area) {
      errors.area = 'area is required';
    }
    if (!startDate) {
      errors.startDate = 'startDate is required';
    }
    if (!roomType) {
      errors.roomType = 'roomType is required';
    }
    if (!description) {
      errors.description = 'description is required';
    }
    if (!location) {
      errors.location = 'Project location is required';
    }
    if (!projectType) {
      errors.projectType = 'Project Type is required';
    }

    return errors;
  };

  submitProjectDetails = async () => {
    const validationErrors = this.validateForm();
    if (Object.keys(validationErrors).length === 0) {
      const authToken = localStorage.getItem(config.Token_name);
      const projectData = this.state.projectData;
      const postData = {
        authToken,
        client_id: this.state.data.id,
        contract_start_date: projectData.startDate,
        floor_count: projectData.floors,
        project_address: projectData.location,
        project_area: projectData.area,
        project_budget: projectData.budget,
        project_build_type: projectData.type,
        project_description: projectData.description,
        project_division: projectData.division,
        project_name: projectData.roomType,
        project_scope: projectData.scope,
        project_source: projectData.source,
        project_type: projectData.projectType
      };

      // Send postData to the API here
      let serviceUrl = config.BASE_API_URL + 'project';
      let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
      if (reply.status === 'success') {
        Postie.showAlert(reply);
      }

      // Clear the form or reset the state after successful submission
      this.setState({
        projectData: {
          roomType: '',
          startDate: '',
          buildType: '',
          area: '',
          budget: '',
          description: '',
          division: '',
          scope: '',
          source: '',
          type: '',
          floors: '',
          location: ''
        },
        validationErrors: {},
        isFormFilled: false
      });
    } else {
      // Display validation errors
      this.setState({ validationErrors });
    }
  };
  render() {
    const { projectData, validationErrors, isFormFilled, startDate } = this.state;

    return (
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'column',

          padding: '0px',
          marginLeft: '10px'
        }}
      >
        <h1>Create New Presales Leads</h1>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ flex: 1, marginRight: '16px' }}>
            <FormControl variant="outlined" sx={{ width: '80%' }}>
              <InputLabel>Choose Lead Source:</InputLabel>
              <Select
                label="Choose Lead Source:"
                labelId="source"
                id="source"
                name="source"
                value={projectData.source}
                onChange={(event) => this.handleProjectDetails(event, 'source')}
                required // Mark the field as required
                error={Boolean(validationErrors.source)}
                helperText={validationErrors.source}
              >
                {/* {data?.areas.map((area, index) => (
                    <MenuItem key={index} value={area.project_area_name}>
                      {area.project_area_name}
                    </MenuItem>
                  ))} */}
                <MenuItem value="">Choose</MenuItem> {/* Set an empty value for the default */}
                <MenuItem value="Nexus">Nexus</MenuItem>
                <MenuItem value="Livspace">Livspace</MenuItem>
                <MenuItem value="InteriorLanes">InteriorLanes</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{ flex: 1, marginRight: '20px' }}>
            <TextField
              label="Floors"
              name="floors"
              value={projectData.floors}
              onChange={(e) => this.handleProjectDetails(e, 'floors')}
              fullWidth
              required // Mark the field as required
              style={{ marginBottom: '16px' }} // Add spacing
              error={Boolean(validationErrors.floors)}
              helperText={validationErrors.floors}
              sx={{ width: '80%' }}
            />
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ flex: 1, marginRight: '16px' }}>
            <FormControl variant="outlined" sx={{ width: '80%' }}>
              <InputLabel>Choose Property Type:</InputLabel>
              <Select
                label="Choose Property Type:"
                value={projectData.type}
                onChange={(event) => this.handleProjectDetails(event, 'type')}
                required
                error={Boolean(validationErrors.type)}
                helperText={validationErrors.type}
              >
                {/* {data?.areas.map((area, index) => (
                    <MenuItem key={index} value={area.project_area_name}>
                      {area.project_area_name}
                    </MenuItem>
                  ))} */}
                <MenuItem value="">Choose</MenuItem>
                <MenuItem value="Society High Rise">Society High Rise</MenuItem>
                <MenuItem value="Villa">Villa</MenuItem>
                <MenuItem value="Independent Floor">Independent Floor</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{ flex: 1, marginRight: '20px' }}>
            <TextField
              label="Budget"
              name="budget"
              type="number" // Add this line to restrict input to numeric values
              value={projectData.budget} // Use the correct state value for budget
              onChange={(e) => this.handleProjectDetails(e, 'budget')}
              fullWidth
              style={{ marginBottom: '16px' }} // Add spacing
              required // Mark the field as required
              error={Boolean(validationErrors.budget)}
              helperText={validationErrors.budget}
              sx={{ width: '80%' }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ flex: 1, marginRight: 'px' }}>
            <TextField
              sx={{ width: '79%' }}
              label="Area in sq.ft"
              name="area"
              type="number"
              value={projectData.area}
              onChange={(event) => this.handleProjectDetails(event, 'area')}
              fullWidth
              required // Mark the field as required
              style={{ marginBottom: '16px' }} // Add spacing
              error={Boolean(validationErrors.area)}
              helperText={validationErrors.area}
            />
          </div>
          <div style={{ flex: 1, marginRight: '20px' }}>
            <label for="start date">Start Date:</label>
            <input
              type="date"
              id="startDate"
              name="Start Date"
              style={{ width: '450px', height: '40px' }}
              value={projectData.startDate}
              onChange={(e) => this.handleProjectDetails(e, 'startDate')}
              required
              error={Boolean(validationErrors.startDate)}
              helperText={validationErrors.startDate}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ flex: 1, marginRight: '16px' }}>
            <FormControl variant="outlined" sx={{ width: '80%' }}>
              <InputLabel>Choose Room Type:</InputLabel>
              <Select
                label="Choose Room Type:"
                value={projectData.roomType}
                onChange={(event) => this.handleProjectDetails(event, 'roomType')}
                required
                error={Boolean(validationErrors.roomType)}
                helperText={validationErrors.roomType}
              >
                {/* {data?.areas.map((area, index) => (
                    <MenuItem key={index} value={area.project_area_name}>
                      {area.project_area_name}
                    </MenuItem>
                  ))} */}
                <MenuItem value="">Choose</MenuItem>

                <MenuItem value="1 BHK">1 BHK</MenuItem>
                <MenuItem value="2 BHK">2 BHK</MenuItem>
                <MenuItem value="3 BHK">3 BHK</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{ flex: 1, marginRight: '20px' }}>
            <FormControl fullWidth>
              <TextField
                label="Project Description"
                name="Project Description"
                rows={2}
                value={projectData.description}
                onChange={(event) => this.handleProjectDetails(event, 'description')}
                fullWidth
                required // Mark the field as required
                style={{ marginBottom: '16px' }} // Add spacing
                error={Boolean(validationErrors.description)}
                helperText={validationErrors.description}
                multiline
                sx={{ width: '80%' }}
              />
            </FormControl>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ flex: 1, marginRight: '16px' }}>
            <TextField
              label="Project Location / Society Location "
              name="Project Location / Society Location"
              value={projectData.location}
              onChange={(event) => this.handleProjectDetails(event, 'location')}
              fullWidth
              required // Mark the field as required
              style={{ marginBottom: '16px' }} // Add spacing
              error={Boolean(validationErrors.location)}
              helperText={validationErrors.location}
              sx={{ width: '80%' }}
            />
          </div>
          <div style={{ flex: 1, marginRight: '20px' }}>
            <FormControl variant="outlined" sx={{ width: '80%' }}>
              <InputLabel>Choose Project Type:</InputLabel>
              <Select
                label="Choose Project Type:"
                value={projectData.projectType}
                onChange={(event) => this.handleProjectDetails(event, 'projectType')}
                required
                error={Boolean(validationErrors.projectType)}
                helperText={validationErrors.projectType}
              >
                {/* {data?.areas.map((area, index) => (
                    <MenuItem key={index} value={area.project_area_name}>
                      {area.project_area_name}
                    </MenuItem>
                  ))} */}
                <MenuItem value="">Choose</MenuItem>
                <MenuItem value="Full Home Interior- New">Full Home Interior- New</MenuItem>
                <MenuItem value="Full Home Interior- Renovation">
                  Full Home Interior- Renovation{' '}
                </MenuItem>
                <MenuItem value="Modular Works - New">Modular Works - New </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: '80px' }}
            onClick={this.submitProjectDetails}
            //disabled={!isFormFilled} // Disable the button if the form is not filled
          >
            Submit
          </Button>
        </DialogActions>
      </Grid>
    );
  }
}

export default AddNewLead;
