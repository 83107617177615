import React, { Component } from 'react';
import { Grid} from '@mui/material';
import { Button,Divider, TextField } from '@mui/material';
//import { Box, Button,Divider, TextField } from '@mui/material';
//import { styled } from '@mui/material';
import config from '../../../constants/index.jsx';
import Auth from 'app/helpers/authorization';
import displayer from 'app/helpers/displayer';
import Postie from 'app/helpers/postie';
import { Delete, Edit } from '@mui/icons-material';
import EditServiceInBoq from './EditServiceInBoq';
import swal from 'sweetalert';
import ChangeServiceImage from './ChangeServiceImage.jsx';

// const AccordionRoot = styled(Box)(({ theme }) => ({
//   width: '100%',
//   '& .heading': {
//     fontSize: theme.typography.pxToRem(15),
//     [theme.breakpoints.down('sm')]: {
//       fontSize: theme.typography.pxToRem(12)
//     }
//   },
//   '& .secondaryHeading': {
//     color: theme.palette.text.secondary,
//     fontSize: theme.typography.pxToRem(15),
//     [theme.breakpoints.down('sm')]: {
//       fontSize: theme.typography.pxToRem(12)
//     }
//   },
//   '& .icon': {
//     width: 20,
//     height: 20,
//     verticalAlign: 'bottom'
//   },
//   '& .details': { alignItems: 'center' },
//   '& .column': { flexBasis: '33.33%' },
//   '& .helper': {
//     padding: theme.spacing(1, 2),
//     borderLeft: `2px solid ${theme.palette.divider}`
//   },
//   '& .link': {
//     textDecoration: 'none',
//     color: theme.palette.primary.main,
//     '&:hover': { textDecoration: 'underline' }
//   }
// }));

const BreakupRow = (props) => (
  <Grid
    container
    spacing={2}
    style={{ borderBottom: '1px Solid', maxWidth: '98%', overflowX: 'auto' }}
  >
    {console.log('imagesData', props.imageData)}
    <Grid item xs={12} sm={6} md={props.service.type === 'service' ? 2 : 3}>
      <h5>Image</h5>
      {props.service.image ? (
        <img src={props.service.image} width="90%"  alt=""/>
      ) : (
        <img src={props.elevationImage} width="90%" alt=""/>
      )}
      {props.setEditMode && <Button onClick={() => props.handleChangeImage()}>Change</Button>}
    </Grid>
    {props.service.type === 'service' && (
      <Grid item xs={12} sm={6} md={2}>
        {props.service.type === 'service' && <h5>Type</h5>}
        {props.service.type === 'service' && <p>{props.service.workcategory?.sow}</p>}
        {props.service.remark && props.service.type === 'service' ? (
          <p style={{ color: 'red' }}>Remark:{props.service.remark}</p>
        ) : null}
      </Grid>
    )}
    <Grid item xs={12} sm={6} md={props.service.type === 'service' ? 2 : 3}>
      <h5 className="capitalize">{props.service.type} Name</h5>
      <p>
        {props.service.type === 'service'
          ? props.service.service
            ? props.service.service.name
            : props.service.ref_id
          : props.service.product
          ? props.service.product.name
          : props.service.ref_id}
      </p>
      {props.service.base_material && <p>Base Material:{props.service.base_material}</p>}
      {props.service.brand && <p>Make:{props.service.brand}</p>}
      {props.service.finish && <p>Finish:{props.service.finish}</p>}
    </Grid>
    <Grid item xs={12} sm={6} md={1}>
      <h5>Area / Qty</h5>
      <p>
        {props.service.qty} &nbsp;
        {props.service.type === 'service' ? props.service.uom : 'No.s'}
      </p>
    </Grid>
    <Grid item xs={12} sm={6} md={1}>
      <h5>Rate </h5>
      {props.is_editable ? (
        <TextField
          key={props.service.id}
          type="number"
          min={0}
          defaultValue={props.service.rate}
          onChange={(e) => this.handlerateChange(e, props.service.id)}
          placeholder={'Enter Rate'}
          name={'rate'}
          fullWidth
        ></TextField>
      ) : (
        <p>&#8377;{props.service.rate}</p>
      )}
    </Grid>
    <Grid item xs={12} sm={6} md={props.setEditMode ? 1 : 2}>
      <h5>Cost :</h5>
      <p>&#8377;{props.service.cost}</p>
    </Grid>
    <Grid item xs={12} sm={6} md={2}>
      <h5>Exclusions</h5>
      <p>{props.service.exclusions}</p>
    </Grid>
    {props.setEditMode && (
      <Grid item xs={12} sm={6} md={1}>
        <h5>Actions :</h5>
        <Button
          onClick={() => props.onEditService()}
          color="secondary"
          variant="outlined"
          size="large"
        >
          <Edit />
        </Button>
        <Button
          onClick={() => props.onDeleteService()}
          style={{ marginTop: '2px' }}
          color="error"
          variant="outlined"
          size="large"
        >
          <Delete />
        </Button>
      </Grid>
    )}
  </Grid>
);
class RowComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      setOpenServicePopup: false,
      serviceData: '',
      editType: '',
      changeImagePopup: { show: false, imageData: [], serviceId: '' },
      viewImages: {}
    };
  }

  onEditService = (service, type) => {
    this.setState({
      serviceData: service,
      setOpenServicePopup: true,
      editType: type
    });
  };
  closeServicePopup = () => {
    this.setState({
      setOpenServicePopup: false
    });
  };

  onDeleteService = async (service) => {
    const willDelete = await swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this Service?',
      icon: 'warning',
      buttons: ['No, cancel it!', 'Yes, I am sure!'],
      dangerMode: true
    });

    if (willDelete) {
      swal('Deleted!', 'Your service has been deleted!', 'success');
      let postData = {
        token: Auth.urlTokenizer(),
        name: 'status',
        serviceId: service.id,
        value: '0'
      };
      let url = config.BASE_API_URL + 'cms/estimate/updateservice';
      let json = await Postie.sendAsynchrnousCall(postData, url);
      console.log('json', json);
      if (json.status === 'success') {
        this.props.callBack();
      }
    }
  };

  handleChangeImage = async (images, serviceId) => {
    this.setState(
      {
        changeImagePopup: {
          show: true,
          imageData: images,
          serviceId: serviceId
        }
      },
      function () {
        console.log('handleChangeImage', this.state.changeImagePopup);
      }
    );
  };

  closeChangeImagePOpup = async () => {
    this.setState({
      changeImagePopup: {
        show: false,
        imageData: [],
        serviceId: ''
      }
    });
  };

  handleSelectImage = async (image) => {
    let postData = {
      token: Auth.urlTokenizer(),
      name: 'image',
      serviceId: this.state.changeImagePopup.serviceId,
      value: image
    };
    let url = config.BASE_API_URL + 'cms/estimate/updateservice';
    let json = await Postie.sendAsynchrnousCall(postData, url);
    console.log('json', json);
    if (json.status === 'success') {
      this.props.callBack();
      this.closeChangeImagePOpup();
    }
  };

  render() {
    const { data} = this.props;
    //console.log('servicesbreakup', data.servicesbreakup);
    //console.log('data', data);
    return (
      <Grid container spacing={2} style={{ margin: '5px' }}>
        <Grid container spacing={2} item xs={12} sm={12} md={12}>
          {data.servicesbreakup &&
            data.servicesbreakup.map((service, index) => (
              <>
                {(service.workcategory?.sow === this.props.scopeFilterData?.value ||
                  this.props.scopeFilterData.value === '') && (
                  <BreakupRow
                    key={index}
                    service={service}
                    estimateId={this.props.estimateId}
                    is_editable={this.props.is_editable}
                    elevationImage={displayer.absolutingImageURl(data.cfg.image)}
                    setEditMode={this.props.setEditMode}
                    onEditService={() => this.onEditService(service, 'service')}
                    onDeleteService={() => this.onDeleteService(service)}
                    handleChangeImage={() =>
                      this.handleChangeImage(this.props.areaImages, service.id)
                    }
                  />
                )}
                <Divider />
              </>
            ))}
          {data.productsbreakup &&
            data.productsbreakup.map(
              (product, index) =>
                (product.workcategory?.sow === this.props.scopeFilterData?.value ||
                  this.props.scopeFilterData.value === '') && (
                  <BreakupRow
                    key={index}
                    service={product}
                    estimateId={this.props.estimateId}
                    is_editable={false}
                    elevationImage={displayer.absolutingImageURl(product.remark)}
                    setEditMode={this.props.setEditMode}
                    onEditService={() => this.onEditService(product, 'product')}
                    onDeleteService={() => this.onDeleteService(product)}
                    handleChangeImage={() =>
                      this.handleChangeImage(this.props.areaImages, product.id)
                    }
                  />
                )
            )}
        </Grid>
        {this.state.setOpenServicePopup && this.props.serviceEstimateData && (
          <EditServiceInBoq
            setOpenPopup={this.state.setOpenServicePopup}
            closePopup={() => this.closeServicePopup()}
            fitoutCategories={this.props.fitoutCategories}
            modularCategories={this.props.modularCategories}
            furnitureCategories={this.props.furnitureCategories}
            projectId={this.props.projectId}
            serviceEstimateData={this.props.serviceEstimateData}
            callBack={() => this.props.callBack()}
            serviceData={this.state.serviceData}
            editType={this.state.editType}
          />
        )}
        {this.state.changeImagePopup && (
          <ChangeServiceImage
            setOpenPopup={this.state.changeImagePopup.show}
            closePopup={() => this.closeChangeImagePOpup()}
            imageData={this.state.changeImagePopup.imageData}
            handleSelectImage={(image) => this.handleSelectImage(image)}
            projectId={data.project_id}
            areaId={data.area_id}
            elevation={data.elevation}
          />
        )}
      </Grid>
    );
  }
}

export default RowComponent;
