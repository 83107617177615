import React, { Component } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Table, TableBody, TableCell, TableRow, TableHead, Tabs, Tab } from '@mui/material';
import { Box, Button, Chip, Divider } from '@mui/material';
import Accordion from '@mui/material/Accordion';

class UnitSummary extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { data, area } = this.props;

    return (
      <TableRow>
        <TableCell style={{ textAlign: 'center' }}>
          <h5>{area}</h5>
        </TableCell>
        <TableCell style={{ textAlign: 'center' }}>
          <text>{data.unit}</text>
        </TableCell>
        <TableCell style={{ textAlign: 'center' }}>
          <text>{data.qty}</text>
        </TableCell>
        <TableCell style={{ textAlign: 'center' }}>
          <text> &#8377;{data.cost}</text>
        </TableCell>
      </TableRow>
    );
  }
}

export default UnitSummary;
