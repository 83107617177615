import React, { Component } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Grid,
  Tabs,
  Tab,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Rating,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { Box, Button, Chip, Divider } from '@mui/material';
import FileView from '../bids/components/fileView';
import displayer from 'app/helpers/displayer';
import config from '../../constants/index.jsx';
import Postie from 'app/helpers/postie';
import Auth from 'app/helpers/authorization';
import UploadFile from '../files/uploadFile';
import ImageBox from './components/ImageBox';
import { json } from 'react-router-dom';

class PresalesInspiration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { images: [] },
      selectedImages: [],
      areaId: '',
      query: '',
      openCommentPopup: false,
      addedRemark: ''
    };
    //console.log('statedata', this.state.data);
  }

  componentDidMount = () => {
    const queryParams = displayer.getUrlParams('/areainspiration/');
    //console.log(queryParams);
    this.setState(
      {
        ...this.state,

        areaId: queryParams[1]
      },
      function () {
        console.log('areaId', this.state.areaId);
      }
    );
    this.getInspirationDetails(queryParams[0], queryParams[1]);
    this.getAreaDetails(queryParams[0], queryParams[1]);
  };

  getAreaDetails = async (id, areaId) => {
    const url =
      config.BASE_API_URL + 'project/areadetails/' + id + '/' + areaId + '/' + Auth.urlTokenizer();
    const response = await Postie.fetchUrl(url);
    if (response) {
      console.log('area api response:', response);
      let selectedSection = {};
      selectedSection.images = [];
      selectedSection.imgdata = [];
      response.section.images.map((img) => {
        selectedSection.images.push(img.image_id);
        selectedSection.imgdata.push({
          id: img.image_id,
          src: img.url,
          thumbnail: img.url,
          rating: img.ratings,
          comment: img.comment,
          caption: selectedSection.description,
          useForDemo: true,
          remarks: img.remarks
        });
      });
      this.setState({
        section: {
          id: response.section.id,
          inspiration_id: response.section.inspiration_id,
          name: response.section.name,
          description: response.section.description,
          show_order: response.section.show_order,
          images: selectedSection.images,
          area_id: response.section.area_id,
          imgdata: selectedSection.imgdata,
          layout_url: response.section.layout_url,
          version: response.section.version
        },
        selectedImages: selectedSection.imgdata
      });
      // } else {
      //   console.log(data, 'data');
      //   swal('Error !!', data.message, data.status);
    }
  };

  getInspirationDetails = async (id, areaId, url = '') => {
    let userLogged = await Auth.isUserLoggedIn();
    let append = true;
    if (userLogged) {
      if (url === '') {
        append = false;
        url = config.BASE_API_URL + 'gallery/paged/' + id + '?aid=' + areaId;
      } else {
        url += '&aid=' + areaId;
      }
      if (this.state.query !== '') {
        url += '&query=' + this.state.query;
      }

      let jsonData = await Postie.fetchUrl(url);
      //console.log('sata', jsonData);
      if (jsonData) {
        if (append) {
          let Images = this.state.data.images;
          jsonData.images.forEach((Img) => Images.push(Img));
          jsonData.images = Images;
        }
        this.setState(
          {
            ...this.state,
            data: jsonData,
            query: jsonData.query !== null ? jsonData.query : '',
            dataLoaded: true // Set dataLoaded to true when data is loaded
          },
          function () {
            console.log('Inspiration Data ', this.state.data, this.state.areaId);
          }
        );
      }
    }
  };
  handleWriteComment = (event, file) => {
    const { name, value } = event.target;
    //console.log('handleWriteComment', value);
    // this.setState(
    //   {
    //     ...this.state,
    //     addedRemark: value
    //   },
    //   function () {
    //     console.log('handleWriteComment', this.state.addedRemark);
    //   }
    // );
    this.handleSubmitRemark(file, name, value);
  };
  handleSubmitRemark = async (file, field, value) => {
    let postData = {
      section_id: this.state.section.id,
      image_id: file.id,
      field: field,
      value: value,
      authToken: localStorage.getItem(config.Token_name)
    };
    let url = config.BASE_API_URL + 'project/updateSectionImage';
    let jsonData = await Postie.sendAsynchrnousCall(postData, url);
    if (jsonData.status === 'success') {
      this.getInspirationDetails(this.state.data.id, this.state.section.area_id);
      this.getAreaDetails(this.state.data.id, this.state.section.area_id);
    } else {
      Postie.showAlert(jsonData);
    }
  };

  handleUploadFile = (file) => {
    let newFile = { id: file.data.id, src: file.data.url };
    this.handleFile(newFile);
  };

  handleFile = async (file) => {
    console.log('file', file);
    let postData = {
      section_id: this.state.section.id,
      inspiration_id: this.state.data.id,
      image_id: file.id,
      url: file.src,
      version: this.state.section.version,
      authToken: localStorage.getItem(config.Token_name)
    };
    let url = config.BASE_API_URL + 'project/addSectionImage';
    let jsonData = await Postie.sendAsynchrnousCall(postData, url);
    if (jsonData.status === 'success') {
      this.getInspirationDetails(this.state.data.id, this.state.section.area_id);
      this.getAreaDetails(this.state.data.id, this.state.section.area_id);
    } else {
      Postie.showAlert(jsonData);
    }
  };
  handleRemoveFile = async (file) => {
    let postData = {
      section_id: this.state.section.id,
      inspiration_id: this.state.data.id,
      image_id: file.id,
      url: file.src,
      version: this.state.section.version,
      authToken: localStorage.getItem(config.Token_name)
    };
    let url = config.BASE_API_URL + 'project/removeSectionImage';
    let jsonData = await Postie.sendAsynchrnousCall(postData, url);
    if (jsonData.status === 'success') {
      this.getInspirationDetails(this.state.data.id, this.state.section.area_id);
      this.getAreaDetails(this.state.data.id, this.state.section.area_id);
    } else {
      Postie.showAlert(jsonData);
    }
  };
  setSearchText(searchbox) {
    let query = this.state.query;
    if (query.length > 0) {
      query += ',' + searchbox.value;
    } else {
      query = searchbox.value;
    }
    this.setState(
      {
        ...this.state,
        query: query
      },
      function () {
        searchbox.value = '';
        this.getInspirationDetails(this.state.data.id, this.state.areaId, '');
      }
    );
  }
  removeFilter(query) {
    let prevQuery = this.state.query;
    let newQuery = [];
    prevQuery.split(',').forEach((pq, index) => {
      if (pq !== query) {
        newQuery.push(pq);
      }
    });
    this.setState(
      {
        ...this.state,
        query: newQuery.join(',')
      },
      function () {
        this.getInspirationDetails(this.state.data.id, this.state.areaId, '');
      }
    );
  }
  showImages(file) {
    let ret = true;
    let selectedImages = this.state.selectedImages;
    selectedImages.forEach((image) => {
      if (image.id === file.id) {
        ret = false;
      }
    });
    return ret;
  }

  render() {
    const { data, selectedImages } = this.state;
    return (
      <Grid>
        <Grid container spacing={2} style={{ margin: '10px 20px' }}>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <h3>
                <Button
                  onClick={() => {
                    Postie.navigateLink('/project/view/' + this.state.data.project_id);
                  }}
                >
                  Project id: {this.state.data.project_id}
                </Button>
              </h3>
            </Grid>
            <Grid item md={3}>
              <h3>Area : {this.state?.section?.name}</h3>
            </Grid>
            <Grid item md={6}>
              <UploadFile
                filename=" Refrence Images"
                displayTosection={false}
                areaName=""
                type="reference"
                color="success"
                variant="contained"
                projectId={data.project_id}
                areaId={this.state.areaId}
                callback={(File) => this.handleUploadFile(File)}
                showAlert={false}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid item md={3}>
            <FormGroup style={{ width: '90%' }}>
              <TextField
                type="text"
                placeholder={`Search ${''}`}
                onBlur={(e) => this.setSearchText(e.target)}
              />
            </FormGroup>
          </Grid>
          <Grid item md={9}>
            {this.state.query &&
              this.state.query
                .split(',')
                .map((query, index) => (
                  <Chip label={query} key={index} onDelete={() => this.removeFilter(query)}></Chip>
                ))}
          </Grid>
          <Grid container spacing={2}>
            <Grid item md="12">
              <h5>Shortlisted Images</h5>
            </Grid>
            {selectedImages?.map((record, index) => (
              <ImageBox
                record={record}
                key={index}
                forClient={false}
                selectedImages={selectedImages}
                handleFile={(file) => this.handleRemoveFile(file)}
                forRemark={true}
                addRemark={(e, file) => this.handleWriteComment(e, file)}
              />
            ))}
          </Grid>
          <Grid container spacing={2}>
            <Grid item md="12">
              <h5>All Images</h5>
            </Grid>

            {data.images?.map(
              (record, index) =>
                this.showImages(record) && (
                  <ImageBox
                    record={record}
                    key={index}
                    forClient={false}
                    selectedImages={selectedImages}
                    handleFile={(file) => this.handleFile(file)}
                  />
                )
            )}
            <Grid item md="12" style={{ textAlign: 'center' }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() =>
                  this.getInspirationDetails(data.id, this.state.areaId, data.nextpage)
                }
              >
                Load more images
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default PresalesInspiration;
