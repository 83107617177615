import React, { Component } from 'react';
import {
  Grid,
  Box,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  Button,
  MenuItem,
  FormGroup,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import displayer from 'app/helpers/displayer';
import config from '../../../constants/index.jsx';
import Auth from 'app/helpers/authorization';
import Postie from 'app/helpers/postie';
import UploadFile from 'app/views/files/uploadFile.jsx';
import FileView from 'app/views/bids/components/fileView.jsx';

class EditModularEstimates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      selectedTab: 0,
      openConnectPopup: false,
      openSowsPopup: ''
    };
  }

  componentDidMount = () => {
    const queryParams = displayer.getUrlParams('/estimate/change/');
    //console.log(queryParams);
    this.getProjectDetails(queryParams[0]);
  };

  getProjectDetails = async (id) => {
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url = config.BASE_API_URL + 'estimate/get/' + id;
      let jsonData = await Postie.fetchUrl(url);
      //console.log('sata', jsonData);
      if (jsonData) {
        this.setState(
          {
            ...this.state,
            data: jsonData,
            dataLoaded: true // Set dataLoaded to true when data is loaded
          },
          function () {
            console.log('Edit Estimate', this.state.data);
          }
        );
      }
    }
  };

  handleTabChange = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };

  openProductPopup = () => {
    this.setState({
      openConnectPopup: true
    });
  };

  closeProductPopup = () => {
    this.setState({
      openConnectPopup: false
    });
  };

  openSowsPopup = () => {
    this.setState({
      openSowsPopup: true
    });
  };
  closeSowsPopup = () => {
    this.setState({
      openSowsPopup: false
    });
  };

  render() {
    const { data, selectedTab } = this.state;
    console.log('Edit Estimate', data);
    return (
      <Grid>
        <h2 style={{ marginLeft: '10px' }}>Project Id:{data.project_id}</h2>
        <Grid container spacing="2" style={{ marginLeft: '10px' }}>
          <Grid item md={6}>
            <h3>Product Name:</h3>
            <FormGroup style={{ width: '90%' }}>
              <TextField
                type="text"
                placeholder="write name"
                //onBlur={(e) => this.setSearchText(e.target)}
              />
            </FormGroup>
          </Grid>
          <Grid item md={6}>
            <h3>Remarks</h3>
            <FormControl variant="outlined" style={{ width: '90%' }}>
              <InputLabel>Choose Work Category:</InputLabel>
              <Select onChange={''} value={''} label="Choose Work Category">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                <MenuItem key={''} value={''}>
                  Ac Fitting
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <h3>Width in mm</h3>
            <FormGroup style={{ width: '90%' }}>
              <TextField
                type="text"
                placeholder="write width"
                //onBlur={(e) => this.setSearchText(e.target)}
              />
            </FormGroup>
          </Grid>
          <Grid item md={6}>
            <h3>Depth in mm</h3>
            <FormGroup style={{ width: '90%' }}>
              <TextField
                type="text"
                placeholder="write depth"
                //onBlur={(e) => this.setSearchText(e.target)}
              />
            </FormGroup>
          </Grid>
          <Grid item md={6}>
            <h3>height in mm</h3>
            <FormGroup style={{ width: '90%' }}>
              <TextField
                type="text"
                placeholder="write height"
                //onBlur={(e) => this.setSearchText(e.target)}
              />
            </FormGroup>
          </Grid>
          <Grid item md={6}>
            <h3>Cost:</h3>
            <FormGroup style={{ width: '90%' }}>
              <TextField
                type="text"
                placeholder="write cost"
                //onBlur={(e) => this.setSearchText(e.target)}
              />
            </FormGroup>
          </Grid>
          <Grid item md={6}>
            <h3>Upload Image:</h3>
            <UploadFile
              filename=" CFg Image"
              type="cfg_image"
              variant="contained"
              projectId={data.project_id}
              areaId={data.areaId}
              callback={(File) => this.handleFile(File)}
              showAlert={false}
              fullWidth
            />
          </Grid>
          <Grid item md={6}>
            <h3>Uploaded Image:</h3>
            <FileView
              key={`nobaseImage`}
              id={''}
              style={{ width: '10px', height: '10px' }}
              url={displayer.absolutingImageURl('')}
            />
          </Grid>
        </Grid>
        <Grid container spacing="2" style={{ margin: '10px' }}>
          <Tabs
            value={selectedTab}
            onChange={this.handleTabChange}
            style={{ color: '#0000FF', padding: '8px' }}
          >
            <Tab label="Products" />
            <Tab label="Sows" />
          </Tabs>
        </Grid>
        {selectedTab === 0 && (
          <Grid>
            <h2 style={{ marginLeft: '10px' }}>Products</h2>
            <Button
              variant="contained"
              color="primary"
              style={{ float: 'right', marginRight: '70px', marginBottom: '20px' }}
              onClick={() => this.openProductPopup()}
            >
              connect Products
            </Button>
            <Grid container spacing="2" style={{ margin: '10px' }}>
              <Grid item md={2}>
                <h3>name</h3>
                <Box>LED PANEL LIGHT 8W </Box>
              </Grid>
              <Grid item md={1}>
                <h3>Avg Cost</h3>
                <Box>50.00</Box>
              </Grid>
              <Grid item md={1}>
                <h3>Width</h3>
                <Box>120mm</Box>
              </Grid>
              <Grid item md={1}>
                <h3>Depth</h3>
                <Box>120</Box>
              </Grid>
              <Grid item md={1}>
                <h3>Height</h3>

                <Box>20</Box>
              </Grid>
              <Grid item md={2}>
                <h3>Image</h3>
                <FileView
                  key={`nobaseImage`}
                  id={''}
                  style={{ width: '10px', height: '10px' }}
                  url={displayer.absolutingImageURl('')}
                />
              </Grid>
              <Grid item md={2}>
                <h3>Qty</h3>
                <FormGroup style={{ width: '90%' }}>
                  <TextField
                    type="text"
                    placeholder="write cost"
                    //onBlur={(e) => this.setSearchText(e.target)}
                  />
                </FormGroup>
              </Grid>
              <Grid item md={2}>
                <h3>Actions</h3>
                <Button variant="contained" color="primary">
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {selectedTab === 1 && (
          <Grid>
            <h2 style={{ marginLeft: '10px' }}>Sows</h2>
            <Button
              variant="contained"
              color="primary"
              style={{ float: 'right', marginRight: '70px', marginBottom: '20px' }}
              onClick={() => this.openSowsPopup()}
            >
              connect Sows
            </Button>
            <Grid container spacing="2" style={{ margin: '10px' }}>
              <Grid item md={2}>
                <h3>Work Category</h3>
                <Box>FALSE CEILING </Box>
              </Grid>
              <Grid item md={1}>
                <h3>Service Name</h3>
                <Box>POP COVE BAND</Box>
              </Grid>
              <Grid item md={1}>
                <h3>UOM</h3>
                <Box> R.Ft.</Box>
              </Grid>
              <Grid item md={1}>
                <h3>Avg Rate</h3>
                <Box>0</Box>
              </Grid>
              <Grid item md={1}>
                <h3>Width in mm</h3>

                <TextField
                  type="number"
                  placeholder="write width"
                  //onBlur={(e) => this.setSearchText(e.target)}
                />
              </Grid>
              <Grid item md={2}>
                <h3>Depth in mm</h3>
                <TextField
                  type="number"
                  placeholder="write depth"
                  //onBlur={(e) => this.setSearchText(e.target)}
                />
              </Grid>
              <Grid item md={2}>
                <h3>Height in mm</h3>
                <FormGroup style={{ width: '90%' }}>
                  <TextField
                    type="text"
                    placeholder="write height"
                    //onBlur={(e) => this.setSearchText(e.target)}
                  />
                </FormGroup>
              </Grid>
              <Grid item md={2}>
                <h3>Actions</h3>
                <Button variant="contained" color="primary">
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Dialog open={this.state.openConnectPopup} onClose={this.closeProductPopup} fullWidth>
          <DialogTitle>Confirmation On Selected Image:</DialogTitle>
          <DialogContent>
            <Grid container spacing="2" style={{ margin: '10px' }}>
              <Grid item md={2}>
                <h3>Work Category</h3>
                <Box>FALSE CEILING </Box>
              </Grid>
              <Grid item md={1}>
                <h3>Service Name</h3>
                <Box>POP COVE BAND</Box>
              </Grid>
              <Grid item md={1}>
                <h3>UOM</h3>
                <Box> R.Ft.</Box>
              </Grid>
              <Grid item md={1}>
                <h3>Avg Rate</h3>
                <Box>0</Box>
              </Grid>
              <Grid item md={1}>
                <h3>Width in mm</h3>

                <TextField
                  type="number"
                  placeholder="write width"
                  //onBlur={(e) => this.setSearchText(e.target)}
                />
              </Grid>
              <Grid item md={2}>
                <h3>Depth in mm</h3>
                <TextField
                  type="number"
                  placeholder="write depth"
                  //onBlur={(e) => this.setSearchText(e.target)}
                />
              </Grid>
              <Grid item md={2}>
                <h3>Height in mm</h3>
                <FormGroup style={{ width: '90%' }}>
                  <TextField
                    type="text"
                    placeholder="write height"
                    //onBlur={(e) => this.setSearchText(e.target)}
                  />
                </FormGroup>
              </Grid>
              <Grid item md={2}>
                <h3>Actions</h3>
                <Button variant="contained" color="primary">
                  Delete
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeProductPopup} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.openSowsPopup} onClose={this.closeSowsPopup} fullWidth>
          <DialogTitle>Confirmation On Selected Image:</DialogTitle>
          <DialogContent>
            <h4>Are you sure you want to approve this image?</h4>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={this.handleConfirmYes}>
              Yes
            </Button>
            <Button onClick={this.closeSowsPopup} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default EditModularEstimates;
