import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';
import { Box, Button, Chip, Divider } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import TeamInfo from '../presales/components/TeamInfo';

const AccordionRoot = styled(Box)(({ theme }) => ({
  width: '100%',
  '& .heading': {
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12)
    }
  },
  '& .secondaryHeading': {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12)
    }
  },
  '& .icon': {
    width: 20,
    height: 20,
    verticalAlign: 'bottom'
  },
  '& .details': { alignItems: 'center' },
  '& .column': { flexBasis: '33.33%' },
  '& .helper': {
    padding: theme.spacing(1, 2),
    borderLeft: `2px solid ${theme.palette.divider}`
  },
  '& .link': {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': { textDecoration: 'underline' }
  }
}));

export default function DetailedExpansionPanel() {
  return (
    <AccordionRoot>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Box className="column ">
                <Typography className="heading">#6379</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box className="column">
                <Typography className="heading">mr. John Doe</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box className="column">
                <Typography className="heading">Ajnara Grand Haritage</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box className="column">
                <Typography className="heading">Briefing Call Done </Typography>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <Divider />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <AccordionDetails className="details">
              <TeamInfo />
            </AccordionDetails>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AccordionDetails className="details">
              <Box className="column">
                <Typography className="heading">Follow up Date</Typography>
                <h3 className="heading">23 Sept 2023</h3>
                <h3 className="heading">Client will come to ec </h3>
              </Box>
            </AccordionDetails>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AccordionDetails className="details">
              <Box className="column">
                <Typography className="heading">
                  Client Budget: <span style={{ float: 'right' }}>Rs.500000</span>
                </Typography>
                <Divider />
                <Typography className="heading">
                  Fitout:<span style={{ float: 'right' }}>Rs.100000</span>
                </Typography>
                <Typography className="heading">
                  Modular: <span style={{ float: 'right' }}>Rs.200000</span>
                </Typography>
                <Typography className="heading">
                  Modular: <span style={{ float: 'right' }}>Rs.200000</span>
                </Typography>
                <Typography className="heading">
                  Furniture: <span style={{ float: 'right' }}>Rs.100000</span>
                </Typography>
                <Divider />
                <Typography className="heading">
                  Total: <span style={{ float: 'right' }}>Rs.500000</span>
                </Typography>
              </Box>
            </AccordionDetails>
          </Grid>
        </Grid>

        <Divider />

        <AccordionActions>
          <Button size="small" variant="outlined">
            View
          </Button>
          <Button size="small" variant="outlined">
            Inactive
          </Button>
        </AccordionActions>
      </Accordion>
    </AccordionRoot>
  );
}
