import React, { Component } from 'react';
import { Card, CardHeader, CardContent, Button, Typography } from '@mui/material';
import DropzoneComponent from 'react-dropzone-component';
import swal from 'sweetalert';
import $ from 'jquery';
import config from '../../constants/index';
import 'react-dropzone-component/styles/filepicker.css'; // Import the CSS for DropzoneComponent
import 'dropzone/dist/min/dropzone.min.css'; // Import the CSS for Dropzone (Dropzone library)

const URLUploader = (props) => (
  <div>
    <textarea
      id="urlBoxId"
      type="textarea"
      className="form-control"
      placeholder="Paste Image Url"
      rows="5"
      onChange={() => props.urlPasted()}
    ></textarea>
    {props.imageStatus === 'processing' ? (
      <span className="mb-2">
        {' '}
        <i className="feather icon-upload animated">Uploading ...</i>
      </span>
    ) : null}
    {props.imageStatus === 'uploaded' ? (
      <span className="m-2 float-right">
        {' '}
        <i className="feather icon-check-circle text-success"></i>
      </span>
    ) : null}
  </div>
);

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: this.props.saveURL,
      showDropzone: this.props.showDropzone ? this.showDropZone : true,
      imageStatus: ''
    };
    this.djsConfig = {
      addRemoveLinks: false
    };
    this.componentConfig = {
      showFiletypeIcon: false,
      postUrl: this.state.url
    };

    this.handleFileAdded = this.handleFileAdded.bind(this);
    this.handleUploaderChange = this.handleUploaderChange.bind(this);
  }

  handleFileAdded(file) {
    let response = JSON.parse(file.xhr.response);
    console.log('response in files upload:', response);
    swal(response.status, response.message, response.status);
  }

  handleUploaderChange(state) {
    this.setState({ showDropzone: state });
  }

  async urlPasted() {
    let url = $('#urlBoxId').val();
    this.setState({ imageStatus: 'uploading' });
    let servicesUrl = this.state.url.replace('upload', 'uploadfromUrl');
    console.log('urlPasted', url, 'postUrl', servicesUrl);
    let postData = {
      url: url,
      authToken: localStorage.getItem(config.Token_name)
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    };
    const resp = await fetch(servicesUrl, requestOptions);
    const json = await resp.json();
    if (json.status === 'success') {
      this.setState({ imageStatus: 'uploaded' });
      $('#urlBoxId').val('');
      console.log('json data:', json.data);
      let file = { xhr: { response: json } };
      this.props.callback(file);
    } else {
      swal('Error', json.message, 'error');
    }
  }

  render() {
    const { showDropzone } = this.state;

    return (
      <Card>
        <CardHeader>
          <Typography variant="h5">File Upload</Typography>
        </CardHeader>
        <CardContent>
          {showDropzone ? (
            <DropzoneComponent
              style={{ width: '100%', height: '100%' }}
              config={this.componentConfig}
              djsConfig={this.djsConfig}
              eventHandlers={{ success: this.props.callback }}
            />
          ) : (
            <URLUploader urlPasted={() => this.urlPasted()} imageStatus={this.state.imageStatus} />
          )}

          <div className="text-center m-1">
            {showDropzone ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => this.handleUploaderChange(false)}
              >
                Upload from URL
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handleUploaderChange(true)}
              >
                Upload from Computer
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default FileUpload;
